import React, { createContext, useState } from 'react'
import type { CheckMarkValue } from '../Table.types'

export interface ITableContext {
	checkMarkValue?: CheckMarkValue
	openGroupNodes?: string[]
	suspendNavigation?: boolean
	setCheckMarkValue?: (value: CheckMarkValue) => void
	setOpenGroupNodes?: (nodes: string[]) => void
	setSuspendNavigation?: (value: boolean) => void
}

export const TableContext = createContext<ITableContext>({})

export const TableContextProvider = (props: { children: JSX.Element }) => {
	const [headerCheckMarkValue, setHeaderCheckMarkValue] = useState<CheckMarkValue>(false)
	const [openGroupNodes, setOpenGroupNodes] = useState<string[]>([])

	return (
		<TableContext.Provider
			value={{
				checkMarkValue: headerCheckMarkValue,
				setCheckMarkValue: setHeaderCheckMarkValue,
				openGroupNodes,
				setOpenGroupNodes,
			}}
		>
			{props.children}
		</TableContext.Provider>
	)
}
