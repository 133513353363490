import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import type { e_TextHTMLVariant } from '../Text'
import { Text } from '../Text'
import { Button } from '../Button'
import { useTranslation } from '../../translation'

const classes = createStyle((theme) => ({
	tabControlItem: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: 0,
		marginBottom: '-1px', // to make sure the border used for focus does not create a gap down to the separator line on the header

		fontSize: theme.typography.body1.fontSize,
		fontWeight: theme.typography.body1.fontWeight,
		cursor: 'pointer',
		color: theme.colors.button.text,
		background: 'none',
		minHeight: '32px',
		userSelect: 'none',
		outline: 0,
		border: `1px solid transparent`,
		'&:not(:disabled):hover': {
			backgroundColor: theme.colors.button.hoveredBackground,
		},
		'&:not(:disabled):active': {
			backgroundColor: theme.colors.button.pressedBackground,
		},
		'&:disabled': {
			cursor: 'default',
			opacity: 0.5,
		},
		'&:focus-visible': {
			border: `1px solid ${theme.colors.body.focusBorder}`,
		},
	},
	headerContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4px 10px 3px',
	},

	content: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	hiddenBoldText: {
		visibility: 'hidden',
		height: 0,
		fontWeight: 600,
	},
	closeButton: {
		marginLeft: '8px',
	},

	selected: {
		color: theme.palette.primary.themePrimary,
		'& $activeIndicator': {
			opacity: 1,
		},
		'&:hover $activeIndicator': {
			width: '100%',
		},
	},
	stretch: { flexGrow: 1, alignItems: 'center' },
	activeIndicator: {
		width: 'calc(100% - 12px)',
		alignSelf: 'center',
		height: 3,
		opacity: 0,
		background: theme.palette.primary.themePrimary,
		transition: `opacity, width ${theme.transitions.duration.shorter} ${theme.transitions.easing.easeOut}`,
	},
}))

interface ITabControlHeaderItem {
	text: string | React.ReactNode
	onActivate: (e: React.MouseEvent) => void
	isActive?: boolean
	disabled?: boolean
	hidden?: boolean
	stretch?: boolean
	variant?: keyof typeof e_TextHTMLVariant
	onClose?: () => void
	dataAttributes?: Record<string, string>
	screenTip?: string
}

export const TabControlHeaderItem = React.forwardRef(
	(props: ITabControlHeaderItem, ref: React.Ref<HTMLButtonElement>) => {
		const { tcvi } = useTranslation()

		if (props.hidden) {
			return null
		}

		return (
			<button
				className={classNames(classes.tabControlItem, {
					[classes.stretch]: props.stretch,
					[classes.selected]: props.isActive,
				})}
				onClick={(e: React.MouseEvent) => {
					props.onActivate(e)
				}}
				disabled={props.disabled}
				ref={ref}
				{...props.dataAttributes}
				title={props.screenTip}
			>
				<span className={classes.headerContent}>
					<span className={classes.content}>
						<Text variant={props.variant} style={{ margin: 0 }}>
							{props.text}
						</Text>
						<span className={classes.hiddenBoldText}>{props.text}</span>
					</span>
					{props.onClose && (
						<Button
							className={classes.closeButton}
							variant="inline"
							size="extraSmall"
							icon="Fluent-ChromeClose"
							onClick={props.onClose}
							screenTip={tcvi('GENERAL:CLOSE')}
						/>
					)}
				</span>

				<span className={classes.activeIndicator} />
			</button>
		)
	}
)

TabControlHeaderItem.displayName = 'TabControlItem'
