import { AxiosError } from 'axios'
import {
	Button,
	Checkbox,
	Dropdown,
	FormControl,
	Modal,
	RadioButtonGroup,
	Text,
	TextInput,
} from '@genusbiz/web-ui/controls'
import { Card } from '@genusbiz/web-ui/surfaces'
import { createStyle } from '@genusbiz/web-ui/theming'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { defaultIcons } from 'src/consts/defaultIcons'
import { e_EnvironmentType, e_EnvironmentTypeName } from 'src/enums/e_EnvironmentType'
import { e_OperatorStorageKeys } from 'src/enums/e_OperatorStorageKeys'
import { e_RuntimeEditorMode } from 'src/enums/e_RuntimeEditorMode'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { IDataPartition, IGService, IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { operatorApi } from 'src/modules/operatorApi'
import { capitalize } from 'src/utils/capitalize'
import { generateGuid } from 'src/utils/generateGuid'
import sortByValue from 'src/utils/sortByValue'

const classes = createStyle({
	container: {
		display: 'flex',
		padding: '12px',
		flexDirection: 'column',
		justifyContent: 'space-around',
		width: '500px',
	},
	cardHeader: {},
	cardBody: {
		padding: '8px',
	},
	detailField: {
		marginTop: '8px',
	},
	servicesContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '12px',
		marginBottom: '12px',
	},
	cardFooter: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '8px',
		marginTop: '12px',
	},
	top: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		justifyContent: 'flex-end',
	},
})

interface IProps {
	isOpen: boolean
	onClose: () => void
	k8sRuntime?: IK8sRuntime
	editorMode: e_RuntimeEditorMode
}

export const RuntimeEditor = (props: IProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	// const allK8sRuntimes = useSelector(operatorFrontendSelectors.selectK)
	const allK8sRuntimes = useSelector(kubernetesSelectors.selectK8sRuntimes)

	const [environmentType, setEnvironmentType] = useState<e_EnvironmentType>(e_EnvironmentType.origin)
	const [runtimeName, setRuntimeName] = useState<string>('')
	const [dataPartitionId, setDataPartitionId] = useState<string>('')
	const [dataPartitions, setDataPartitions] = useState<IDataPartition[]>([])
	const [features, setFeatures] = useState<IGService[]>([])
	const [dataPartitionName, setDataPartitionName] = useState<string>('')
	const [enableSave, setEnableSave] = useState<boolean>(props.editorMode === e_RuntimeEditorMode.edit)
	const username = sessionStorage.getItem(e_OperatorStorageKeys.username) || ''

	const reload = () => {
		dispatch(operatorFrontendActions.fetchK8sRuntimes())
	}

	useEffect(() => {
		if (props.k8sRuntime) {
			setEnvironmentType(props.k8sRuntime.environmentType)
			setRuntimeName(props.k8sRuntime.name)
			setDataPartitionId(props.k8sRuntime.dataPartitionId)
			setFeatures(sortByValue(props.k8sRuntime.services, 'name'))
		} else if (props.editorMode === e_RuntimeEditorMode.create) {
			operatorApi
				.fetchRuntimeFeatures()
				.then((features) => {
					setFeatures(sortByValue(features, 'name'))
				})
				.catch((error) => {
					dispatch(modalManagerActions.showErrorModal(error))
				})
		}
	}, [props.k8sRuntime, props.editorMode])

	useEffect(() => {
		operatorApi
			.fetchDataPartitions()
			.then((partitions: IDataPartition[]) => {
				setDataPartitions(partitions)
				const effectiveDataPartition: IDataPartition | undefined = partitions.find(
					(partition: IDataPartition) => partition.id === props.k8sRuntime?.dataPartitionId
				)
				if (typeof effectiveDataPartition !== 'undefined') {
					setDataPartitionName(effectiveDataPartition.name)
				}
			})
			.catch((error: Error | AxiosError) => {
				dispatch(modalManagerActions.showErrorModal(error))
			})
	}, [])

	useEffect(() => {
		if (props.editorMode === e_RuntimeEditorMode.create) {
			if (!runtimeName || !dataPartitionId) {
				setEnableSave(false)
			} else if (allK8sRuntimes.some((runtime) => runtime.name === runtimeName)) {
				setEnableSave(false)
			} else {
				setEnableSave(true)
			}
		}
	}, [runtimeName, dataPartitionId])

	const saveRuntime = () => {
		if (props.editorMode === e_RuntimeEditorMode.create) {
			const newK8sRuntime: IK8sRuntime = {
				id: generateGuid(),
				name: runtimeName,
				environmentType: environmentType,
				currentPublication: null,
				dataPartitionId: dataPartitionId,
				services: features,
				changeSeqNo: 0,
				// NOTE! modifiedBy and createdBy should be set to specific
				// user when Entra ID is implemented in Operator.
				modifiedBy: username,
				createdBy: username,
			}

			operatorApi
				.createOperatorK8sRuntime(newK8sRuntime)
				.then(() => {
					dispatch(
						modalManagerActions.showSnackbar({
							message: `${t('RUNTIMES:CREATE_SUCCESSFUL')}: '${newK8sRuntime.name}'`,
						})
					)
					reload()
				})
				.catch((error) => {
					dispatch(modalManagerActions.showErrorModal(error))
				})
		} else if (props.editorMode === e_RuntimeEditorMode.edit && props.k8sRuntime) {
			const updatedK8sRuntime: IK8sRuntime = {
				...props.k8sRuntime,
				services: features,
				modifiedBy: username,
			}

			operatorApi
				.updateOperatorK8sRuntime(updatedK8sRuntime)
				.then(() => {
					dispatch(
						modalManagerActions.showSnackbar({
							message: `${t('RUNTIMES:UPDATE_SUCCESSFUL')}: '${updatedK8sRuntime.name}'`,
						})
					)
					reload()
				})
				.catch((error) => {
					dispatch(modalManagerActions.showErrorModal(error))
				})
		} else {
			dispatch(
				modalManagerActions.showSnackbar({
					message: 'Unknown error occurred.',
					icon: defaultIcons.error,
				})
			)
		}

		props.onClose()
	}
	return (
		<Modal isOpen={props.isOpen}>
			<Card className={classes.container}>
				{props.editorMode === e_RuntimeEditorMode.create && (
					<>
						<Text variant="h3">{t('RUNTIMES:NEW_RUNTIME')}</Text>
						<TextInput
							label={t('GENERAL:NAME') + ':'}
							className={classes.detailField}
							value={runtimeName}
							onChange={setRuntimeName}
						/>
						<Dropdown
							label={t('RUNTIMES:DATA_PARTITION') + ':'}
							className={classes.detailField}
							value={dataPartitionId}
							options={dataPartitions.map((partition) => {
								return { label: partition.name, value: partition.id }
							})}
							onChange={(value) => {
								setDataPartitionId(value)
							}}
						/>
						<RadioButtonGroup
							label={t('RUNTIMES:ENVIRONMENT') + ':'}
							className={classes.detailField}
							value={environmentType}
							orientation="horizontal"
							onChange={(value) => {
								setEnvironmentType(value)
							}}
							options={Object.keys(e_EnvironmentTypeName)
								.filter((type) => (type as e_EnvironmentTypeName) !== e_EnvironmentTypeName.operator)
								.map((type) => {
									return {
										label: capitalize(type),
										value: e_EnvironmentType[type as keyof typeof e_EnvironmentType],
									}
								})}
						/>
					</>
				)}
				{props.editorMode === e_RuntimeEditorMode.edit && (
					<>
						<Text variant="h3">{t('RUNTIMES:EDIT_RUNTIME')}</Text>
						<div className={classes.detailField}>
							<FormControl disableBorder labelPosition="top" label={t('GENERAL:NAME') + ':'}>
								{runtimeName}
							</FormControl>
						</div>
						<div className={classes.detailField}>
							<FormControl disableBorder labelPosition="top" label={t('RUNTIMES:DATA_PARTITION') + ':'}>
								{dataPartitionName}
							</FormControl>
						</div>
						<div className={classes.detailField}>
							<FormControl disableBorder labelPosition="top" label={t('RUNTIMES:ENVIRONMENT') + ':'}>
								{capitalize(e_EnvironmentType[environmentType])}
							</FormControl>
						</div>
					</>
				)}
				<div className={classes.servicesContainer}>
					<Text variant="h6">{t('RUNTIMES:FEATURES')}</Text>
					<div style={{ marginLeft: '4px' }}>
						{features.map((service) => {
							return (
								<Checkbox
									valueLabel={t('FEATURES:' + service.name.toUpperCase())}
									key={service.name}
									checked={service.enabled}
									onChange={(checked) => {
										setFeatures(
											features.map((svc) => {
												if (svc.name === service.name) {
													return { ...svc, enabled: checked }
												} else {
													return svc
												}
											})
										)
									}}
								/>
							)
						})}
					</div>
				</div>
				<div className={classes.cardFooter}>
					<div className={classes.buttonGroup}>
						<Button
							label={t('GENERAL:SAVE')}
							dataAttributes={{ 'data-cy': 'runtime-save-button' }}
							onClick={saveRuntime}
							variant="primary"
							disabled={!enableSave}
						/>
						<Button
							label={t('GENERAL:CANCEL')}
							dataAttributes={{ 'data-cy': 'runtime-cancel-button' }}
							onClick={props.onClose}
							style={{ marginLeft: '8px' }}
						/>
					</div>
				</div>
			</Card>
		</Modal>
	)
}
