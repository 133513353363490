import tinycolor from 'tinycolor2'
import type { RGB, HSV, HSL, HEX } from './colorFormats.types'
import { namedCSSColors } from './namedCSSColors'

export function invertHexColor(color: HEX): HEX {
	if (color.startsWith('#')) {
		color = color.substring(1)
	}

	const hex = parseInt(color, 16)
	const invertedHex = (hex & 0xff000000) | (hex & 0x00ffffff)
	return '#' + Number(invertedHex).toString(16)
}

export function isNamedCSSColor(name: string): name is keyof typeof namedCSSColors {
	return name in namedCSSColors
}

export function namedCSSColor2Hex(name: string): HEX {
	if (!isNamedCSSColor(name)) {
		return '#000000'
	}

	return namedCSSColors[name]
}

const rgbStringRegex = /rgba?\(\s?(\d+),\s?(\d+),\s?(\d+)/

export function isRgbString(rgb: string) {
	return !!rgbStringRegex.exec(rgb)
}

export function rgbString2Rgb(rgb: string): RGB {
	const match = rgbStringRegex.exec(rgb)
	if (!match) {
		return {
			r: 0,
			g: 0,
			b: 0,
		}
	}

	return {
		r: parseInt(match[1]),
		g: parseInt(match[2]),
		b: parseInt(match[3]),
	}
}

export function isHexString(hex: string) {
	return !!/#[a-fA-F0-9]{3,6}/.exec(hex)
}

export function hex2Rgb(hex: HEX) {
	if (hex.startsWith('#')) {
		hex = hex.substring(1)
	}

	// If shorthand hex, convert to full-length
	if (hex.length === 3) {
		hex = hex + hex
	}

	const rgb: RGB = {
		r: clamp(parseInt(hex.substring(0, 2), 16), 0, 255),
		g: clamp(parseInt(hex.substring(2, 4), 16), 0, 255),
		b: clamp(parseInt(hex.substring(4, 6), 16), 0, 255),
	}
	return rgb
}

export function hex2RgbString(hex: string) {
	const rgb = hex2Rgb(hex)
	return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`
}

export function rgb2Hex(rgb: RGB, leadingHash = true) {
	const r = fillZeros(Math.round(rgb.r).toString(16), 2, true)
	const g = fillZeros(Math.round(rgb.g).toString(16), 2, true)
	const b = fillZeros(Math.round(rgb.b).toString(16), 2, true)

	return (leadingHash ? '#' : '') + r + g + b
}

export function getBestContrastColor(color: string, contrastColors: string[]) {
	return tinycolor.mostReadable(color, contrastColors).toHexString()
}

// Adapted from https://bgrins.github.io/TinyColor/docs/tinycolor.html#section-16
export function rgb2Hsv(rgb: RGB) {
	const r = bound(rgb.r, 255)
	const g = bound(rgb.g, 255)
	const b = bound(rgb.b, 255)

	const max = Math.max(r, g, b)
	const min = Math.min(r, g, b)
	const d = max - min

	let h: number
	const s = max === 0 ? 0 : d / max
	const v = max

	if (max === min) {
		h = 0
	} else {
		if (max === r) {
			h = (g - b) / d + (g < b ? 6 : 0)
		} else if (max === g) {
			h = (b - r) / d + 2
		} else {
			h = (r - g) / d + 4
		}
		h /= 6
	}

	return { h: h * 360, s: s, v: v } as HSV
}

// Adapted from https://bgrins.github.io/TinyColor/docs/tinycolor.html#section-15
export function hsv2Rgb(hsv: HSV) {
	const h = bound(hsv.h, 360) * 6
	const s = hsv.s
	const v = hsv.v

	const i = Math.floor(h)

	const f = h - i
	const p = v * (1 - s)
	const q = v * (1 - f * s)
	const t = v * (1 - (1 - f) * s)

	const mod = i % 6
	const r = [v, q, p, p, t, v][mod]
	const g = [t, v, v, q, p, p][mod]
	const b = [p, p, t, v, v, q][mod]

	return {
		r: Math.round(r * 255),
		g: Math.round(g * 255),
		b: Math.round(b * 255),
	} as RGB
}

// Adapted from https://bgrins.github.io/TinyColor/docs/tinycolor.html#section-13
export function rgb2Hsl(rgb: RGB) {
	const r = bound(rgb.r, 255)
	const g = bound(rgb.g, 255)
	const b = bound(rgb.b, 255)

	const max = Math.max(r, g, b)
	const min = Math.min(r, g, b)

	let h: number
	let s: number
	const l = (max + min) / 2

	if (max === min) {
		h = s = 0
	} else {
		const d = max - min
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
		if (max === r) {
			h = (g - b) / d + (g < b ? 6 : 0)
		}
		if (max === g) {
			h = (b - r) / d + 2
		} else {
			h = (r - g) / d + 4
		}
		h /= 6
	}

	return { h: h, s: s, l: l } as HSL
}

// Adapted from https://bgrins.github.io/TinyColor/docs/tinycolor.html#section-14
export function hsl2Rgb(hsl: HSL) {
	const h = hsl.h
	const s = hsl.s
	const l = hsl.l

	const hue2Rgb = (p: number, q: number, t: number) => {
		if (t < 0) {
			t += 1
		}
		if (t > 1) {
			t -= 1
		}
		if (t < 1 / 6) {
			return p + (q - p) * 6 * t
		}
		if (t < 1 / 2) {
			return q
		}
		if (t < 2 / 3) {
			return p + (q - p) * (2 / 3 - t) * 6
		}

		return p
	}

	let r: number
	let g: number
	let b: number

	if (s === 0) {
		r = g = b = l
	} else {
		const q = l < 0.5 ? l * (1 + s) : l + s - l * s
		const p = 2 * l - q

		r = hue2Rgb(p, q, h + 1 / 3)
		g = hue2Rgb(p, q, h)
		b = hue2Rgb(p, q, h - 1 / 3)
	}

	return {
		r: Math.round(r * 255),
		g: Math.round(g * 255),
		b: Math.round(b * 255),
	} as RGB
}

export function hex2Hsv(hex: HEX) {
	return rgb2Hsv(hex2Rgb(hex))
}

export function hsv2Hex(hsv: HSV) {
	return rgb2Hex(hsv2Rgb(hsv))
}

export function hex2Hsl(hex: HEX) {
	return rgb2Hsl(hex2Rgb(hex))
}

export function hsl2Hex(hsl: HSL) {
	return rgb2Hex(hsl2Rgb(hsl))
}

export function clamp(n: number, min = 0, max = 100) {
	return Math.min(max, Math.max(min, n))
}

export function bound(n: number, max: number) {
	n = Math.min(max, Math.max(0, parseFloat(n.toString()))) // TODO added .toString to avoid TS error -> runtime error?

	if (Math.abs(n - max) < 0.000001) {
		return 1
	}

	return n / parseFloat(max.toString())
}

export function fillZeros(string: string, n: number, front = false) {
	if (string.length >= n) {
		return string
	}

	const zeros = '0'.repeat(n - string.length)

	if (front) {
		return zeros + string
	}

	return string + zeros
}

/**
 * https://www.w3.org/TR/WCAG22/#dfn-contrast-ratio
 *
 * @param colorA hex color (#FFFFFF)
 * @param colorB hex color (#FFFFFF)
 * @returns contrast ratio between luminances
 */
export function getColorContrastRatio(colorA: string, colorB: string) {
	const l1 = getColorimetricRelativeLuminance(colorA)
	const l2 = getColorimetricRelativeLuminance(colorB)
	if (l1 >= l2) {
		return (l1 + 0.05) / (l2 + 0.05)
	} else {
		return (l2 + 0.05) / (l1 + 0.05)
	}
}

/**
 * @param color hex color
 * @returns r, g and b values normalized to values between 0 and 1
 */
function getNormalizedRGBValuesFromHex(color: HEX) {
	const rgb = hex2Rgb(color)
	return {
		r: rgb.r / 255,
		g: rgb.g / 255,
		b: rgb.b / 255,
	}
}

function createGetRelativeLuminance(
	rConst = 0.2126,
	gConst = 0.7152,
	bConst = 0.0722,
	colorComponentPreprocessor = getLinearIntensityValue
) {
	return function (color: HEX) {
		const { r, g, b } = getNormalizedRGBValuesFromHex(color)

		const rLinear = colorComponentPreprocessor(r)
		const gLinear = colorComponentPreprocessor(g)
		const bLinear = colorComponentPreprocessor(b)

		return rConst * rLinear + gConst * gLinear + bConst * bLinear
	}
}

/**
 * @param color Hex color written as #FFFFFF
 * @returns relative luminance of the color: Y-linear
 */
export const getColorimetricRelativeLuminance = createGetRelativeLuminance()

const identity = (v: number) => v

const getRec601Luminance = createGetRelativeLuminance(0.299, 0.587, 0.114, identity)

const e_LuminanceConversion = {
	colorimetric: 'colorimetric',
	rec601: 'rec601',
} as const

type e_LuminanceConversion = (typeof e_LuminanceConversion)[keyof typeof e_LuminanceConversion]

function getRelativeLuminanceFactory(type: e_LuminanceConversion): (color: HEX) => number {
	switch (type) {
		case e_LuminanceConversion.colorimetric:
			return getColorimetricRelativeLuminance
		case e_LuminanceConversion.rec601:
			return getRec601Luminance
	}
}

const EPS = 0.055

/**
 * Gamma expands an R, G or B value.
 * @param cSRGB Normalized R, G or B value
 * @returns gamma expanded linear intensity value, C-linear
 */
function getLinearIntensityValue(cSRGB: number) {
	if (cSRGB <= 0.04045) {
		return cSRGB / 12.92
	} else {
		return ((cSRGB + EPS) / (1 + EPS)) ** 2.4
	}
}

/**
 * Used as part of grayscaling a color
 *
 * @param yLinear relative luminance
 * @returns
 */
function gammaCompressLuminance(yLinear: number) {
	if (yLinear <= 0.0031308) {
		return 12.92 * yLinear
	} else {
		return (1 + EPS) * yLinear ** (1 / 2.4) - EPS
	}
}

export interface IGetGrayscaleOptions {
	/**
	 * The type of conversion
	 */
	conversionType?: e_LuminanceConversion
	/**
	 *
	 * @param yLinear luminance value between 0 and 1
	 * @returns value with shift
	 */
	shift?: (yLinear: number) => number
}

/**
 * Transforms a color to grayscale using colorimetric (perceptual luminance-preserving) conversion
 *
 * @param color hex color
 * @returns a grayscale value
 */
function getGrayscaleValue(color: HEX, options?: IGetGrayscaleOptions) {
	const { conversionType = e_LuminanceConversion.colorimetric, shift = identity } = options ?? {}
	const getRelativeLuminance = getRelativeLuminanceFactory(conversionType)
	const yLinear = shift(getRelativeLuminance(color))
	return gammaCompressLuminance(yLinear) * 255
}

/**
 * Transforms a color to grayscale using colorimetric (perceptual luminance-preserving) conversion
 *
 * @param color hex color
 * @returns a grayscale value
 */
export function getGrayscale(color: HEX, options?: IGetGrayscaleOptions) {
	const v = getGrayscaleValue(color, options)
	return '#' + Math.round(v).toString(16).repeat(3)
}
