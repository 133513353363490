import type { SemanticColors } from '../semanticMapping'
import { cssVar } from '../../theming/themingManager'

export const generateChipColorsFromSemanticPalette = (semanticPalette: SemanticColors) => {
	return {
		colors: {
			background: cssVar(semanticPalette.button, 'disabledBackground'),
			color: cssVar(semanticPalette.links, 'text'),
			border: cssVar(semanticPalette.button, 'border'),
		},
		hoverColors: {
			background: cssVar(semanticPalette.button, 'hoveredBackground'),
			color: cssVar(semanticPalette.links, 'text'),
			border: cssVar(semanticPalette.button, 'border'),
		},
		focusedColors: {
			background: cssVar(semanticPalette.button, 'disabledBackground'),
			color: cssVar(semanticPalette.primaryButton, 'hoveredText'),
			border: cssVar(semanticPalette.button, 'focusBorder'),
			outline: cssVar(semanticPalette.button, 'outline'),
		},
		pressedColors: {
			background: cssVar(semanticPalette.button, 'pressedBackground'),
			color: cssVar(semanticPalette.button, 'pressedText'),
			border: cssVar(semanticPalette.primaryButton, 'border'),
		},
		checkedColors: {
			background: cssVar(semanticPalette.primaryButton, 'background'),
			color: cssVar(semanticPalette.primaryButton, 'text'),
			border: cssVar(semanticPalette.primaryButton, 'border'),
		},
		checkedHoverColors: {
			background: cssVar(semanticPalette.primaryButton, 'hoveredBackground'),
			color: cssVar(semanticPalette.primaryButton, 'hoveredText'),
			border: cssVar(semanticPalette.primaryButton, 'border'),
		},
		disabledColors: {
			background: cssVar(semanticPalette.primaryButton, 'disabledBackground'),
			color: cssVar(semanticPalette.primaryButton, 'disabledText'),
			border: undefined, //cssVar(semanticPalette.primaryButton, 'disabledBorder'),
		},
		disabledCheckedColors: {
			background: cssVar(semanticPalette.primaryButton, 'disabledBackground'),
			color: cssVar(semanticPalette.primaryButton, 'disabledText'),
			border: undefined, //cssVar(semanticPalette.primaryButton, 'disabledBorder'),
		},
	}
}
