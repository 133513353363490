import React from 'react'
import { createStyle } from '../../theming'
import type { LocaleUtils } from 'react-day-picker'
import { DatePickerType } from './DatePicker.types'
import { useTranslation } from '../../translation'
import { DatePickerButton } from './DatePickerButton'

export interface IYearPickerProps {
	tabIndex: number
	date: Date
	setPickerType: (picker: DatePickerType) => void
	setStartYear: (year: number) => void
	startYear: number
	pickerType: DatePickerType
	onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
	locale: string
	localeUtils: LocaleUtils
	onChange: (date: Date) => void
}

const classes = createStyle({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	year: {
		width: 100,
	},
	pickerButton: { fontWeight: 'bold' },
	buttons: {
		display: 'flex',
	},
	button: { border: 'none' },
})

export const DatePickerHeader = (props: IYearPickerProps) => {
	const { tcvvi } = useTranslation()

	const arrowScreenTip: { up?: string; down?: string } = { up: undefined, down: undefined }

	let arrowUp = () => {
		return
	}
	let arrowDown = () => {
		return
	}

	const updatePickerType = (pickerType: DatePickerType) => {
		if (pickerType === props.pickerType) {
			props.setPickerType(DatePickerType.dayPicker)
		} else {
			props.setPickerType(pickerType)
		}
	}

	switch (props.pickerType) {
		case DatePickerType.monthPicker:
			arrowUp = () => {
				props.onChange(new Date(props.date.getFullYear() - 1, 0))
			}
			arrowDown = () => {
				props.onChange(new Date(props.date.getFullYear() + 1, 0))
			}
			arrowScreenTip.up = tcvvi('GENERAL:PREVIOUS_YEAR')
			arrowScreenTip.down = tcvvi('GENERAL:NEXT_YEAR')

			break
		case DatePickerType.yearPicker:
			arrowUp = () => {
				props.setStartYear(props.startYear - 10)
				props.onChange(new Date(props.startYear - 10, props.date.getMonth()))
			}
			arrowDown = () => {
				props.setStartYear(props.startYear + 10)
				props.onChange(new Date(props.startYear + 10, props.date.getMonth()))
			}
			arrowScreenTip.up = tcvvi('GENERAL:PREVIOUS_DECADE')
			arrowScreenTip.down = tcvvi('GENERAL:NEXT_DECADE')

			break

		default:
			arrowUp = () => {
				props.onChange(new Date(props.date.getFullYear(), props.date.getMonth() - 1))
			}
			arrowDown = () => {
				props.onChange(new Date(props.date.getFullYear(), props.date.getMonth() + 1))
			}
			arrowScreenTip.up = tcvvi('GENERAL:PREVIOUS_MONTH')
			arrowScreenTip.down = tcvvi('GENERAL:NEXT_MONTH')

			break
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		// Stop arrow events on datepicker
		if (e.key === 'ArrowUp' || e.key === 'ArrowRight' || e.key === 'ArrowDown' || e.key === 'ArrowLeft') {
			e.stopPropagation()
		}
	}

	const nextDatePickerType =
		props.pickerType === DatePickerType.dayPicker
			? DatePickerType.monthPicker
			: props.pickerType === DatePickerType.monthPicker
			  ? DatePickerType.yearPicker
			  : DatePickerType.monthPicker
	const datepickerLabel =
		props.pickerType === DatePickerType.dayPicker
			? `${props.localeUtils.getMonths(props.locale)[props.date.getMonth()].slice(0, 3)} ${props.date
					.getFullYear()
					.toString()}`
			: props.pickerType === DatePickerType.monthPicker
			  ? props.date.getFullYear().toString()
			  : `${props.startYear.toString()} - ${(props.startYear + 10).toString()}`
	const datepickerScreenTip =
		nextDatePickerType === DatePickerType.yearPicker
			? tcvvi('GENERAL:OPEN_YEAR_PICKER')
			: tcvvi('GENERAL:OPEN_MONTH_PICKER')

	return (
		<div className={classes.container}>
			<div className={classes.buttons}>
				<DatePickerButton
					label={datepickerLabel}
					onClick={() => updatePickerType(nextDatePickerType)}
					className={classes.pickerButton}
					screenTip={datepickerScreenTip}
					dataAttributes={{ ['data-pickertype']: 'true' }}
				/>
			</div>
			<div className={classes.buttons}>
				<div onKeyDown={onKeyDown}>
					<DatePickerButton icon="Fluent-ChevronUp" onClick={arrowUp} screenTip={arrowScreenTip.up} />
				</div>
				<div onKeyDown={onKeyDown}>
					<DatePickerButton icon="Fluent-ChevronDown" onClick={arrowDown} screenTip={arrowScreenTip.down} />
				</div>
			</div>
		</div>
	)
}
