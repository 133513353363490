import type { MouseEvent } from 'react'
import React, { useCallback, useContext, useEffect } from 'react'
import { SelectionCheckMark } from '.'
import { TableContext } from '../providers/TableContextProvider'
import type { GridApi, ColumnApi, IRowNode } from '@ag-grid-community/core'
import type { TData } from '../Table.types'
import { refreshCheckMarkColumn } from '../utils/selectionUtils'

interface IProps {
	api: GridApi<TData>
	columnApi: ColumnApi
	eGridHeader: HTMLElement
	node?: IRowNode<TData>
	inPreviewMode?: boolean
}

/**
 * We don't want to render this at all if we don't use multiselect.
 * We should therefore assume that multiSelect is true when we are in the context of this component.
 */
export const HeaderCheckMark = (props: IProps) => {
	const { checkMarkValue, setCheckMarkValue } = useContext(TableContext)

	const isMultiSelectColumn = true

	const onCheckboxColumnClicked = useCallback(
		(e: MouseEvent | KeyboardEvent) => {
			e.stopPropagation()
			e.preventDefault()

			switch (checkMarkValue) {
				case true:
					props.api.deselectAllFiltered()
					setCheckMarkValue?.(false)
					break
				case false:
				case 'mixed':
					props.api.selectAllFiltered()
					setCheckMarkValue?.(true)
					break
			}
			refreshCheckMarkColumn(props.api, props.columnApi)
		},
		[checkMarkValue, props.api, props.columnApi, setCheckMarkValue]
	)

	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			if (e.key === ' ') {
				isMultiSelectColumn && onCheckboxColumnClicked(e)
			}
		}
		props.eGridHeader.addEventListener('keydown', onKeyDown)
		return () => {
			props.eGridHeader.removeEventListener('keydown', onKeyDown)
		}
	}, [isMultiSelectColumn, onCheckboxColumnClicked, props.eGridHeader])

	return (
		<SelectionCheckMark
			node={props.node}
			value={checkMarkValue ?? props.inPreviewMode}
			onClick={onCheckboxColumnClicked}
		/>
	)
}
