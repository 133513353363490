import className from 'clsx'
import React from 'react'
import { createStyle } from '../../theming'

const classes = createStyle({
	value: {
		marginLeft: '0.2rem',
		whiteSpace: 'nowrap',
		lineHeight: 'normal',
	},
	wrap: {
		textAlign: 'left',
		whiteSpace: 'pre-wrap',
		overflowWrap: 'break-word',
	},
	disabled: {
		opacity: 0.5,
	},
})

interface IProps {
	value: string
	wordWrap?: boolean
	disabled?: boolean
}

export const ValueLabel = (props: IProps) => {
	return (
		<span className={className(classes.value, { [classes.wrap]: props.wordWrap, [classes.disabled]: props.disabled })}>
			{props.value}
		</span>
	)
}
