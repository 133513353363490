import type { MouseEvent, MutableRefObject } from 'react'

import type { TData } from '../Table.types'
import { e_RowSelectionType } from '../Table.types'
import type { ColumnApi, GridApi, IRowNode } from '@ag-grid-community/core'

const handleShiftSelection = (
	indexClicked: number | null,
	indexPreviouslyClicked: IRowNode<TData>,
	nodeIsSelected: boolean,
	api: GridApi<TData>
) => {
	let index = indexClicked ?? 0
	const previousIndex = indexPreviouslyClicked.rowIndex ?? 0
	if (index === 0 && previousIndex === 0) {
		api.getDisplayedRowAtIndex(index)?.setSelected(!nodeIsSelected)
	} else {
		const addition = previousIndex - index > 0 ? true : false
		let notDone = addition ? index < previousIndex : index > previousIndex
		while (notDone) {
			const currentNode = api.getDisplayedRowAtIndex(index)
			currentNode?.setSelected(!nodeIsSelected)
			index = addition ? index + 1 : index - 1
			notDone = addition ? index < previousIndex : index > previousIndex
		}
	}
}

export const createSetSelectionMouse =
	(
		previouslyClickedRow: MutableRefObject<IRowNode<TData> | undefined>,
		selectionType: e_RowSelectionType,
		toggleSelectionOnRowClick: boolean
	) =>
	(e: MouseEvent, node: IRowNode<TData>, rowIndex: number, api: GridApi<TData>, columnApi: ColumnApi) => {
		if (selectionType === e_RowSelectionType.none) {
			return
		}

		const shift = e.shiftKey
		const ctrl = e.ctrlKey || e.metaKey
		const nodeIsSelected = node.isSelected() ?? false

		if (shift && !ctrl && selectionType === e_RowSelectionType.multi) {
			if (previouslyClickedRow.current === undefined) {
				node.setSelected(!nodeIsSelected)
			} else {
				handleShiftSelection(rowIndex, previouslyClickedRow.current, nodeIsSelected, api)
			}
		} else if (ctrl || toggleSelectionOnRowClick) {
			node.setSelected(!nodeIsSelected)
		} else {
			node.setSelected(true, true)
		}

		previouslyClickedRow.current = node
		refreshCheckMarkColumn(api, columnApi)
	}

export const refreshCheckMarkColumn = (gridApi: GridApi, columnApi: ColumnApi) => {
	const firstColumn = columnApi.getAllDisplayedColumns()[0]
	const columns = [firstColumn]

	if (firstColumn.getColId() === 'ag-Grid-AutoColumn') {
		columns.push(columnApi.getAllDisplayedColumns()[1])
	}

	gridApi.refreshCells({ columns, suppressFlash: true, force: true })
}

export const selectFirstCell = (gridApi: GridApi | undefined) => {
	let nodeSelected = false
	gridApi?.forEachNodeAfterFilterAndSort((node) => {
		if (!nodeSelected && !node.group) {
			node.setSelected(true)
			nodeSelected = true
		}
	})
}
