import { e_DataType } from '../enums/e_DataType'
import { e_Interpretation } from '../enums/e_Interpretation'
import { makeComparator } from './makeComparator'
import type {
	Column,
	GetQuickFilterTextParams,
	ITooltipParams,
	KeyCreatorParams,
	IRowNode,
	SuppressKeyboardEventParams,
	ValueFormatterParams,
} from '@ag-grid-community/core'
import type { TData, CellData, Value } from '../Table.types'

// Function to return one of three Simple Filters for filtering strings, numbers and dates, provided by the grid.
export const getFilter = (dataType: e_DataType | undefined, interpretation: e_Interpretation | undefined) => {
	if (interpretation === e_Interpretation.domainReference) {
		return 'agTextColumnFilter'
	}

	switch (dataType) {
		case e_DataType.date:
		case e_DataType.dateTime:
			return 'agDateColumnFilter'
		case e_DataType.int:
		case e_DataType.float:
			return 'agNumberColumnFilter'
		default:
			return 'agTextColumnFilter'
	}
}

// Function to return a string key for a value.
export const keyCreator = (params: KeyCreatorParams<TData, CellData>) => {
	if (params.value?.value === null || params.value?.value === undefined || params.value?.value === '') {
		return '---'
	}

	return params.value?.value.toString()
}

// Override the default sorting order by providing a custom sort comparator.
export const getComparator = (dataType = e_DataType.string, reverse = false) => {
	const comparator = makeComparator(dataType)

	return (
		valueA: CellData | Date | string,
		valueB: CellData | Date | string,
		nodeA: IRowNode<TData> | undefined,
		nodeB: IRowNode<TData> | undefined
	) => {
		const a = getValue(valueA, nodeA, dataType)
		const b = getValue(valueB, nodeB, dataType)

		return reverse ? comparator(b, a) : comparator(a, b)
	}
}

const getValue = (value: CellData | Date | string, node: IRowNode<TData> | undefined, dataType: e_DataType) => {
	if (node?.group) {
		return node.key
	}
	if (typeof value === 'object' && 'sortValue' in value && value.sortValue !== undefined) {
		return value.sortValue
	}
	if (typeof value === 'string') {
		return value
	}
	if (value instanceof Date) {
		return value
	}
	if (
		dataType === e_DataType.int ||
		dataType === e_DataType.float ||
		dataType === e_DataType.date ||
		dataType === e_DataType.dateTime
	) {
		return value?.value
	}
	return value?.formattedValue ?? value?.value
}

type FormatterTValue = CellData | { value: Value; values: Value[] } | Value
type FormatterParams = ValueFormatterParams<TData, FormatterTValue>

const applyFormatting = (value: Value, params: FormatterParams) => {
	if (params.colDef.cellRendererParams?.formatCellValue) {
		return params.colDef.cellRendererParams.formatCellValue(value)
	}
	return value
}

const getValueFromFormatterTValue = (value: FormatterTValue) => {
	if (typeof value === 'object') {
		return value?.value
	}
	return value
}

const formatValueWithCallback = (value: FormatterTValue, params: FormatterParams) => {
	return applyFormatting(getValueFromFormatterTValue(value), params)
}

// A function or expression to format a value, should return a string.
export const getValueFormatter =
	(isCheckMarkControl: boolean, hasSummaryFunction: boolean, tcvi: (t: string) => string) =>
	(params: FormatterParams) => {
		if (params.node?.group) {
			return formatValueWithCallback(params.value, params)
		}
		if (params.node?.footer) {
			if (!hasSummaryFunction) {
				return ''
			}
			return formatValueWithCallback(params.value, params)
		}

		if (isCheckMarkControl) {
			return getValueFromFormatterTValue(params.value) ? tcvi('GENERAL:YES') : tcvi('GENERAL:NO')
		}

		const cellDataValue = params.value as CellData
		return getFormattedValue(cellDataValue).toString()
	}

export const getFormattedValue = (value: CellData) => {
	return value?.formattedValue ?? value?.value ?? ''
}

export const getTooltipValueGetter =
	(
		isAvatarCell: boolean,
		isCheckmarkCell: boolean,
		getTooltip: ((nodeId: string | undefined, colId: string | undefined) => string | undefined) | undefined,
		toolTip: string | undefined,
		tcvi: (t: string) => string
	) =>
	(params: ITooltipParams<TData, CellData>) => {
		if (!params.node) {
			return
		}

		const { value } = params

		if (params.node.group || params.node.footer) {
			return (params.valueFormatted ?? value?.value)?.toString()
		}

		const screenTip = getTooltip?.(params.node?.id, (params.column as Column | undefined)?.getId?.()) ?? toolTip

		const defaultTitle = isAvatarCell
			? value?.avatarLabel
			: isCheckmarkCell
			  ? value?.value
					? tcvi('GENERAL:YES')
					: tcvi('GENERAL:NO')
			  : (value?.formattedValue ?? value?.value)?.toString()
		return value?.error ?? screenTip ?? defaultTitle
	}

// Params to be passed to the filter component specified in filter or filterFramework.
export const getFilterParams = (
	colId: string,
	dataType: e_DataType | undefined,
	interpretation: e_Interpretation | undefined,
	isCheckMarkControl: boolean,
	hasSummaryFunction: boolean,
	tcvi: (t: string) => string
) => {
	const filter = getFilter(dataType, interpretation)

	// PBU 07.12.2023: From the second code snippet here https://www.ag-grid.com/react-data-grid/filter-multi/#enabling-the-multi-filter
	// we can see that the compare function is reversed in the date filter. I don't know why AgGrid ha chosen to do it like this, but that's the reason why the comparator is reversed here.
	// The confirmation that the comparator is used like this can be found in scalarFilter.mjs of the AgGrid source code in evaluateNonNullValue. Especially the IN_RANGE case makes this clear.
	return {
		filters: [
			{
				filter: filter,
				display: 'subMenu',
				filterParams: {
					buttons: ['apply', 'reset'],
					closeOnApply: true,
					excelMode: 'windows',
					newRowsAction: 'keep',
					textFormatter: (params: CellData | string) => {
						const value = typeof params === 'string' ? params : params.formattedValue ?? params.value
						if (value === undefined || value === null) {
							return ''
						} else if (typeof value === 'string') {
							return value.toLocaleLowerCase()
						} else {
							return value.toString()
						}
					},
					comparator: filter === 'agDateColumnFilter' ? getComparator(e_DataType.date, true) : undefined,
				},
			},
			{
				filter: 'agSetColumnFilter',
				filterParams: {
					buttons: ['apply', 'reset'],
					closeOnApply: true,
					excelMode: 'windows',
					newRowsAction: 'keep',
					valueFormatter: getValueFormatter(isCheckMarkControl, hasSummaryFunction, tcvi),
					comparator: filter === 'agDateColumnFilter' ? getComparator(e_DataType.date) : undefined,
				},
			},
		],
	}
}

// Suppress certain keyboard events in the grid cell.
export const suppressKeyboardEvent = (params: SuppressKeyboardEventParams) => {
	const code = params.event.code
	if (params.event.ctrlKey && (code === 'ArrowUp' || code === 'ArrowDown' || code === 'Space')) {
		params.event.preventDefault()
		return true
	}

	return false
}

export const getQuickFilterText = (params: GetQuickFilterTextParams<TData, CellData>) => params.value?.value as string

export const getCellDataFromGroupNodeChild = (rowNode: IRowNode<TData> | null) => {
	if (rowNode?.field) {
		return rowNode?.allLeafChildren?.[0]?.data?.[rowNode.field]
	}
}
