import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DetailPanel } from './InternalComponents/DetailPanel'
import { DetailView } from './InternalComponents/DetailView'
import { MasterPanel } from './InternalComponents/MasterPanel'
import { MasterView } from './InternalComponents/MasterView'
import { useWidthChange } from './utils/useWidthChange'

import { SwitchTransition, CSSTransition } from 'react-transition-group'
import type { ISortedColumn } from './types/ISortedColumn'
import { createStyle } from '../../theming'
import type { IDataPropertyDescription } from './types/IDataPropertyDescription'
import type { IDataWithIdentifier } from './types/IDataWithIdentifier'
import type { IMasterDetailPreakpoint } from './types/IMasterDetailBreakpoint'

import './../../theming/masterdetail.css'
import type { DetailItemSurface } from './types/IDetailItemSurfaceProps'
import type { IActionEntry } from './types/IActionDescription'
import type { IMasterViewColumnItemDescription } from './types/IMasterViewColumnItemDescription'
import { useID } from '../../controls/utils'

const classes = createStyle({
	masterdetailsurface: {
		flex: 1,
		border: '1px solid silver',
		borderRadius: '5px',
		background: 'white',
		boxShadow: `0 6.4px 14.4px 0 rgba(${'0,0,0'}, 0.132), 0 1.2px 3.6px 0 rgba(${'0,0,0'}, 0.108)`,

		overflow: 'hidden',
		display: 'flex',
		alignItems: 'stretch',
		justifyContent: 'stretch',
		position: 'relative',
		minHeight: '500px',
	},

	view: {
		overflow: 'hidden',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'stretch',
		background: 'inherit',
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
	},
})

const standardBreakpoints: IMasterDetailPreakpoint[] = [
	{
		name: 'phone',
		maxWidth: 640,
	},
	{
		name: 'ipad',
		minWidth: 640,
		maxWidth: 1024,
	},
	{
		name: 'pc',
		minWidth: 1024,
	},
]

interface IMasterDetailSurfaceProps {
	propertyDescriptions: IDataPropertyDescription[]

	columnGroups?: IMasterViewColumnItemDescription[]

	data: IDataWithIdentifier[]

	contentName: string | (() => string)
	contentNamePlural: string | (() => string)

	itemName?: string | ((item: IDataWithIdentifier) => string)
	summaryContent?: string | ((item: IDataWithIdentifier) => string)
	avatarContent?: string | ((item: IDataWithIdentifier) => JSX.Element | string)

	detailComponent?: DetailItemSurface

	itemActions?: IActionEntry[]
	selectedItemsActions?: IActionEntry[]
	globalActions?: IActionEntry[]
	handleGlobalAction?: (actionId: string) => void

	handleItemAction?: (actionId: string, itemId: string) => void
	handleSelectedItemsAction?: (actionId: string, itemIds: string[]) => void

	id?: string
	dataAttributes?: Record<string, string>
	minHeight?: number
}

export const MasterDetailSurface = (props: IMasterDetailSurfaceProps) => {
	const surfaceId = useID(props.id)
	const container = useRef<HTMLDivElement>(null)

	const detailRef = useRef<HTMLDivElement>(null)
	const masterRef = useRef<HTMLDivElement>(null)
	const masterViewRef = useRef<HTMLDivElement>(null)
	const masterPanelRef = useRef<HTMLDivElement>(null)
	const detailViewRef = useRef<HTMLDivElement>(null)
	const detailPanelRef = useRef<HTMLDivElement>(null)

	const [breakpoint, setBreakpoint] = useState<string>('')

	const [isMultiSelect, setIsMultiSelect] = useState(false)

	const breakpointChanged = useCallback(
		(breakpointName: string) => {
			if (breakpoint === breakpointName) {
				return
			}

			setBreakpoint(breakpointName)
		},
		[breakpoint]
	)

	useWidthChange(container, standardBreakpoints, breakpointChanged)
	const [view, setView] = useState<'master' | 'detail'>('master')

	const [selectedIds, setSelectedIds] = useState<string[]>([])
	const [activeId, setActiveId] = useState<string | undefined>()

	const [filterText, setFilterText] = useState('')
	const [sortedColumns, setSortedColumns] = useState<ISortedColumn[]>([])

	const [filteredData, setFilteredData] = useState<any[]>([])

	const getElementRef = (viewId: string, breakpointId: string) => {
		if (viewId === 'master') {
			return masterRef
		}

		if (viewId === 'detail') {
			return detailRef
		}

		if (viewId === 'master' && breakpointId === 'pc') {
			return masterViewRef
		}

		if (viewId === 'detail' && breakpointId === 'pc') {
			return detailViewRef
		}

		if (viewId === 'master' && breakpointId === 'phone') {
			return masterPanelRef
		}

		if (viewId === 'detail' && breakpointId === 'phone') {
			return detailPanelRef
		}

		if (viewId === 'master' && breakpointId === 'ipad') {
			return masterViewRef
		}

		if (viewId === 'detail' && breakpointId === 'ipad') {
			return detailPanelRef
		}

		return null
	}

	const activeViewRef = getElementRef(view, breakpoint)

	const getFallbackItemName = (item: IDataWithIdentifier) => {
		return (item['name'] || item['title'] || item['caption'])?.toString()
	}

	const getFallbackAvatarContent = (item: IDataWithIdentifier) => {
		const itemName = getFallbackItemName(item)

		return itemName
			? itemName
					.split(' ')
					.map((part: string) => part[0])
					.join('')
					.substring(0, 2)
					.toLocaleUpperCase()
			: 'X'
	}

	const getFallbackSummaryContent = () => {
		return ''
	}

	const sortData = (data: IDataWithIdentifier[], sortColumns: ISortedColumn[]) => {
		if (sortColumns.length === 0) {
			return data
		}

		return [...data].sort((item1: IDataWithIdentifier, item2: IDataWithIdentifier) => {
			for (let i = 0; i <= sortColumns.length - 1; i++) {
				const sortedColumn = sortColumns[i]

				const sortValue1 = item1[sortedColumn.propertyId]
				const sortValue2 = item2[sortedColumn.propertyId]

				const sortOrder = sortedColumn.order === 'asc' ? 1 : -1

				if (sortValue1 === sortValue2) {
					continue
				}

				if (!sortValue1 && sortValue2) {
					return -1 * sortOrder
				}
				if (sortValue1 && !sortValue2) {
					return 1 * sortOrder
				}

				const compareResult =
					sortValue1!.toString().toLowerCase().localeCompare(sortValue2!.toString().toLowerCase()) * sortOrder

				if (compareResult !== 0) {
					return compareResult
				}
			}

			return 0 // if all column values are otherwise equal
		})
	}

	const filterData = (data: IDataWithIdentifier[], filterText: string, filterColumns: string[]) => {
		if (!filterText) {
			return data
		}

		return data.filter((dataItem) => {
			const columnMatch = filterColumns.find(
				(columnId) => dataItem[columnId] && dataItem[columnId]!.toString().toLowerCase().includes(filterText)
			)

			return columnMatch !== undefined
		})
	}

	useEffect(() => {
		const filterPropertyIds = props.propertyDescriptions
			.filter((propDesc) => !propDesc.omitFromTextFilter)
			.map((filterPropDesc) => filterPropDesc.propertyId)
		setFilteredData(sortData(filterData(props.data, filterText, filterPropertyIds), sortedColumns))
	}, [props.data, filterText, sortedColumns, props.propertyDescriptions])

	useEffect(() => {
		if (!isMultiSelect && selectedIds.length > 1) {
			setSelectedIds([selectedIds[0]])
		}
	}, [isMultiSelect, selectedIds])

	const getItemTitle = () => {
		return typeof props.contentName === 'function' ? props.contentName() : props.contentName
	}

	const getPluralTitle = () => {
		return typeof props.contentNamePlural === 'function' ? props.contentNamePlural() : props.contentNamePlural
	}

	const currentBreakpoint = standardBreakpoints.find((item) => item.name === breakpoint)!

	return (
		<div
			id={props.id}
			{...props.dataAttributes}
			className={classes.masterdetailsurface}
			ref={container}
			style={{ minHeight: props.minHeight }}
		>
			<SwitchTransition mode={'out-in'}>
				<CSSTransition
					key={view}
					nodeRef={activeViewRef}
					addEndListener={(done) => {
						activeViewRef &&
							activeViewRef.current &&
							activeViewRef.current.addEventListener('transitionend', done, false)
					}}
					classNames={view === 'detail' ? 'detail' : 'master'}
				>
					<>
						{view === 'master' && (
							<div className={classes.view} ref={masterRef} key="master">
								{view === 'master' && (breakpoint === 'pc' || breakpoint === 'ipad') && (
									<MasterView
										title={getPluralTitle()}
										items={filteredData}
										id={`${surfaceId}_table`}
										selectedIds={selectedIds}
										setSelectedIds={setSelectedIds}
										setActiveId={(id: string | undefined) => {
											if (isMultiSelect) {
												return
											}
											setActiveId(id)
											setView('detail')
											setIsMultiSelect(false)
										}}
										activeId={activeId}
										ref={masterViewRef}
										columns={props.propertyDescriptions}
										columnGroups={props.columnGroups}
										sortedColumns={sortedColumns}
										sortColumns={setSortedColumns}
										filterText={filterText}
										setFilterText={setFilterText}
										isMultiSelect={isMultiSelect}
										setIsMultiSelect={setIsMultiSelect}
										itemActions={props.itemActions}
										handleItemAction={props.handleItemAction}
										selectedItemsActions={props.selectedItemsActions}
										breakpoint={currentBreakpoint}
										handleSelectedItemsAction={props.handleSelectedItemsAction}
										globalActions={props.globalActions}
										handleGlobalAction={props.handleGlobalAction}
									/>
								)}
								{view === 'master' && breakpoint === 'phone' && (
									<MasterPanel
										title={getPluralTitle()}
										items={filteredData}
										selectedIds={selectedIds}
										setSelectedIds={setSelectedIds}
										setActiveId={(id: string | undefined) => {
											if (isMultiSelect) {
												return
											}
											setActiveId(id)
											setView('detail')
											setIsMultiSelect(false)
										}}
										activeId={activeId}
										ref={masterPanelRef}
										itemName={props.itemName ? props.itemName : getFallbackItemName}
										avatarContent={props.avatarContent ? props.avatarContent : getFallbackAvatarContent}
										summaryContent={props.summaryContent ? props.summaryContent : getFallbackSummaryContent}
										columns={props.propertyDescriptions}
										sortedColumns={sortedColumns}
										sortColumns={setSortedColumns}
										filterText={filterText}
										setFilterText={setFilterText}
										isMultiSelect={isMultiSelect}
										setIsMultiSelect={setIsMultiSelect}
										selectedItemsActions={props.selectedItemsActions}
										handleSelectedItemsAction={props.handleSelectedItemsAction}
										globalActions={props.globalActions}
										handleGlobalAction={props.handleGlobalAction}
									/>
								)}
							</div>
						)}

						{view === 'detail' && (
							<div className={classes.view} ref={detailRef} key="detail">
								{view === 'detail' && breakpoint === 'pc' && (
									<DetailView
										items={filteredData}
										selectedIds={selectedIds}
										setSelectedIds={setSelectedIds}
										activeId={activeId}
										setActiveId={setActiveId}
										listTitle={getPluralTitle()}
										title={getItemTitle()}
										back={() => {
											setView('master')
											setIsMultiSelect(false)
										}}
										ref={detailViewRef}
										itemName={props.itemName ? props.itemName : getFallbackItemName}
										avatarContent={props.avatarContent ? props.avatarContent : getFallbackAvatarContent}
										summaryContent={props.summaryContent ? props.summaryContent : getFallbackSummaryContent}
										columns={props.propertyDescriptions}
										sortedColumns={sortedColumns}
										sortColumns={setSortedColumns}
										filterText={filterText}
										setFilterText={setFilterText}
										detailComponent={props.detailComponent}
										isMultiSelect={isMultiSelect}
										setIsMultiSelect={setIsMultiSelect}
										breakpoint={currentBreakpoint}
										itemActions={props.itemActions}
										selectedItemsActions={props.selectedItemsActions}
										handleItemAction={props.handleItemAction}
										handleSelectedItemsAction={props.handleSelectedItemsAction}
										globalActions={props.globalActions}
										handleGlobalAction={props.handleGlobalAction}
									/>
								)}
								{view === 'detail' && (breakpoint === 'ipad' || breakpoint === 'phone') && (
									<DetailPanel
										items={filteredData}
										columns={props.propertyDescriptions}
										activeId={activeId}
										title={getItemTitle()}
										back={() => setView('master')}
										ref={detailPanelRef}
										breakpoint={currentBreakpoint}
										itemName={props.itemName ? props.itemName : getFallbackItemName}
										detailComponent={props.detailComponent}
										itemActions={props.itemActions}
										handleItemAction={props.handleItemAction}
									/>
								)}
							</div>
						)}
					</>
				</CSSTransition>
			</SwitchTransition>
		</div>
	)
}
