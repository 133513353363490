import React, { useEffect, useRef, useState } from 'react'

import type { IButtonProps } from './Button'
import { Button } from './Button'
import { useForwardedRef } from '../utils/useForwardedRef'
import { e_Placement } from '../../enums/e_Placement'
import type { MenuItem } from '../Menu'
import { Menu } from '../Menu'
import classNames from 'clsx'

type IPickedButtonProps = Omit<
	IButtonProps,
	keyof ['onClick', 'onMouseDown', 'onEnterPress', 'showDropdownButton', 'children']
>
interface IDropdownButtonProps extends IPickedButtonProps {
	menuButtonClick?: () => void
	menuItems: MenuItem[]
	minMenuWidth?: number
	onMenuClose?: () => void
}

export const DropdownButton = React.forwardRef((props: IDropdownButtonProps, ref: React.Ref<HTMLButtonElement>) => {
	const buttonRef = useForwardedRef<HTMLButtonElement>(ref)
	const popperClassName = useRef<string>()
	const menuStyle = useRef<React.CSSProperties>({})
	const popperWidth = useRef<string>('')
	const { minMenuWidth = 0 } = props

	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const handleOpen = (e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()

		props.menuButtonClick?.()

		setIsMenuOpen(true)
	}

	const handleClose = () => {
		setIsMenuOpen(false)
		props.onMenuClose?.()
	}

	useEffect(() => {
		if (buttonRef.current) {
			popperClassName.current = buttonRef.current.className

			const computedButtonStyle = window.getComputedStyle(buttonRef.current)
			menuStyle.current.borderRadius = computedButtonStyle.getPropertyValue('border-radius')
			menuStyle.current.width = '100%'

			const buttonWidth = Number(computedButtonStyle.getPropertyValue('width').slice(0, -2))
			popperWidth.current = buttonWidth < minMenuWidth ? `${minMenuWidth}px` : ''
		}
	}, [buttonRef.current])

	return (
		<>
			<Button
				id={props.id}
				ref={buttonRef}
				variant={props.variant}
				className={props.className}
				size={props.size}
				style={props.style}
				label={props.label}
				dataAttributes={props.dataAttributes}
				icon={props.icon}
				iconPosition={props.iconPosition}
				type={props.type}
				screenTip={props.screenTip}
				disabled={props.disabled}
				isChecked={isMenuOpen}
				onMouseDown={(e) => isMenuOpen && e.preventDefault()}
				onClick={isMenuOpen ? handleClose : handleOpen}
				badgeValue={props.badgeValue}
				showSpinner={props.showSpinner}
				showDropdownButton
				dropdownIconSize={props.dropdownIconSize}
			>
				{props.children}
			</Button>
			<Menu
				open={isMenuOpen}
				onClose={handleClose}
				items={props.menuItems}
				anchorEl={buttonRef}
				placement={e_Placement.bottomStart}
				popperClassName={classNames(popperClassName.current)}
				popperStyle={{ width: popperWidth.current, padding: 0, margin: 'unset', height: 'unset' }}
				menuStyle={menuStyle.current}
				popperOffset={'0px, 2px'}
			/>
		</>
	)
})

DropdownButton.displayName = 'DropdownButton'
