export enum e_EnvironmentOperatingType {
	active = 0,
	origin = 1,
	passive = 2,
	unknown = 4,
}

export enum e_EnvironmentOperatingTypeName {
	active = 'Active',
	origin = 'Origin',
	passive = 'Passive',
}
