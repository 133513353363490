import React from 'react'
import { useFormControlContext, ValueLabel } from '../FormControl'
import type { StyleObject } from '../utils/useMergeStyles'
import { useMergeStyles } from '../utils/useMergeStyles'
import { createStyle } from '../../theming'
import { InputHeight } from '../utils/InputHeight'
import { useDataAttributes } from '../utils/useDataAttributes'
import { useID } from '../utils/useID'
import classNames from 'clsx'

const classes = createStyle((theme) => ({
	radioButton: {
		fontWeight: 'inherit',
		padding: '0 2px',
		border: 'none',
		background: 'none',
		position: 'relative',
		alignItems: 'baseline',
		display: 'flex',
		color: 'inherit',
		lineHeight: 'normal',
		minHeight: 20,
		outlineStyle: 'solid',
		outlineColor: 'transparent',
		outlineWidth: 1,
		outlineOffset: -1,
		transition: `outline-color ${theme.transitions.duration.shorter}`,
		'&:focus-visible': { outlineColor: theme.colors.body.focusBorder },
		'&:not(:disabled)': {
			cursor: 'pointer',
		},
		// Selection styles for outer circle
		'&[aria-checked=true]&:not(:disabled) $radioButtonBorder': {
			// when checked and not disabled, display outer circle with theme primary color
			borderColor: theme.colors.primaryButton.background,
		},

		'&:disabled $radioButtonBorder': {
			// when disabled, display outer circle as disabled (gray)
			borderColor: theme.colors.button.disabledText,
		},

		// Selection styles for inner circle
		'&[aria-checked=false]&:hover:not(:disabled) $radioButtonInner': {
			// when not checked and not disabled, display inner circle on hover
			opacity: 1,
		},

		'&[aria-checked=true]&:not(:disabled) $radioButtonInner': {
			// when checked and not disabled, display outer circle with theme primary color
			background: theme.colors.primaryButton.background,
			opacity: 1,
		},

		'&[aria-checked=true]:disabled $radioButtonInner': {
			// when checked and disabled, display inner circle as disabled (gray)
			background: theme.colors.button.disabledText,
			opacity: 1,
		},
	},

	radioButtonBorder: {
		width: 'max(1em, 16px)',
		height: 'max(1em, 16px)',
		alignSelf: 'center',
		background: theme.colors.button.background,
		borderRadius: '50%',
		border: '1px solid ' + theme.colors.button.text,
		transition: 'border-color 0.5s',
		position: 'relative',
		flex: '0 0 auto',
	},

	radioButtonInner: {
		position: 'absolute',
		borderRadius: '50%',
		left: '4px',
		top: '4px',
		right: '4px',
		bottom: '4px',
		opacity: 0,
		transition: 'opacity 0.25s ease',
		background: theme.colors.button.text,
	},

	radioButtonInnerChecked: {
		opacity: 1,
		background: theme.colors.primaryButton.background,
	},

	radioButtonPadding: {
		padding: theme.controls.input.padding,
	},
}))

export type IRadioButtonSize = 20 | 28 | 42

interface IRadioButtonProps<T> {
	index: number
	id?: string
	groupId?: string
	dataAttributes?: Record<string, string>
	name?: string
	onChange?: (value: T) => void
	value: T
	valueLabel: string
	checked?: boolean
	disabled?: boolean
	readOnly?: boolean
	tabStop: boolean
	margin?: StyleObject
	selectedRef?: React.Ref<HTMLButtonElement>
	disableUniformHeight?: boolean
	wordWrapValueLabel?: boolean
	buttonSize?: IRadioButtonSize
}

export const RadioButton = <T extends string | number>(props: IRadioButtonProps<T>) => {
	const { buttonSize = 20 } = props

	const buttonSizeStyle = {
		width: `${buttonSize}px`,
		height: `${buttonSize}px`,
	}

	const id = useID(props.id)
	const groupId = useID(props.groupId)

	const styles = useMergeStyles({ margin: props.margin }, [props.margin])

	const dataAttributes = useDataAttributes(props.dataAttributes, props.index)

	const onClick = () => {
		props.onChange?.(props.value)
	}

	const formControlContext = useFormControlContext()

	return (
		<button
			id={id}
			{...dataAttributes}
			className={classNames(classes.radioButton, props.disableUniformHeight && classes.radioButtonPadding)}
			onClick={!props.disabled && !props.readOnly ? onClick : undefined}
			role="radio"
			aria-checked={props.checked ? true : false}
			aria-describedby={formControlContext.isSubTextVisible ? `${groupId}-subtext` : undefined}
			style={styles}
			disabled={props.disabled}
			tabIndex={props.tabStop === false && !props.disabled ? -1 : undefined}
			ref={props.selectedRef}
		>
			{!props.disableUniformHeight && <InputHeight border />}
			<span className={classes.radioButtonBorder} style={buttonSizeStyle}>
				<span className={classes.radioButtonInner} />
			</span>
			{props.valueLabel && (
				<ValueLabel value={props.valueLabel} wordWrap={props.wordWrapValueLabel} disabled={props.disabled} />
			)}
		</button>
	)
}

RadioButton.displayName = 'RadioButton'
