import React, { useMemo, useRef } from 'react'
import type { IColumnDef, RowData } from '../../../controls/AgGridTable/Table.types'
import { e_RowSelectionType } from '../../../controls/AgGridTable/Table.types'
import { createStyle } from '../../../theming'
import type { IDataPropertyDescription, IDataWithIdentifier, ISortedColumn } from '../types'
import { AgGridTable } from './../../../controls/AgGridTable'
import type { IActionEntry } from '../types/IActionDescription'
import { e_actionType } from '../types/IActionDescription'
import type { IMasterViewColumnItemDescription } from '../types/IMasterViewColumnItemDescription'
import type { MenuItem } from '../../../controls/Menu'
import { e_MenuItemType } from '../../../controls/Menu'

const classes = createStyle({
	masterTable: {
		display: 'grid',
		flex: 1,
		flexDirection: 'column',
		overflow: 'auto',
		background: 'white',
		contain: 'strict',
		padding: '16px',
	},
})

interface IMasterDetailAgTableViewProps {
	id: string
	items: IDataWithIdentifier[]

	selectedIds: string[]
	setSelectedIds: (ids: string[]) => void

	activeId: string | undefined
	setActiveId: (id: string | undefined) => void
	columns: IDataPropertyDescription[]
	columnGroups?: IMasterViewColumnItemDescription[]

	sortedColumns?: ISortedColumn[]
	sortColumns?: (sortedColumns: ISortedColumn[]) => void

	isMultiSelect?: boolean

	itemActions?: IActionEntry[]

	handleItemAction?: (actionId: string, itemId: string) => void
}

const createColumnGroupFromDescription = (
	columnDescription: IMasterViewColumnItemDescription,
	columns: IDataPropertyDescription[],
	lookupDataRow: (id: string) => IDataWithIdentifier | undefined
): IColumnDef => {
	if (typeof columnDescription === 'string') {
		const column = columns.find((item) => item.propertyId === columnDescription)

		if (!column) {
			throw new Error(`Failed to create column, did not find column with id ${columnDescription}`)
		}

		return createColumnFromDescription(column, lookupDataRow)
	}

	return {
		field: columnDescription.groupId,
		headerName: columnDescription.displayName,
		children: columnDescription.children?.map((child) =>
			createColumnGroupFromDescription(child, columns, lookupDataRow)
		),
	}
}

const createColumnFromDescription = (
	columnDescription: IDataPropertyDescription,
	lookupDataRow: (id: string) => IDataWithIdentifier | undefined
): IColumnDef => {
	return {
		field: columnDescription.propertyId,
		headerName: columnDescription.displayName,
		width: columnDescription.widthHintPx,
		onRenderCell: columnDescription.renderData
			? (row: RowData, columnId: string) => {
					const dataRow = lookupDataRow(row.id)

					if (dataRow) {
						return columnDescription.renderData!(dataRow, columnId)
					}

					return null
			  }
			: undefined,
	}
}

export const MasterDetailAgTableView = (props: IMasterDetailAgTableViewProps) => {
	const tableElement = useRef<HTMLDivElement>(null)

	const handleItemDoubleClick = (rowData: RowData) => {
		props.setActiveId(rowData.id)
	}

	const itemsRef = useRef(props.items)

	const lookupDataById = useMemo(() => {
		itemsRef.current = props.items
		return (id: string) => {
			return itemsRef.current.find((item) => item.id === id)
		}
	}, [props.items])

	const columnDefs: IColumnDef[] = useMemo(() => {
		if (props.columnGroups && props.columnGroups.length > 0) {
			return props.columnGroups.map((groupItem) =>
				createColumnGroupFromDescription(groupItem, props.columns, lookupDataById)
			)
		} else {
			return props.columns.map((columnDescription) => createColumnFromDescription(columnDescription, lookupDataById))
		}
	}, [props.columns, props.columnGroups])

	const tableData = useMemo(() => {
		return props.items.map((item) => {
			const tableData: RowData = {
				id: item.id,
				columns: {},
			}

			Object.keys(item).forEach((propertyId) => {
				tableData.columns[propertyId] = { value: item[propertyId] }
			})

			return tableData
		})
	}, [props.items])

	const handleContextMenu = (
		id: string,
		columnId: string,
		contextMenuCallback: (contextMenuItems: MenuItem[]) => void
	) => {
		if (props.isMultiSelect) {
			return
		}

		const contextMenuItems = props.itemActions
			? props.itemActions.map((itemAction) => {
					if (itemAction.type === e_actionType.action) {
						return {
							type: e_MenuItemType.action as const,
							name: itemAction.displayName,
							iconClassName: itemAction.iconClassName,
							onClick: () => {
								props.handleItemAction && props.handleItemAction(itemAction.id, id)
							},
							disabled: itemAction.disabled,
						}
					} else {
						//if (itemAction.type === e_actionType.separator) {

						return {
							type: e_MenuItemType.divider as const,
						}
					}
			  })
			: undefined

		if (contextMenuItems) {
			contextMenuCallback(contextMenuItems)
		}
	}

	return (
		<div className={classes.masterTable} ref={tableElement}>
			<AgGridTable
				id={props.id}
				columnDefs={columnDefs}
				rowData={tableData}
				onContextMenu={handleContextMenu}
				rowSelectionType={props.isMultiSelect ? e_RowSelectionType.multi : e_RowSelectionType.single}
				onDblClick={handleItemDoubleClick}
				onSelectionChange={props.setSelectedIds}
				selection={props.selectedIds}
				contextMenuButton={props.itemActions && props.itemActions.length > 0 ? 'inline' : undefined}
				disableMovableColumns
			/>
		</div>
	)
}
