import { useEffect, useMemo } from 'react'
import { parseDurationTextValue, parseIntegerTimeTextValue } from './parsingUtils'

export const useTextValueChange = (
	updateInternalValue: (
		value: number | undefined,
		updateTextValue: boolean,
		raiseValueChanged: boolean,
		isTextValueInvalid: boolean
	) => void,
	setDisplayValue: (value: React.SetStateAction<string>) => void,
	isEnteredValueValid: React.MutableRefObject<boolean>,
	userIsWriting: React.MutableRefObject<boolean>,
	isIntegerTime?: boolean,
	charPress?: string
) => {
	const parseTextValueFunc = useMemo(
		() => (isIntegerTime ? parseIntegerTimeTextValue : parseDurationTextValue),
		[isIntegerTime]
	)

	const parseTextValue = (textValue: string) => {
		if (!textValue) {
			isEnteredValueValid.current = true
			updateInternalValue(undefined, false, true, false)
			setDisplayValue(textValue)

			return
		}

		const parsedValue = parseTextValueFunc(textValue)

		updateInternalValue(parsedValue, false, true, parsedValue === undefined)
		setDisplayValue(textValue)
	}

	useEffect(() => {
		if (charPress) {
			parseTextValue(charPress)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (event: React.ChangeEvent<HTMLInputElement>) => {
		userIsWriting.current = true
		const textValue = event.target.value

		parseTextValue(textValue)
	}
}
