import React from 'react'

import { createStyle } from '@genusbiz/web-ui/theming'

// Selectors
import { useSelector } from 'react-redux'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { useTranslation } from 'react-i18next'
import { AgGridTable } from '@genusbiz/web-ui/controls'
import { IColumnDef, RowData } from '@genusbiz/web-ui/controls/AgGridTable/Table.types'

const classes = createStyle({
	container: {
		flex: 1,
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		backgroundColor: 'white',
	},
	table: {
		width: '100%',
		height: '100%',
	},
})

export const OperatorEndpointTable = () => {
	const { t } = useTranslation()

	const endpointsRowData = useSelector(kubernetesSelectors.selectEndpoints)

	const k8sRuntimes = useSelector(kubernetesSelectors.selectK8sRuntimes)

	const columns: IColumnDef[] = [
		{
			headerName: t('GENGERAL:ID'),
			field: 'id',
			hide: true,
		},
		{
			headerName: t('GENGERAL:URI'),
			field: 'uri',
		},
		{
			headerName: t('ADMINISTRATION:BLUE_RUNTIME'),
			field: 'blueK8sRuntime',
		},
		{
			headerName: t('ADMINISTRATION:GREEN_RUNTIME'),
			field: 'greenK8sRuntime',
		},
	]

	const rowData: RowData[] = endpointsRowData.map((endpoint) => {
		return {
			id: endpoint.id,
			columns: {
				uri: { value: endpoint.uri },
				blueK8sRuntime: {
					value: k8sRuntimes.find((k8sRuntime) => k8sRuntime.id === endpoint.blueK8sRuntimeId)?.name ?? '',
				},
				greenK8sRuntime: {
					value: k8sRuntimes.find((k8sRuntime) => k8sRuntime.id === endpoint.greenK8sRuntimeId)?.name ?? '',
				},
			},
		}
	})

	return (
		<div className={classes.container}>
			<AgGridTable columnDefs={columns} compact rowData={rowData} />
		</div>
	)
}
