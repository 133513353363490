import React, { useRef } from 'react'
import { createStyle } from '../../theming'
import type { MenuItemLink } from './MenuItem.types'
import { MenuItemContent } from './MenuItemContent'
import { useFocusSelected } from './hooks/useFocusSelected'
import { generateMenuScreenTip } from './generateMenuScreenTip'

const classes = createStyle({
	item: {
		display: 'flex',
		textDecoration: 'none',
		'&:focus': {
			outline: 'none',
		},
	},
})

interface IPopupMenuItemProps {
	menuIndex: number
	item: MenuItemLink

	isSelected: boolean
	iconColorClass?: string
	onMouseEnter: () => void
	onActivate?: (e: React.MouseEvent | React.KeyboardEvent) => void
}

export const PopupMenuLinkItem = (props: IPopupMenuItemProps) => {
	const menuItem = props.item

	const menuItemRef = useRef<HTMLAnchorElement>(null)

	useFocusSelected(props.isSelected, menuItemRef)

	if (menuItem.hidden) {
		return null
	}

	return (
		<a
			className={classes.item}
			id={menuItem.id}
			onClick={!menuItem.disabled ? props.onActivate : undefined}
			onMouseEnter={!menuItem.disabled ? props.onMouseEnter : undefined}
			ref={menuItemRef}
			tabIndex={0}
			title={generateMenuScreenTip(props.item.screenTip, props.item.keyboardShortcut)}
			href={menuItem.href}
			{...menuItem.dataAttributes}
		>
			<MenuItemContent
				iconClassName={menuItem.iconClassName}
				iconString={menuItem.iconString}
				displayText={menuItem.name}
				iconColorClass={props.iconColorClass}
				isSelected={props.isSelected}
				isChecked={menuItem.isChecked}
				disabled={menuItem.disabled}
				contentLayout={menuItem.contentLayout}
			/>
		</a>
	)
}
