import React, { useState, useEffect } from 'react'
import classNames from 'clsx'

import { createStyle } from '../../theming'
import type { StyleObject } from '../utils/useMergeStyles'
import { useMergeStyles } from '../utils/useMergeStyles'

const classes = createStyle((theme) => ({
	avatar: {
		background: theme.palette.background.neutralQuaternaryAlt,
		color: theme.palette.foreground.neutralDark,
		display: 'flex',
		flex: '0 0',
		position: 'relative',
		overflow: 'hidden',
		outlineStyle: 'solid',
		outlineColor: 'transparent',
		outlineWidth: 1,
		outlineOffset: -1,
		transition: `outline-color ${theme.transitions.duration.shorter}`,
	},
	button: {
		background: 'none',
		border: '0px solid transparent',
		padding: 0,
		outline: 0,
		position: 'relative',
		'&:focus-visible': {
			'& $avatar': {
				outlineColor: theme.colors.body.focusBorder,
			},
		},
	},
	extraSmall: {
		width: 28,
		height: 28,
		flexBasis: 28,
		borderRadius: 14,
	},
	extraSmallOneLetter: { fontSize: 16 },
	extraSmallTwoLetters: { fontSize: 12 },
	extraSmallThreeLetters: { fontSize: 9, fontWeight: 'bold' },
	small: {
		width: 42,
		height: 42,
		flexBasis: 42,
		borderRadius: 21,
	},
	smallOneLetter: { fontSize: 16 },
	smallTwoLetters: { fontSize: 12 },
	smallThreeLetters: { fontSize: 9, fontWeight: 'bold' },
	medium: {
		width: 56,
		height: 56,
		flexBasis: 56,
		borderRadius: 28,
	},
	mediumOneLetter: { fontSize: 20 },
	mediumTwoLetters: { fontSize: 16 },
	mediumThreeLetters: { fontSize: 12 },
	large: {
		width: 84,
		height: 84,
		flexBasis: 84,
		borderRadius: 42,
	},
	largeOneLetter: { fontSize: 33 },
	largeTwoLetters: { fontSize: 26 },
	largeThreeLetters: { fontSize: 20 },
	extraLarge: {
		width: 128,
		height: 128,
		flexBasis: 128,
		borderRadius: 64,
	},
	extraLargeOneLetter: { fontSize: 37 },
	extraLargeTwoLetters: { fontSize: 30 },
	extraLargeThreeLetters: { fontSize: 24 },
	isClickable: {
		cursor: 'pointer',
	},
	span: {
		flex: 1,
		textAlign: 'center',
		alignSelf: 'center',
		textTransform: 'uppercase',
		'$extraSmall &': { marginTop: -2 },
		'$small &, $medium &': { marginTop: -2 },
		'$large &': { marginTop: -3 },
		'$mediumOneLetter &': { marginTop: -4 },
		'$largeOneLetter &': { marginTop: -6 },
		'$extraLargeOneLetter &': { marginTop: -5 },
		'$extraLargeTwoLetters &': { marginTop: -5 },
		'$extraLargeThreeLetters &': { marginTop: -6 },
	},
	image: {
		width: '100%',
		height: '100%',
		borderRadius: '50%',
		objectFit: 'cover',
	},
	defaultIcon: {
		display: 'flex',
		width: '100%',
		alignSelf: 'center',
		textAlign: 'center',
		color: theme.palette.background.white,
	},
}))

interface AvatarProps {
	onClick?: (e: React.MouseEvent) => void
	id?: string
	caption?: string
	className?: string
	initials?: string
	numberOfInitials?: 1 | 2 | 3
	screenTip?: string
	margin?: StyleObject
	imageUrl?: string
	uniqueTag?: string
	disabled?: boolean
	size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge'
	dataAttributes?: Record<string, string>
}

export const Avatar = (props: AvatarProps) => {
	const { size = 'medium', numberOfInitials = 3 } = props
	const initials = props.initials ? props.initials.replace(/\s+/g, '').substring(0, numberOfInitials) : null

	type ICSSClasses = keyof typeof classes

	const styles = useMergeStyles({ margin: props.margin }, [props.margin])

	const css = classNames(
		classes.avatar,
		classes[size],
		{
			[classes.isClickable]: !!props.onClick,
			[classes[(size + 'OneLetter') as ICSSClasses]]: numberOfInitials === 1,
			[classes[(size + 'TwoLetters') as ICSSClasses]]: numberOfInitials === 2,
			[classes[(size + 'ThreeLetters') as ICSSClasses]]: numberOfInitials === 3,
		},
		props.className
	)

	const [imageLoaded, setImageLoaded] = useState(false)

	useEffect(() => {
		setImageLoaded(false)
		if (props.imageUrl) {
			const img = new Image()

			img.onload = () => setImageLoaded(true)
			img.src = props.imageUrl
			img.alt = ''
		}
	}, [props.imageUrl])

	const avatarContent = (
		<span
			className={css}
			style={styles}
			id={!props.onClick ? props.id : undefined}
			{...props.dataAttributes}
			title={!props.onClick ? props.screenTip : undefined}
			aria-hidden
		>
			{imageLoaded && (
				<img
					src={props.imageUrl}
					className={classes.image}
					alt={props.caption ? props.caption : ''}
					title={props.screenTip}
				/>
			)}
			{props.imageUrl && !imageLoaded && (
				<i
					className={classNames(classes.defaultIcon, classes[(size + 'OneLetter') as ICSSClasses], 'Fluent-Contact')}
				/>
			)}
			{!props.imageUrl && <span className={classes.span}>{initials}</span>}
		</span>
	)

	return props.onClick ? (
		<button
			id={props.id}
			tabIndex={props.disabled ? -1 : undefined}
			aria-label={props.screenTip}
			className={classes.button}
			onClick={props.onClick}
			style={styles}
			title={props.screenTip}
			{...props.dataAttributes}
		>
			{avatarContent}
		</button>
	) : (
		avatarContent
	)
}
