import React, { createContext, useContext, useMemo } from 'react'

import type { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import type { IIconSize } from '../Icon'
import type { e_SubTextVariant } from '../../enums/e_SubTextVariant'
import type { e_RequiredIndicatorStyle } from '../../enums/e_RequiredIndicatorStyle'
import type { IButtonProps } from '../Button/Button'

export interface IStyleContext {
	style?: Style
}

export type Style = {
	button?: Pick<IButtonProps, 'size' | 'variant'> & { iconSize?: IIconSize; width?: number }
	input?: {
		colonAfterLabel?: boolean
		boldLabel?: boolean
		dynamicsStyle?: boolean
		placeholder?: string
		readOnlyIndicatorStyle?: e_ReadOnlyIndicatorStyle
		requiredIndicatorStyle?: e_RequiredIndicatorStyle
		subTextVariant?: e_SubTextVariant
	}
}

interface IStyleProviderProps {
	style?: Style
	children?: React.ReactNode[] | React.ReactNode
}

const StyleContext = createContext<IStyleContext>({})

export const StyleProvider = (props: IStyleProviderProps) => {
	const memoizedStyle = useMemo(() => {
		return { style: props.style }
	}, [props.style])

	return <StyleContext.Provider value={memoizedStyle}>{props.children}</StyleContext.Provider>
}

export const useStyleContext = () => useContext(StyleContext)

export const useGetStyle = (style?: Style) => {
	const styleContext = useStyleContext().style

	return useMemo(() => {
		return {
			button: style?.button ?? styleContext?.button,
			input: style?.input ?? styleContext?.input,
		}
	}, [style, styleContext])
}
