import { defaultTypography } from '../../theming/defaults'
import { minifyCssString } from './utils/minifyCssString'
import { px2pt } from './utils/px2pt'

const getThemeFontSizeString = (inherit?: boolean) => {
	if (inherit) {
		return ''
	}

	return `font-size: ${px2pt(defaultTypography.body1.fontSize)}pt;`
}

const getFontFamilyString = (inherit?: boolean) => {
	if (inherit) {
		return ''
	}
	return 'font-family: Calibri, Helvetica, sans-serif;'
}

export const getCssString = (id?: string, inherit?: boolean) => {
	const idSelectorFragment = id ? `#${id} ` : ''
	return `
	${idSelectorFragment}.RichTextTheme__root {
		${getThemeFontSizeString(inherit)}
		${getFontFamilyString(inherit)}
		word-break: break-word;
		--list-indent: 28px;
	}

	.RichTextTheme__ltr {
		text-align: left;
	}
	.RichTextTheme__rtl {
		text-align: right;
	}
	.RichTextTheme__paragraph {
		margin: 0;
		position: relative;
	}

	.RichTextTheme__table {
		border-collapse: collapse;
		border-spacing: 0;
		max-width: 100%;
		overflow-y: scroll;
		table-layout: fixed;
		margin: 20px 0;
	}
	.RichTextTheme__tableCell {
		border: 1px solid #bbb;
		min-width: 75px;
		vertical-align: top;
		text-align: start;
		padding: 6px 8px;
		position: relative;
		cursor: default;
		outline: none;
	}
	.RichTextTheme__tableCellHeader {
		background-color: rgba(128, 128, 128, 0.2);
		text-align: start;
	}
	.RichTextTheme__link {
		color: rgb(30, 111, 219);
		text-decoration: none;
	}

	.RichTextTheme__link:hover {
		text-decoration: underline;
	}

	.RichTextTheme__bold {
		font-weight: bold;
	}

	.RichTextTheme__italic {
		font-style: italic;
	}

	.RichTextTheme__underline {
		text-decoration: underline;
	}

	.RichTextTheme__strikethrough {
		text-decoration: line-through;
	}

	.RichTextTheme__underlineStrikethrough {
		text-decoration: underline line-through;
	}

	.RichTextTheme__image {
		display: inline-block;
		position: relative;
	}

	.RichTextTheme__image img.focused {
		outline: 2px solid rgb(33, 111, 219);
	}

	.RichTextTheme__ol1 {
		padding: 0;
		margin: 0;
		margin-left: var(--list-indent, 28px);
	}
	.RichTextTheme__ol2 {
		padding: 0;
		margin: 0;
		margin-left: var(--list-indent, 28px);
		list-style-type: upper-alpha;
	}
	.RichTextTheme__ol3 {
		padding: 0;
		margin: 0;
		margin-left: var(--list-indent, 28px);
		list-style-type: lower-alpha;
	}
	.RichTextTheme__ol4 {
		padding: 0;
		margin: 0;
		margin-left: var(--list-indent, 28px);
		list-style-type: upper-roman;
	}
	.RichTextTheme__ol5 {
		padding: 0;
		margin: 0;
		margin-left: var(--list-indent, 28px);
		list-style-type: lower-roman;
	}

	.RichTextTheme__ul {
		padding: 0;
		margin: 0;
		margin-left: var(--list-indent, 28px);
	}
	.RichTextTheme__listItem {
		margin: 0 0px;
	}

	.RichTextTheme__listItemChecked,
	.RichTextTheme__listItemUnchecked {
		position: relative;
		margin-left: 8px;
		margin-right: 8px;
		padding-left: 24px;
		padding-right: 24px;
		list-style-type: none;
		outline: none;
	}
	.RichTextTheme__listItemChecked {
		text-decoration: line-through;
	}
	.RichTextTheme__listItemUnchecked:before,
	.RichTextTheme__listItemChecked:before {
		content: '';
		width: 16px;
		height: 16px;
		top: 2px;
		left: 0;
		cursor: pointer;
		display: block;
		background-size: cover;
		position: absolute;
	}
	.RichTextTheme__listItemUnchecked[dir='rtl']:before,
	.RichTextTheme__listItemChecked[dir='rtl']:before {
		left: auto;
		right: 0;
	}
	.RichTextTheme__listItemUnchecked:focus:before,
	.RichTextTheme__listItemChecked:focus:before {
		box-shadow: 0 0 0 2px #a6cdfe;
		border-radius: 2px;
	}
	.RichTextTheme__listItemUnchecked:before {
		border: 1px solid #999;
		border-radius: 2px;
	}
	.RichTextTheme__listItemChecked:before {
		border: 1px solid rgb(61, 135, 245);
		border-radius: 2px;
		background-color: #3d87f5;
		background-repeat: no-repeat;
	}
	.RichTextTheme__listItemChecked:after {
		content: '';
		cursor: pointer;
		border-color: #fff;
		border-style: solid;
		position: absolute;
		display: block;
		top: 6px;
		width: 3px;
		left: 7px;
		height: 6px;
		transform: rotate(45deg);
		border-width: 0 2px 2px 0;
	}
	.RichTextTheme__nestedListItem {
		list-style-type: none;
	}
	.RichTextTheme__nestedListItem:before,
	.RichTextTheme__nestedListItem:after {
		display: none;
	}
	.RichTextTheme__code {
  	color: #323130;
  	background-color: rgb(240, 242, 245);
  	font-family: ui-monospace, "Cascadia Mono", "Segoe UI Mono", "Liberation Mono", Menlo, Monaco, Consolas, monospace;
  	display: block;
  	padding: 8px 8px 8px 52px;
  	line-height: 1.53;
  	font-size: 13px;
  	margin: 0;
  	margin-top: 8px;
  	margin-bottom: 8px;
  	overflow-x: auto;
  	position: relative;
  	tab-size: 2;
  	border-radius: 5px;
}

.RichTextTheme__code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.RichTextTheme__tokenComment {
  color: slategray;
}
.RichTextTheme__tokenPunctuation {
  color: #999;
}
.RichTextTheme__tokenProperty {
  color: #905;
}
.RichTextTheme__tokenSelector {
  color: #690;
}
.RichTextTheme__tokenOperator {
  color: #9a6e3a;
}
.RichTextTheme__tokenAttr {
  color: #07a;
}
.RichTextTheme__tokenVariable {
  color: #e90;
}
.RichTextTheme__tokenFunction {
  color: #dd4a68;
}
.RichTextTheme__textCode {
  background-color: rgb(240, 242, 245);
  padding: 2px 4px;
  font-family: ui-monospace, "Cascadia Mono", "Segoe UI Mono", "Liberation Mono", Menlo, Monaco, Consolas, monospace;
  border-radius: 4px;
  font-size: 90%;
}
	${
		inherit
			? ''
			: `
${idSelectorFragment}.RichTextTheme__heading1 {
  font-size: ${defaultTypography.h1.fontSize}px;
  font-weight: ${defaultTypography.h1.fontWeight};
}
${idSelectorFragment}.RichTextTheme__heading2 {
  font-size: ${defaultTypography.h2.fontSize}px;
  font-weight: ${defaultTypography.h2.fontWeight};
}
${idSelectorFragment}.RichTextTheme__heading3 {
  font-size: ${defaultTypography.h3.fontSize}px;
  font-weight: ${defaultTypography.h3.fontWeight};
}
${idSelectorFragment}.RichTextTheme__heading4 {
  font-size: ${defaultTypography.h4.fontSize}px;
  font-weight: ${defaultTypography.h4.fontWeight};
}
${idSelectorFragment}.RichTextTheme__heading5 {
  font-size: ${defaultTypography.h5.fontSize}px;
  font-weight: ${defaultTypography.h5.fontWeight};
}
${idSelectorFragment}.RichTextTheme__heading6 {
  font-size: ${defaultTypography.h6.fontSize}px;
  font-weight: ${defaultTypography.h6.fontWeight};
}`
	}
`
}

const richTextEditorStyles = minifyCssString(getCssString())
const richTextEditorStylesInherit = minifyCssString(getCssString(undefined, true))

export const getRichTextEditorStyles = (inherit?: boolean) => {
	if (inherit) {
		return richTextEditorStylesInherit
	}
	return richTextEditorStyles
}
