import classNames from 'clsx'
import { createStyle } from '../../../theming'

const classes = createStyle((theme) => {
	// const checkedBackground = theme.controls.chip.checkedBackground || theme.colors.primaryButton.background
	// const background = theme.controls.chip.background || theme.colors.button.disabledBackground
	// const color = theme.controls.chip.color || theme.palette.primary.themePrimary
	// const checkedColor = theme.controls.chip.checkedColor || theme.colors.primaryButton.text
	// const pressedBackground = theme.controls.chip.pressedBackground || theme.colors.button.pressedBackground
	// const pressedColor = theme.controls.chip.pressedColor || theme.colors.button.pressedText
	return {
		chip: {
			display: 'inline-flex',
			minWidth: 40,
			overflow: 'hidden',
			textDecoration: theme.controls.chip.textDecoration,
			boxShadow: theme.controls.chip.dropShadow,
			background: theme.controls.chip.colors.background,
			color: theme.controls.chip.colors.color,
			borderColor: theme.controls.chip.colors.border,
			padding: theme.controls.chip.padding,
			fontSize: theme.controls.chip.fontSize,
			fontWeight: theme.controls.chip.fontWeight,
			borderRadius: theme.controls.chip.borderRadius,
			'&:focus-visible': {
				outlineOffset: theme.controls.chip.focusBorderOffset,
				outlineColor: theme.controls.chip.focusedBorderColor,
				outlineStyle: theme.controls.chip.focusBorderStyle,
				outlineWidth: theme.controls.chip.focusBorderWidth,
				background: theme.controls.chip.focusedColors.background,
			},
			'&:not([aria-disabled="true"])': {
				border: `${theme.controls.chip.borderWidth}px ${theme.controls.chip.borderStyle} ${theme.controls.chip.colors.border}`,
			},
		},
		clickable: {
			background: theme.controls.chip.colors.background,
			color: theme.controls.chip.colors.color,
			cursor: 'pointer',
			'&:active': {
				backgroundColor: theme.controls.chip.pressedColors.background,
				color: theme.controls.chip.pressedColors.color,
				borderColor: theme.controls.chip.pressedColors.border,
			},
			'&:hover:not([aria-disabled="true"])': {
				backgroundColor: theme.controls.chip.hoverColors.background,
				color: theme.controls.chip.hoverColors.color,
				borderColor: theme.controls.chip.hoverColors.border,
			},
		},
		checked: {
			backgroundColor: theme.controls.chip.checkedColors.background, //colors.primaryButton.background
			color: theme.controls.chip.checkedColors.color, // theme.colors.primaryButton.text
			borderColor: theme.controls.chip.checkedColors.border,

			cursor: 'pointer',
			'&:hover:not([aria-disabled="true"])': {
				background: theme.controls.chip.checkedHoverColors.background, //theme.colors.primaryButton.hoveredBackground
				color: theme.controls.chip.checkedHoverColors.color, // theme.colors.primaryButton.hoveredBackground
				borderColor: theme.controls.chip.checkedHoverColors.border,
			},
			'&:focus-visible': {
				outlineColor: theme.controls.chip.focusedBorderColor, //theme.colors.primaryButton.text,
			},
			'&:active': {
				background: theme.controls.chip.pressedColors.background, //theme.colors.primaryButton.hoveredBackground
				color: theme.controls.chip.pressedColors.color, // theme.colors.primaryButton.hoveredBackground
				borderColor: theme.controls.chip.pressedColors.border,
			},
		},
		collapsed: {
			maxWidth: 96,
		},
		disabled: {
			cursor: 'default',
			pointerEvents: 'none',
			background: theme.controls.chip.disabledColors.background, //theme.colors.input.disabledBackground
			color: theme.controls.chip.disabledColors.color, // theme.colors.button.disabledText
			borderColor: theme.controls.chip.disabledColors.border, // undefined
		},
		disabledActive: {
			background: theme.controls.chip.disabledCheckedColors.background, //theme.colors.list.checkedBackground
			color: theme.controls.chip.disabledCheckedColors.color, // theme.colors.button.disabledText
			borderColor: theme.controls.chip.disabledCheckedColors.border, // undefined
		},
		readOnly: {
			background: theme.controls.chip.colors.background,
			color: theme.controls.chip.colors.color,
			borderColor: theme.controls.chip.colors.border,
		},
		readOnlyActive: {
			backgroundColor: theme.controls.chip.checkedColors.background, //colors.primaryButton.background
			color: theme.controls.chip.checkedColors.color, // theme.colors.primaryButton.text
			borderColor: theme.controls.chip.checkedColors.border,
		},
		showTextOnly: {
			background: 'transparent',
		},
	}
})

export const useChipClassNames = (
	disabled: boolean | undefined,
	readOnly: boolean | undefined,
	isChecked: boolean | undefined,
	clickable: boolean | undefined,
	showTextOnly: boolean | undefined,
	className: string | undefined,
	isCollapsed: boolean | undefined
) => {
	return classNames(
		classes.chip,
		{
			[classes.clickable]: clickable,
			[classes.checked]: !disabled && !readOnly && isChecked,
			[classes.collapsed]: isCollapsed,
			[classes.disabled]: disabled,
			[classes.disabledActive]: disabled && isChecked,
			[classes.readOnly]: readOnly && !isChecked,
			[classes.readOnlyActive]: readOnly && isChecked,
			[classes.showTextOnly]: showTextOnly,
		},
		className
	)
}
