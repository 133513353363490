import moment from 'moment'
import numeral from 'numeral'
import { TICKS_PER_MINUTE } from './consts'
import { useCallback } from 'react'

export const getInternalFormatFromTicksMS = (valueTicksMs: number | undefined | null): number | undefined => {
	if (valueTicksMs === undefined || valueTicksMs === null) {
		return undefined
	}
	return valueTicksMs
}

export const useInternalFormatConverter = (isIntegerTime: boolean | undefined) => {
	const getInternalFormatFromIntegerTime = useCallback(
		(integerTimeValue: number | undefined | null): number | undefined => {
			if (integerTimeValue === undefined || integerTimeValue === null) {
				return undefined
			}

			const hours = Math.floor(integerTimeValue / 100)
			const minutes = Math.floor(integerTimeValue % 100)

			return (hours * 60 + minutes) * TICKS_PER_MINUTE
		},
		[]
	)

	return isIntegerTime ? getInternalFormatFromIntegerTime : getInternalFormatFromTicksMS
}

export const useExternalFormatConverter = (isIntegerTime: boolean | undefined) => {
	const getIntegerTimeFromInternalFormat = useCallback((durationTicksMS: number | undefined): number | undefined => {
		if (durationTicksMS === undefined) {
			return undefined
		}

		if (durationTicksMS === 0) {
			return 0
		}

		const duration = moment.duration(durationTicksMS)

		if (!duration.isValid()) {
			return undefined
		}

		return duration.hours() * 100 + duration.minutes()
	}, [])

	const getTicksMSFromInternalFormat = (durationTicksMS: number | undefined): number | undefined => {
		return durationTicksMS
	}

	return isIntegerTime ? getIntegerTimeFromInternalFormat : getTicksMSFromInternalFormat
}

export const useFormatDurationFunc = (isIntegerTime: boolean | undefined) => {
	const formatIntegerTimeValue = useCallback((durationTicksMS: number | undefined) => {
		if (durationTicksMS === undefined) {
			return ''
		}

		const duration = moment.duration(durationTicksMS)

		if (!duration.isValid()) {
			return ''
		}

		const momentTimeOfDay = moment({
			hour: duration.hours(),
			minute: duration.minutes(),
		})

		return momentTimeOfDay.format('LT')
	}, [])

	const formatDurationValue = useCallback((durationTicksMS: number | undefined) => {
		if (durationTicksMS === undefined) {
			return ''
		}

		if (durationTicksMS === 0) {
			return '00:00'
		}

		const duration = moment.duration(durationTicksMS)

		if (!duration.isValid()) {
			return ''
		}

		const hours = Math.floor(duration.asHours())
		const minutes = Math.floor(duration.minutes())

		return numeral(hours).format('00') + ':' + numeral(minutes).format('00')
	}, [])

	return isIntegerTime ? formatIntegerTimeValue : formatDurationValue
}
