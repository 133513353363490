import { useState } from 'react'
import type { XYCoord } from 'react-dnd'
import type { IListItem } from '../List'

export interface IInsertIndex {
	index: number | undefined
	groupIndex: number | undefined
}

export interface IInsertIndexSpecified {
	index: number
	groupIndex: number
}

const getRowIdsFromPos = (
	clientOffset: XYCoord | null,
	getIndexPositionById: (id: string) => IInsertIndexSpecified
):
	| {
			index: number
			groupIndex: number
	  }
	| undefined => {
	if (!clientOffset) {
		return
	}

	// get element from dom being hovered if its a valid drop itme
	// DRE fix in future
	const htmlDomElementsfromPoint: any[] = document.elementsFromPoint(clientOffset.x, clientOffset.y)

	const element = htmlDomElementsfromPoint.find((element) => {
		return element.dataset.rowid
	})

	if (!element) {
		return
	}

	const elementRect = element.getBoundingClientRect()

	const hoverIndex = getIndexPositionById(element.dataset.rowid)
	//if in the top half of the drop element
	if (clientOffset.y < elementRect.y + elementRect.height / 2) {
		return hoverIndex
	}

	// if in the bottom half of the element
	if (clientOffset.y >= elementRect.y + elementRect.height / 2) {
		return {
			...hoverIndex,
			index: hoverIndex.index + 1,
		}
	}
}

export const useDropObject = (
	items: IListItem[][] | IListItem[],
	getIndexPositionById: (id: string) => {
		groupIndex: number
		index: number
	},
	onDrop?: (insertIndex: IInsertIndex, itemType: string | symbol, item: IListItem) => void,
	onCanDrop?: (insertIndex: IInsertIndex, itemType: string | symbol | null, item: IListItem) => boolean
) => {
	const [insertIndex, setInsertIndex] = useState<{
		index: number | undefined
		groupIndex: number | undefined
	}>({ index: undefined, groupIndex: undefined })

	const handleDropHover = (
		itemType: string | symbol | null,
		item: IListItem,
		isOver: boolean,
		isDirectlyOver: boolean,
		clientOffset: XYCoord | null
	) => {
		const dropPosition = getRowIdsFromPos(clientOffset, getIndexPositionById)

		if (!dropPosition) {
			return
		}

		setInsertIndex(dropPosition as IInsertIndex)
	}

	const handleDrop = (itemType: string | symbol | null, item: IListItem) => {
		if (!onDrop) {
			return
		}
		const returnValue: IInsertIndex = items.length === 0 ? { index: 0, groupIndex: 0 } : insertIndex
		setInsertIndex({
			index: undefined,
			groupIndex: undefined,
		})

		// returns the group index and item index, of where the new item should be placed
		onDrop(returnValue, itemType as string, item)
	}

	const handleCanDrop = (itemType: string | symbol | null, item: IListItem) => {
		if (!onDrop) {
			return false
		}
		if (!onCanDrop) {
			return true
		}
		return onCanDrop(insertIndex, itemType, item)
	}

	const handleDragLeave = () => {
		setInsertIndex({
			index: undefined,
			groupIndex: undefined,
		})
	}

	return [insertIndex, handleDropHover, handleDrop, handleDragLeave, handleCanDrop] as const
}
