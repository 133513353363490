export enum e_NavigationItem {
	runtimes = 'runtimes',
	workloads = 'workloads',
	endpoints = 'endpoints',
	availability = 'availability',
	eventLog = 'eventlog',
	errorLog = 'errorlog',
	kubernetesEvents = 'kubernetesevents',
	metaModelManager = 'metamodelmanager',
}
