import React, { useRef, useState } from 'react'
import classNames from 'clsx'
import { Menu } from '../Menu'
import type { MenuItem } from '../Menu/MenuItem.types'
import { Icon } from '../Icon'
import type { ICommandBarClasses } from './CommandBar'

interface ICommandBarOverflowMenuProps {
	classes: ICommandBarClasses
	items: MenuItem[]
	size?: 'medium' | 'small'
}

export const CommandBarOverflowMenu = (props: ICommandBarOverflowMenuProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const ref = useRef<HTMLDivElement>(null)

	const hasItems = !!props.items.length
	const classes = classNames(props.classes.commandBarItem, {
		[props.classes.enabled]: hasItems,
		[props.classes.hidden]: !hasItems,
		[props.classes.active]: isOpen,
		[props.classes.commandBarItemSmall]: props.size === 'small',
	})

	const handleClose = () => {
		setIsOpen(false)
	}

	const toggleIsOpen = (e: React.MouseEvent | React.KeyboardEvent) => {
		setIsOpen(!isOpen)
		e.preventDefault()
		e.stopPropagation()
	}

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key === ' ') {
			e.preventDefault()
			e.stopPropagation()

			toggleIsOpen(e)
		}
	}

	return (
		<React.Fragment>
			<div
				className={classes}
				role="button"
				aria-disabled={!hasItems}
				ref={ref}
				onClick={toggleIsOpen}
				onKeyPress={handleKeyPress}
				tabIndex={0}
			>
				<span className={classNames(props.classes.buttonIcon)}>
					<Icon iconName="Fluent-More" />
				</span>
			</div>

			{hasItems && <Menu open={isOpen} onClose={handleClose} anchorEl={ref} items={props.items} />}
		</React.Fragment>
	)
}
