import React, { Suspense } from 'react'

// Providers
import { TableContextProvider } from './providers/TableContextProvider'

// Enums and Interfaces
import type { ITableRowData, ITableTreeData } from './Table.types'
import { SuspenseFallback } from './components'

// Lazy load Ag Grid Table, to ensure AG-grid is not loaded on initial load
const LazyTable = React.lazy(() => import('./LazyTable'))

const TableBase = (props: ITableRowData | ITableTreeData) => (
	<Suspense fallback={<SuspenseFallback {...props} />}>
		<TableContextProvider>
			<LazyTable {...props} />
		</TableContextProvider>
	</Suspense>
)

export const AgGridTable = React.memo(TableBase)
