import React, { useCallback, useEffect, useRef } from 'react'
import type PopperJS from 'popper.js'
import classNames from 'clsx'
import { getFirstFocusable, FocusTrap, Popper, useOnScrollOrResize, useMergeStyles, useID } from '../../controls/utils'
import { e_Placement } from '../../enums/e_Placement'
import { useRegisterKeyboardShortcutListenerContext } from '../../controls/utils/keyboard/useRegisterKeyboardShortcutListenerContext'
import { positionCalloutAndSetEdgeWithTransform } from './utils/positionCalloutAndSetEdgeWithTransform'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	callout: {
		background: theme.colors.body.background,
		boxShadow: theme.shadows.strong,
		overflow: 'hidden',
	},
	calloutBeak: {
		position: 'absolute',
		transform: 'rotate(45deg)',
		background: 'inherit',
		boxShadow: 'inherit',
	},
	calloutMain: {
		overflow: 'hidden',
		minHeight: 'inherit',
		position: 'relative',
		minWidth: 'max-content',
		background: 'inherit',
		padding: '20px 24px',
	},

	calloutHeader: {
		paddingBottom: '12px',
		fontSize: theme.typography.h3.fontSize,
		fontWeight: theme.typography.h3.fontWeight,
	},
}))

export type GapSpace = 0 | 5 | 10 | 15 | 20 | 25 | 30
export type BeakWidth = 10 | 15 | 20 | 25 | 30 | 35 | 40 | 45 | 50

interface ICalloutProps {
	anchorElement: React.RefObject<HTMLElement>
	children: React.ReactNode | React.ReactNodeArray
	header?: string
	isOpen: boolean
	placement?: e_Placement
	blockingModal?: boolean
	showBeak?: boolean
	gapSpace?: GapSpace
	beakWidth?: BeakWidth
	minWidth?: number
	maxWidth?: number
	onClose: () => void
	className?: string
	padding?: number
	fade?: boolean
	disableFocusOnOpen?: boolean
}

export const Callout = (props: ICalloutProps) => {
	const { onClose } = props

	const callout = useRef<HTMLDivElement>(null)
	const calloutBeak = useRef<HTMLDivElement>(null)
	const calloutContents = useRef<HTMLDivElement>(null)

	const popperInstance = useRef<PopperJS>()

	const intersectionObserver = useRef<IntersectionObserver>()
	const anchorElement = props.anchorElement.current
	useEffect(() => {
		const anchorElementVisibilityChanged = (entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				if (entry.intersectionRatio === 0) {
					props.onClose()
				}
			})
		}

		if (anchorElement && !(anchorElement instanceof SVGElement)) {
			intersectionObserver.current = new IntersectionObserver(anchorElementVisibilityChanged)
			intersectionObserver.current.observe(anchorElement)
		}
		return () => {
			if (intersectionObserver.current) {
				intersectionObserver.current.disconnect()

				intersectionObserver.current = undefined
			}
		}
	}, [anchorElement, props.onClose])
	const calloutId = useID()

	const dialogKeyboardShortcutContext = useRegisterKeyboardShortcutListenerContext(calloutId, true)

	useEffect(() => {
		if (props.disableFocusOnOpen) {
			return
		}

		if (props.isOpen) {
			const firstFocusable = getFirstFocusable(callout.current)

			firstFocusable?.focus()
		}
	}, [props.disableFocusOnOpen, props.isOpen])

	const onTabPressed = useCallback(
		(e: KeyboardEvent) => {
			if (!callout.current?.contains(document.activeElement)) {
				const firstFocusable = getFirstFocusable(callout.current)
				if (firstFocusable) {
					e.preventDefault()
					firstFocusable?.focus()
				} else {
					onClose()
				}
			}
		},
		[onClose]
	)

	const onEscapePressed = useCallback(() => {
		onClose()

		props.anchorElement.current?.focus()
	}, [onClose, props.anchorElement])

	const onOutsideClick = () => {
		if (!props.blockingModal) {
			onClose()
		}
	}

	const onScrollOrResize = () => {
		popperInstance.current?.update()
	}

	useOnScrollOrResize(onScrollOrResize, callout, props.isOpen)

	const handleApplyStyle = (data: PopperJS.Data) => {
		if (!calloutContents.current) {
			return
		}

		popperInstance.current = data.instance

		positionCalloutAndSetEdgeWithTransform(
			data.instance.popper as HTMLDivElement,
			data.instance.reference as HTMLElement,
			calloutContents,
			calloutBeak,
			props.placement,
			props.showBeak,
			props.gapSpace,
			props.beakWidth
		)
	}

	const styleMain = useMergeStyles(
		{
			padding: props.padding,
		},
		[props.padding]
	)

	return (
		<Popper
			anchorElement={props.anchorElement}
			open={props.isOpen}
			placement={e_Placement.bottom}
			onOutsideClick={onOutsideClick}
			enablePortal
			enableBackdrop
			blocking={props.blockingModal}
			applyStyle={handleApplyStyle}
			onTabPressed={onTabPressed}
			onEscapePressed={onEscapePressed}
			fade={props.fade}
			useStylesFromDocumentRoot
		>
			<FocusTrap preventScrollOnFocus>
				<div
					className={classNames(classes.callout, props.className)}
					ref={callout}
					data-keyboardlistener={dialogKeyboardShortcutContext.contextId}
				>
					{<div className={classes.calloutBeak} ref={calloutBeak} />}
					<div className={classes.calloutMain} style={styleMain} ref={calloutContents}>
						{props.header && <div className={classes.calloutHeader}>{props.header}</div>}
						{props.children}
					</div>
				</div>
			</FocusTrap>
		</Popper>
	)
}
