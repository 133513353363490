import React, { useState } from 'react'
import classNames from 'clsx'

import { Avatar } from '../Avatar'
import type { IIconSize } from '../Icon'
import { Icon } from '../Icon'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useChipClassNames } from './utils/useChipClassNames'
import type { IIconNames } from '@genusbiz/icon-set/dist/utils/IIconNames'
import { createStyle } from '../../theming'
import { useGetStyle } from '../utils/Style.context'

const classes = createStyle((theme) => {
	return {
		clickableLink: { '&:hover:not([aria-disabled="true"])': { textDecoration: 'underline' } },
		avatar: {
			display: 'flex',
			justifyContent: 'center',
			height: '100%',
			overflow: 'hidden',
			userSelect: 'none',
			marginRight: 4,
			flexBasis: '20px',
		},
		content: {
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			padding: '0 4px 0 3px',
			userSelect: 'none',
			alignItems: 'center',
			display: 'flex',
			'& > i, & > svg': {
				marginRight: 6,
			},
		},
		deleteButton: {
			display: 'flex',
			alignItems: 'center',
			fontSize: '0.5em',
			background: 'transparent',
			border: '1px solid transparent',
			color: theme.controls.chip.colors.color,
			cursor: 'pointer',
			outline: 0,
			'&:not(:disabled):hover': {
				background: theme.controls.chip.hoverColors.background,
			},
			'&:focus': {
				border: '1px solid ' + theme.controls.chip.focusedBorderColor,
			},
			'&:active': {
				background: theme.controls.chip.pressedColors.background,
			},
		},
	}
})

export interface AvatarInfo {
	initials: string
}

interface IChipProps {
	index?: number
	label: string
	dataId?: string | number
	screenTip?: string
	deleteScreenTip?: string
	avatar?: AvatarInfo
	isActive?: boolean
	tabStop?: boolean
	disabled?: boolean
	readOnly?: boolean
	clickable?: boolean
	isLink?: boolean
	collapsible?: boolean
	defaultCollapsed?: boolean
	className?: string
	style?: React.CSSProperties
	onClick?: (index?: number) => void
	onKeyDown?: (e: React.KeyboardEvent<Element>) => void
	onKeyPress?: (e: React.KeyboardEvent<Element>) => void
	onDelete?: (index?: number) => void
	showTextOnly?: boolean
	dataAttributes?: Record<string, string>
	icon?: IIconNames
	iconSize?: IIconSize
}

export const Chip = React.forwardRef((props: IChipProps, ref: React.Ref<HTMLDivElement>) => {
	const chipRef = useForwardedRef<HTMLDivElement>(ref)

	const [isCollapsed, setIsCollapsed] = useState(props.collapsible && props.defaultCollapsed)

	const onClick = (e: React.MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
		if (!props.clickable || props.disabled || props.readOnly) {
			return
		}

		props.collapsible && setIsCollapsed(!isCollapsed)
		props.onClick?.(props.index)
	}

	const onDelete = (e: React.MouseEvent) => {
		e.stopPropagation()

		if (props.disabled || props.readOnly) {
			return
		}

		props.onDelete?.(props.index)
	}

	const clickable = !props.disabled && !props.readOnly && (props.clickable || !!props.onClick)

	const chipClassNames = useChipClassNames(
		props.disabled,
		props.readOnly,
		props.isActive,
		clickable,
		props.showTextOnly,
		props.className,
		isCollapsed
	)

	const styleIconSize = useGetStyle().button?.iconSize

	return (
		<div
			className={chipClassNames}
			style={props.style}
			ref={chipRef}
			tabIndex={getTabIndex(props.isActive, props.tabStop, props.disabled)}
			onKeyDown={props.onKeyDown}
			onKeyPress={props.onKeyPress}
			title={props.screenTip}
			role={!props.disabled ? 'button' : undefined}
			aria-disabled={props.disabled}
			onClick={props.clickable ? onClick : undefined}
			data-id={props.dataId}
			{...props.dataAttributes}
		>
			<span className={classNames(classes.content, { [classes.clickableLink]: clickable && props.isLink })}>
				{props.avatar && <Avatar initials={props.avatar.initials} className={classes.avatar} size="extraSmall" />}
				{props.icon && <Icon iconName={props.icon} size={props.iconSize || styleIconSize || 'extraSmall'} />}
				{props.label}
			</span>
			{props.onDelete && !props.showTextOnly && !props.readOnly && (
				<button
					onClick={onDelete}
					onKeyDown={(e) => e.key === 'Enter' && e.stopPropagation()}
					className={classes.deleteButton}
					aria-hidden="true"
					disabled={props.disabled}
					title={props.deleteScreenTip}
					tabIndex={-1}
				>
					<Icon iconName="Fluent-ChromeClose" size="extraSmall" style={{ fontSize: '8px' }} />
				</button>
			)}
		</div>
	)
})

Chip.displayName = 'Chip'

Chip.defaultProps = {
	clickable: true,
} as Partial<IChipProps>

function getTabIndex(
	isActive: boolean | undefined,
	tabStop: boolean | undefined,
	isDisabled: boolean | undefined
): number | undefined {
	if (isDisabled) {
		return undefined
	}
	return isActive || tabStop ? 0 : -1
}
