import {
	DurationParsePatterns,
	IntegerTimeParsePatterns,
	TICKS_PER_DAY,
	TICKS_PER_HOUR,
	TICKS_PER_MINUTE,
} from './consts'

export const parseIntegerTimeTextValue = (textValue: string): number | undefined => {
	for (const pattern of IntegerTimeParsePatterns) {
		const match = pattern.exec(textValue)

		if (match?.groups) {
			const hour = match.groups.hour ? Number.parseInt(match.groups.hour) % 24 : 0
			const minute = match.groups.minute ? Number.parseInt(match.groups.minute) : 0
			const ampm = match.groups.ampm

			const pmHours = ampm?.toLowerCase() === 'pm' ? 12 : 0

			return (hour + pmHours) * TICKS_PER_HOUR + minute * TICKS_PER_MINUTE
		}
	}

	return undefined
}

export const parseDurationTextValue = (textValue: string): number | undefined => {
	for (const pattern of DurationParsePatterns) {
		const match = pattern.exec(textValue)

		if (match?.groups) {
			const days = match.groups.days ? Number.parseInt(match.groups.days) : 0
			const hours = match.groups.hours ? Number.parseInt(match.groups.hours) : 0
			const minutes = match.groups.minutes ? Number.parseInt(match.groups.minutes) : 0

			return days * TICKS_PER_DAY + hours * TICKS_PER_HOUR + minutes * TICKS_PER_MINUTE
		}
	}

	return undefined
}
