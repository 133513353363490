import React from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'

import '@genusbiz/icon-set/dist/fonts'
import type { ISvgIcons } from '@genusbiz/icon-set/dist/react-svg-paths'
import { getReactSvgPaths } from '@genusbiz/icon-set/dist/react-svg-paths'
import type { IIconNames } from '@genusbiz/icon-set/dist/utils/IIconNames'
import { svgIconLookup } from '@genusbiz/icon-set/dist/utils/svgIconLookup'

import type { StyleObject } from '../utils/useMergeStyles'
import { useMergeStyles } from '../utils/useMergeStyles'

export type IIconSize =
	| 'extraSmall'
	| 'small'
	| 'medium'
	| 'large'
	| 'extraLarge'
	| 'size12'
	| 'size16'
	| 'size20'
	| 'size24'
	| 'size32'
	| 'size48'
	| 'size64'

interface IIconProps {
	iconName?: IIconNames
	iconClassName?: string

	className?: string
	color?: string
	size?: IIconSize
	rotate?: 45 | -45 | 90 | -90 | 135 | -135 | 180 | -180 | 225 | -225 | 270 | -270 | 315 | -315
	margin?: number | StyleObject
	style?: StyleObject
	screenTip?: string
}

const classes = createStyle({
	icon: {
		overflow: 'hidden',
		flexShrink: 0,
		flexGrow: 0,
	},
	extraLarge: {
		fontSize: 64,
		lineHeight: '64px',
	},
	large: {
		fontSize: 48,
		lineHeight: '48px',
	},
	medium: {
		fontSize: 32,
		lineHeight: '32px',
	},
	small: {
		fontSize: 16,
		lineHeight: '16px',
	},
	extraSmall: {
		fontSize: 12,
		lineHeight: '12px',
	},
	size64: {
		fontSize: 64,
		lineHeight: '64px',
	},
	size48: {
		fontSize: 48,
		lineHeight: '48px',
	},
	size32: {
		fontSize: 32,
		lineHeight: '32px',
	},
	size24: {
		fontSize: 24,
		lineHeight: '24px',
	},
	size20: {
		fontSize: 20,
		lineHeight: '20px',
	},
	size16: {
		fontSize: 16,
		lineHeight: '16px',
	},
	size12: {
		fontSize: 12,
		lineHeight: '12px',
	},
})

const mapSizeStringToNumber = {
	extraSmall: 12,
	small: 16,
	medium: 32,
	large: 48,
	extraLarge: 64,
	size64: 64,
	size48: 48,
	size32: 32,
	size24: 24,
	size20: 20,
	size16: 16,
	size12: 12,
} as const

export const Icon = (props: IIconProps) => {
	const { size = 'small' } = props

	const style = useMergeStyles(
		{
			color: props.color,
			margin: props.margin,
			...(props.style ? props.style : {}),
		},
		[props.color, props.margin]
	)

	const iconName = backwardsCompatibility(props.iconName || props.iconClassName)

	if (iconName in svgIconLookup) {
		const SvgPaths = getReactSvgPaths(iconName as ISvgIcons)
		const sizeAsNumber = mapSizeStringToNumber[size]
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 32 32"
				width={sizeAsNumber}
				height={sizeAsNumber}
				className={classNames(
					'svg-inherit-color',
					props.rotate && 'genusIconRotate' + String(props.rotate),
					props.className
				)}
				style={style}
				aria-hidden
			>
				{props.screenTip && <title>{props.screenTip}</title>}
				{SvgPaths}
			</svg>
		)
	}

	return (
		<i
			className={classNames(
				classes.icon,
				iconName.includes('-') ? iconName : 'Fluent-' + iconName,
				props.rotate && 'rotate' + String(props.rotate),
				classes[size],
				props.className
			)}
			title={props.screenTip}
			style={style}
			aria-hidden
		/>
	)
}

const backwardsCompatibility = (iconName?: string | IIconNames) => {
	if (!iconName) {
		return ''
	}

	if (iconName.startsWith('ms-Icon ms-Icon--')) {
		iconName = iconName
			.replace('ms-Icon ms-Icon--', 'Fluent-')
			.replace('12PointStar', 'TwelvePointStar')
			.replace('6PointStar', 'SixPointStar')
	} else if (iconName.startsWith('genus-Icon genus-Icon--')) {
		iconName = iconName
			.replace('genus-Icon genus-Icon--', 'Genus-')
			.replace('2dgrid', 'Twodgrid')
			.replace('3dgrid', 'Threedgrid')
	}

	return iconName
}
