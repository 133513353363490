import { useEffect, useRef, useState } from 'react'
import { useTranslation } from '../../../translation'
import { useExternalFormatConverter, useFormatDurationFunc, useInternalFormatConverter } from './formatUtils'
import { TICKS_PER_MINUTE } from './consts'
import type { DurationValueType } from '../DurationInput'

export const useUpdateInternalValue = (
	inputRef: React.RefObject<HTMLInputElement>,
	propValue: DurationValueType,
	isIntegerTime: boolean | undefined,
	minValueMinutes: number,
	maxValueMinutes: number | undefined,
	required: boolean | undefined,
	error: boolean | undefined,
	onChange: (value?: DurationValueType) => void
) => {
	const { tcvi, tcvvi } = useTranslation()

	const internalValue = useRef<number | undefined>()
	const isEnteredValueValid = useRef(true)
	const [internalErrorMessage, setInternalErrorMessage] = useState<string | undefined>()
	const convertToExternalFormatFunc = useExternalFormatConverter(isIntegerTime)
	const convertToInternalFormatFunc = useInternalFormatConverter(isIntegerTime)

	const formatDurationFunc = useFormatDurationFunc(isIntegerTime)
	const [displayValue, setDisplayValue] = useState('')
	const userIsWriting = useRef(false)

	const validateValue = (valueTicksMS: number | undefined): string | undefined => {
		if (valueTicksMS === undefined && required && error) {
			return tcvi('GENERAL:DATA_VALIDATION_ERROR_VALUE_CANNOT_BE_BLANK')
		}
		// check if value is within range
		if (valueTicksMS !== undefined) {
			if (minValueMinutes && valueTicksMS < minValueMinutes * TICKS_PER_MINUTE) {
				return tcvvi('GENERAL:DATA_VALIDATION_VALUE_BELOW_MIN', formatDurationFunc(minValueMinutes * TICKS_PER_MINUTE))
			}

			if (maxValueMinutes && valueTicksMS > maxValueMinutes * TICKS_PER_MINUTE) {
				return tcvvi('GENERAL:DATA_VALIDATION_VALUE_ABOVE_MAX', formatDurationFunc(maxValueMinutes * TICKS_PER_MINUTE))
			}
		}

		return undefined
	}

	useEffect(() => {
		// a new value has been passed in

		const oldValue = internalValue.current
		if (oldValue !== undefined && oldValue === convertToInternalFormatFunc(propValue)) {
			return
		}

		internalValue.current = convertToInternalFormatFunc(propValue)

		setDisplayValue(formatDurationFunc(internalValue.current))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [propValue])

	useEffect(() => {
		updateInternalValue(internalValue.current, false, false, false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [required, propValue, error])

	useEffect(() => {
		if (inputRef.current === document.activeElement && !userIsWriting.current) {
			inputRef.current?.select()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayValue])

	const updateInternalValue = (
		value: number | undefined,
		updateTextValue: boolean,
		raiseValueChanged: boolean,
		isTextValueInvalid: boolean
	) => {
		internalValue.current = value

		const validationMessage = validateValue(internalValue.current)

		setInternalErrorMessage(isTextValueInvalid ? tcvi('GENERAL:DATA_VALIDATION_INVALID_VALUE') : validationMessage)

		isEnteredValueValid.current = true

		if (updateTextValue) {
			setDisplayValue(formatDurationFunc(value))
		}

		if (raiseValueChanged) {
			const externalFormat = convertToExternalFormatFunc(value)

			onChange?.(externalFormat)
		}
	}

	return {
		updateInternalValue,
		convertToExternalFormatFunc,
		displayValue,
		internalValue,
		internalErrorMessage,
		setDisplayValue,
		isEnteredValueValid,
		userIsWriting,
	}
}
