export function alphaCompareObjectsByStringProperty<T>(property: keyof T) {
	return function (obj1: T, obj2: T) {
		const prop1 = obj1[property]
		const prop2 = obj2[property]
		if (typeof prop1 !== 'string' || typeof prop2 !== 'string') {
			throw Error(`Trying to string-compare non string property ${String(property)}`)
		}

		//TODO: getFormattingCultureLocale SWI23032020
		return prop1.localeCompare(prop2, 'en', { sensitivity: 'base' })
	}
}

export function alphaSortObjectsByStringProperty<K extends keyof T, T extends { [key in K]: string }>(
	objectArray: T[],
	property: K
) {
	const filteredArray = objectArray.filter((object) => typeof object[property] === 'string')
	filteredArray.sort(alphaCompareObjectsByStringProperty(property))

	return filteredArray
}

export function sortObjectsByStringProperties<T>(
	objectArray: T[],
	properties: { value: keyof T; direction: 'asc' | 'desc' | undefined; comparator?: (a: T, b: T) => number }[]
) {
	return [...objectArray].sort((a, b) => {
		for (const prop of properties) {
			const sortDescending = prop.direction === 'desc'
			if (!prop.comparator) {
				let compareFn
				if (a[prop.value] === b[prop.value]) {
					compareFn = () => 0
				} else if (a[prop.value] === null) {
					compareFn = () => 1
				} else if (b[prop.value] === null) {
					compareFn = () => -1
				} else if (typeof a[prop.value] === 'string' && typeof b[prop.value] === 'string') {
					compareFn = alphaCompareObjectsByStringProperty(prop.value)
				} else if (typeof a[prop.value] === 'boolean' && typeof b[prop.value] === 'boolean') {
					compareFn = (a: T, b: T) => (a[prop.value] && b[prop.value] ? 0 : a[prop.value] ? -1 : b[prop.value] ? 1 : 0)
				} else if (
					(typeof a[prop.value] === 'bigint' || typeof a[prop.value] === 'number') &&
					(typeof b[prop.value] === 'bigint' || typeof b[prop.value] === 'number')
				) {
					compareFn = (a: T, b: T) => (a[prop.value] > b[prop.value] ? 1 : a[prop.value] === b[prop.value] ? 0 : -1)
				} else {
					throw Error(
						`Trying to compare values that are not string, boolean, bigint or number on property ${String(prop.value)}`
					)
				}
				const compareValue = compareFn(a, b) * (sortDescending ? -1 : 1)
				if (compareValue !== 0) {
					return compareValue
				}
			} else {
				const compareValue = prop.comparator(a, b) * (sortDescending ? -1 : 1)
				if (compareValue !== 0) {
					return compareValue
				}
			}
		}
		return 0
	})
}
