import React from 'react'
import { createStyle } from '../../theming'
import type { IFormControl } from '../FormControl'
import { FormControl } from '../FormControl'

import { useID } from '../utils/useID'

const classes = createStyle((theme) => ({
	'@keyframes onAutoFillStart': {},
	input: {
		whiteSpace: 'nowrap',
		'&:-webkit-autofill': {
			background: theme.colors.input.background,
			animationName: '$onAutoFillStart',
		},
	},
}))

interface IPasswordInput extends IFormControl {
	onChange?: (value: string) => void
	onKeyDown?: (e: React.KeyboardEvent) => void
	onAutoFill?: () => void
	placeholder?: string
	autoComplete?: string
	subText?: string
	warning?: boolean
	error?: boolean
}

export const PasswordInput = React.forwardRef((props: IPasswordInput, forwardedRef: React.Ref<HTMLInputElement>) => {
	const id = useID(props.id)

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange?.(e.target.value)
	}

	return (
		<FormControl
			disableBorder={props.disableBorder}
			labelSubText={props.labelSubText}
			validationText={props.validationText}
			validationTextPosition={props.validationTextPosition}
			subText={props.subText}
			warning={props.warning}
			error={props.error}
			screenTip={props.screenTip}
			label={props.label}
			labelPosition={props.labelPosition}
		>
			<input
				id={id}
				{...props.dataAttributes}
				ref={forwardedRef}
				className={classes.input}
				type="password"
				onAnimationEnd={props.onAutoFill}
				onChange={onChange}
				onKeyDown={props.onKeyDown}
				spellCheck="false"
				autoComplete={props.autoComplete || 'off'}
				autoCapitalize="none"
				placeholder={props.placeholder}
				name={props.name}
			/>
		</FormControl>
	)
})

PasswordInput.displayName = 'PasswordInput'
