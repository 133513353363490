import React, { useEffect, useMemo, useRef } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../../../../theming'

import { TextInput } from '../../../../TextInput'
import { e_Interpretation } from '../../../enums/e_Interpretation'
import type { ICellRenderer } from '../../../Table.types'
import { CELL_PADDING } from '../../../consts'
//
const classes = createStyle({
	textInput: { flex: 1 },
	displayField: {
		overflow: 'hidden',
		padding: `0px ${CELL_PADDING}px`,
	},
})

interface ICellRendererText extends ICellRenderer {
	value: string | undefined
	interpretation: e_Interpretation
	onBlur: () => void
}

enum e_TextInputTypes {
	email = 'email',
	phone = 'phone',
	url = 'url',
	text = 'text',
	password = 'password',
}

export const CellRendererText = (props: ICellRendererText) => {
	const ref = useRef<HTMLInputElement>(null)

	useEffect(() => {
		ref.current?.focus()
		ref.current?.select()
	}, [])

	const textControlType: e_TextInputTypes = useMemo(() => {
		switch (props.interpretation) {
			case e_Interpretation.email: {
				return e_TextInputTypes.email
			}
			case e_Interpretation.internetUrl:
			case e_Interpretation.fileUrl: {
				return e_TextInputTypes.url
			}
			case e_Interpretation.phone: {
				return e_TextInputTypes.phone
			}
			case e_Interpretation.password: {
				return e_TextInputTypes.password
			}
			default:
				return e_TextInputTypes.text
		}
	}, [props.interpretation])

	return (
		<TextInput
			ref={ref}
			value={props.value}
			className={classNames(classes.textInput, props.className)}
			onChange={props.setValue}
			labelPosition={'top'}
			textType={textControlType}
			hideActionButton
			error={props.hasError}
			onBlur={props.onBlur}
		/>
	)
}
