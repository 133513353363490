import React, { useRef, useState, useEffect, useContext, useLayoutEffect } from 'react'

import classNames from 'clsx'
import { DialogConfirmationMessage } from './DialogConfirmationMessage'

import { generateCommonButtonDescription } from './utils/generateCommonButtonDescription'
import type { DialogButtonDescription } from './DialogTypes'
import { topWindow } from '../utils/topWindow'
import { Button } from '../Button'
import { Modal } from '../Modal'
import { ResizableOverlay } from '../Resizable'
import { DialogTitle } from './DialogTitle'
import throttle from 'lodash/throttle'
import { getFirstFocusable } from '../utils/getFirstFocusable'
import { useEventListener } from '../utils/useEventListener'
import { useID } from '../utils/useID'
import { useTranslation } from '../../translation'
import type { CommandBarItem } from '../CommandBar'
import { KeyboardShortcutListenerContext, useRegisterKeyboardShortcutListenerContext } from '../utils/keyboard'
import { createStyle } from '../../theming'

const MOBILE_WIDTH = 600

let modalRootSelector: string | undefined

export const setModalRootSelector = (selector: string | undefined) => {
	modalRootSelector = selector
}

const formatPixel = (number: number) => {
	return `${number}px`
}

const PADDNG_WIDTH = 12

const classes = createStyle((theme) => {
	return {
		dialog: {
			color: theme.colors.list.text,
			background: theme.palette.background.white,
			flex: 1,
			height: '100%',
			borderRadius: theme.controls.dialog.borderRadius,
			display: 'flex',
			flexDirection: 'column',
			outline: 'none',
			overflow: 'auto',
			[`@media (max-width:${MOBILE_WIDTH}px)`]: { borderWidth: 0, height: '100%' },
		},

		resizableBorder: {
			borderRadius: theme.controls.dialog.borderRadius,
		},

		severityHeader: {
			height: '4px',
		},

		severityHeaderError: {
			background: theme.palette.error.errorPrimary,
		},

		severityHeaderWarning: {
			background: theme.palette.warning.warningPrimary,
		},

		dropShadow: {
			boxShadow: theme.shadows.strong,
		},
		dialogHeader: {
			display: 'inline-flex',
			paddingTop: 12,
			paddingLeft: 12,
			paddingRight: 12,
			paddingBottom: 12,
			marginBottom: 8,
		},

		dialogHeaderSmall: {
			paddingTop: 8,
			paddingLeft: 8,
			paddingRight: 8,
			paddingBottom: 8,
			marginBottom: 0,
		},
		dialogHeaderContent: {
			flex: '1',
			display: 'flex',
			cursor: 'move',
			overflow: 'hidden',
			touchAction: 'none',
			alignItems: 'start',
			[`@media (max-width:${MOBILE_WIDTH}px)`]: { cursor: 'default' },
		},
		dialogHeaderButtons: {
			display: 'flex',
			alignItems: 'center',
		},
		dialogHeaderText: {
			flex: 1,
			fontSize: theme.controls.dialog.headerFontSize,
			fontWeight: theme.controls.dialog.headerFontWeight,
		},
		dialogHeaderTextSmall: {
			flex: 1,
			fontSize: theme.controls.dialog.smallHeaderFontSize,
			fontWeight: theme.controls.dialog.smallHeaderFontWeight,
		},
		textOverflowEllipsis: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },

		dialogBorder: {
			borderWidth: theme.controls.dialog.borderWidth,
			borderColor: theme.controls.dialog.borderColor,
			borderStyle: 'solid',
		},
		severityErrorBorder: {
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: theme.palette.error.errorPrimary,
		},
		dialogContents: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			overflow: 'auto',
			paddingLeft: PADDNG_WIDTH,
			paddingRight: PADDNG_WIDTH,
			marginBottom: 8,
		},
		dialogContentsSmall: {
			paddingLeft: 8,
			paddingRight: 8,
			marginBottom: 8,
		},

		dialogContentsWithoutHeader: {
			paddingTop: 12,
		},
		dialogContentsWithoutHeaderSmall: {
			paddingTop: 8,
		},

		dialogAutoHeight: {
			flex: 'unset',
		},
		dialogButtons: {
			display: 'flex',
			flexDirection: 'row',
			flexShrink: 0,
			marginTop: 12,
			marginBottom: 12,
			paddingLeft: 12,
			paddingRight: 12,
			overflow: 'auto',
		},
		dialogButtonsSmall: {
			marginTop: 8,
			marginBottom: 8,
			paddingLeft: 8,
			paddingRight: 8,
		},
		dialogButtonsLeft: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			flexGrow: 1,
			'& button': {
				margin: 2,
				whiteSpace: 'nowrap',
			},
		},
		dialogButtonsRight: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			'& button': {
				margin: 2,
				whiteSpace: 'nowrap',
			},
		},
		dialogButton: {
			border: '1px solid transparent',
		},
	}
})

type ICommonButtonTypes = 'ok' | 'cancel' | 'yes' | 'no' | 'close'

export interface IDialogProps {
	title?: string
	width?: number | 'auto'
	widthType?: 'inner' | 'outer'
	position?: 'center' | 'cascade'
	height?: number | 'auto'
	heightType?: 'inner' | 'outer'
	isOpen: boolean
	isModified?: boolean
	isValid?: boolean
	children?: React.ReactNode
	stack?: string
	disableCancelOnClickOutside?: boolean
	disableCancelOnEscape?: boolean
	hideBackdrop?: boolean
	hideDialogHeader?: boolean
	stayOnTop?: boolean
	disableShowConfirmationMessageIfModified?: boolean
	commonButtonSet?: 'okCancel' | 'yesNo' | 'yesNoCancel' | ICommonButtonTypes
	buttonDescriptions?: DialogButtonDescription[]
	handleClose?: (commonButton: ICommonButtonTypes) => void
	onKeyDown?: (e: React.KeyboardEvent) => void
	updatePosition?: boolean
	positionUpdated?: () => void
	severity?: string
	headerCommands?: CommandBarItem[]
	resizable?: boolean
	onResize?: (width: number, height: number) => void
	classes?: object
	variant?: 'normal' | 'small'
	modalClassName?: string
	backdropClassName?: string
	wrapTitle?: boolean
}
let currentX = 0
let currentY = 0

const CLASSNAME_CONTENT = 'dialogContent'
const CLASSNAME_BUTTONS = 'dialogButtons'

export const Dialog = (props: IDialogProps) => {
	const { widthType = 'outer', heightType = 'outer' } = props

	const isValidInnerHeight = heightType === 'inner' && typeof props.height === 'number'
	const isValidInnerWidth = widthType === 'inner' && typeof props.width === 'number'

	const innerWidth = typeof props.width === 'number' ? props.width : 0
	const innerHeight = typeof props.height === 'number' ? props.height : 0

	const domRef = useRef<HTMLDivElement>(null)
	const domDialog = useRef<HTMLDivElement>(null)

	const { tcvi } = useTranslation()

	const dialogId = useID(undefined)

	const parentContext = useContext(KeyboardShortcutListenerContext)
	const dialogKeyboardShortcutContext = useRegisterKeyboardShortcutListenerContext(dialogId, true)

	const fullScreen = useRef(topWindow.innerWidth <= MOBILE_WIDTH)

	const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)
	const [shallFitInnerSize, setShallFitInnerSize] = useState(true)

	useEffect(() => {
		window.setTimeout(() => setInitialFocus(), 0)
	}, [])

	const currentStyle = domDialog?.current?.style

	// Reapply styling when react portal domNode changes
	useLayoutEffect(() => {
		if (!domDialog.current || !currentStyle) {
			return
		}

		const style = domDialog.current.style

		style.width = currentStyle.width
		style.height = currentStyle.height

		style.setProperty('--width', currentStyle.width)
		style.setProperty('--height', currentStyle.height)

		style.left = currentStyle.left
		style.top = currentStyle.top

		if (currentStyle.transform === '') {
			style.setProperty('--left', currentStyle.getPropertyValue('--left'))
			style.setProperty('--top', currentStyle.getPropertyValue('--top'))
		} else {
			style.transform = currentStyle.transform
		}
	}, [modalRootSelector])

	const getElementsByClassName = (className: string): HTMLElement | undefined => {
		if (!domRef.current) {
			return undefined
		}

		const elements = domRef.current.getElementsByClassName(className)

		if (!elements) {
			return undefined
		}

		if (elements.length !== 1) {
			return undefined
		}

		return elements[0] as HTMLElement
	}

	const setInitialFocus = () => {
		// find first focusable element annd set focus

		// find first focusable element inside content, or as a fallback focus first enabled button
		let focusElement: any
		let parentElement = getElementsByClassName(CLASSNAME_CONTENT)
		if (parentElement) {
			focusElement = getFirstFocusable(parentElement)
		}

		if (!focusElement) {
			parentElement = getElementsByClassName(CLASSNAME_BUTTONS)
			if (parentElement) {
				focusElement = getFirstFocusable(parentElement)
			}
		}

		if (focusElement !== undefined && focusElement !== null) {
			focusElement.select ? focusElement.select() : focusElement.focus()
		}
	}

	const completePointerOperation = (updateValue: boolean, event: Event) => {
		setTimeout(() => {
			const pointerEvent = event as PointerEvent

			const dialogHeader = pointerEvent.target as Element

			dialogHeader.removeEventListener('pointerup', handlePointerUp)
			dialogHeader.removeEventListener('pointermove', handlePointerMove)
			dialogHeader.removeEventListener('pointercancel', handlePointerCancel)
		}, 0)
	}

	const handlePointerUp = (event: Event) => {
		completePointerOperation(true, event)

		event.preventDefault()
		event.stopPropagation()
	}

	const handlePointerMove = (event: Event) => {
		event.preventDefault()
		event.stopPropagation()

		// set the element's new position:
		if (domRef === null || domRef.current === null) {
			return
		}
		const element = domDialog.current
		if (!element) {
			return
		}

		const pointerEvent = event as PointerEvent

		// calculate the new cursor position:

		const elementBounds = element.getBoundingClientRect()

		// clamp the offset so that the dialog is not dragged outside the document
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		const mousePos = { x: pointerEvent.clientX, y: pointerEvent.clientY }
		const minPos = {
			x: 0,
			y: 0,
		}
		const maxPos = {
			x: topWindow.innerWidth - elementBounds.width,
			y: topWindow.innerHeight - elementBounds.height,
		}
		const offsetX = currentX - mousePos.x
		const offsetY = currentY - mousePos.y
		currentX = mousePos.x
		currentY = mousePos.y

		const nextDialogPos = {
			left: Math.max(minPos.x, Math.min(maxPos.x, element.offsetLeft - offsetX)),
			top: Math.max(minPos.y, Math.min(maxPos.y, element.offsetTop - offsetY)),
		}

		// updateDialogStylePos(element, nextDialogPos)
		element.style.setProperty('--left', formatPixel(nextDialogPos.left))
		element.style.setProperty('--top', formatPixel(nextDialogPos.top))
	}

	const handlePointerCancel = (event: Event) => {
		completePointerOperation(false, event)
	}

	const handlePointerDown = (event: React.PointerEvent) => {
		const headerElement = event.target as Element

		if (!headerElement) {
			return
		}

		event.preventDefault()
		event.stopPropagation()

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		headerElement.setPointerCapture(event.pointerId)

		headerElement.addEventListener('pointerup', handlePointerUp)
		headerElement.addEventListener('pointermove', handlePointerMove)
		headerElement.addEventListener('pointercancel', handlePointerCancel)

		currentX = event.clientX
		currentY = event.clientY
	}

	const hasCancelButton = () => {
		return (
			props.commonButtonSet === 'okCancel' || props.commonButtonSet === 'yesNoCancel' || props.handleClose !== undefined
		)
	}

	const handleCancelKeyDown = (e: React.KeyboardEvent) => {
		e.stopPropagation()
		handleClose('cancel')
	}

	const handleBackdropClick = (e: React.MouseEvent) => {
		e.stopPropagation()

		if (!props.disableCancelOnClickOutside && hasCancelButton()) {
			handleClose('cancel')
		}

		// if user has clicked outside, the user should get a notification, for instance flash dialog border
	}

	const handleClose = (commonButton: ICommonButtonTypes) => {
		if (
			!props.disableShowConfirmationMessageIfModified &&
			props.commonButtonSet === 'okCancel' &&
			commonButton === 'cancel' &&
			props.isModified
		) {
			setShowConfirmationMessage(true)
		} else {
			previousActiveElement.current?.focus && previousActiveElement.current?.focus()

			props.handleClose?.(commonButton)
		}
	}

	const handleCloseButtonClick = () => {
		handleClose('cancel')
	}

	const onConfirmationMessageYes = () => {
		setShowConfirmationMessage(false)

		previousActiveElement.current?.focus && previousActiveElement.current?.focus()

		props.handleClose?.('ok')
	}

	const onConfirmationMessageNo = () => {
		setShowConfirmationMessage(false)

		previousActiveElement.current?.focus && previousActiveElement.current?.focus()

		props.handleClose?.('cancel')
	}

	const onConfirmationMessageCancel = () => {
		setShowConfirmationMessage(false)
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (props.onKeyDown) {
			props.onKeyDown(e)
		}
	}

	const leftButtons = props.buttonDescriptions
		? props.buttonDescriptions.filter((description) => description.alignLeft === true)
		: []
	const rightCustomButtons = props.buttonDescriptions
		? props.buttonDescriptions.filter((description) => !description.alignLeft)
		: []

	const buttonCombos = {
		['okCancel']: {
			['ok']: 'ok',
			['cancel']: 'cancel',
		},
		['yesNo']: {
			['yes']: 'yes',
			['no']: 'no',
		},
		['yesNoCancel']: {
			['yes']: 'yes',
			['no']: 'no',
			['cancel']: 'cancel',
		},
		['ok']: {
			['ok']: 'ok',
		},
		['yes']: {
			['yes']: 'yes',
		},
		['no']: {
			['no']: 'no',
		},
		['cancel']: {
			['cancel']: 'cancel',
		},
		['close']: {
			['close']: 'close',
		},
	}

	const commonButtons =
		props.commonButtonSet && buttonCombos[props.commonButtonSet]
			? Object.values(buttonCombos[props.commonButtonSet]).map((commonButton: string) =>
					generateCommonButtonDescription(commonButton as ICommonButtonTypes, handleClose, tcvi, props.isValid)
			  )
			: []

	const rightButtons = [...commonButtons, ...rightCustomButtons]

	const width = props.width === undefined || props.width === 'auto' || isValidInnerWidth ? undefined : props.width
	const height = props.height === 'auto' || isValidInnerHeight ? undefined : props.height

	const allowHorizontalResize = props.width !== 'auto'
	const allowVerticalResize = props.height !== 'auto'

	const resizeOptions = {
		corner: allowVerticalResize && allowHorizontalResize,
		right: allowHorizontalResize,
		left: allowHorizontalResize,
		top: allowVerticalResize,
		bottom: allowVerticalResize,
	}

	const onResize = throttle(() => {
		fullScreen.current = topWindow.innerWidth <= MOBILE_WIDTH
	}, 25)

	useEventListener('resize', onResize)

	const previousActiveElement = useRef<HTMLElement | null>(topWindow.document.activeElement as HTMLElement | null)

	const hideHeader = props.hideDialogHeader === true

	useLayoutEffect(() => {
		if (!domDialog.current || !props.isOpen) {
			return
		}

		const style = domDialog.current.style

		if (width) {
			style.width = formatPixel(width)
			style.setProperty('--width', formatPixel(width))
		}

		if (height) {
			style.height = formatPixel(height)
			style.setProperty('--height', formatPixel(height))
		}
	}, [props.isOpen])

	const modifiedOnResize = (width: number, height: number) => {
		setShallFitInnerSize(false)

		props.onResize?.(width, height)
	}

	const shallFitInnerHeight = shallFitInnerSize && isValidInnerHeight
	const shallFitInnerWidth = shallFitInnerSize && isValidInnerWidth

	const isAutoHeight = (props.height === 'auto' || shallFitInnerHeight) && !fullScreen.current

	const dialogAutoHeight = isAutoHeight ? classes.dialogAutoHeight : undefined

	const innerSizing = {
		height: shallFitInnerHeight ? formatPixel(innerHeight) : undefined,
		width: shallFitInnerWidth ? formatPixel(innerWidth + 2 * PADDNG_WIDTH) : undefined,
	}

	return (
		<>
			{showConfirmationMessage && (
				<DialogConfirmationMessage
					onCancel={onConfirmationMessageCancel}
					onYes={onConfirmationMessageYes}
					onNo={onConfirmationMessageNo}
					isValid={props.isValid === undefined || props.isValid}
				/>
			)}
			<KeyboardShortcutListenerContext.Provider value={dialogKeyboardShortcutContext}>
				<Modal
					hideBackdrop={props.hideBackdrop}
					stayOnTop={props.stayOnTop}
					modalRootSelector={modalRootSelector}
					fullScreen={fullScreen.current}
					isOpen={props.isOpen}
					ref={domDialog}
					ariaLabel={props.title}
					onBackdropClick={handleBackdropClick}
					onEscapePressed={!props.disableCancelOnEscape && hasCancelButton() ? handleCancelKeyDown : undefined}
					modalClassName={props.modalClassName}
					backdropClassName={props.backdropClassName}
					blocking={props.disableCancelOnClickOutside}
				>
					<div
						className={classNames(
							classes.dialog,
							classes.dialogBorder,
							classes.dropShadow,
							props.severity === 'error' && classes.severityErrorBorder
						)}
						style={{ maxHeight: fullScreen.current ? undefined : topWindow.innerHeight }}
						onKeyDown={onKeyDown}
						ref={domRef}
						data-keyboardlistener={
							dialogKeyboardShortcutContext !== parentContext ? dialogKeyboardShortcutContext.contextId : undefined
						}
					>
						{props.severity && props.severity !== 'info' && (
							<div
								className={classNames(
									classes.severityHeader,
									props.severity === 'error' && classes.severityHeaderError,
									props.severity === 'warning' && classes.severityHeaderWarning
								)}
							/>
						)}
						{!hideHeader && (
							<DialogTitle
								title={props.title}
								classes={classes}
								handlePointerDown={handlePointerDown}
								handleCloseButtonClick={handleCloseButtonClick}
								hasCancelButton={hasCancelButton()}
								severity={props.severity}
								disableCancelOnEscape={props.disableCancelOnEscape}
								headerCommands={props.headerCommands}
								variant={props.variant}
								wrapTitle={props.wrapTitle}
							/>
						)}

						<div
							className={classNames(
								CLASSNAME_CONTENT,
								classes.dialogContents,
								dialogAutoHeight,
								props.variant === 'small' && classes.dialogContentsSmall,
								hideHeader && props.variant !== 'small' && classes.dialogContentsWithoutHeader,
								hideHeader && props.variant === 'small' && classes.dialogContentsWithoutHeaderSmall
							)}
							style={innerSizing}
						>
							{props.children}
						</div>

						{(leftButtons.length > 0 || rightButtons.length > 0) && (
							<div
								className={classNames(
									CLASSNAME_BUTTONS,
									classes.dialogButtons,
									props.variant === 'small' && classes.dialogButtonsSmall
								)}
							>
								<div className={classes.dialogButtonsLeft}>
									{leftButtons.map((buttonDescription) => {
										if (!buttonDescription.isHidden) {
											return (
												<Button
													key={buttonDescription.title}
													label={buttonDescription.title}
													disabled={!buttonDescription.isEnabled}
													variant={buttonDescription.isDefault ? 'primary' : undefined}
													className={classes.dialogButton}
													onClick={buttonDescription.onClick}
													dataAttributes={buttonDescription.dataAttributes}
												/>
											)
										}
									})}
								</div>
								<div className={classes.dialogButtonsRight}>
									{rightButtons.map((buttonDescription) => {
										if (!buttonDescription?.isHidden) {
											return (
												<Button
													key={buttonDescription.title}
													label={buttonDescription.title}
													disabled={!buttonDescription.isEnabled}
													variant={buttonDescription.isDefault ? 'primary' : undefined}
													onClick={buttonDescription.onClick}
													dataAttributes={buttonDescription.dataAttributes}
												/>
											)
										}
									})}
								</div>
							</div>
						)}
					</div>
					<ResizableOverlay
						fullScreen={fullScreen.current}
						enable={props.resizable && !fullScreen.current ? resizeOptions : {}}
						defaultSize={{ width, height }}
						onResize={modifiedOnResize}
						targetElement={domDialog}
					/>
				</Modal>
			</KeyboardShortcutListenerContext.Provider>
		</>
	)
}
