import type { Palette } from '../theming/themeProperties/colors/paletteGenerator'
import { hex2Hsl, hex2Rgb } from '../theming/themeProperties/colors/colorUtils'
import { cssVar } from './themingManager'

export type SemanticColors = ReturnType<typeof mapSemanticColorsFromPalette>

export const mapSemanticColorsFromPalette = (palette: Palette) => {
	const { primary, foreground, background, error, warning, success, info } = palette

	// AppBar text should always be 'light', even if background is light/dark
	const backgroundIsDark = hex2Hsl(background.white).l < 0.5
	const dynamicBrightColor = backgroundIsDark ? foreground.black : background.white
	const dynamicDarkColor = backgroundIsDark ? background.white : foreground.black
	const darkRGB = hex2Rgb(dynamicDarkColor)

	const primaryLightness = hex2Hsl(primary.themePrimary).l
	const primaryIsDark = hex2Hsl(primary.themePrimary).l < 0.8

	const foregroundLightness = hex2Hsl(foreground.neutralPrimary).l
	const backgroundLightness = hex2Hsl(background.white).l
	const deltaLightnessPrimaryForeground = Math.abs(primaryLightness - foregroundLightness)
	const deltaLightnessPrimaryBackground = Math.abs(primaryLightness - backgroundLightness)
	const primaryTextContrastColor =
		deltaLightnessPrimaryForeground > deltaLightnessPrimaryBackground ? foreground.neutralPrimary : background.white

	return {
		appBar: {
			background: cssVar(primary, 'themePrimary'),
			hoveredBackground: primaryIsDark ? cssVar(primary, 'themeDarkAlt') : cssVar(primary, 'themeLighterAlt'),
			checkedBackground: primaryIsDark ? cssVar(primary, 'themeDark') : cssVar(primary, 'themeLighterAlt'),
			checkedHoveredBackground: primaryIsDark ? cssVar(primary, 'themeDarker') : cssVar(primary, 'themeLight'),
			pressedBackground: primaryIsDark ? cssVar(primary, 'themeDark') : cssVar(primary, 'themeTertiary'),
			text: primaryTextContrastColor,
		},
		notificationPopup: {
			background: cssVar(primary, 'themePrimary'),
			text: primaryTextContrastColor,
		},

		body: {
			background: cssVar(background, 'white'),
			backgroundAlt: cssVar(background, 'neutralLighterAlt'),
			// selected backgrounds - might be removed
			selectedBackground: cssVar(primary, 'themeLighter'),

			selectedBorder: cssVar(primary, 'themePrimary'),
			divider: cssVar(background, 'neutralQuaternaryAlt'),
			line: cssVar(background, 'neutralLighter'),

			text: cssVar(foreground, 'neutralPrimary'),
			subtext: cssVar(foreground, 'neutralSecondary'),
			disabledText: cssVar(foreground, 'neutralTertiary'),
			errorText: cssVar(error, 'errorPrimary'),
			warningText: cssVar(warning, 'warningPrimary'),
			successText: cssVar(success, 'successPrimary'),
			infoText: cssVar(info, 'infoPrimary'),

			focusBorder: cssVar(foreground, 'neutralPrimary'),
		},
		links: {
			text: cssVar(primary, 'themePrimary'),
			hoveredText: cssVar(primary, 'themeDarker'),
		},
		actionLinks: {
			text: cssVar(foreground, 'neutralPrimary'),
			hoveredText: cssVar(foreground, 'neutralDark'),
		},
		badge: {
			text: dynamicBrightColor,
			background: cssVar(error, 'errorPrimary'),
		},
		button: {
			background: cssVar(background, 'white'),
			checkedBackground: cssVar(background, 'neutralTertiaryAlt'),
			hoveredBackground: cssVar(background, 'neutralLight'),
			checkedHoveredBackground: cssVar(background, 'neutralLight'),
			pressedBackground: cssVar(background, 'neutralQuaternaryAlt'),
			disabledBackground: cssVar(background, 'neutralLighter'),

			border: cssVar(background, 'neutralTertiaryAlt'),
			focusBorder: cssVar(foreground, 'neutralTertiary'),
			outline: cssVar(foreground, 'neutralTertiary'),
			disabledBorder: cssVar(background, 'neutralLighter'),

			text: cssVar(foreground, 'neutralPrimary'),
			hoveredText: cssVar(foreground, 'neutralPrimary'),
			checkedText: cssVar(foreground, 'neutralDark'),
			checkedHoveredText: cssVar(foreground, 'black'),
			pressedText: cssVar(foreground, 'neutralPrimary'),
			disabledText: cssVar(foreground, 'neutralTertiary'),
		},
		modal: {
			background: `rgba(${darkRGB.r}, ${darkRGB.g}, ${darkRGB.b}, 0.3)`,
		},
		toolbar: {
			background: cssVar(background, 'neutralLighter'),
			text: cssVar(foreground, 'black'),
			hoveredBackground: cssVar(background, 'neutralLight'),
			hoveredText: cssVar(foreground, 'neutralDark'),
			pressedBackground: cssVar(background, 'neutralQuaternaryAlt'),
			pressedText: cssVar(foreground, 'neutralDark'),
			checkedBackground: cssVar(background, 'neutralTertiaryAlt'),
			checkedText: cssVar(foreground, 'neutralDark'),
			checkedHoveredBackground: cssVar(background, 'neutralLight'),
			checkedHoveredText: cssVar(foreground, 'black'),
		},
		primaryButton: {
			background: cssVar(primary, 'themePrimary'),
			hoveredBackground: primaryIsDark ? cssVar(primary, 'themeDarkAlt') : cssVar(primary, 'themeLighterAlt'),
			pressedBackground: primaryIsDark ? cssVar(primary, 'themeDark') : cssVar(primary, 'themeLighterAlt'),
			checkedBackground: primaryIsDark ? cssVar(primary, 'themeDarker') : cssVar(primary, 'themeLight'),
			disabledBackground: cssVar(background, 'neutralLighter'),

			border: 'transparent',

			text: cssVar(background, 'white'),
			hoveredText: cssVar(background, 'white'),
			pressedText: cssVar(background, 'white'),
			checkedText: cssVar(background, 'white'),
			disabledText: cssVar(background, 'neutralQuaternary'),
		},
		input: {
			background: cssVar(background, 'white'),
			checkedBackground: cssVar(primary, 'themePrimary'),
			checkedHoveredBackground: cssVar(primary, 'themeDarkAlt'),
			disabledBackground: cssVar(background, 'neutralLighter'),

			border: cssVar(foreground, 'neutralTertiary'),
			hoveredBorder: cssVar(foreground, 'neutralPrimary'),
			focusBorder: cssVar(primary, 'themePrimary'),

			text: cssVar(foreground, 'neutralPrimary'),
			placeholderText: cssVar(foreground, 'neutralSecondary'),
			checkedText: cssVar(background, 'white'),
			hoveredText: cssVar(foreground, 'neutralDark'),
			disabledText: cssVar(foreground, 'neutralTertiary'),
		},
		list: {
			background: cssVar(background, 'white'),
			hoveredBackground: cssVar(background, 'neutralLighter'),
			checkedBackground: cssVar(background, 'neutralLight'),
			checkedHoveredBackground: cssVar(background, 'neutralQuaternaryAlt'),
			selectedBackground: cssVar(background, 'neutralLight'),
			selectedHoveredBackground: cssVar(background, 'neutralTertiaryAlt'),
			pressedBackground: cssVar(background, 'neutralLight'),

			border: cssVar(background, 'neutralLight'),
			selectedBorder: cssVar(primary, 'themePrimary'),
			divider: cssVar(background, 'neutralLighter'),

			text: cssVar(foreground, 'neutralPrimary'),
		},
		menu: {
			background: cssVar(background, 'white'),
			hoveredBackground: cssVar(background, 'neutralLighter'),
			pressedBackground: cssVar(background, 'neutralLight'),

			border: cssVar(background, 'neutralTertiaryAlt'),
			divider: cssVar(background, 'neutralQuaternaryAlt'),

			text: cssVar(foreground, 'neutralPrimary'),
			hoveredText: cssVar(foreground, 'neutralDark'),
		},
		panel: {
			background: cssVar(background, 'neutralLighter'),
			hoveredBakground: cssVar(background, 'neutralLight'),
			border: cssVar(background, 'neutralQuaternaryAlt'),
			text: cssVar(foreground, 'neutralPrimary'),
		},
		dataItem: {
			background: backgroundIsDark ? cssVar(primary, 'themeDarker') : cssVar(primary, 'themeLighter'),
			hoveredBakground: backgroundIsDark ? cssVar(primary, 'themeDark') : cssVar(primary, 'themeLight'),

			selectedBackground: backgroundIsDark ? cssVar(primary, 'themeDarkAlt') : cssVar(primary, 'themeTertiary'),
			hoveredSelectedBackground: backgroundIsDark ? cssVar(primary, 'themeDark') : cssVar(primary, 'themeDarker'),
			selectedBorder: cssVar(foreground, 'black'),
		},
	}
}
