import type { IRowNode, ColumnApi, GridApi } from '@ag-grid-community/core'
import type { TData } from '../Table.types'
import { refreshCheckMarkColumn } from './selectionUtils'

export function setNodeSelected(
	selected: boolean,
	props: { node: IRowNode<TData>; gridApi: GridApi<TData>; columnApi: ColumnApi; clearSelection?: boolean }
) {
	const { node, gridApi, columnApi, clearSelection } = props

	node.setSelected(selected, clearSelection)
	refreshCheckMarkColumn(gridApi, columnApi)
}
