import { ICommonRootState } from 'src/interfaces/ICommonRootState'
import { IOperatorRootState } from 'src/reducers'

const selectIsInitialized = (state: ICommonRootState) => state.operatorFrontend.isInitialized

const selectSelectedK8sRuntimeName = (state: ICommonRootState) => state.operatorFrontend.selectedK8sRuntimeName

const selectSelectedK8sRuntimes = (state: ICommonRootState) => state.operatorFrontend.selectedK8sRuntimes

const selectSelectedK8sRuntime = (state: ICommonRootState) => state.operatorFrontend.selectedK8sRuntime

const selectNavigationItem = (state: ICommonRootState) => state.operatorFrontend.navigationItem

const selectSelectedPods = (state: ICommonRootState) => state.operatorFrontend.selectedPods

const selectSelectedWorkloadController = (state: ICommonRootState) => state.operatorFrontend.selectedWorkloadController

const selectIsPhoneHomeEnabled = (state: ICommonRootState) =>
	state.operatorFrontend.config.optionalFunctionality.isPhoneHomeEnabled

const selectEventLogUrl = (state: ICommonRootState) => state.operatorFrontend.config.eventLogUrl

const selectAutoReload =
	(resource: string) =>
	(state: ICommonRootState): boolean =>
		state.operatorFrontend.autoReload[resource]

const selectErrorLogs = (state: IOperatorRootState) => state.operatorFrontend.operatorErrorLog.logs

const selectControlLoopStatus = (state: IOperatorRootState) => state.operatorFrontend.controlLoopStatus

const selectKubernetesVersion = (state: IOperatorRootState) => state.operatorFrontend.kubernetesVersion

const selectUserInfo = (state: IOperatorRootState) => state.operatorFrontend.userInfo

export const operatorFrontendSelectors = {
	selectIsInitialized,
	selectSelectedK8sRuntimeName,
	selectSelectedK8sRuntimes,
	selectSelectedK8sRuntime,
	selectNavigationItem,
	selectSelectedPods,
	selectSelectedWorkloadController,
	selectIsPhoneHomeEnabled,
	selectEventLogUrl,
	selectAutoReload,
	selectErrorLogs,
	selectControlLoopStatus,
	selectKubernetesVersion,
	selectUserInfo,
}
