import { registeredCSSVars } from '../../theming/themingManager/initTheming'
import type { CSSValues, PartialTheme, ThemeVariation } from '../../theming/themingManager/themingManager.types'
import { cssVarDeclareFormat, cssVarNameFormat } from './cssVarStringUtils'

/**
 * Create new css variable definitions from partial theme
 */
export const getCSSVariablesFromTheme = <T extends PartialTheme<ThemeVariation>>(theme: T) => {
	const themeValues: Record<string, CSSValues> = {}

	const getVariablesFromThemeStructure = <T extends PartialTheme<ThemeVariation>>(theme: T, keyPrefix = '') => {
		Object.entries(theme).forEach(([key, value]) => {
			const cssVarName = keyPrefix === '' ? cssVarNameFormat(key) : cssVarNameFormat(keyPrefix, key)
			const cssVar = cssVarDeclareFormat(cssVarName)

			if (typeof value === 'object') {
				getVariablesFromThemeStructure(value, cssVarName)
			} else if (value !== undefined) {
				if (!registeredCSSVars.has(cssVar)) {
					// eslint-disable-next-line no-console
					// console.warn(`Theme was not initialized with CSS variable ${cssVar}`)
				}
				themeValues[cssVar] = value
			}
		})
	}
	getVariablesFromThemeStructure(theme)

	return themeValues
}
