import React from 'react'
import clsx from 'clsx'
import { createStyle } from '../../theming'

import { getScreenTip } from './utils/getScreenTip'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useGetStyle } from '../utils/Style.context'
import { useIconAndLabelUtils } from './utils/useIconAndLabelUtils'
import { useKeyboardShortcut } from './utils/useKeyboardShortcut'
import { Badge } from '../Badge'
import type { IIconSize } from '../Icon'
import { Icon } from '../Icon'
import type { IKeyboardShortcut } from '../utils/keyboard/IKeyboardShortcut'
import { Spinner } from '../Spinner'

const classes = createStyle((theme) => ({
	button: {
		display: 'flex',
		position: 'relative',
		justifyContent: 'center',
		alignItems: 'center',
		outline: 0,
		border: 0,
		padding: 0,

		borderRadius: theme.controls.button.borderRadius,
		borderStyle: theme.controls.button.borderStyle,
		borderWidth: theme.controls.button.borderWidth,
		boxShadow: theme.controls.button.boxShadow,
		fontSize: theme.controls.button.fontSize,
		fontWeight: theme.controls.button.fontWeight,
		margin: theme.controls.button.margin,

		'&:disabled': {
			boxShadow: 'unset', // Disable drop dhadow for disabled buttons
		},
	},
	content: {
		display: 'flex',
		lineHeight: '16px',
		alignItems: 'center',
		gap: 6,
	},
	contentVertical: { flexDirection: 'column' },
	contentIconAfterText: { '& > i, & > svg': { order: 2 } },

	contentLineHeight: { lineHeight: '16px' }, // Class used to ensure uniform height between buttons with/without labels and icons. Might need revisit?
	labelLineHeight: { lineHeight: '32px' }, // Class used to ensure uniform height between buttons with/without labels and icons. Might need revisit?
	extraSmall: { fontSize: 12, padding: '2px 6px' },
	extraSmallIconButton: { padding: '2px' },
	small: { fontSize: 12, padding: '4px 16px' },
	smallIconButton: { padding: '4px' },
	medium: { padding: '6px 18px' },
	mediumIconButton: { padding: '6px' },
	large: { padding: '8px 34px' },
	largeIconButton: { padding: '8px' },
	extraLarge: { padding: '18px 48px' },
	extraLargeIconButton: { padding: '18px' },
	none: { padding: theme.controls.button.padding },
	defaultBase: {
		color: theme.controls.button.colors.color,
		'&:focus-visible': {
			borderColor: theme.controls.button.focusedColors.border,
			color: theme.controls.button.focusedColors.color,
		},
		// If you tap on something that has a :hover state but you don’t leave the page then, on a mobile device, there is a chance that :hover state “sticks”.
		// To avoid this, @media (any-hover: hover) is used (will only apply styles on devices with hover capability).
		// https://css-tricks.com/solving-sticky-hover-states-with-media-hover-hover/
		'@media (any-hover: hover)': {
			'&:hover': {
				backgroundColor: theme.controls.button.hoverColors.background,
				color: theme.controls.button.hoverColors.color,
			},
		},
		'&:active': {
			backgroundColor: theme.controls.button.pressedColors.background,
			color: theme.controls.button.pressedColors.color,
		},
		'&:disabled, &:disabled:hover': {
			backgroundColor: theme.controls.button.disabledColors.background,
			color: theme.controls.button.disabledColors.color,
		},
	},
	default: {
		backgroundColor: theme.controls.button.colors.background,
		borderColor: theme.controls.button.colors.border,
		padding: theme.controls.button.focusBorderPadding,
		textDecoration: theme.controls.button.textDecoration,
		'&:focus-visible': {
			backgroundClip: 'content-box',
			backgroundColor: theme.controls.button.focusedColors.background,
			outlineColor: theme.controls.button.focusedColors.outline,
			outlineOffset: `calc(0px - ${theme.controls.button.borderWidth} + ${theme.controls.button.focusOutlineOffset})`,
			outlineStyle: theme.controls.button.focusBorderStyle,
			outlineWidth: theme.controls.button.focusOutlineWidth,
		},
		// If you tap on something that has a :hover state but you don’t leave the page then, on a mobile device, there is a chance that :hover state “sticks”.
		// To avoid this, @media (any-hover: hover) is used (will only apply styles on devices with hover capability).
		// https://css-tricks.com/solving-sticky-hover-states-with-media-hover-hover/
		'@media (any-hover: hover)': {
			'&:hover': {
				backgroundColor: theme.controls.button.hoverColors.background,
				borderColor: theme.controls.button.hoverColors.border,
			},
		},
		'&:active': {
			backgroundColor: theme.controls.button.pressedColors.background,
			borderColor: theme.controls.button.pressedColors.border,
		},

		'&:disabled, &:disabled:hover': { borderColor: theme.controls.button.disabledColors.border },
	},
	inline: {
		backgroundColor: 'transparent',
		borderColor: 'transparent',
		'&:focus-visible': {
			borderColor: theme.controls.button.focusedColors.outline,
		},
		'&:disabled, &:disabled:hover': {
			backgroundColor: 'transparent',
			color: theme.colors.button.disabledText,
		},
	},
	primaryBase: {
		'&:hover': {
			backgroundColor: theme.colors.primaryButton.hoveredBackground,
			color: theme.colors.primaryButton.hoveredText,
		},
		'&:active': {
			backgroundColor: theme.colors.primaryButton.pressedBackground,
			color: theme.colors.primaryButton.pressedText,
		},
		'&:disabled, &:disabled:hover': {
			backgroundColor: theme.colors.primaryButton.disabledBackground,
			color: theme.colors.primaryButton.disabledText,
			border: `1px solid ${theme.colors.primaryButton.disabledBackground}`,
		},
	},
	inlinePrimary: {
		backgroundColor: 'transparent',
		color: theme.colors.primaryButton.background,
		borderColor: 'transparent',
		'&:focus-visible': { borderColor: theme.colors.body.focusBorder },
	},
	primary: {
		borderColor: 'transparent',
		backgroundColor: theme.colors.primaryButton.background,
		color: theme.colors.primaryButton.text,
		'&:focus-visible': {
			outlineColor: theme.colors.primaryButton.text,
			outlineOffset: `calc(-2px - ${theme.controls.button.borderWidth})`,
			outlineStyle: 'solid',
			outlineWidth: '1px',
		},
	},
	checked: {
		backgroundColor: theme.controls.button.checkedColors.background,
		color: theme.controls.button.checkedColors.color,
		borderColor: theme.controls.button.checkedColors.border,
		'&$primary': {
			backgroundColor: theme.colors.primaryButton.checkedBackground,
			color: theme.colors.primaryButton.checkedText,
		},
	},

	dropdownIcon: { paddingRight: '8px' },
}))

export interface IButtonProps {
	/** Click event handler that is called when the button is clicked or activated by keyboard */
	onClick?: (e: React.MouseEvent) => void
	onMouseDown?: (e: React.MouseEvent) => void
	onEnterPress?: () => void
	disabled?: boolean
	screenTip?: string
	size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge' | 'none'
	/** Categories of buttons controlling fill and outline */
	variant?: 'default' | 'inline' | 'primary' | 'inlinePrimary'
	/** Text displayed inside button */
	label?: string
	/** Checks the button by changing appearance and adding area */
	isChecked?: boolean
	/** Add custom classes */
	className?: string
	/** Add inline style */
	style?: React.CSSProperties
	id?: string
	/** Use this to add attributes to button, i.e. cy-data used for testing */
	dataAttributes?: Record<string, string>

	/** Icon name. Mostly sourced from fluent icons. See https://genus.gitlab.io/packages/icon-set/ for full list */
	icon?: string
	iconPosition?: 'left' | 'right' | 'top' | 'bottom'
	type?: 'button' | 'submit' | 'reset'
	dropdownIconSize?: IIconSize

	badgeValue?: number
	showSpinner?: boolean
	showDropdownButton?: boolean

	ariaLabel?: string
	children?: React.ReactNode
	keyboardShortcut?: IKeyboardShortcut
}

type ClassKeys = keyof typeof classes

/**
 * Primary UI component for user interaction
 *
 * Color is determined by theming which is controlled by the theme provider, not the button itself
 */
export const Button = React.forwardRef((props: IButtonProps, ref: React.Ref<HTMLButtonElement>) => {
	const { iconPosition = 'left', type = 'button' } = props

	const {
		iconSize: styleIconSize,
		variant = props.variant ?? 'default',
		size = props.size ?? 'medium',
		width,
	} = { ...useGetStyle().button }

	const isPrimary = ['primary', 'inlinePrimary'].includes(variant)

	const buttonRef = useForwardedRef<HTMLButtonElement>(ref)

	const screenTip = getScreenTip(props.screenTip, props.keyboardShortcut)

	const { hasLabel, hasIcon, positionIconAfterText, isContentVertical, iconSize, sizeIsLarge } = useIconAndLabelUtils(
		iconPosition,
		size,
		styleIconSize,
		props.label,
		props.icon
	)

	useKeyboardShortcut(props.id, buttonRef, props.disabled, props.keyboardShortcut)

	const onClick = (e: React.MouseEvent) => {
		if (props.onClick) {
			e.stopPropagation()
			props.onClick(e)
		}
	}

	const onMouseDown = (e: React.MouseEvent) => {
		if (props.onMouseDown) {
			e.stopPropagation()
			props.onMouseDown(e)
		}
	}

	const onkeydown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && props.onEnterPress) {
			e.preventDefault()
			e.stopPropagation()
			props.onEnterPress()
		}
	}

	const buttonClasses = clsx(
		classes.button,
		isPrimary ? classes.primaryBase : classes.defaultBase,
		classes[variant],
		{ [classes.checked]: props.isChecked },
		props.className
	)

	const contentClasses = clsx(classes.content, classes.contentLineHeight, {
		[classes.contentIconAfterText]: positionIconAfterText,
		[classes.contentVertical]: isContentVertical,
		[classes[size]]: hasLabel ?? size === 'none',
	})

	const iconClasses = clsx({ [classes[(size + 'IconButton') as ClassKeys]]: !hasLabel && hasIcon && size !== 'none' })

	return (
		<button
			id={props.id}
			ref={buttonRef}
			title={screenTip.length > 0 ? screenTip.join('\n') : undefined}
			tabIndex={props.disabled ? -1 : undefined}
			type={type}
			disabled={props.disabled}
			onClick={onClick}
			onMouseDown={onMouseDown}
			onKeyDown={onkeydown}
			style={{ ...props.style, width }}
			className={buttonClasses}
			{...props.dataAttributes}
			aria-label={props.ariaLabel}
			role={props.isChecked ? 'button' : undefined} // role er påkrevet dersom man bruker bla aria-checked
			aria-checked={props.isChecked ? true : undefined}
		>
			<Badge value={props.badgeValue} offsetRight={-16} offsetTop={-4}>
				<span className={contentClasses}>
					{props.showSpinner && <Spinner size="small" />}
					{hasIcon && <Icon iconClassName={props.icon} size={iconSize} className={iconClasses} />}
					{hasLabel && (
						<span className={clsx(!isContentVertical && sizeIsLarge && classes.labelLineHeight)}>{props.label}</span>
					)}
				</span>
				{props.children}
				{props.showDropdownButton && (
					<Icon
						iconClassName="Fluent-ChevronDown"
						className={classes.dropdownIcon}
						size={props.dropdownIconSize ?? iconSize}
					/>
				)}
			</Badge>
		</button>
	)
})

Button.displayName = 'Button'
