import { useMemo, useState } from 'react'
import type { IRowNode, PaginationChangedEvent, GridApi } from '@ag-grid-community/core'
import type { TData } from '../Table.types'

interface IPaginationValues {
	pagination: boolean | undefined
	paginationPageSize: number | undefined
	paginationAutoPageSize: boolean | undefined
}

export const usePagination = (pageSize: string | number | undefined) =>
	useMemo((): IPaginationValues => {
		if (pageSize === 'auto') {
			return { pagination: true, paginationAutoPageSize: true, paginationPageSize: undefined }
		}

		if (typeof pageSize === 'number') {
			return { pagination: true, paginationPageSize: pageSize, paginationAutoPageSize: undefined }
		}

		return { pagination: undefined, paginationPageSize: undefined, paginationAutoPageSize: undefined }
	}, [pageSize])

export interface IPaginationDisplay {
	currentPage: number
	numberOfObjects: number
	totalNumberOfObjects: number
	totalNumberOfPages: number
}

export const usePaginationChanged = (onPageBreakChange?: (visibleIds: string[]) => void) => {
	const [paginationDisplay, setPaginationDisplay] = useState<IPaginationDisplay>({
		currentPage: 0,
		numberOfObjects: 0,
		totalNumberOfObjects: 0,
		totalNumberOfPages: 0,
	})

	const onPaginationChanged = (event: PaginationChangedEvent<TData>) => {
		const currentPage = event.api.paginationGetCurrentPage()
		const numberOfObjects = event.api.paginationGetPageSize()

		setPaginationDisplay({
			currentPage,
			numberOfObjects,
			totalNumberOfObjects: event.api.paginationGetRowCount(),
			totalNumberOfPages: event.api.paginationGetTotalPages(),
		})

		if (onPageBreakChange && (event.newData || event.newPage)) {
			const visibleRows = getVisibleRowsAfterPagination(currentPage, numberOfObjects, event.api)
			onPageBreakChange(visibleRows)
		}
	}

	return { paginationDisplay, onPaginationChanged }
}

const getVisibleRowsAfterPagination = (currentPage: number, numberOfObjects: number, api: GridApi<TData>) => {
	const startIndex = currentPage * numberOfObjects
	const endIndex = startIndex + numberOfObjects
	const filteredAndSortedRows: string[] = []
	const groups: IRowNode<TData>[] = []
	api.forEachNodeAfterFilterAndSort((node) => {
		if (node.group) {
			groups.push(node)
		} else {
			node.id && filteredAndSortedRows.push(node.id)
		}
	})

	if (groups.length > 0) {
		//Pagination works on the topmost level of rows. If this is a group level, find the children
		const visibleGroups = groups.slice(startIndex, endIndex)
		return getChildrenOfGroups(visibleGroups).map((node) => node.id!)
	} else {
		return filteredAndSortedRows.slice(startIndex, endIndex)
	}
}

const getChildrenOfGroups = (groups: IRowNode<TData>[]) => {
	let allLeafNodes: IRowNode<TData>[] = []
	groups.forEach((group) => {
		const children = group.childrenAfterFilter
		if (!children || children.length === 0) {
			return
		}
		if (children[0].group) {
			//Another group level
			allLeafNodes = allLeafNodes.concat(getChildrenOfGroups(children))
		} else {
			allLeafNodes = allLeafNodes.concat(children)
		}
	})

	return allLeafNodes.filter((node) => node.id)
}
