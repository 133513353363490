import { useCallback, useContext, useEffect } from 'react'
import { useID } from '../../utils/useID'
import { KeyboardShortcutListenerContext } from '../../utils/keyboard'
import type { IKeyboardShortcut } from '../../utils/keyboard/IKeyboardShortcut'
import {
	registerKeyboardShortcut,
	removeRegisteredKeyboardShortcut,
} from '../../utils/keyboard/registerKeyboardShortcut'

export const useKeyboardShortcut = (
	id: string | undefined,
	buttonRef: React.RefObject<HTMLButtonElement>,
	disabled = false,
	keyboardShortcut?: IKeyboardShortcut
) => {
	const keyboardShortcutContext = useContext(KeyboardShortcutListenerContext)
	const keyboardShortcutId = useID(id)

	const handleShortcut = useCallback(() => {
		if (disabled) {
			return
		}

		buttonRef.current?.click()
	}, [disabled])

	useEffect(() => {
		if (keyboardShortcutContext && keyboardShortcut) {
			registerKeyboardShortcut(keyboardShortcut, keyboardShortcutContext.contextId, keyboardShortcutId, handleShortcut)
		}
		return () => {
			if (keyboardShortcutContext && keyboardShortcut) {
				removeRegisteredKeyboardShortcut(keyboardShortcutContext.contextId, keyboardShortcutId)
			}
		}
	}, [keyboardShortcutContext, keyboardShortcut, handleShortcut, keyboardShortcutId])
}
