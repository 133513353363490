import React, { useEffect, useRef, useCallback } from 'react'
import { FOCUSABLE_ELEMENTS } from './getFirstFocusable'

interface IFocusTrap {
	disable?: boolean
	preventScrollOnFocus?: boolean
	children?: React.ReactNode | React.ReactNode[]
}

export const FocusTrap = (props: IFocusTrap) => {
	const trapRef = useRef<HTMLDivElement>(null)

	const loopFocus = useCallback(
		(backwardLooping?: boolean) => {
			if (!props.disable && trapRef.current) {
				const nodes = trapRef.current.querySelectorAll(FOCUSABLE_ELEMENTS)

				if (backwardLooping) {
					const lastFocusable = nodes[nodes.length - 1] as HTMLElement | undefined
					lastFocusable?.focus(props.preventScrollOnFocus ? { preventScroll: true } : undefined)
				} else {
					const firstFocusable = nodes[0] as HTMLElement | undefined
					firstFocusable?.focus(props.preventScrollOnFocus ? { preventScroll: true } : undefined)
				}
			}
		},
		[props.disable]
	)

	useEffect(() => {
		loopFocus()
	}, [loopFocus])

	return (
		<React.Fragment>
			<div tabIndex={0} onFocus={() => loopFocus(true)} />
			<div ref={trapRef} style={{ width: '100%', height: '100%' }}>
				{props.children}
			</div>
			<div tabIndex={0} onFocus={() => loopFocus()} />
		</React.Fragment>
	)
}
