import moment from 'moment'
import numeral from 'numeral'

const fallbackFormattingCultureLocale = 'en'
let formattingCultureLocale = fallbackFormattingCultureLocale

export const getFormattingCultureLocale = () => {
	return formattingCultureLocale
}

const setNumeralLocale = (formattingCultureLocale: string) => {
	const numeralLocale = mapFormattingCultureToNumeralLocale(formattingCultureLocale)
	numeral.locale(numeralLocale)
}

/**
 * Not all formatting cultures are supported by numeral.
 * The requested formatting culture is activated if possible, otherwise a good alternative is selected.
 * Returns the input if it can be used, else a good alternative.
 * @param {*} formattingCulture The requested formatting culture.
 */
const mapFormattingCultureToNumeralLocale = (formattingCulture: string) => {
	let mappedFormattingCulture

	switch (formattingCulture) {
		// Map all norwegian cultures to "no"
		case 'nn-NO':
		case 'nn':
		case 'nb-NO':
		case 'nb':
			mappedFormattingCulture = 'no'
			break

		// This is where other culture dataFilterConfigs should be performed
		// Look in numeral/locales/ to find supported locales

		default:
			mappedFormattingCulture = formattingCulture
	}

	try {
		// Try loading the culture. If it does not exist an exception is thrown
		require('numeral/locales/' + mappedFormattingCulture)
		return mappedFormattingCulture
	} catch (error) {
		try {
			// Check wether the locale is registered in numeral. If not, default to English
			// This additional check for registered language seems to fix issue with locale not being set to Norwegian in Dashboards
			numeral.localeData(mappedFormattingCulture)
			return mappedFormattingCulture
		} catch (error) {
			return 'en' // No need to load "en". It is hard wired.
		}
	}
}

export const setFormattingLocale = (locale: string) => {
	moment.locale(locale)
	setNumeralLocale(locale)
	formattingCultureLocale = locale
}
