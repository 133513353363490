import React, { useEffect, useRef, useState } from 'react'
import classNames from 'clsx'

import { createStyle } from '@genusbiz/web-ui/theming'

// AgGrid
import { AgGridReact } from '@ag-grid-community/react'

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import {
	ColumnApi,
	GridApi,
	FirstDataRenderedEvent,
	ValueGetterParams,
	ColDef,
	ValueFormatterParams,
} from '@ag-grid-community/core'

import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { IClusterEvent } from 'src/interfaces/IClusterEvent'
import { convertUTCtoLocalTime } from 'src/utils/dateTimeUtils'
import { useTranslation } from 'react-i18next'
// Consts
import { DATA_RELOAD_INTERVAL } from 'src/consts/constants'
import { operatorApi } from 'src/modules/operatorApi'
import { useDispatch, useSelector } from 'react-redux'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { operatorFrontendSelectors } from 'src/features/OperatorFrontend/duck/operatorFrontendSelectors'
import { e_OperatorResources } from 'src/enums/e_OperatorResources'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { defaultColors } from 'src/consts/defaultColors'

const classes = createStyle({
	container: {
		flex: 1,
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	table: {
		width: '100%',
		height: '100%',
	},
})

export const OperatorClusterEventTable = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const autoReloadEnabled = useSelector(
		operatorFrontendSelectors.selectAutoReload(e_OperatorResources.kubernetesEvents)
	)

	const [gridApi, setGridApi] = useState<GridApi>()
	// const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
	const k8sRuntimes = useSelector(kubernetesSelectors.selectK8sRuntimes)

	const gridApiRef = useRef<GridApi>()

	const onGridReady = (params: { api: GridApi; columnApi: ColumnApi }) => {
		setGridApi(params.api)
		// setGridColumnApi(params.columnApi)
		gridApiRef.current = params.api
	}

	const getAllOperatorClusterEvents = () => {
		let allClusterEvents: IClusterEvent[] = []
		const fetchAllClusterEvents = k8sRuntimes.map((k8sRuntime) =>
			operatorApi.fetchOperatorClusterEvents(k8sRuntime.name)
		)

		Promise.all(fetchAllClusterEvents)
			.then((runtimeConfigurationClusterEvents) => {
				runtimeConfigurationClusterEvents.forEach((clusterEvents) => {
					allClusterEvents = allClusterEvents.concat(clusterEvents)
				})
				if (!gridApi) {
					return
				}
				gridApi.hideOverlay()
				gridApi.setRowData(allClusterEvents)
			})
			.catch((err) => dispatch(modalManagerActions.showErrorModal(err)))
	}

	useEffect(() => {
		if (!k8sRuntimes) {
			return
		}

		getAllOperatorClusterEvents()
		const podReloadTimer = setInterval(() => {
			autoReloadEnabled && getAllOperatorClusterEvents()
		}, DATA_RELOAD_INTERVAL * 1000)

		return () => {
			clearInterval(podReloadTimer)
		}
	}, [k8sRuntimes, autoReloadEnabled, gridApi])

	const defaultColDef = {
		editable: false,
		sortable: true,
		resizable: true,
		filter: true,
		filterParams: {
			newRowsAction: 'keep',
		},
	}

	const columns: ColDef[] = [
		{
			headerName: t('GENERAL:ID'),
			field: 'id',
			colId: 'id',
			cellClass: 'no-border',
			hide: true,
		},
		{
			headerName: t('TABLE_HEADERS:CREATION_TIMESTAMP'),
			field: 'creationTimestamp',
			colId: 'creationTimestamp',
			cellClass: 'no-border',
			valueFormatter: (params: ValueFormatterParams) => {
				if (params.data) {
					return convertUTCtoLocalTime(params.data.creationTimestamp)
				}
				return ''
			},
		},
		{
			headerName: t('TABLE_HEADERS:MESSAGE'),
			field: 'message',
			colId: 'message',
			cellClass: 'no-border',
			filter: 'agTextColumnFilter',
			// suppressSizeToFit: true,
			width: 700,
			wrapText: true,
		},
		{
			headerName: t('TABLE_HEADERS:RUNTIMES'),
			field: 'runtimeConfigurationName',
			colId: 'runtimeConfigurationName',
			cellClass: 'no-border',
			rowGroup: true,
			hide: true,
		},
		{
			headerName: t('TABLE_HEADERS:NAME'),
			field: 'name',
			colId: 'name',
			cellClass: 'no-border',
			rowGroup: true,
			hide: true,
			suppressSizeToFit: true,
			valueGetter: (params: ValueGetterParams) => {
				if (params.data) {
					return (params.data as IClusterEvent).data.involvedObject.name
				}
				return ''
			},
		},

		{
			headerName: t('TABLE_HEADERS:TYPE'),
			field: 'eventTypeName', //'eventTypeName',
			colId: 'eventTypeName',
			cellClass: 'no-border',
			cellStyle: (params) => {
				if (params.value === 'Warning') {
					return { background: defaultColors.warningBackground }
				} else {
					return null
				}
			},
			filter: 'agSetColumnFilter',
			filterParams: {
				newRowsAction: 'keep',
			},
		},
		{
			headerName: t('TABLE_HEADERS:REASON'),
			field: 'reason',
			colId: 'reason',
			cellClass: 'no-border',
		},
		{
			headerName: t('TABLE_HEADERS:KIND'),
			field: 'kind',
			colId: 'kind',
			cellClass: 'no-border',
			valueGetter: (params: ValueGetterParams) => {
				if (params.data) {
					return (params.data as IClusterEvent).data.involvedObject.kind
				}
				return ''
			},
		},
	]

	const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
		params.columnApi.autoSizeColumns(['creationTimestamp', 'type', 'reason', 'kind'], true)
		// params.columnApi.autoSizeAllColumns(true)
	}

	return (
		<div className={classes.container}>
			<div className={classNames('ag-theme-alpine', classes.table)}>
				<AgGridReact
					modules={[SetFilterModule, ClientSideRowModelModule]}
					defaultColDef={defaultColDef}
					columnDefs={columns}
					onFirstDataRendered={onFirstDataRendered}
					loadingCellRenderer={'customLoadingOverlay'}
					onGridReady={onGridReady}
					// rowSelection={'multiple'}
					// singleClickEdit
					groupDefaultExpanded={-1}
					// rowGroupPanelShow="always"
				/>
			</div>
		</div>
	)
}

// Drag-droping av kolonner fører til at kolonner blir borte, hvis de droppes utenfor ag-grid
//
