import React from 'react'

interface IDropIndicator {
	rowId?: string
	height: number
	level?: number
	number?: number
	inset?: number
	hoveredPos: 'top' | 'bottom' | 'left' | 'right'
	children: React.ReactNode | React.ReactNode[]
	visible?: boolean
	className?: string
}

export const DropIndicator = (props: IDropIndicator) => {
	const isHorizontal = props.hoveredPos === 'top' || props.hoveredPos === 'bottom'

	const dropIndicatorSize = props.height || 2

	const dropIndicatorInset = props.level && props.inset !== undefined ? `${14 + props.level * props.inset}px` : '0px'

	return (
		<div style={{ position: 'relative' }} data-rowid={props.rowId} className={props.className}>
			{props.children}
			{props.visible && (
				<hr
					style={
						isHorizontal
							? {
									position: 'absolute',
									margin: '0px',
									borderTop: `${dropIndicatorSize}px solid black`,
									top: props.hoveredPos === 'top' ? `${1 - dropIndicatorSize / 2}px` : undefined,
									bottom: props.hoveredPos === 'bottom' ? `${dropIndicatorSize / 2 - dropIndicatorSize}px` : undefined,
									left: dropIndicatorInset,
									right: '0px',
							  }
							: {
									position: 'absolute',
									margin: '0px',
									borderTop: `${dropIndicatorSize}px solid black`,
									left: props.hoveredPos === 'left' ? `${1 - dropIndicatorSize / 2}px` : undefined,
									right: props.hoveredPos === 'right' ? `${dropIndicatorSize / 2 - dropIndicatorSize}px` : undefined,
									top: dropIndicatorInset,
									bottom: '0px',
							  }
					}
				/>
			)}
		</div>
	)
}
