import React, { useRef } from 'react'
import { e_LabelContentLayout } from '../../../FormControl'
import { Icon } from '../../../Icon'
import { Link } from '../../../Link'
import { e_RenderType } from '../../Table.types'
import { CELL_PADDING } from '../../consts'
import { createStyle } from '../../../../theming'
interface ICellIconProps {
	nodeId: string
	iconColor: string | undefined
	iconName: string | undefined
	position: 'before' | 'after'
	renderType: e_RenderType
	onIconClick?: (targetEl: React.RefObject<HTMLElement>) => void
}

const classes = createStyle({
	link: {
		display: 'flex',
	},
})

export const CellIcon = (props: ICellIconProps) => {
	const { nodeId, iconName } = props

	const iconButtonRef = useRef<HTMLAnchorElement>(null)
	if (!iconName) {
		return null
	}

	const cellIconStyle =
		props.renderType === e_RenderType.none
			? { padding: `0 ${CELL_PADDING}px` }
			: props.position === 'before'
			  ? { paddingRight: CELL_PADDING }
			  : { paddingLeft: CELL_PADDING }

	if (props.onIconClick) {
		return (
			<Link
				key={`${nodeId}-${iconName}`}
				icon={iconName}
				ref={iconButtonRef}
				onActivate={() => props.onIconClick?.(iconButtonRef)}
				url={'#'}
				displayName={''}
				contentLayout={e_LabelContentLayout.icon}
				className={classes.link}
				color={props.iconColor}
			/>
		)
	}

	return <Icon key={`${nodeId}-${iconName}`} color={props.iconColor} iconClassName={iconName} style={cellIconStyle} />
}
