import type { RowData, TData } from '../../../Table.types'
import { tDataToRowData } from '../../../utils'

export const getOnLinkClick = (
	nodeData: TData | undefined,
	onClick: ((rowData: RowData, targetEl: React.RefObject<HTMLElement>) => void) | undefined
) => {
	return onClick
		? (targetEl: React.RefObject<HTMLElement>) => {
				const rowData = tDataToRowData(nodeData)
				if (rowData) {
					onClick?.(rowData, targetEl)
				}
		  }
		: undefined
}
