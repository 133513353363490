import React from 'react'

import type { IFormControl } from '../FormControl'
import { FormControl } from '../FormControl'
import { useID } from '../utils/useID'
import { type ISwitchSize, SwitchContent } from './SwitchContent'

export interface ISwitchProps extends IFormControl {
	checked?: boolean
	onChange?: (checked: boolean) => void
	valueLabel?: string
	screenTip?: string
	switchSize?: ISwitchSize
	isInteractive?: boolean
}

export const Switch = (props: ISwitchProps) => {
	const { disabled = false } = props

	const id = useID(props.id)

	return (
		<FormControl
			id={id}
			label={props.label}
			labelPosition={props.labelPosition}
			hideLabel={props.hideLabel}
			labelProps={props.labelProps}
			icon={props.icon}
			labelContentLayout={props.labelContentLayout}
			screenTip={props.screenTip}
			labelSubText={props.labelSubText}
			validationText={props.validationText}
			validationTextPosition={props.validationTextPosition}
			subText={props.subText}
			error={props.error}
			warning={props.warning}
			reserveHelperTextSpace={props.reserveHelperTextSpace}
			disabled={disabled}
			readOnly={props.readOnly}
			required={props.required}
			disableBorder
			margin={props.margin}
			className={props.className}
		>
			<SwitchContent id={id} {...props} />
		</FormControl>
	)
}
