import React, { useRef, useState, useEffect, useCallback } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'

import type { IFormControl } from '../FormControl'
import { FormControl } from '../FormControl'
import { MultiValueInput } from '../MultiValueInput'
import { useStyleContext } from '../utils/Style.context'
import { useID } from '../utils/useID'
import { DropdownList, DropdownOptionsHandler } from '../Dropdown'
import type { MultiValueInputItem } from '../MultiValueInput/MultiValueInput.types'
import { useTranslation } from '../../translation'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { ReadOnlyIndicator } from '../FormControl/ReadOnlyIndicator'

const classes = createStyle({
	inputWrapper: {
		display: 'flex',
		width: '100%',
	},
	multiValueInput: {
		maxHeight: '128px',
	},
})

interface ITextLookupQuery {
	type: 'lookupQuery'
	value: string
}
export interface LookupOption<T> {
	value: T
	label: string
}

interface IMultiLookupInputProps<T> extends IFormControl {
	values: T[]
	namedValues: LookupOption<T>[]
	performLookup?: (
		lookupQuery: string,
		lookupToken: number,
		onOptionsAvailable: (lookupToken: number, options: LookupOption<T>[]) => void
	) => void
	onSelection?: (itemIds: T[]) => void

	selectItem?: (itemId: T) => void
	selectTextItem?: (text: string) => void
	defaultAction?: () => void
	placeholder?: string
	enableAutocomplete?: boolean
	minSearchTermLength?: number
}

enum Modes {
	display,
	lookupQuery,
}

export function MultiLookupInput<T>(props: IMultiLookupInputProps<T>) {
	const { tcvi } = useTranslation()

	const id = useID(props.id)
	const inputRef = useRef<HTMLInputElement>(null)
	const lookupToken = useRef<number>()
	const delayedLookupQuery = useRef<number>()

	const [tagValues, setTagValues] = useState<MultiValueInputItem<T>[]>([])

	const [mode, setMode] = useState<Modes>(Modes.display)
	const [lookupQuery, setLookupQuery] = useState('')

	const [isInputAutocompleteOpen, setIsInputAutocompleteOpen] = useState(false)
	const [isAutoCompleteInProgress, setIsAutoCompleteInProgress] = useState(false)
	const [autoCompleteOptions, setAutoCompleteOptions] = useState<LookupOption<T>[] | undefined>()

	useEffect(() => {
		const createTagValues = (values: T[], namedValues: LookupOption<T>[]): MultiValueInputItem<T>[] => {
			const result: MultiValueInputItem<T>[] = []
			return values.reduce((displayValues, value) => {
				const namedValue = namedValues.find((item) => item.value === value)

				if (namedValue) {
					displayValues.push({
						value: namedValue.value,
						label: namedValue.label,
					})
				}

				return displayValues
			}, result)
		}

		const tagValues = createTagValues(props.values, props.namedValues)
		setTagValues(tagValues)
	}, [props.values, props.namedValues])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value

		if (delayedLookupQuery.current) {
			window.clearInterval(delayedLookupQuery.current)
			delayedLookupQuery.current = 0
		}

		if (!value.length) {
			setMode(Modes.display)
			props.enableAutocomplete && setIsInputAutocompleteOpen(false)
			setAutoCompleteOptions(undefined)
			setLookupQuery(value)
		} else {
			setMode(Modes.lookupQuery)
			props.enableAutocomplete && setIsInputAutocompleteOpen(true)
			setLookupQuery(value)
			props.enableAutocomplete && performAutocompleteLookup(value.trim())
		}
	}

	const onSelection = (itemIds: T[]) => {
		props.onSelection?.(itemIds)
	}

	const focusInputField = () => {
		inputRef.current?.focus()
		mode === Modes.display && inputRef.current?.select()
	}

	const handleClick = (e: React.MouseEvent) => {
		if (e.ctrlKey || e.metaKey) {
			if (mode === Modes.display) {
				props.defaultAction?.()
			}
		} else {
			focusInputField()
			setIsInputAutocompleteOpen(true)
		}
	}

	const performAutocompleteLookup = (query: string) => {
		const currentLookupToken = Date.now()
		lookupToken.current = currentLookupToken

		setAutoCompleteOptions(undefined)

		if (!props.performLookup) {
			return
		}

		if (props.minSearchTermLength && props.minSearchTermLength > query.length) {
			return
		}

		setIsAutoCompleteInProgress(true)

		delayedLookupQuery.current = window.setTimeout(() => {
			props.performLookup?.(query, currentLookupToken, onAutocompleteOptionsAvailable)
		}, 300)
	}

	const onAutocompleteOptionsAvailable = (token: number, options: LookupOption<T>[]) => {
		if (token !== lookupToken.current) {
			return
		}

		setIsAutoCompleteInProgress(false)

		setAutoCompleteOptions(options)
	}

	const revertToStoredValue = (selectValue: boolean) => {
		selectValue && inputRef.current?.select()

		setMode(Modes.display)
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (mode !== Modes.lookupQuery) {
			return
		}

		if (e.key === 'Escape') {
			e.preventDefault()

			if (isInputAutocompleteOpen) {
				setIsInputAutocompleteOpen(false)
			} else {
				revertToStoredValue(true)
			}
		}
	}

	const onFocus = () => {
		inputRef.current?.select()
		setMode(Modes.lookupQuery)
	}

	const onBlur = () => {
		if (mode !== Modes.lookupQuery) {
			return
		}

		// on blur it would be possible to actually search but since the lookup is async it may be difficult so
		// for now we cancel search on blur
		revertToStoredValue(false)
	}

	const closeDropdown = useCallback(() => {
		props.enableAutocomplete && setIsInputAutocompleteOpen(false)
	}, [props.enableAutocomplete])

	const selectFromAvailableOptions = (item: T | ITextLookupQuery) => {
		if (item && (item as ITextLookupQuery).type === 'lookupQuery') {
			selectTextAutocompleteItem((item as ITextLookupQuery).value)
		} else {
			selectAutocompleteItem(item as T)
		}
	}

	const selectAutocompleteItem = (itemId: T) => {
		setIsInputAutocompleteOpen(false)
		setAutoCompleteOptions(undefined)
		setLookupQuery('')

		props.selectItem?.(itemId)

		inputRef.current?.focus()
	}

	const selectTextAutocompleteItem = (text: string) => {
		setIsInputAutocompleteOpen(false)
		setAutoCompleteOptions(undefined)
		setLookupQuery('')

		props.selectTextItem?.(text)
		inputRef.current?.focus()
	}

	const shouldDisplayValueOptions = autoCompleteOptions && autoCompleteOptions.length > 0 && props.selectItem
	const shouldDisplayTextOption = lookupQuery && props.selectTextItem

	const availableOptions: LookupOption<T | ITextLookupQuery>[] = []

	if (shouldDisplayValueOptions && autoCompleteOptions) {
		availableOptions.push(...autoCompleteOptions)
	}

	if (shouldDisplayTextOption) {
		availableOptions.push({
			label: tcvi('GENERAL:USE') + ': "' + lookupQuery + '"',
			value: { type: 'lookupQuery', value: lookupQuery },
		})
	}

	const dropdownOptions = new DropdownOptionsHandler<T | ITextLookupQuery>(availableOptions, false, tcvi)

	const { placeholder, readOnlyIndicatorStyle } = { ...useStyleContext().style?.input }

	const multiClassNames = classNames(classes.multiValueInput, props.className)

	return (
		<FormControl
			id={id}
			label={props.label}
			hideLabel={props.hideLabel}
			labelProps={props.labelProps}
			labelPosition={props.labelPosition}
			icon={props.icon}
			labelContentLayout={props.labelContentLayout}
			labelSubText={props.labelSubText}
			validationText={props.validationText}
			validationTextPosition={props.validationTextPosition}
			subText={props.subText}
			reserveHelperTextSpace={props.reserveHelperTextSpace}
			disabled={props.disabled}
			readOnly={props.readOnly}
			required={props.required}
			error={props.error}
			warning={props.warning}
			margin={props.margin}
			className={props.className}
			disableBorder={props.disableBorder}
			screenTip={props.screenTip}
			dataAttributes={props.dataAttributes}
		>
			{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeValue && (
				<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignLeft />
			)}
			<MultiValueInput<T>
				id={props.id}
				dataAttributes={props.dataAttributes}
				ref={inputRef}
				placeholder={props.placeholder ?? placeholder}
				inputValue={lookupQuery}
				tags={tagValues}
				className={multiClassNames}
				onSelection={onSelection}
				onClick={handleClick}
				onInputFocus={onFocus}
				onKeyDown={onKeyDown}
				onInputBlur={onBlur}
				onInputChange={handleChange}
				disabled={props.disabled}
				readOnly={props.readOnly}
			/>
			{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterValue && (
				<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
			)}

			{props.enableAutocomplete && inputRef.current && (
				<div>
					{dropdownOptions.length > 0 && (
						<DropdownList<T | ITextLookupQuery>
							isOpen={isInputAutocompleteOpen}
							anchorElement={inputRef}
							minWidth={240}
							options={dropdownOptions}
							onChange={(value: T | ITextLookupQuery | null) => {
								value && selectFromAvailableOptions(value)
							}}
							onClose={closeDropdown}
							multiSelect={false}
							isLoadingOptions={isAutoCompleteInProgress}
							preselectedIndex={0}
							disableSelectWithSpace
							className={props.className}
						/>
					)}
				</div>
			)}
		</FormControl>
	)
}
