import React, { useState } from 'react'

// import { selectionHasFormat } from './selectionHasFormat'

import { useToolbarButtons } from './useToolbarButtons'
import { createStyle } from '../../../../theming'
import type { e_ToolbarElement } from '../../interfaces/e_ToolbarElement'
import { CommandBar } from '../../../CommandBar'
import { LinkDialog } from '../../dialogs/LinkDialog/LinkDialog'
import { InsertTableDialog } from '../../dialogs/InsertTableDialog'

const classes = createStyle({
	commandBar: {
		flex: 'none',
	},
})

interface IProps {
	isDisabled?: boolean
	readOnly?: boolean
	inheritTheme: boolean | undefined
	displayInToolbar?: Partial<Record<e_ToolbarElement, boolean>>
}

export const ToolbarPlugin = (props: IProps) => {
	const { isDisabled, readOnly } = props

	const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false)
	const [isInsertTableDialogOpen, setIsInsertTableDialogOpen] = useState(false)

	const buttons = useToolbarButtons(
		!!props.inheritTheme,
		!!isDisabled,
		props.displayInToolbar,
		() => setIsLinkDialogOpen(true),
		() => setIsInsertTableDialogOpen(true)
	)

	return readOnly !== true ? (
		<>
			<CommandBar
				items={buttons}
				handleOverflow
				className={classes.commandBar}
				dataAttributes={{ 'data-cy': 'editor-toolbar' }}
			/>
			<LinkDialog isOpen={isLinkDialogOpen} onClose={() => setIsLinkDialogOpen(false)} />
			<InsertTableDialog isOpen={isInsertTableDialogOpen} onClose={() => setIsInsertTableDialogOpen(false)} />
		</>
	) : null
}
