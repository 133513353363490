import { defaultColors, defaultBorderRadius, defaultTypography } from '../theming/defaults'
import { transitions } from '../theming/themeProperties/transitions'
import { zIndexes } from '../theming/themeProperties/zIndexes'
import type { Palette } from '../theming/themeProperties/colors/paletteGenerator'
import { paletteGenerator } from '../theming/themeProperties/colors/paletteGenerator'
import type { ShadowTypes } from '../theming/themeProperties/shadows'
import { generateShadows } from '../theming/themeProperties/shadows'
import type { SemanticColors } from './semanticMapping'
import { mapSemanticColorsFromPalette } from './semanticMapping'
import { cssVar } from './themingManager'
import { generateAppBarColorsFromPalette } from './generateControlColorsFromPalette/generateAppBarColorsFromPalette'
import { generateButtonColorsFromSemanticPalette } from './generateControlColorsFromPalette/generateButtonColorsFromSemanticPalette'
import { generateInputColorsFromSemanticPalette } from './generateControlColorsFromPalette/generateInputColorsFromSemanticPalette'
import { generateNavigationPaneColorsFromPalette } from './generateControlColorsFromPalette/generateNavigationPaneColorsFromPalette'
import { generateListColorsFromPalette } from './generateControlColorsFromPalette/generateListColorsFromPalette'
import { getTypographyStyles } from '../theming/themeProperties/typography'
import { generateChipColorsFromSemanticPalette } from './generateControlColorsFromPalette/generateChipColorsFromSemanticPalette'

const generateControlStyles = (palette: Palette, semanticPalette: SemanticColors, shadows: ShadowTypes) => {
	return {
		general: {
			dialogBorderRadius: '2px',
			navigationPanePadding: undefined,
		},
		dialog: {
			borderRadius: '2px',
			borderWidth: undefined,
			borderColor: cssVar(semanticPalette.body, 'line'),
			backdropColor: 'rgba(0, 0, 0, 0.2)',
			headerFontSize: '20px',
			headerFontWeight: 600,
			smallHeaderFontSize: '17px',
			smallHeaderFontWeight: 600,
		},
		button: {
			...generateButtonColorsFromSemanticPalette(semanticPalette),
			borderRadius: '2px',
			borderStyle: 'solid',
			borderWidth: '1px',
			iconSize: 'small',
			boxShadow: 'none',
			focusOutlineOffset: '-2px',
			focusBorderPadding: '0px',
			focusOutlineWidth: '1px',
			margin: 0,
			padding: undefined,
			focusBorderStyle: 'solid',
			focusBorderColor: undefined,
			focusBorderWidth: '1px',
			fontSize: '14px',
			fontWeight: 600,
			textDecoration: 'none',
		},
		chip: {
			...generateChipColorsFromSemanticPalette(semanticPalette), // #css-var Chip farger fungerer ikke
			borderRadius: '2px',
			borderStyle: 'solid',
			borderWidth: '1px',
			borderWidthStrong: '2px',
			dropShadow: undefined,
			focusBorderOffset: -2,
			focusBorderStyle: 'solid',
			focusBorderWidth: '1px',
			textDecoration: undefined,
			padding: '0px',
			focusedBorderColor: cssVar(semanticPalette.body, 'focusBorder'),
			fontSize: undefined,
			fontWeight: undefined,
		},
		header: {
			...getTypographyStyles(defaultTypography),
		},
		input: {
			...generateInputColorsFromSemanticPalette(palette, semanticPalette),
			borderRadius: '2px',
			borderWidth: '1px',
			focusBorderWidth: '2px',
			padding: '6px 4px 5px 4px',
			dropShadow: cssVar(shadows, 'none'),
			readOnlyFontStyle: undefined,
			placeholderTextColor: cssVar(semanticPalette.input, 'placeholderText'),
			placeholderTextFontStyle: undefined,
			subText: {
				spacing: '4px',
				padding: '4px',
				borderWidth: '1px',
				colors: {
					background: undefined, //cssVar(semanticPalette.input, 'background'),
					color: cssVar(semanticPalette.input, 'text'),
					border: cssVar(semanticPalette.input, 'border'),
				},
				warningColors: {
					background: undefined, //cssVar(palette.warning, 'warningLight'),
					color: cssVar(palette.warning, 'warningPrimary'),
					border: cssVar(palette.warning, 'warningPrimary'),
				},
				errorColors: {
					background: undefined, //cssVar(palette.error, 'errorLight'),
					color: cssVar(palette.error, 'errorPrimary'),
					border: cssVar(palette.error, 'errorPrimary'),
				},
			},
		},
		appBar: {
			...generateAppBarColorsFromPalette(palette),

			height: undefined,
			padding: undefined,

			showHelp: undefined,
			showSettings: undefined,
			showUserProfile: undefined,
		},
		navigationPane: {
			...generateNavigationPaneColorsFromPalette(palette, semanticPalette),
		},
		list: {
			...generateListColorsFromPalette(palette),
		},
	}
}

export function generateTheme(customColors?: Partial<{ [key in keyof typeof defaultColors]: string }>) {
	const themeColors = { ...defaultColors, ...customColors }

	const palette = paletteGenerator(themeColors)

	const semanticColors = mapSemanticColorsFromPalette(palette)
	const shadows = generateShadows(palette.foreground.black)
	const theme = {
		palette: palette,
		colors: semanticColors,
		shadows: shadows,
		borderRadius: defaultBorderRadius,
		transitions,
		zIndex: zIndexes,
		typography: getTypographyStyles(defaultTypography),
		controls: generateControlStyles(palette, semanticColors, shadows),
	}

	return { ...theme }
}
