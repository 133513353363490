import clsx from 'clsx'
import React, { useRef, useState } from 'react'
import type { IDataPropertyDescription } from '../types/IDataPropertyDescription'
import type { ISortedColumn } from '../types/ISortedColumn'
import { createStyle } from '../../../theming'
import { e_Placement } from '../../../enums/e_Placement'
import { Callout } from '../../Callout'
import { Divider } from '../../../controls/Divider'
import { GenericButton } from '../../../controls/GenericButton'
import { useTranslation } from '../../../translation'

const classes = createStyle({
	sortComponent: {},

	sortPopup: {
		borderRadius: '5px',
		border: '1px solid silver',
	},
	sortPanel: {
		display: 'flex',
		flexDirection: 'column',
		background: 'white',

		minWidth: '150px',
		alignItems: 'stretch',
	},
	filterTextBorderActive: {
		flex: 1,
		borderColor: 'silver',
	},

	item: {
		display: 'flex',
	},

	filterTextInput: {
		flex: 1,
		border: 'none',
		outline: 'none',
		padding: '1px 4px',
	},

	sortButton: {
		fontWeight: 'initial',
	},

	sortButtonStretch: {
		flex: 1,
		justifyContent: 'flex-start',
	},

	sortButtonActive: {
		background: 'rgba(0,0,0,0.2)',
	},
})

interface IColumnSortPanelProps {
	columns?: IDataPropertyDescription[]
	sortedColumns?: ISortedColumn[]
	sortColumns?: (sortedColumns: ISortedColumn[]) => void
}

export const ColumnSortPanel = (props: IColumnSortPanelProps) => {
	const { tcvvi } = useTranslation()

	const [displaySearchPanel, setDisplaySearchPanel] = useState(false)
	const sortComponent = useRef<HTMLDivElement>(null)

	const toggleSortPanel = () => {
		setDisplaySearchPanel(!displaySearchPanel)
	}

	const closeSortPanel = () => {
		setDisplaySearchPanel(false)
	}

	const changeSortOrder = (columnId: string, order: 'asc' | 'desc') => {
		if (!props.sortedColumns) {
			return
		}

		if (!props.sortColumns) {
			return
		}
		const nextSortedColumns = props.sortedColumns.map((col) =>
			col.propertyId !== columnId ? col : { ...col, order: order }
		)

		props.sortColumns(nextSortedColumns)
	}

	const removeFromSort = (columnId: string) => {
		if (!props.sortedColumns) {
			return
		}

		if (!props.sortedColumns.find((col) => col.propertyId === columnId)) {
			return
		}

		if (!props.sortColumns) {
			return
		}

		const nextSortedColumns = props.sortedColumns.filter((col) => col.propertyId !== columnId)

		props.sortColumns(nextSortedColumns)
	}

	const addToSort = (columnId: string, order: 'asc' | 'desc') => {
		if (!props.sortedColumns) {
			return
		}

		if (props.sortedColumns.find((col) => col.propertyId === columnId)) {
			return
		}

		if (!props.sortColumns) {
			return
		}

		const nextSortedColumns = [...props.sortedColumns, { propertyId: columnId, order: order }]

		props.sortColumns(nextSortedColumns)
	}

	return (
		<div className={classes.sortComponent} ref={sortComponent}>
			<GenericButton
				onClick={toggleSortPanel}
				icon={'Fluent-Sort'}
				screenTip={tcvvi('GENERAL:SORT')}
				dataAttributes={{ 'data-cy': 'toggle-sort-panel' }}
			/>

			<Callout
				anchorElement={sortComponent}
				isOpen={displaySearchPanel}
				onClose={closeSortPanel}
				padding={4}
				placement={e_Placement.bottomEnd}
				className={classes.sortPopup}
			>
				<div className={classes.sortPanel}>
					{props.sortedColumns && props.sortedColumns.length > 0 && (
						<>
							{props.sortedColumns.map((sordedColumn) => {
								const columnDesc = props.columns?.find((desc) => desc.propertyId === sordedColumn.propertyId)
								return (
									<div className={classes.item} key={sordedColumn.propertyId}>
										<GenericButton
											className={clsx(classes.sortButton, classes.sortButtonStretch)}
											label={`${columnDesc?.displayName || '(unknown)'} (${tcvvi(
												sordedColumn.order === 'asc'
													? 'CONTROL:AG_ARIADROPZONECOLUMNCOMPONENTSORTASCENDING'
													: 'CONTROL:AG_ARIADROPZONECOLUMNCOMPONENTSORTDESCENDING'
											)})`}
											onClick={() =>
												changeSortOrder(sordedColumn.propertyId, sordedColumn.order === 'asc' ? 'desc' : 'asc')
											}
											screenTip={tcvvi('GENERAL:REMOVE')}
										/>
										<GenericButton
											className={classes.sortButton}
											icon={'Fluent-ChromeClose'}
											onClick={() => removeFromSort(sordedColumn.propertyId)}
											screenTip={tcvvi('GENERAL:REMOVE')}
										/>
									</div>
								)
							})}
							<Divider />
						</>
					)}

					{props.columns &&
						props.columns
							.filter((column) => {
								return (
									column.sortable && !props.sortedColumns?.find((sorted) => sorted.propertyId === column.propertyId)
								)
							})
							.map((col) => (
								<div className={classes.item} key={col.propertyId}>
									<GenericButton
										key={col.propertyId}
										className={clsx(classes.sortButton, classes.sortButtonStretch)}
										label={col.displayName}
										onClick={() => addToSort(col.propertyId, 'asc')}
									/>
									<GenericButton
										className={classes.sortButton}
										icon={'Fluent-Transparent'}
										onClick={() => {}}
										disabled
									/>
								</div>
							))}
				</div>
			</Callout>
		</div>
	)
}
