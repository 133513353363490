import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Dialog } from '../Dialog'
import { TextInput } from '../TextInput'
import type { IColumnProps } from '../Table'
import { Table, TableRow, TableCell } from '../Table'
import type { Option } from './LookupInputWithDialog'
import { useTranslation } from '../../translation'
import { useSortedRows } from '../utils/useSortedRows'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	tableWrapper: {
		overflowY: 'auto',
		border: '1px solid ' + theme.colors.list.border,
		flex: 1,
	},
	matchedSubstring: {
		textShadow: '1px 0 0 currentColor',
	},
	noResults: {
		margin: 8,
		textAlign: 'center',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	modalAbovePopper: {
		// In order for dialog to stay above floating search panel
		zIndex: theme.zIndex.popper,
	},
}))

export interface ILookupInputDialogManagerProps {
	label?: string
	lookupQuery: string
	dialogOptions: Option[]
	isDialogOpen: boolean
	onCancel: () => void
	onItemSelected: (value: string | number) => void
	columnProps: IColumnProps[]
}

export const LookupInputDialogManager = (props: ILookupInputDialogManagerProps) => {
	const { tcvvi } = useTranslation()

	const inputRef = useRef<HTMLInputElement>(null)

	const [selectedItemId, setSelectedItemId] = useState<string | number>()

	const [dialogOptionsFiltered, setDialogOptionsFiltered] = useState<Option[]>(props.dialogOptions)
	const [lookupResultsFilter, setLookupResultsFilter] = useState('')

	const columnIds = useMemo(
		() =>
			props.columnProps.reduce(
				(prev, current) => {
					prev[current.id] = current.id
					return prev
				},
				{} as Record<string, string>
			),
		[props.columnProps]
	)

	const defaultSortOrder = useMemo(
		() =>
			props.columnProps.reduce((prev, current) => {
				if (current.columnSortOrder) {
					prev.push(current.id)
				}
				return prev
			}, [] as string[]),
		[props.columnProps]
	)

	const { sortedRows, sortConfig, onSortColumnClick } = useSortedRows(
		dialogOptionsFiltered,
		columnIds,
		(colId) => colId,
		defaultSortOrder
	)

	useEffect(() => {
		setDialogOptionsFiltered(props.dialogOptions)
	}, [props.dialogOptions])

	useEffect(() => {
		if (!props.isDialogOpen) {
			return
		}

		inputRef.current?.focus()
	}, [props.isDialogOpen, props.dialogOptions])

	const onKeyDown = (e: React.KeyboardEvent) => {
		switch (e.key) {
			case 'ArrowDown': {
				e.preventDefault()
				e.stopPropagation()

				if (!dialogOptionsFiltered.length) {
					return
				}

				const currentIndex = dialogOptionsFiltered.findIndex((option) => option.value === selectedItemId)
				const newIndex = currentIndex >= dialogOptionsFiltered.length - 1 ? 0 : currentIndex + 1

				setSelectedItemId(dialogOptionsFiltered[newIndex].value)
				break
			}
			case 'ArrowUp': {
				e.preventDefault()

				if (!dialogOptionsFiltered.length) {
					return
				}

				const currentIndex = dialogOptionsFiltered.findIndex((option) => option.value === selectedItemId)
				const newIndex =
					currentIndex <= 0 ? dialogOptionsFiltered.length - 1 : (currentIndex - 1) % dialogOptionsFiltered.length

				setSelectedItemId(dialogOptionsFiltered[newIndex].value)
				break
			}
			case 'Enter': {
				selectedItemId && props.onItemSelected(selectedItemId)
				break
			}
		}
	}

	const onTableActivate = (itemId: string | number) => {
		setSelectedItemId(itemId)

		props.onItemSelected(itemId)
	}

	const onFilterChange = (value: string) => {
		const dialogOptionsFiltered = props.dialogOptions.filter((item) =>
			Object.entries(item).find(([colId, label]) => {
				if (colId === 'value') {
					return false
				}
				return (label || '').toString().toLowerCase().includes(value.toLowerCase().trim())
			})
		)

		setLookupResultsFilter(value)
		setDialogOptionsFiltered(dialogOptionsFiltered)
		setSelectedItemId(dialogOptionsFiltered.map((option) => option.value).find((id) => id === selectedItemId))
	}

	const generateLabelSubstrings = (label: string) => {
		const splitIndex = label.toLowerCase().indexOf(lookupResultsFilter.toLowerCase())
		if (splitIndex === -1) {
			return [label, '', '']
		}

		return [
			label.substring(0, splitIndex),
			label.substring(splitIndex, splitIndex + lookupResultsFilter.length),
			label.substring(splitIndex + lookupResultsFilter.length, label.length),
		]
	}

	const onClose = (commonButton: 'ok' | 'cancel' | 'yes' | 'no' | 'close') => {
		if (commonButton === 'ok') {
			selectedItemId && props.onItemSelected(selectedItemId)
		} else if (commonButton === 'cancel') {
			props.onCancel()
		}
	}

	const selectedRowIds = selectedItemId
		? [typeof selectedItemId === 'number' ? selectedItemId.toString() : selectedItemId]
		: undefined

	const columns = props.columnProps.map((colProps) => ({
		...colProps,
		columnSortOrder: sortConfig[colProps.id],
	}))

	return (
		<Dialog
			title={props.label}
			width={600}
			height={400}
			isValid={selectedItemId !== undefined}
			disableShowConfirmationMessageIfModified
			handleClose={onClose}
			isOpen
			onKeyDown={onKeyDown}
			commonButtonSet="okCancel"
			backdropClassName={classes.modalAbovePopper}
			modalClassName={classes.modalAbovePopper}
		>
			<>
				{props.dialogOptions.length > 0 && (
					<TextInput ref={inputRef} placeholder="Lookup" value={lookupResultsFilter} onChange={onFilterChange} />
				)}
				<div className={classes.tableWrapper} ref={(optionList) => optionList?.focus()}>
					{props.dialogOptions.length === 0 && (
						<div className={classes.noResults}>
							{tcvvi('GENERAL:NO_MATCHES_FOUND_FOR_SEARCH_CRITERIA', props.lookupQuery)}
						</div>
					)}
					{props.dialogOptions.length > 0 && (
						<Table
							columns={columns}
							onActivate={onTableActivate}
							setSelection={(rowIds: any) => setSelectedItemId(rowIds[0])}
							columnSortOnClick={onSortColumnClick}
							selectedRowIds={selectedRowIds}
						>
							{sortedRows.map((item, i) => {
								const tableCells = props.columnProps.map((colProps, i) => {
									const e = item[colProps.id]
									const labelSubstrings = generateLabelSubstrings((e || '').toString())
									return (
										<TableCell key={i}>
											<span>
												<span>{labelSubstrings[0]}</span>
												<span className={classes.matchedSubstring}>{labelSubstrings[1]}</span>
												<span>{labelSubstrings[2]}</span>
											</span>
										</TableCell>
									)
								})

								return (
									<TableRow key={item.value + '-' + i} id={item.value ? item.value.toString() : ''}>
										{tableCells}
									</TableRow>
								)
							})}
						</Table>
					)}
				</div>
			</>
		</Dialog>
	)
}
