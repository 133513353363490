import clsx from 'clsx'
import React from 'react'
import { HeaderControl } from './HeaderControl'
import { createStyle } from '../../../theming'
import type { IDataWithIdentifier } from '../types/IDataWithIdentifier'
import type { IDataPropertyDescription } from '../types/IDataPropertyDescription'
import type { DetailItemSurface } from '../types/IDetailItemSurfaceProps'
import type { IActionEntry } from '../types/IActionDescription'
import type { IMasterDetailPreakpoint } from '../types/IMasterDetailBreakpoint'

const classes = createStyle({
	detailPanel: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},

	withBorder: {
		background: 'white',
		border: '1px solid silver',
		borderRadius: '5px',
	},

	withShadow: {
		boxShadow: `0 6.4px 14.4px 0 rgba(${'0,0,0'}, 0.132), 0 1.2px 3.6px 0 rgba(${'0,0,0'}, 0.108)`,
	},

	leftMargin: {
		marginLeft: '16px',
	},

	rightMargin: {
		marginLeft: '16px',
	},

	leftBorder: {
		borderLeft: '1px solid silver',
	},
	item: {
		display: 'flex',
		padding: '8px',
		flexDirection: 'column',
		alignItems: 'stretch',
	},

	property: {
		display: 'flex',
		padding: '4px',

		'& > label': {
			width: '120px',
			textAlign: 'left',
		},
	},
})

interface IDetailPanelProps {
	title: string
	items: IDataWithIdentifier[]

	leftBorder?: boolean

	activeId: string | undefined

	itemName?: string | undefined | ((item: IDataWithIdentifier) => string | undefined)

	columns: IDataPropertyDescription[]

	itemActions?: IActionEntry[]

	breakpoint: IMasterDetailPreakpoint

	handleItemAction?: (actionId: string, itemId: string) => void

	back?: () => void

	detailComponent?: DetailItemSurface
}

export const DetailPanel = React.forwardRef((props: IDetailPanelProps, forwardedRef: React.Ref<HTMLDivElement>) => {
	const activeItem = props.activeId ? props.items.find((item) => item.id === props.activeId) : undefined

	const getItemName = (item: IDataWithIdentifier) => {
		if (!props.itemName) {
			return item.id
		}

		if (typeof props.itemName === 'string') {
			return item[props.itemName]?.toString()
		}

		if (typeof props.itemName === 'function') {
			return props.itemName(item)
		}
	}

	const DetailComponent = props.detailComponent

	return (
		<div className={clsx(classes.detailPanel, props.leftBorder && classes.leftBorder)} ref={forwardedRef}>
			<HeaderControl title={(activeItem ? getItemName(activeItem) : props.title) || ''} back={props.back} />

			{DetailComponent && activeItem && <DetailComponent dataItem={activeItem} breakpoint={props.breakpoint} />}
			{!DetailComponent && activeItem && (
				<div className={classes.item}>
					{props.columns.map((propDesc) => (
						<div className={classes.property} key={propDesc.propertyId}>
							<label>{propDesc.displayName}</label>
							<input value={activeItem[propDesc.propertyId] ? activeItem[propDesc.propertyId]!.toString() : ''} />
						</div>
					))}
				</div>
			)}
		</div>
	)
})

DetailPanel.displayName = 'DetailPanel'
