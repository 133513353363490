import { useMemo } from 'react'
import { keyCreator } from '../utils/colDefUtils'
import type { ColDef } from '@ag-grid-community/core'
import type { TData } from '../Table.types'

export const useDefaultColDef = () =>
	useMemo(
		() => {
			const colDef: ColDef<TData> = {
				resizable: true,
				sortable: true,
				enableRowGroup: true,
				enableCellChangeFlash: true,
				suppressMenu: true,
				keyCreator,
			}

			return colDef
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)
