import React, { useMemo, useState } from 'react'

// Web UI
import { e_OperatorStorageKeys } from 'src/enums/e_OperatorStorageKeys'
import { useTranslation } from 'react-i18next'
import { Dropdown, DropdownInput } from '@genusbiz/web-ui/controls'
import classNames from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { userSettingsActions } from 'src/features/UserSettings/duck/userSettingsActions'
import { userSettingsSelectors } from 'src/features/UserSettings/duck/userSettingsSelectors'
import { createStyle } from '@genusbiz/web-ui/theming'
import { getTimezoneOptions } from 'src/utils/getTimezoneOptions'
import { convertIntegerToHoursMinutes } from 'src/utils/dateTimeUtils'
import moment from 'moment-timezone'
import { e_StandardTimezones } from 'src/enums/e_StandardTimezones'
import { MOBILE_WIDTH, TABLET_WIDTH } from 'src/consts/cssBreakpoints'
import { operatorFrontendSelectors } from 'src/features/OperatorFrontend/duck/operatorFrontendSelectors'

const classes = createStyle((theme) => ({
	externalLink: {
		display: 'inline-block',
		margin: '4px 0px',
		color: theme.colors.links.text,
		textDecoration: 'none',
		cursor: 'pointer',
		'&:visited': {
			color: theme.colors.links.text,
		},
		'&:hover': {
			color: theme.colors.links.hoveredText,
			textDecoration: 'underline',
		},
		'&:active': {
			color: theme.colors.links.hoveredText,
			textDecoration: 'underline',
		},
	},

	rightSideBarTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	apiKeysContainer: {
		paddingBottom: '20px',
	},
	timezonePicker: {
		display: 'none',
		flexDirection: 'column',
		flex: '0 0 auto',
		marginBottom: 10,
		[`@media (max-width:${TABLET_WIDTH}px)`]: {
			display: 'flex',
		},
	},

	settingElement: {
		display: 'flex',
		flexDirection: 'column',
		flex: '0 0 auto',
		marginBottom: 10,
		[`@media (max-width:${MOBILE_WIDTH}px)`]: { maxWidth: 'none' },
	},
}))

interface IRuntimeUserSettingsPanelProps {
	activeAppId?: string
	navigateToProfileApp?: (appId: string, openInNewTab: boolean) => void
	appIndex: { id: string }[] | undefined
	close: () => void
	signOut: () => void
}

export const RuntimeUserSettingsPanel = (props: IRuntimeUserSettingsPanelProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [utcOffset, setUtcOffset] = useState<string>(convertIntegerToHoursMinutes(moment().utcOffset()))

	const username =
		useSelector(operatorFrontendSelectors.selectUserInfo)?.username ||
		sessionStorage.getItem(e_OperatorStorageKeys.username) ||
		''
	const kubernetesVersion = useSelector(operatorFrontendSelectors.selectKubernetesVersion) || ''
	const preferredLanguage = useSelector(userSettingsSelectors.selectPreferredLanguge)
	const preferredFormattingCulture = useSelector(userSettingsSelectors.selectPreferredFormattingCulture)
	const selectedTimezone = useSelector(userSettingsSelectors.selectSelecedTimezone)
	const languageOptions = useMemo(() => {
		return [
			{ value: 'nb', label: t('GENERAL:NORWEGIAN') },
			{ value: 'en', label: t('GENERAL:ENGLISH') },
		]
	}, [t])

	const displayFormatOptions = useMemo(() => {
		return [
			{ value: 'nb', label: t('GENERAL:NORWEGIAN') },
			{ value: 'en-gb', label: t('GENERAL:ENGLISH') + ' (UK)' },
			{ value: 'en', label: t('GENERAL:ENGLISH') + ' (US)' },
		]
	}, [t])
	const setLanguage = (value: string) => {
		// translator.changeLanguage(value as 'nb' | 'en')
		dispatch(userSettingsActions.setPreferedLanguage(value as 'nb' | 'en'))
	}
	const setFormattingCulture = (value: string) => {
		// translator.changeFormattingCulture(value as 'nb' | 'en-gb' | 'en')
		dispatch(userSettingsActions.setPreferedFormattingCulture(value as 'nb' | 'en-gb' | 'en'))
	}

	const setTimezone = (timezone: string) => {
		let offset = 0
		if (timezone === e_StandardTimezones.browserTime) {
			dispatch(userSettingsActions.setSelectedTimezone(moment.tz.guess()))
			offset = moment().utcOffset()
		} else {
			dispatch(userSettingsActions.setSelectedTimezone(timezone))
			timezone = timezone.replaceAll(' ', '_')
			offset = moment.tz(timezone).utcOffset()
		}
		setUtcOffset(convertIntegerToHoursMinutes(offset))
	}

	const versionNumber = (window.env.GENUS_OPERATOR_VERSION_NUMBER as string) || ''
	const versionString = 'Genus Operator ' + versionNumber
	return (
		<div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between' }}>
			<div>
				<div className={classes.settingElement}>{t('GENERAL:USERNAME') + ': ' + username}</div>

				<div className={classes.settingElement}>
					<Dropdown
						label={t('GENERAL:LANGUAGE')}
						value={preferredLanguage}
						options={languageOptions}
						onChange={setLanguage}
					/>
				</div>

				<div className={classes.settingElement}>
					<Dropdown
						label={t('GENERAL:DISPLAY_FORMAT')}
						value={preferredFormattingCulture}
						options={displayFormatOptions}
						onChange={setFormattingCulture}
					/>
				</div>

				<div className={classes.timezonePicker}>
					<DropdownInput
						label={t('APP_BAR:TIME_ZONE')}
						labelPosition="top"
						value={selectedTimezone}
						options={getTimezoneOptions()}
						onChange={(value: string) => setTimezone(value)}
					/>
					<div style={{ alignItems: 'center', display: 'flex', margin: '5px' }}>{`UTC${utcOffset}`}</div>
				</div>

				<div onClick={props.signOut} className={classNames(classes.externalLink, classes.settingElement)}>
					{t('GENERAL:SIGN_OUT')}
				</div>
			</div>

			<div
				title={versionString}
				style={{
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
				}}
			>
				<span>{versionString}</span>
				<div className={classes.settingElement}>{t('GENERAL:KUBERNETES_VERSION') + ': ' + kubernetesVersion}</div>
			</div>
		</div>
	)
}
