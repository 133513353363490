import { Reducer } from '@reduxjs/toolkit'
import { e_NavigationItem } from 'src/enums/e_NavigationItems'
import { IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { IPod } from 'src/interfaces/IPod'
import { IOperatorFrontendActions } from './IOperatorFrontendActions'
import { operatorFrontendTypes } from './operatorFrontendTypes'
import { IConfig } from 'src/interfaces/IConfig'
import { IUserInfo } from 'src/interfaces/IUserInfo'
import { e_OperatorResources } from 'src/enums/e_OperatorResources'
import { IOperatorErrorLog } from 'src/interfaces/IErrorLog'
import { IControlLoopStatus } from 'src/interfaces/IControlLoopStatus'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'
/*
 * Reducer for App runtime
 */

export interface IOperatorFrontendState {
	isInitialized: boolean
	config: IConfig
	selectedK8sRuntimeName: string | null
	selectedK8sRuntimes: string[]
	selectedK8sRuntime: IK8sRuntime | null
	activeK8sRuntimes: IK8sRuntime[]
	selectedPods: IPod[]
	selectedWorkloadController: IWorkloadController | null
	navigationItem: e_NavigationItem
	// autoReload: Record<e_OperatorResources, boolean>
	autoReload: { [resource: string]: boolean }
	operatorErrorLog: IOperatorErrorLog
	controlLoopStatus: IControlLoopStatus | null
	kubernetesVersion: string
	userInfo: IUserInfo | null
}
const initialState: IOperatorFrontendState = {
	isInitialized: false,
	config: {
		optionalFunctionality: {
			isPhoneHomeEnabled: false,
		},
		eventLogUrl: '',
	},
	selectedK8sRuntimes: [],
	activeK8sRuntimes: [],
	selectedK8sRuntimeName: null,
	selectedK8sRuntime: null,
	selectedPods: [],
	selectedWorkloadController: null,
	navigationItem: location.pathname.substring(1) as e_NavigationItem,
	autoReload: { [e_OperatorResources.availabilityWindows]: true, [e_OperatorResources.kubernetesEvents]: false },
	operatorErrorLog: { redisKey: '', logName: '', retrievelTimestamp: '', logs: [] },
	controlLoopStatus: null,
	kubernetesVersion: '',
	userInfo: null,
}

const operatorFrontendReducer: Reducer<IOperatorFrontendState, IOperatorFrontendActions> = (
	state = initialState,
	action: IOperatorFrontendActions
): IOperatorFrontendState => {
	switch (action.type) {
		case operatorFrontendTypes.SET_INITIALIZED: {
			return {
				...state,
				isInitialized: true,
			}
		}
		case operatorFrontendTypes.SET_CONFIG: {
			return {
				...state,
				config: action.config,
			}
		}

		case operatorFrontendTypes.SET_SELECTED_K8S_RUNTIME_NAMES: {
			return {
				...state,
				selectedK8sRuntimes: action.k8sRuntimeNames,
			}
		}

		case operatorFrontendTypes.SET_SELECTED_K8S_RUNTIME_NAME: {
			return {
				...state,
				selectedK8sRuntimeName: action.k8sRuntime,
			}
		}

		case operatorFrontendTypes.SET_SELECTED_K8S_RUNTIME: {
			return {
				...state,
				selectedK8sRuntime: action.k8sRuntime,
			}
		}

		case operatorFrontendTypes.SET_SELECTED_PODS: {
			return {
				...state,
				selectedPods: action.pods,
			}
		}

		case operatorFrontendTypes.SET_SELECTED_WORKLOAD_CONTROLLER: {
			return {
				...state,
				selectedWorkloadController: action.workloadController,
			}
		}

		case operatorFrontendTypes.SET_NAVIGATION_ITEM: {
			// Clear selected pods on navigation
			const selectedPods = action.navigationItem === e_NavigationItem.workloads ? state.selectedPods : []

			// Clear selected runtimeConfigurations on navigation
			const selectedK8sRuntimes = action.navigationItem === e_NavigationItem.runtimes ? state.selectedK8sRuntimes : []

			return {
				...state,
				navigationItem: action.navigationItem,
				selectedPods: selectedPods,
				selectedK8sRuntimes: selectedK8sRuntimes,
			}
		}

		case operatorFrontendTypes.SET_AUTO_RELOAD: {
			return {
				...state,
				autoReload: { ...state.autoReload, [action.resource]: action.value },
			}
		}
		case operatorFrontendTypes.SET_OPERATOR_ERROR_LOG: {
			return {
				...state,
				operatorErrorLog: action.operatorErrorLog,
			}
		}
		case operatorFrontendTypes.SET_CONTROL_LOOP_STATUS: {
			return {
				...state,
				controlLoopStatus: action.controlLoopStatus,
			}
		}

		case operatorFrontendTypes.SET_KUBERNETES_VERSION: {
			return {
				...state,
				kubernetesVersion: action.kubernetesVersion,
			}
		}

		case operatorFrontendTypes.SET_USER_INFO: {
			return {
				...state,
				userInfo: action.userInfo,
			}
		}

		default:
			return state
	}
}

export const operatorFrontendRootReducer: Reducer<IOperatorFrontendState, IOperatorFrontendActions> = (
	state,
	action
) => {
	return operatorFrontendReducer(state, action)
}
