import React from 'react'
import type { TData } from '../Table.types'
import { refreshCheckMarkColumn } from '../utils/selectionUtils'
import { SelectionCheckMark } from './SelectionCheckMark'
import type { IRowNode, ColumnApi, GridApi } from '@ag-grid-community/core'
import { getRowNodeId } from './CellRenderer/getRowNodeId'

interface IProps {
	api: GridApi<TData>
	columnApi: ColumnApi
	node: IRowNode<TData>
	disabled?: boolean
}

export const CellSelectionCheckMark = (props: IProps) => {
	const nodeId = getRowNodeId(props.node)
	const isSelected = getIsSelected(props.node)

	const selectChildRows = (select: boolean) => {
		props.node.allLeafChildren.forEach((rowNode) => {
			rowNode.setSelected(select)
		})
	}

	const onClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()

		if (props.node.group) {
			selectChildRows(!isSelected)
		} else {
			props.node.setSelected(!isSelected)
		}
		refreshCheckMarkColumn(props.api, props.columnApi)
	}

	return (
		<SelectionCheckMark
			key={`${nodeId}-multiSelectCheckMark`}
			node={props.node}
			value={isSelected ?? 'mixed'}
			disableSelection={props.disabled}
			onClick={onClick}
		/>
	)
}

function getIsSelected(node: IRowNode<TData>) {
	if (node.group) {
		let hasFoundSelectedChild = false
		let hasFoundUnselectedChild = false
		for (const child of node.allLeafChildren) {
			if (child.isSelected()) {
				if (hasFoundUnselectedChild) {
					return undefined
				}
				hasFoundSelectedChild = true
			} else {
				if (hasFoundSelectedChild) {
					return undefined
				}
				hasFoundUnselectedChild = true
			}
		}
		return hasFoundSelectedChild
	} else {
		return node.isSelected()
	}
}
