import React, { useRef } from 'react'
import { createStyle } from '../../theming'

import classNames from 'clsx'
import type { IFormControl } from '../FormControl'
import { FormControl, FormControlButton } from '../FormControl'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useID } from '../utils/useID'
import { useStyleContext } from '../utils/Style.context'
import { DropdownList } from '../Dropdown'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { ReadOnlyIndicator } from '../FormControl/ReadOnlyIndicator'
import {
	getEventHandlers,
	getLogicalFocusAndHoverHandlers,
	useAvailableOptions,
	useDropdownPanel,
	useIncrementCurrentValue,
	useMaxValueMinutes,
	useMinValueMinutes,
	useOptionResolutionMinutes,
	useTextValueChange,
	useUpdateInternalValue,
} from './utils'

const classes = createStyle({
	alignRight: {
		textAlign: 'right',
	},
	alignCenter: {
		textAlign: 'center',
	},
	input: { fontWeight: 'inherit' },
})

export type DurationValueType = number | null | undefined

interface IDurationProps extends IFormControl {
	value: DurationValueType
	placeholder?: string
	textAlignment?: 'left' | 'center' | 'right'
	charPress?: string
	dropdownOpen?: boolean
	onChange: (value?: DurationValueType) => void
	onClose?: () => void
	onBlur?: (value?: DurationValueType) => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
	isIntegerTime?: boolean
	cannotBeBlank?: boolean
	enableOptionDropdown?: boolean
	optionsResolutionMinutes?: number
	minValueTicksMS?: number
	maxValueTicksMS?: number
	actionButtonRef?: React.Ref<HTMLButtonElement>
}

export const DurationInput = React.forwardRef((props: IDurationProps, forwardedRef: React.Ref<HTMLInputElement>) => {
	const { onClose } = props

	const inputRef = useForwardedRef<HTMLInputElement>(forwardedRef)
	const id = useID(props.id)
	const anchorElementRef = useRef<HTMLDivElement>(null)

	const maxValueMinutes = useMaxValueMinutes(props.maxValueTicksMS, props.isIntegerTime)
	const minValueMinutes = useMinValueMinutes(props.minValueTicksMS)
	const optionResolutionMinutes = useOptionResolutionMinutes(props.optionsResolutionMinutes)

	const {
		updateInternalValue,
		convertToExternalFormatFunc,
		displayValue,
		internalValue,
		internalErrorMessage,
		setDisplayValue,
		isEnteredValueValid,
		userIsWriting,
	} = useUpdateInternalValue(
		inputRef,
		props.value,
		props.isIntegerTime,
		minValueMinutes,
		maxValueMinutes,
		props.required,
		props.error,
		props.onChange
	)

	const { options, displayOptionDropDown, dropdownIndex } = useAvailableOptions(
		internalValue,
		props.optionsResolutionMinutes,
		props.minValueTicksMS,
		props.maxValueTicksMS,
		props.isIntegerTime,
		props.enableOptionDropdown
	)

	const { isDropdownOpen, openedByTouch, closeDropdown, toggleDropdownPanel, onOptionClick } = useDropdownPanel(
		inputRef,
		updateInternalValue,
		onClose,
		props.dropdownOpen
	)

	const onTextValueChange = useTextValueChange(
		updateInternalValue,
		setDisplayValue,
		isEnteredValueValid,
		userIsWriting,
		props.isIntegerTime,
		props.charPress
	)

	const onBlur = (e: React.FocusEvent) => {
		userIsWriting.current = false
		props.setLogicalFocus?.(false)
		if (anchorElementRef.current?.contains(e.relatedTarget as Node)) {
			return
		}

		if (internalValue.current === undefined && props.cannotBeBlank) {
			internalValue.current = 0
		}

		updateInternalValue(internalValue.current, true, false, false)

		if (props.onBlur) {
			const externalFormat = convertToExternalFormatFunc(internalValue.current)
			props.onBlur(externalFormat)
		}
	}

	const incrementCurrentValue = useIncrementCurrentValue(
		internalValue,
		minValueMinutes,
		maxValueMinutes,
		optionResolutionMinutes,
		props.isIntegerTime,
		updateInternalValue
	)

	const { placeholder, readOnlyIndicatorStyle } = { ...useStyleContext().style?.input }

	const { onMouseOver, onMouseOut, onFocus } = getLogicalFocusAndHoverHandlers(
		props.setLogicalHover,
		props.setLogicalFocus
	)
	const { handleKeyDown } = getEventHandlers(
		openedByTouch,
		isDropdownOpen,
		incrementCurrentValue,
		props.readOnly,
		props.disabled,
		props.defaultAction,
		props.onKeyDown
	)

	const contentCSS = classNames(
		{
			[classes.alignRight]: props.textAlignment === 'right',
			[classes.alignCenter]: props.textAlignment === 'center',
		},
		classes.input
	)

	return (
		<>
			<FormControl
				id={id}
				label={props.label}
				labelPosition={props.labelPosition}
				hideLabel={props.hideLabel}
				labelProps={props.labelProps}
				icon={props.icon}
				labelContentLayout={props.labelContentLayout}
				labelSubText={props.labelSubText}
				validationText={props.validationText}
				validationTextPosition={props.validationTextPosition}
				subText={internalErrorMessage || props.subText}
				reserveHelperTextSpace={props.reserveHelperTextSpace}
				disabled={props.disabled}
				readOnly={props.readOnly}
				required={props.required}
				ref={anchorElementRef}
				error={props.error || internalErrorMessage !== undefined}
				warning={props.warning}
				margin={props.margin}
				className={props.className}
				disableBorder={props.disableBorder}
				logicalHover={props.logicalHover}
				logicalFocus={props.logicalFocus}
				screenTip={props.screenTip}
			>
				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignLeft />
				)}
				<input
					disabled={props.disabled}
					className={contentCSS}
					id={id}
					{...props.dataAttributes}
					ref={inputRef}
					placeholder={props.placeholder ?? placeholder}
					value={displayValue}
					type="text"
					inputMode="decimal"
					onChange={onTextValueChange}
					onKeyDown={handleKeyDown}
					onBlur={onBlur}
					readOnly={props.readOnly}
					name={props.name}
					spellCheck={false}
					autoComplete="off" // autoComplete="off" does now work with Chrome
					onMouseOver={onMouseOver}
					onMouseOut={onMouseOut}
					onFocus={onFocus}
				/>
				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
				)}

				{displayOptionDropDown && (
					<FormControlButton
						disabled={props.disabled || props.readOnly}
						onMouseDown={(e) => isDropdownOpen && e.preventDefault()}
						onClick={toggleDropdownPanel}
						iconClassName="Fluent-ChevronDown"
						isActive={isDropdownOpen}
						onTouchStart={() => (openedByTouch.current = true)}
						onBlur={onBlur}
						tabStop={false}
						iconSize="extraSmall" // to avoid massive chevron
					/>
				)}
				{props.defaultAction && !props.hideActionButton && (
					<FormControlButton
						iconClassName={props.actionIcon}
						screenTip={props.actionScreenTip}
						onClick={!props.disabled ? props.defaultAction : undefined}
						disabled={props.disabled}
						ref={props.actionButtonRef}
						{...props.dataAttributes}
					/>
				)}
			</FormControl>
			{displayOptionDropDown && (
				<DropdownList
					disableCloseOnViewportChange={openedByTouch.current}
					anchorElement={anchorElementRef}
					maxHeight={200}
					isOpen={isDropdownOpen}
					options={options}
					onChange={onOptionClick}
					onClose={closeDropdown}
					multiSelect={false}
					typeJumping
					preselectedIndex={dropdownIndex}
				/>
			)}
		</>
	)
})

DurationInput.defaultProps = {
	actionIcon: 'Fluent-Forward',
}

DurationInput.displayName = 'DurationInput'
