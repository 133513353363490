import jss from 'jss'
import type { PartialTheme, StyleDeclaration, ThemeVariation } from './themingManager.types'
import { getCSSVariablesFromTheme } from '../../theming/utils/getCSSVariablesFromTheme'
import { useLayoutEffect, useMemo, useState } from 'react'
import { registeredCSSVars } from './initTheming'

export const registeredThemeVariations = new Map<string, string>()

/**
 * Create a stylesheet declaring CSS variables for a theme variation
 *
 */
export const createThemeVariation = <T extends ThemeVariation>(
	themeVariation: PartialTheme<T>,
	id: string,
	selector?: string
) => {
	if (registeredThemeVariations.has(id)) {
		// eslint-disable-next-line no-console
		console.warn(`A theme variation already created with id: ${id}`)
	}
	const themeValues = getCSSVariablesFromTheme(themeVariation)
	let styleDefinition: StyleDeclaration = { [id]: themeValues }
	let className = ''

	if (Object.keys(themeValues).length === 0) {
		return ''
	}

	if (selector) {
		// Hack to get a new generated classname from JSS (by non-attaching a sheet)
		const dummySheet = jss.createStyleSheet({ [id]: undefined })
		className = dummySheet.classes[id]
		styleDefinition = {
			'@global': {
				[`${selector}, .${className}`]: themeValues,
			},
		}
	}

	const sheet = jss.createStyleSheet(styleDefinition, { meta: id }).attach()
	if (!selector) {
		className = sheet.classes[id]
	}

	registeredThemeVariations.set(id, className)
	return className
}

let groupedThemeVariationsCount = 0
export const createThemeVariations = <T extends ThemeVariation>(themeVariations: [PartialTheme<T>, string][]) => {
	const styleDefinitions: StyleDeclaration = {}

	themeVariations.forEach(([variation, id]) => {
		const themeValueCSSVars = getCSSVariablesFromTheme(variation)
		styleDefinitions[id] = themeValueCSSVars
	})

	const sheet = jss
		.createStyleSheet(styleDefinitions, {
			meta: `grouped-theme-variations-${++groupedThemeVariationsCount}`,
		})
		.attach()

	return Object.values(sheet.classes)
}

/**
 * Check if a theme has been created
 *
 */
export const hasThemeVariation = (id: string) => registeredThemeVariations.has(id)

/**
 * Get classname for created theme variation
 *
 */
export const getThemeVariation = (id: string) => {
	if (!registeredThemeVariations.has(id)) {
		// eslint-disable-next-line no-console
		console.warn(`No theme variation registered with id: ${id}`)
		return ''
	}

	return registeredThemeVariations.get(id)
}

/**
 * Map a partial theme with values to CSS variables. Use sparingly, e.g. for non-predefined numerical values
 *
 */
export const useDynamicThemeVariation = <T extends ThemeVariation>(themeVariation: PartialTheme<T>) => {
	return useMemo(() => {
		const themeValues = getCSSVariablesFromTheme(themeVariation)
		return themeValues
	}, [themeVariation])
}

/**
 * Get computed CSS variable values for a given HTMLElement ref.
 * Used when needing to transfer inherited values to a React child rendered in a portal
 *
 */
export const useGetComputedThemeVariation = <E extends HTMLElement>(
	ref: React.RefObject<E | undefined> | undefined,
	preventComputingStyle: boolean
) => {
	const [themeVariation, setThemeVariation] = useState<Record<string, string> | undefined>()

	useLayoutEffect(() => {
		if (!ref?.current) {
			return
		}

		if (preventComputingStyle) {
			return
		}
		const themeVariation: Record<string, string> = {}
		const computedStyle = getComputedStyle(ref.current)
		registeredCSSVars.forEach((originalValue, varName) => {
			const value = computedStyle.getPropertyValue(varName)
			if (!value || originalValue === value) {
				return
			}
			themeVariation[varName] = value
		})

		if (Object.keys(themeVariation).length === 0) {
			return
		}
		setThemeVariation(themeVariation)
	}, [ref, preventComputingStyle])

	return themeVariation as React.CSSProperties | undefined
}
