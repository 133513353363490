import calculateSize from 'calculate-size'
import type { GridApi } from '@ag-grid-community/core'
import { VERTICAL_HEADER_BASE_HEIGHT, MAX_HEADER_HEIGHT, VERTICAL_HEADER_PADDING } from '../consts'
import type { TData } from '../Table.types'

export const setHeaderHeight = (ref: React.RefObject<HTMLDivElement>, gridApi: GridApi<TData>) => {
	const columnDefs = gridApi.getColumnDefs()
	if (!columnDefs?.length) {
		return
	}

	const longestHeaderNameColDef = columnDefs.reduce((longestLabelColDef, colDef) => {
		const colDefHeaderNameLength = colDef?.headerName?.length || 0
		const longestColDefHeaderNameLength = longestLabelColDef?.headerName?.length || 0
		return colDefHeaderNameLength > longestColDefHeaderNameLength ? colDef : longestLabelColDef
	})

	const headerCellDiv = ref.current?.querySelector<HTMLDivElement>('.ag-header-cell')
	const longestHeaderNameLabelWidth = calculateSize(
		longestHeaderNameColDef.headerName || '',
		headerCellDiv
			? {
					font: headerCellDiv.style.font,
					fontSize: headerCellDiv.style.fontSize,
					fontWeight: headerCellDiv.style.fontWeight,
			  }
			: undefined
	).width

	gridApi.setHeaderHeight(
		Math.min(longestHeaderNameLabelWidth + VERTICAL_HEADER_BASE_HEIGHT + VERTICAL_HEADER_PADDING, MAX_HEADER_HEIGHT)
	)
}
