import type { RefObject, SyntheticEvent } from 'react'
import React, { useRef, useEffect } from 'react'
import { createStyle } from '../../theming'

import { useEventListener } from '../utils/useEventListener'
import { Chip } from '../Chip'
import type { MultiValueInputItem } from './MultiValueInput.types'
import { InputHeight } from '../utils/InputHeight'

const classes = createStyle({
	chip: {
		margin: '1px 4px 0 0',
	},
	chipsList: {
		display: 'inline',
		marginLeft: '0.5rem',
		'&:focus': {
			outline: 'none',
		},
	},
	chipWrapper: {
		display: 'inline-flex',
	},
})

interface StyleProps {
	maxWidth?: string // So chips will ellipsing, and not go outside container
}

interface ChipListProps<T> extends StyleProps {
	id?: string
	displayChipLabel?: string
	chips: MultiValueInputItem<T>[]
	currentIndex: number
	collapsible?: boolean
	defaultCollapsed?: boolean
	disabled?: boolean
	readOnly?: boolean
	selectNewChip?: (index: number) => void
	onDeleteChip?: (deleteIndex: number) => void
	onBlur?: () => void
}

export function MultiValueInputChipList<T>(props: ChipListProps<T>) {
	const chipListRef: RefObject<HTMLDivElement> = useRef(null)

	const handleMouseDown = (e: SyntheticEvent) => {
		if (e.defaultPrevented) {
			return
		}

		if (document.contains(e.target as Node)) {
			if (!chipListRef.current?.contains(e.target as Node)) {
				onBlur()
			}
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent<Element>) => {
		switch (e.key) {
			case 'Delete':
			case 'Backspace':
				e.preventDefault()
				onDeleteChip(props.currentIndex)
				break
			case 'Escape':
				e.stopPropagation()
				onBlur()
				break
			case 'ArrowUp':
			case 'ArrowLeft':
				e.preventDefault()
				selectNewChip(navigateLeft(props.currentIndex, props.chips.length))
				break
			case 'ArrowDown':
			case 'ArrowRight':
				e.preventDefault()
				selectNewChip(navigateRight(props.currentIndex, props.chips.length))
				break
			case 'Tab':
				if (e.shiftKey) {
					if (props.currentIndex === 0) {
						selectNewChip(-1)
					} else {
						e.preventDefault()
						selectNewChip(navigateLeft(props.currentIndex, props.chips.length))
					}
				} else {
					e.preventDefault()
					selectNewChip(navigateRight(props.currentIndex, props.chips.length))
				}
				break
			case 'Home':
				e.preventDefault()
				selectNewChip(0)
				break
			case 'End':
				e.preventDefault()
				selectNewChip(-1)
				break
		}
	}

	const navigateRight = (index: number, arrayLength: number) => {
		return index >= 0 && index < arrayLength - 1 ? index + 1 : -1
	}

	const navigateLeft = (index: number, arrayLength: number) => {
		return index === -1 ? arrayLength - 1 : Math.max(index - 1, 0)
	}

	const selectNewChip = (index: number) => {
		if (index !== props.currentIndex) {
			props.selectNewChip?.(index)
		}
	}

	const onClickChip = (index?: number) => {
		if (index === undefined) {
			return
		}

		selectNewChip(index)
	}

	const onDeleteChip = (deleteIndex?: number) => {
		if (deleteIndex === undefined) {
			return
		}

		props.onDeleteChip?.(deleteIndex)
	}

	const onBlur = () => {
		props.onBlur?.()
	}

	useEventListener('mousedown', handleMouseDown)

	useEventListener('keydown', handleKeyDown, chipListRef)

	useEffect(() => {
		if (props.currentIndex === -1) {
			return
		}

		if (chipListRef?.current) {
			chipListRef.current.focus()
		}
	}, [props.currentIndex])

	const maxWidthStyle = props.maxWidth ? { maxWidth: props.maxWidth } : undefined
	return (
		<div className={classes.chipsList} ref={chipListRef} tabIndex={-1} data-cy={`${props.id || ''}-chip-list`}>
			<InputHeight />
			{props.displayChipLabel ? (
				<div className={classes.chipWrapper}>
					<Chip
						label={props.displayChipLabel}
						disabled={props.disabled}
						readOnly={props.readOnly}
						onDelete={onDeleteChip}
						className={classes.chip}
						style={maxWidthStyle}
					/>
				</div>
			) : (
				props.chips.map((chip, index) => {
					const stringifyedValue = JSON.stringify(chip.value)
					const label = chip.label ? chip.label : stringifyedValue

					return (
						<div key={index} className={classes.chipWrapper}>
							<Chip
								index={index}
								label={label}
								isActive={index === props.currentIndex}
								disabled={props.disabled}
								readOnly={props.readOnly}
								onClick={onClickChip}
								onKeyDown={handleKeyDown}
								onDelete={onDeleteChip}
								className={classes.chip}
								dataId={stringifyedValue}
								dataAttributes={{ 'data-cy': stringifyedValue }}
								style={maxWidthStyle}
							/>
						</div>
					)
				})
			)}
		</div>
	)
}
