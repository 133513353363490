import { useMemo } from 'react'

let idCounter = 1

export const useID = (givenId?: string) =>
	useMemo(() => {
		if (givenId) {
			return givenId
		}
		return 'gwui-' + idCounter++
	}, [givenId])
