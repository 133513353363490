import { useEffect, useState } from 'react'
import { useFormatDurationFunc } from './formatUtils'
import type { IDropdownOption } from '../../Dropdown'
import { DropdownOptionType, DropdownOptionsHandler } from '../../Dropdown'
import { generateOptionsForDropdown } from './dropdownUtils'
import { useMaxValueMinutes, useMinValueMinutes, useOptionResolutionMinutes } from './valueGetters'
import { useTranslation } from '../../../translation'

export const useAvailableOptions = (
	internalValue: React.MutableRefObject<number | undefined>,
	optionsResolutionMinutes: number | undefined,
	minValueTicksMS: number | undefined,
	maxValueTicksMS: number | undefined,
	isIntegerTime: boolean | undefined,
	enableOptionDropdown: boolean | undefined
) => {
	const { tcvi } = useTranslation()
	const formatDurationFunc = useFormatDurationFunc(isIntegerTime)
	const maxValueMinutes = useMaxValueMinutes(maxValueTicksMS, isIntegerTime)
	const minValueMinutes = useMinValueMinutes(minValueTicksMS)
	const optionResolutionMinutes = useOptionResolutionMinutes(optionsResolutionMinutes)

	const [availableOptions, setAvailableOptions] = useState<IDropdownOption<number>[]>()

	const displayOptionDropDown = enableOptionDropdown && maxValueMinutes !== undefined

	const dropdownIndex = displayOptionDropDown
		? availableOptions?.findIndex(
				(option) => option.type === DropdownOptionType.item && option.value === internalValue.current
		  )
		: undefined

	const options = new DropdownOptionsHandler<number>(availableOptions, false, tcvi)

	const refreshDropdownOptions = () => {
		const options =
			minValueMinutes !== undefined && maxValueMinutes !== undefined
				? generateOptionsForDropdown(optionResolutionMinutes, minValueMinutes, maxValueMinutes, formatDurationFunc)
				: undefined

		setAvailableOptions(options)
	}

	// select available options
	useEffect(() => {
		refreshDropdownOptions()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [optionsResolutionMinutes, isIntegerTime, minValueTicksMS, maxValueTicksMS])

	return { options, displayOptionDropDown, dropdownIndex }
}
