import type { ReactNode } from 'react'
import React from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'
import { useLocalStorage } from '../utils/useLocalStorage'
import { Button } from '../Button/Button'

const classes = createStyle((theme) => ({
	header: {
		display: 'flex',
		alignItems: 'center',
		padding: '4px',
		borderBottom: '1px solid ' + theme.colors.panel.border,
		borderTop: '1px solid ' + theme.colors.panel.border,
		background: theme.colors.panel.background,
		fontSize: theme.typography.subtitle2.fontSize,
		fontWeight: theme.typography.subtitle2.fontWeight,
		'& i': {
			fontSize: '9px',
			lineHeight: '9px',
			transform: 'rotate(0deg)',
			transition: `transform ${theme.transitions.duration.short}`,
		},
		'& button:disabled, & button:disabled:hover': {
			color: theme.colors.body.text,
		},
	},
	headerSuppressTopBorder: {
		borderTopWidth: 0,
	},
	isClosed: {
		'&:not(:last-child)': {
			borderBottomWidth: 0,
		},
		'& i': {
			transform: 'rotate(-90deg)',
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		background: theme.palette.background.white,
	},
	contentRemainingHeight: {
		flexGrow: 1,
	},
}))

interface IContentPaneSection {
	header?: string
	children?: ReactNode | ReactNode[]
	collapsible?: boolean
	takeRemainingHeight?: boolean
	isOpen?: boolean
	suppressTopBorder?: boolean
	persistKey?: string
}

export const ContentPaneSection = (props: IContentPaneSection) => {
	const [isOpen, setIsOpen] = useLocalStorage(props.persistKey || '', props.isOpen !== undefined ? props.isOpen : true)

	return (
		<>
			{props.header && (
				<div
					className={classNames(classes.header, {
						[classes.isClosed]: !isOpen,
						[classes.headerSuppressTopBorder]: props.suppressTopBorder,
					})}
				>
					<Button
						label={props.header}
						icon={props.collapsible ? 'Fluent-ChevronDown' : undefined}
						variant="inline"
						onClick={() => setIsOpen(!isOpen)}
						size="extraSmall"
						disabled={!props.collapsible}
					/>
				</div>
			)}
			{isOpen && (
				<div className={classNames(classes.content, props.takeRemainingHeight && classes.contentRemainingHeight)}>
					{props.children}
				</div>
			)}
		</>
	)
}
