export enum e_EnvironmentTypeName {
	blue = 'blue',
	green = 'green',
	origin = 'origin',
	operator = 'operator',
}

export enum e_EnvironmentType {
	blue = 0,
	green = 1,
	origin = 2,
	operator = 3,
}
