import React from 'react'
import clsx from 'clsx'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	'@keyframes delay': {
		'0%': {
			transform: 'scaleY(0.05)',
		},
		'40%': {
			transform: 'scaleY(0.05)',
		},
		'100%': {
			transform: 'scaleY(0.05)',
		},
		'20%': {
			transform: 'scaleY(1.0)',
		},
	},

	bar1: {},
	bar2: {
		animationDelay: '-1.4s !important',
	},
	bar3: {
		animationDelay: '-1.2s !important',
	},
	bar4: {
		animationDelay: '-1s !important',
	},
	bar5: {
		animationDelay: '-0.8s !important',
	},
	bar6: {
		animationDelay: '-0.6s !important',
	},

	loader: {
		margin: '0 auto',
		textAlign: 'center',
		fontSize: '10px',
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '20px',

		'& > div': {
			height: '100%',
			width: '8px',
			flex: 1,
			backgroundColor: theme.palette.primary.themePrimary,
			display: 'inline-block',
			marginLeft: '2px',
			animation: '$delay 1.6s infinite ease-in-out',
			borderRadius: '10px',
		},
	},

	small: {
		height: '10px',
		'& > div': {
			width: '2px',
			marginLeft: '1px',
		},
	},
	large: {
		height: '40px',
		'& > div': {
			width: '16px',
		},
	},
}))

interface IWaitIndicatorWavingBarsProps {
	size?: 'small' | 'medium' | 'large'
}

export const WaitIndicatorWavingBars = (props: IWaitIndicatorWavingBarsProps) => {
	return (
		<div
			className={clsx(classes.loader, props.size === 'small' && classes.small, props.size === 'large' && classes.large)}
		>
			<div className={classes.bar1} />
			<div className={classes.bar2} />
			<div className={classes.bar3} />
			<div className={classes.bar4} />
			<div className={classes.bar5} />
			<div className={classes.bar6} />
		</div>
	)
}
