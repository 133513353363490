import { defaultTypography } from '../defaults'

export const getTypography = () => {
	return defaultTypography
}

export type ITypographicStyleEntries = ReturnType<typeof getTypography>
export type TypographicStyleKey = keyof ITypographicStyleEntries

export const getTypographyStyles = (typographyStyles: ITypographicStyleEntries) => {
	return Object.keys(typographyStyles).reduce(
		(acc, key) => {
			const entryKey = key as TypographicStyleKey
			acc[entryKey] = {
				fontSize: `${typographyStyles[entryKey].fontSize}px`,
				fontWeight: typographyStyles[entryKey].fontWeight,
			}
			return acc
		},
		{} as { [key: string]: { fontSize: string; fontWeight: number } }
	)
}

export type ITypographicStyles = ReturnType<typeof getTypographyStyles>
