import type { MouseEvent } from 'react'
import type { CellData, ICellRendererProps, TData } from '../../../Table.types'
import type { IRowNode, ColumnApi, GridApi, ICellRendererParams } from '@ag-grid-community/core'

export const useOnClick = (
	props: ICellRendererProps & ICellRendererParams<TData, CellData>,
	editingMode: boolean,
	singleClickEdit: boolean,
	isCheckMarkCell: boolean,
	setNodeSelected: (
		selected: boolean,
		props: {
			node: IRowNode<TData>
			gridApi: GridApi<TData>
			columnApi: ColumnApi
			clearSelection?: boolean
		}
	) => void,
	setEditingMode: (value: boolean) => void
) => {
	return (e: Event | MouseEvent) => {
		if (editingMode) {
			e.preventDefault()

			return
		}

		if (singleClickEdit && !isCheckMarkCell) {
			e.preventDefault()

			if (props.selectable) {
				setNodeSelected(true, {
					gridApi: props.api,
					columnApi: props.columnApi,
					node: props.node,
					clearSelection: true,
				})
			}

			setEditingMode(true)
		}
	}
}
