import { useRef, useState } from 'react'

export const useCellIsHoverOrFocus = (eGridCell: HTMLElement, interactive = false) => {
	const [hoverOrFocus, setHoverOrFocus] = useState(false)

	const hover = useRef(false)
	const focus = useRef(false)

	if (!interactive) {
		return false
	}

	const onPointerEnterOrFocus = (action: 'pointerenter' | 'focus') => {
		if (action === 'pointerenter') {
			hover.current = true
		} else {
			focus.current = true
		}

		setHoverOrFocus(true)
	}

	const onPointerLeaveOrBlur = (action: 'pointerleave' | 'blur') => {
		if (action === 'pointerleave') {
			hover.current = false
		} else {
			focus.current = false
		}

		if (!hover.current && !focus.current) {
			setHoverOrFocus(false)
		}
	}

	eGridCell.addEventListener('pointerenter', () => onPointerEnterOrFocus('pointerenter'))
	eGridCell.addEventListener('pointerleave', () => onPointerLeaveOrBlur('pointerleave'))

	eGridCell.addEventListener('focus', () => onPointerEnterOrFocus('focus'))
	eGridCell.addEventListener('blur', () => onPointerLeaveOrBlur('blur'))

	return hoverOrFocus
}
