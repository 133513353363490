import React, { useEffect, useState } from 'react'

import { createStyle } from '@genusbiz/web-ui/theming'
import classNames from 'clsx'

// Selectors
import { useDispatch, useSelector } from 'react-redux'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { useTranslation } from 'react-i18next'
import { operatorApi } from 'src/modules/operatorApi'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { translator } from 'src/utils/locale'
import { isActive } from 'src/utils/publicationIsActive'
import { AgGridTable } from '@genusbiz/web-ui/controls/AgGridTable'
import { IColumnDef, RowData, e_RenderType } from '@genusbiz/web-ui/controls/AgGridTable/Table.types'
import { defaultColors } from 'src/consts/defaultColors'
import { e_DataType } from '@genusbiz/web-ui/controls/AgGridTable/enums/e_DataType'
import { RuntimeEditor } from './RuntimeEditor'
import { IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { capitalize } from 'src/utils/capitalize'
import { e_RuntimeEditorMode } from 'src/enums/e_RuntimeEditorMode'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { e_SortOrder } from '@genusbiz/web-ui/controls/AgGridTable/enums/e_SortOrder'

const classes = createStyle({
	container: {
		flex: 1,
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		backgroundColor: 'white',
	},
	activeRuntime: { fontWeight: 700 },
	table: {
		width: '100%',
		height: '100%',
	},
})

export const OperatorK8sRuntimeTable = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const k8sRuntimes = useSelector(kubernetesSelectors.selectK8sRuntimes)
	const [productVersions, setProductVersions] = useState<{ [runtime: string]: string[] }>({})
	const [editorIsOpen, setEditorIsOpen] = useState(false)
	const [selectedK8sRuntime, setSelectedK8sRuntime] = useState<IK8sRuntime>()

	const onCloseEditor = () => {
		setEditorIsOpen(false)
	}
	const setCurrentK8sRuntime = (k8sRuntimeName: string) => {
		if (k8sRuntimeName) {
			const currentK8sRuntime = k8sRuntimes.find((runtime) => runtime.name === k8sRuntimeName)
			if (currentK8sRuntime) {
				setSelectedK8sRuntime(currentK8sRuntime)
				dispatch(operatorFrontendActions.setSelectedK8sRuntime(currentK8sRuntime))
			}
		}
	}
	useEffect(() => {
		const promises = k8sRuntimes.map((runtime) => {
			return operatorApi.fetchOperatorDeployments(runtime.name)
		})
		Promise.all(promises)
			.then((deployments) => {
				const productVersions: { [runtime: string]: string[] } = {}
				k8sRuntimes.forEach((runtime, i) => {
					productVersions[runtime.name] = [
						...new Set(
							deployments[i].map(
								(deployment) =>
									deployment.runtimeConfig.genusVersion || deployment.runtimeConfig.genusOperatorVersion || ''
							)
						),
					]
				})
				setProductVersions(productVersions)
			})
			.catch(() => {
				setProductVersions({})
				dispatch(
					modalManagerActions.showSnackbar({
						message: translator.translate('ERROR:FAILED_GET_DEPLOYMENTS'),
					})
				)
			})
	}, [k8sRuntimes])

	const columns: IColumnDef[] = [
		{
			headerName: t('GENERAL:ID'),
			field: 'id',
			hide: true,
		},
		{
			headerName: capitalize(t('GENERAL:ENVIRONMENT')),
			field: 'environmentType',
			hide: true,
			sort: e_SortOrder.asc,
		},
		{
			headerName: capitalize(t('GENERAL:ENVIRONMENT')),
			field: 'environmentTypeName',
		},
		{
			headerName: t('GENERAL:NAME'),
			field: 'name',
		},
		{
			headerName: t('GENERAL:ACTIVE'),
			field: 'isActive',
		},
		{
			headerName: t('TABLE_HEADERS:PRODUCT_VERSION'),
			field: 'deployedProductVersion',
		},
		{
			headerName: t('RUNTIMES:FEATURES'),
			field: 'services',
			children: [
				{
					field: 'CardDavService',
					headerName: t('TABLE_HEADERS:CARDDAV_SERVICE'),
					dataType: e_DataType.bool,
					renderType: e_RenderType.checkMark,
					textAlignment: 'center',
				},
				{
					field: 'DataMartService',
					headerName: t('TABLE_HEADERS:DATAMART_SERVICE'),
					dataType: e_DataType.bool,
					renderType: e_RenderType.checkMark,
					textAlignment: 'center',
				},
				{
					field: 'MailService',
					headerName: t('TABLE_HEADERS:MAIL_SERVICE'),
					dataType: e_DataType.bool,
					renderType: e_RenderType.checkMark,
					textAlignment: 'center',
				},
				{
					field: 'RestService',
					headerName: t('TABLE_HEADERS:REST_SERVICE'),
					dataType: e_DataType.bool,
					renderType: e_RenderType.checkMark,
					textAlignment: 'center',
					screenTip: t('SERVICES:RESTSERVICE'),
				},
				{
					field: 'ScheduledActionService',
					headerName: t('TABLE_HEADERS:SCHEDULED_ACTION_SERVICE'),
					dataType: e_DataType.bool,
					renderType: e_RenderType.checkMark,
					textAlignment: 'center',
				},
				{
					field: 'WebCalService',
					headerName: t('TABLE_HEADERS:WEBCAL_SERVICE'),
					dataType: e_DataType.bool,
					renderType: e_RenderType.checkMark,
					textAlignment: 'center',
				},
				{
					field: 'XmlService',
					headerName: t('TABLE_HEADERS:XML_SERVICE'),
					dataType: e_DataType.bool,
					renderType: e_RenderType.checkMark,
					textAlignment: 'center',
				},
			],
		},
	]

	const rowData: RowData[] = k8sRuntimes.map((runtime) => {
		const runtimeIsActive = isActive(runtime.currentPublication)
		const isBold = runtimeIsActive
		const gServices: { [name: string]: { value: boolean; screenTip: string } } = {}

		runtime.services.forEach((service) => {
			gServices[service.name] = { value: service.enabled, screenTip: t('RUNTIME:SET_VALUE_IN_STUDIO') }
		})

		return {
			id: runtime.name,
			columns: {
				name: { value: runtime.name, isBold },
				environmentType: { value: runtime.environmentType },
				environmentTypeName: { value: runtime.environmentTypeName, isBold },
				isActive: { value: runtimeIsActive.toString(), isBold },
				deployedProductVersion: {
					value: productVersions[runtime.name]?.join(', ') ?? '',
					isBold,
					background: productVersions[runtime.name]?.length > 1 ? defaultColors.warningBackground : '',
					screenTip: productVersions[runtime.name]?.length > 1 ? t('RUNTIMES:SEVERAL_PRODUCT_VERSIONS_WARNING') : '',
				},
				...gServices,
			},
		}
	})

	return (
		<div className={classNames('ag-theme-genus', classes.container)} style={{}}>
			<AgGridTable
				columnDefs={columns}
				rowData={rowData}
				onSelectionChange={(row) => {
					setCurrentK8sRuntime(row[0])
				}}
				onDblClick={(row) => {
					// setCurrentK8sRuntime is needed here in order to get the current
					// change sequence number which is needed to be able to update a row.
					setCurrentK8sRuntime(row.id)
					setEditorIsOpen(true)
				}}
				compact
				enableGrouping
			/>
			{editorIsOpen && (
				<RuntimeEditor
					isOpen={editorIsOpen}
					editorMode={e_RuntimeEditorMode.edit}
					onClose={onCloseEditor}
					k8sRuntime={selectedK8sRuntime}
				/>
			)}
		</div>
	)
}
