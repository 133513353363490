import React, { useEffect, useState } from 'react'
import classNames from 'clsx'

import { createStyle } from '@genusbiz/web-ui/theming'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Consts
import { defaultColors } from 'src/consts/defaultColors'
import { AgGridTable } from '@genusbiz/web-ui/controls'
import { IColumnDef, RowData, e_RowSelectionType } from '@genusbiz/web-ui/controls/AgGridTable/Table.types'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { e_ScaledBy } from 'src/enums/e_ScaledBy'
import { WorkloadControllerDetails } from './WorkloadControllerDetails'
import { getFormattedDesiredReplicas, getWorkloadControllerScaledBy } from 'src/utils/workloadControllerUtils'
import { IWorkloadsResourcePlan } from 'src/interfaces/IWorkloadsResourcePlan'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { e_DataType } from '@genusbiz/web-ui/controls/AgGridTable/enums/e_DataType'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'

const classes = createStyle({
	header: { marginLeft: '8px' },
	table: {
		width: '100%',
		height: '100%',
	},
	warning: {
		backgroundColor: defaultColors.warningBackground + ' !important',
	},
	success: {
		backgroundColor: defaultColors.successBackground + ' !important',
	},
	error: {
		backgroundColor: defaultColors.errorBackground + ' !important',
	},
	scaledByDefault: {
		backgroundColor: defaultColors.grayBackground,
	},
	scaledByGenusOperator: {
		backgroundColor: defaultColors.successBackground,
	},
	scaledByHpa: {
		backgroundColor: defaultColors.purpleBackground,
	},
	scaledByConflict: {
		backgroundColor: defaultColors.errorBackground,
	},
})

interface IProps {
	k8sRuntime?: IK8sRuntime
	allWorkloadControllers: IWorkloadController[]
	refresh: (emptyBeforeRefresh?: boolean) => Promise<void>
	workloadsResourcePlan: IWorkloadsResourcePlan
}

export const WorkloadControllerTable = (props: IProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const [rowData, setRowData] = useState<RowData[]>([])
	const [activeWorkloadController, setActiveWorkloadController] = useState<IWorkloadController | null>(null)
	const [showWorkloadControllerDetails, setShowWorkloadControllerDetails] = useState(false)
	const currentlyOperatingAs =
		props.k8sRuntime && typeof props.k8sRuntime.currentlyOperatingAs === 'number'
			? props.k8sRuntime.currentlyOperatingAs
			: e_EnvironmentOperatingType.unknown

	const environmentResourceOverview = useSelector(
		kubernetesSelectors.selectCurrentEnvironmentResourceOverview(currentlyOperatingAs)
	)

	const getScaledByCellClass = (nodeId: string | undefined): string => {
		const workloadController = props.allWorkloadControllers.find((wc) => wc.id === nodeId)
		if (!workloadController) {
			return ''
		}
		const status = getWorkloadControllerScaledBy(workloadController)
		switch (status) {
			case e_ScaledBy.default:
				return classNames(classes.scaledByDefault)
			case e_ScaledBy.genusOperator:
				return classNames(classes.scaledByGenusOperator)
			case e_ScaledBy.hpa:
				return classNames(classes.scaledByHpa)
			case e_ScaledBy.conflict:
				return classNames(classes.scaledByConflict)
			default:
				return ''
		}
	}

	const columns: IColumnDef[] = [
		{
			headerName: t('TABLE_HEADERS:DESCRIPTION'),
			field: 'description',
			children: [
				{
					headerName: t('GENERAL:ID'),
					field: 'id',
					hide: true,
				},
				{
					headerName: t('GENERAL:NAME'),
					field: 'name',
					width: 325,
				},
				{
					headerName: t('GENERAL:MICROSERVICE'),
					field: 'appName',
					width: 195,
				},
				{
					headerName: t('TABLE_HEADERS:RUNTIME'),
					field: 'k8sRuntime',
					hide: true,
				},
				{
					headerName: t('TABLE_HEADERS:KIND'),
					field: 'kind',
					hide: true,
				},
				{
					headerName: t('GENERAL:VERSION'),
					field: 'version',
					width: 130,
				},
			],
		},
		{
			headerName: t('TABLE_HEADERS:REPLICAS'),
			field: 'replicas',
			children: [
				{
					headerName: t('TABLE_HEADERS:SCALED_BY'),
					field: 'scaledBy',
					getTooltip: (nodeId) => {
						const workloadController = props.allWorkloadControllers.find((wc) => wc.id === nodeId)
						if (workloadController) {
							const hasAutoScaler = workloadController.hasAutoScaler
							const includedInWorkloadsResourcePlan = workloadController.includedInWorkloadsResourcePlan as boolean
							if (hasAutoScaler && includedInWorkloadsResourcePlan) {
								return t('SCALED_BY:CONFLICT_DESC')
							} else if (hasAutoScaler) {
								return t('SCALED_BY:HPA_DESC')
							} else if (includedInWorkloadsResourcePlan) {
								return t('SCALED_BY:GENUSOPERATOR_DESC')
							}
							return t('SCALED_BY:DEFAULT_DESC')
						} else {
							return ''
						}
					},
					fillVariant: 'pill',
					pillWidth: 'stretch',
					getCellClassNameOnRender: getScaledByCellClass,
					width: 135,
				},
				{
					headerName: t('TABLE_HEADERS:DESIRED'),
					field: 'desiredReplicas',
					textAlignment: 'center',
					dataType: e_DataType.int,
				},

				{
					headerName: t('GENERAL:READY'),
					field: 'readyReplicas',
					textAlignment: 'center',
					dataType: e_DataType.int,
				},
				{
					headerName: t('TABLE_HEADERS:AVAILABLE'),
					field: 'availableReplicas',
					textAlignment: 'center',
					dataType: e_DataType.int,
				},
				{
					headerName: t('TABLE_HEADERS:UNAVAILABLE'),
					field: 'unavailableReplicas',
					textAlignment: 'center',
					dataType: e_DataType.int,
				},
			],
		},
	]

	useEffect(() => {
		const rows = props.allWorkloadControllers.map((workloadController) => {
			return {
				id: workloadController.id,
				columns: {
					id: { value: workloadController.id },
					name: { value: workloadController.name },
					appName: { value: workloadController.appName },
					kind: { value: workloadController.kind },
					version: { value: workloadController.objectMetadata.version },
					desiredReplicas: {
						value: isNaN(workloadController.desiredReplicas) ? 0 : workloadController.desiredReplicas,
						formattedValue: getFormattedDesiredReplicas(
							workloadController,
							props.workloadsResourcePlan,
							environmentResourceOverview
						),
					},
					scaledBy: {
						value: getWorkloadControllerScaledBy(workloadController),
						formattedValue: t('SCALED_BY:' + getWorkloadControllerScaledBy(workloadController).toUpperCase()),
					},
					includedInWorkloadsResourcePlan: { value: workloadController.includedInWorkloadsResourcePlan },
					availableReplicas: {
						value: isNaN(workloadController.availableReplicas) ? 0 : workloadController.availableReplicas,
						formattedValue: isNaN(workloadController.availableReplicas) ? '-' : workloadController.availableReplicas,
					},
					unavailableReplicas: {
						value: isNaN(workloadController.unavailableReplicas) ? 0 : workloadController.unavailableReplicas,
						formattedValue: isNaN(workloadController.unavailableReplicas)
							? '-'
							: workloadController.unavailableReplicas,
					},
					readyReplicas: {
						value: isNaN(workloadController.readyReplicas) ? 0 : workloadController.readyReplicas,
						formattedValue: isNaN(workloadController.readyReplicas) ? '-' : workloadController.readyReplicas,
					},
				},
			}
		})
		setRowData(rows)
		// Update activeWorkloadController
		if (activeWorkloadController) {
			const workloadController =
				props.allWorkloadControllers.find((wc) => wc.id === activeWorkloadController.id) || null
			setActiveWorkloadController(workloadController)
		}
	}, [props.allWorkloadControllers, props.workloadsResourcePlan, environmentResourceOverview])

	const onActivate = (row: RowData) => {
		setShowWorkloadControllerDetails(true)
		const workloadController = props.allWorkloadControllers.find((wc) => wc.id === row.id) || null
		setActiveWorkloadController(workloadController)
	}

	const onSelectionChanged = (ids: string[]) => {
		const workloadController = props.allWorkloadControllers.find((wc) => wc.id === ids[0]) || null
		dispatch(operatorFrontendActions.setSelectedWorkloadController(workloadController))
	}

	return (
		<>
			{showWorkloadControllerDetails && activeWorkloadController && (
				<WorkloadControllerDetails
					isOpen={showWorkloadControllerDetails}
					onClose={() => {
						setShowWorkloadControllerDetails(false)
					}}
					workloadController={activeWorkloadController}
					refresh={props.refresh}
				/>
			)}
			<div className={classNames(classes.table)}>
				<AgGridTable
					columnDefs={columns}
					rowData={rowData}
					onDblClick={onActivate}
					autoHeight
					compact
					height="stretch" //funker dette?
					onSelectionChange={onSelectionChanged}
					rowSelectionType={e_RowSelectionType.single}
					zebraStripes
					zebraColorSet={{
						foreground: 'black',
						background: 'white',
						primary: '#fafafa',
					}}
				/>
			</div>
		</>
	)
}
