import React, { useState } from 'react'
import { CommandBar } from '@genusbiz/web-ui/controls'
import { e_MenuItemType, MenuItem } from '@genusbiz/web-ui/controls/Menu'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createStyle } from '@genusbiz/web-ui/theming'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { RuntimeEditor } from './RuntimeEditor'
import { e_RuntimeEditorMode } from 'src/enums/e_RuntimeEditorMode'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { operatorFrontendSelectors } from 'src/features/OperatorFrontend/duck/operatorFrontendSelectors'
import { IConfirmationModalProps } from 'src/interfaces/IModalProps'
import { operatorApi } from 'src/modules/operatorApi'
import { defaultIcons } from 'src/consts/defaultIcons'

const classes = createStyle((theme) => ({
	operatorCommandBar: {
		backgroundColor: theme.colors.panel.background,
		display: 'flex',
		borderBottom: '1px solid ' + theme.colors.panel.border,
		justifyContent: 'space-between',
	},
	fullWidth: { float: 'left' },
	rightCommandBar: { float: 'right', marginRight: 16 },
}))

export const OperatorK8sRuntimeCommandBar = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [editorIsOpen, setEditorIsOpen] = useState(false)
	const selectedK8sRuntime = useSelector(operatorFrontendSelectors.selectSelectedK8sRuntime)

	const reload = () => {
		dispatch(operatorFrontendActions.fetchK8sRuntimes())
	}

	const onCloseEditor = () => {
		setEditorIsOpen(false)
	}

	const items: MenuItem[] = [
		{
			type: e_MenuItemType.action,
			name: t('RUNTIMES:NEW_RUNTIME'),
			iconClassName: defaultIcons.database,
			onClick: () => {
				setEditorIsOpen(true)
			},
		},
		{
			type: e_MenuItemType.action,
			name: t('RUNTIMES:DELETE_RUNTIME'),
			iconClassName: defaultIcons.delete,
			disabled: !selectedK8sRuntime,
			onClick: () => {
				if (!selectedK8sRuntime) {
					return
				}
				const modalprops: IConfirmationModalProps = {
					title: t('GENERAL:WARNING'),
					message: `${t('RUNTIMES:DELETE_WARNING')}: '${selectedK8sRuntime.name}'? ${t('RUNTIMES:ACTION_UNDONE')}.`,
					declineText: t('GENERAL:NO'),
					confirmText: t('GENERAL:YES'),
					onConfirm: () => {
						operatorApi
							.deleteOperatorK8sRuntime(selectedK8sRuntime.id)
							.then(() => {
								dispatch(
									modalManagerActions.showSnackbar({
										message: `${t('RUNTIMES:RUNTIME')} '${selectedK8sRuntime.name}' ${t(
											'RUNTIMES:DELETE_SUCCESSFUL'
										)}.`,
									})
								)
								reload()
							})
							.catch((error) => {
								dispatch(modalManagerActions.showErrorModal(error))
							})
					},
				}

				dispatch(modalManagerActions.showConfirmationModal(modalprops))
			},
		},
		{ type: e_MenuItemType.divider },
		{
			type: e_MenuItemType.action,
			name: t('GENERAL:REFRESH'),
			iconClassName: 'Fluent-Refresh',
			onClick: () => {
				reload()
			},
		},
	]

	return (
		<div className={classes.operatorCommandBar}>
			<div className={classes.fullWidth}>
				<CommandBar items={items} />
			</div>
			{editorIsOpen && (
				<RuntimeEditor isOpen={editorIsOpen} editorMode={e_RuntimeEditorMode.create} onClose={onCloseEditor} />
			)}
		</div>
	)
}
