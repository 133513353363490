import jss from 'jss'
import { initializedTheme } from './initTheming'
import type { InitializedTheme, StyleDeclaration, ThemeDeclaration } from './themingManager.types'

/**
 * Create and inject a stylesheet, with dynamic values by accessing the theme's CSS variables
 */
export const createStyle = <S extends StyleDeclaration>(styles: S | (<T extends ThemeDeclaration>(theme: T) => S)) => {
	if (!initializedTheme) {
		throw Error('Theming must be initialized before creating styles')
	}

	let styleToCommit: S
	if (typeof styles === 'function') {
		if (Object.prototype.hasOwnProperty.call(styles, 'hasExecuted')) {
			// eslint-disable-next-line no-console
			console.warn('Duplicate stylesheets: Style has already been created for', styles)
		}
		styleToCommit = styles(initializedTheme)
	} else {
		styleToCommit = styles
	}

	const sheet = jss.createStyleSheet(styleToCommit).attach()

	Object.assign(styleToCommit, { hasExecuted: undefined })
	return sheet.classes as Record<keyof S, string>
}

// Has to export, otherwise S is an invalid generic in 'getTypedThemeUtils'
export type CreateStyle<T extends ThemeDeclaration> = <S extends StyleDeclaration>(
	fn: S | ((theme: InitializedTheme<T>) => S)
) => Record<keyof S, string>
