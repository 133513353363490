import type { ColDef, ColumnApi, ColumnState, GridApi } from '@ag-grid-community/core'
import {
	deleteColumnStateFromSessionStorage,
	deleteFilterModelFromSessionStorage,
	deleteExpandedRowNodesFromSessionStorage,
	readColumnStateFromSessionStorage,
} from '../utils/storageUtils'
import { e_InitialSelection } from '../enums/e_InitialSelection'
import { useCallback, useContext } from 'react'
import { TableContext } from '../providers/TableContextProvider'
import type { IGroupingField, TData } from '../Table.types'
import { selectFirstCell } from '../utils/selectionUtils'

// Reset Columns, filter and row groups to initial state and delete from session storage
export const useResetCustomView = (
	id: string | undefined,
	initialColumnDefs: ColDef<TData>[],
	grouping: IGroupingField[] | undefined,
	initialSelection: e_InitialSelection | undefined,
	onColumnStateChanged: (columnState: ColumnState[], resizedColumnIds: string[]) => void,
	updateFirstColumn?: (api: GridApi<TData>, columnApi: ColumnApi) => void
) => {
	const { setOpenGroupNodes } = useContext(TableContext)

	return useCallback(
		(gridApi: GridApi<TData> | undefined, columnApi: ColumnApi) => {
			// Clear active filters
			gridApi?.setFilterModel(null)

			const columnStateFromStorage = id ? readColumnStateFromSessionStorage(id)?.originalColumnState : undefined
			if (columnStateFromStorage) {
				const hasPinnedColumn = columnApi.getColumnState().some((col) => col.pinned)

				if (hasPinnedColumn) {
					// Reset to original Column State (pinned columns)
					columnApi.applyColumnState({ state: columnStateFromStorage, applyOrder: true })
				}
			}

			// Reset to initial Column Definitions
			gridApi?.setColumnDefs(initialColumnDefs)

			// Set groups expanded and closed
			gridApi?.forEachNode((node) => {
				if (node.group) {
					const expand = grouping?.find((group) => group.id === node.field)?.expanded ?? node.level === 1
					node.setExpanded(expand)
				}
			})

			// Reset context
			setOpenGroupNodes?.([])

			// Reset sorting to initial sorting
			columnApi.getColumns()?.forEach((column) => {
				const initialColumn = initialColumnDefs.find((ic) => ic.colId === column.getId())

				if (initialColumn) {
					column.setSort(initialColumn.initialSort)
					column.setSortIndex(initialColumn.initialSortIndex)
				}
			})

			if (initialSelection === e_InitialSelection.selectFirst) {
				gridApi?.deselectAll()

				// Reset to initial selection
				selectFirstCell(gridApi)
			}

			if (gridApi) {
				const gridColumnDefs = gridApi.getColumnDefs() as ColDef<TData>[]
				columnApi.getColumns()?.forEach((col) => {
					const colDef = col.getColDef() as ColDef<TData>

					const gridColDef = gridColumnDefs.find((gcd) => gcd.field === colDef.field)
					if (gridColDef) {
						gridColDef.minWidth = colDef.cellRendererParams.minWidthFromProps
						gridColDef.maxWidth = colDef.cellRendererParams.maxWidthFromProps
					}
				})

				gridApi.setColumnDefs(gridColumnDefs)
			}

			window.setTimeout(() => {
				updateColumnDefs(gridApi, columnApi)
			})

			// Ensure that storage is not deleted too early, as column state changes on reset will recreate them.
			window.setTimeout(() => {
				deleteStorage(id, gridApi, columnApi, onColumnStateChanged, updateFirstColumn)
			}, 100)
		},
		[grouping, id, initialColumnDefs, setOpenGroupNodes, updateFirstColumn]
	)
}

const updateColumnDefs = (gridApi: GridApi<TData> | undefined, columnApi: ColumnApi) => {
	const columns = columnApi.getColumns()
	columns?.forEach((col) => {
		const colId = col.getId()
		const colDef = col.getColDef() as ColDef<TData>

		const width = colDef.cellRendererParams.widthFromProps

		if (typeof width !== 'number') {
			columnApi.autoSizeColumn(colId, width !== 'fitToLabelAndContent')
		}
	})

	if (gridApi) {
		const updatedGridColumnDefs = gridApi.getColumnDefs() as ColDef<TData>[]
		columnApi.getColumns()?.forEach((col) => {
			const colDef = col.getColDef()

			if (col.isVisible()) {
				const gridColDef = updatedGridColumnDefs.find((gcd) => gcd.field === colDef.field)
				if (gridColDef) {
					// Remove max width after initial resize (as it is "initial max width")
					gridColDef.minWidth = undefined
					gridColDef.maxWidth = undefined
				}
			}
		})

		gridApi.setColumnDefs(updatedGridColumnDefs)
	}
}

const deleteStorage = (
	id: string | undefined,
	gridApi: GridApi<TData> | undefined,
	columnApi: ColumnApi,
	onColumnStateChanged: (columnState: ColumnState[], resizedColumnIds: string[]) => void,
	updateFirstColumn: ((api: GridApi<TData>, columnApi: ColumnApi) => void) | undefined
) => {
	if (id) {
		const columns = columnApi.getColumns()
		const allVisibleColIds: string[] = []
		columns?.forEach((col) => {
			const colId = col.getId()

			// Autosize visible columns (that have width = fitToContent/fitToLabelAndContent)
			if (col.isVisible()) {
				allVisibleColIds.push(colId)
			}
		})

		onColumnStateChanged(columnApi.getColumnState(), allVisibleColIds)

		// Update first column
		if (gridApi && columnApi && updateFirstColumn) {
			updateFirstColumn(gridApi, columnApi)
		}

		// Delete session storage
		deleteColumnStateFromSessionStorage(id)
		deleteFilterModelFromSessionStorage(id)
		deleteExpandedRowNodesFromSessionStorage(id)
	}
}
