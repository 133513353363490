import type { CellData, ICellRendererProps, TData, Value, e_RenderType } from '../../../Table.types'
import type { ICellRendererParams } from '@ag-grid-community/core'
import {
	getFormControlButtonIcon,
	hasDefaultAction,
	isAvatarControl,
	isCheckMarkControl,
	isEditable,
} from '../cellRendererUtils'
import { useCellIsHoverOrFocus } from '../useCellIsHoverOrFocus'
import type { e_Interpretation } from '../../../enums/e_Interpretation'

export const useCellFlags = (
	props: ICellRendererProps & ICellRendererParams<TData, CellData>,
	cellDataOnRender: Partial<Pick<CellData, 'readOnly' | 'iconName' | 'iconColor' | 'avatarLabel'>> | undefined,
	value: Value,
	editingMode: boolean,
	displayLabel: string,
	onLinkClick: ((targetEl: React.RefObject<HTMLElement>) => void) | undefined,
	isButtonContextMenuOpen: boolean
) => {
	const cellData = props.value
	const { fillVariant, node } = props

	const readOnly = cellData?.readOnly ?? cellDataOnRender?.readOnly ?? props.readOnly ?? true
	const isAvatarCell = isAvatarControl(props.renderType)
	const isDefaultActionCell = hasDefaultAction(props.renderType, props.interpretation)
	const isSummaryCell = props.node.group || node.footer
	const isCheckMarkCell = isCheckMarkControl(props.renderType)
	const isEditableCell = isEditable(readOnly, isSummaryCell, isAvatarCell)

	const isRenderingPillOrCircle = getIsRenderingPillOrCircle(
		isSummaryCell,
		editingMode,
		props.node.group,
		fillVariant,
		value,
		isCheckMarkCell
	)

	const isMultiSelectColumn = props.isFirstColumn && props.multiSelect

	const editActionIconName = getEditActionName(isEditableCell, props.renderType, props.interpretation)

	const hasContextMenuOnHover =
		(props.hasColumnContextMenu || props.hasContextMenuButton) && !props.lockColumnContextMenu

	const hoverOrFocus = useCellIsHoverOrFocus(
		props.eGridCell,
		!!editActionIconName || hasContextMenuOnHover // These flags tells if Cell Renderer need to render more complex elements on hover/focus
	)

	const { showIconBefore, showIconAfter } = getIconPosition(props.iconPlacement, editingMode, isSummaryCell)
	const showContextMenu = getShowContextMenu(
		props.hasColumnContextMenu,
		props.hasContextMenuButton,
		props.isFirstColumn,
		props.lockColumnContextMenu,
		hoverOrFocus,
		isSummaryCell,
		isButtonContextMenuOpen
	)

	const iconName = cellData?.iconName ?? cellDataOnRender?.iconName ?? props.iconName

	// To optimize, render as few DOM elements as possible
	const isPlainTextCell =
		isSummaryCell ||
		(!isMultiSelectColumn &&
			!(isCheckMarkCell && (isEditableCell || cellData?.value)) &&
			!iconName &&
			!showContextMenu &&
			!(editActionIconName && hoverOrFocus) &&
			!((onLinkClick || isDefaultActionCell) && hoverOrFocus && displayLabel.toString().trim().length) &&
			!((isRenderingPillOrCircle || isAvatarCell) && displayLabel.toString().trim().length))

	const isLinkCell = !!(!isSummaryCell && (onLinkClick || isDefaultActionCell))

	const isFirstColumnWithGrouping = props.isFirstColumn && props.node.level > 0

	return {
		isAvatarCell,
		isDefaultActionCell,
		isSummaryCell,
		isEditableCell,
		isCheckMarkCell,
		isRenderingPillOrCircle,
		isMultiSelectColumn,
		isPlainTextCell,
		isLinkCell,
		isFirstColumnWithGrouping,
		showIconAfter,
		showIconBefore,
		showContextMenu,
		editActionIconName,
		hoverOrFocus,
	}
}

const getIconPosition = (
	iconPlacement: 'before' | 'after' | undefined,
	editingMode: boolean,
	isSummaryCell: boolean
) => {
	const showIconBefore = iconPlacement === 'before' && !editingMode && !isSummaryCell
	const showIconAfter = iconPlacement === 'after' && !editingMode && !isSummaryCell

	return { showIconBefore, showIconAfter }
}

const getIsRenderingPillOrCircle = (
	isSummaryCell: boolean,
	editingMode: boolean,
	group: boolean | undefined,
	fillVariant: 'circle' | 'pill' | 'default',
	value: Value,
	isCheckMarkCell: boolean
) => {
	const isCheckMarkCellAndFalseValue = isCheckMarkCell && value === false
	const pillOrCircle = fillVariant === 'circle' || fillVariant === 'pill'

	return !isSummaryCell && !editingMode && !group && pillOrCircle && (!!value || isCheckMarkCellAndFalseValue)
}

const getEditActionName = (isEditableCell: boolean, renderType: e_RenderType, interpretation: e_Interpretation) =>
	isEditableCell ? getFormControlButtonIcon(renderType, interpretation) : undefined

const getShowContextMenu = (
	hasColumnContextMenu: boolean,
	hasContextMenuButton: boolean,
	isFirstColumn: boolean,
	lockColumnContextMenu: boolean,
	hoverOrFocus: boolean,
	isSummaryCell: boolean,
	isButtonContextMenuOpen: boolean
) => {
	if (isButtonContextMenuOpen) {
		return true
	}
	return (
		(hasColumnContextMenu || (hasContextMenuButton && isFirstColumn)) &&
		(lockColumnContextMenu || hoverOrFocus) &&
		!isSummaryCell
	)
}
