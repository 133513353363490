import React, { useRef } from 'react'
import type PopperJS from 'popper.js'
import classNames from 'clsx'
import { Popper } from '../utils/Popper'
import { Measurer } from '../utils/Measurer'
import { topWindow } from '../utils/topWindow'
import { useOnScrollOrResize } from '../utils/useOnScrollOrResize'
import { e_Placement } from '../../enums/e_Placement'
import { e_DropShadow } from '../../enums/e_DropShadow'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	popper: {
		background: theme.palette.background.white,
		boxShadow: theme.shadows.light,
		overflowX: 'hidden',
		zIndex: theme.zIndex.popper,
	},

	none: { boxShadow: theme.shadows.none },
	extraLight: { boxShadow: theme.shadows.extraLight },
	light: { boxShadow: theme.shadows.light },
	medium: { boxShadow: theme.shadows.medium },
	strong: { boxShadow: theme.shadows.strong },
}))

const PADDING = 5

export interface IDropdownPopperManagerProps {
	open: boolean
	anchorElement: React.RefObject<HTMLElement>
	onOutsideClick: (e: React.MouseEvent) => void
	onViewportChange: (e: Event) => void
	onCreated?: () => void
	enablePortal?: boolean
	children?: React.ReactNode
	width?: string
	maxHeight?: number
	fade?: boolean
	dropShadow?: e_DropShadow
}

export const DropdownPopperManager = (props: IDropdownPopperManagerProps) => {
	const { dropShadow = e_DropShadow.light } = props

	const popperNode = useRef<HTMLDivElement>()
	const placement = useRef(e_Placement.bottomStart)

	useOnScrollOrResize(props.onViewportChange, popperNode, props.open)

	const onPopperCreate = (
		popper: HTMLDivElement,
		popperProperties: PopperJS.PopperOptions,
		anchorElement?: HTMLElement
	) => {
		popperNode.current = popper

		adjustPopper(popper, anchorElement)
		popperProperties.placement = placement.current
	}

	const adjustPopper = (popperNode: HTMLDivElement, anchorEl?: HTMLElement) => {
		const anchorElement = anchorEl || props.anchorElement.current

		if (!anchorElement) {
			return
		}

		const anchor = new Measurer(anchorElement)
		if (props.width) {
			popperNode.style.width = props.width
		} else {
			popperNode.style.width = `${anchor.width}px`
		}

		const requiredHeight = popperNode.firstElementChild!.clientHeight + PADDING

		// we apply the max height restriction if the
		const applyMaxHeight = props.maxHeight && requiredHeight > props.maxHeight

		const contentHeight =
			props.maxHeight && applyMaxHeight ? Math.min(requiredHeight, props.maxHeight + PADDING) : requiredHeight

		// Needs to scroll content if placed below, and has more than twice the space above
		if (
			anchor.actualScreenSpaceBottom < contentHeight &&
			anchor.bottom / topWindow.document.documentElement.offsetHeight > 2 / 3
		) {
			placement.current = e_Placement.topStart
		} else {
			placement.current = e_Placement.bottomStart
		}

		if (placement.current === e_Placement.topStart && contentHeight >= anchor.actualScreenSpaceTop) {
			popperNode.style.height = `${anchor.actualScreenSpaceTop - PADDING}px`
		} else if (placement.current === e_Placement.bottomStart && contentHeight >= anchor.actualScreenSpaceBottom) {
			popperNode.style.height = `${anchor.actualScreenSpaceBottom - PADDING}px`
		} else {
			popperNode.style.height = 'auto'
		}

		if (applyMaxHeight) {
			popperNode.style.maxHeight = contentHeight.toString() + 'px'
		}
	}

	return (
		<Popper
			open={props.open}
			className={classNames(classes.popper, classes[dropShadow])}
			placement={placement.current}
			onCreate={onPopperCreate}
			onCreated={props.onCreated}
			onUpdate={adjustPopper}
			anchorElement={props.anchorElement}
			onOutsideClick={props.onOutsideClick}
			autoFlip={false}
			enablePortal={props.enablePortal === undefined || props.enablePortal}
			fade={props.fade}
		>
			{props.children}
		</Popper>
	)
}
