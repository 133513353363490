import React, { useRef, useEffect } from 'react'
import classNames from 'clsx'
import { FormControl } from '../FormControl'
import { type IRadioButtonSize, RadioButton } from './RadioButton'
import { createStyle } from '../../theming'
import type { IFormControl } from '../FormControl/FormControl.types'
import type { IRadioButtonOption } from './RadioButton.types'
import { useID } from '../utils/useID'

const classes = createStyle({
	horizontal: {
		display: 'flex',
		flexDirection: 'row',
		'& > *:not(:last-child)': {
			marginRight: '16px',
		},
		margin: 4,
		minHeight: 18,
		alignItems: 'center',
	},
	vertical: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: 18,
	},
	wrap: {
		flexWrap: 'wrap',
	},
})

export function getNextOption<T>(i: number, options: IRadioButtonOption<T>[], reverse = false) {
	let nextOption: IRadioButtonOption<T> | null = null
	while (!nextOption) {
		i = i + (reverse ? -1 : 1)
		i = i === options.length ? 0 : i < 0 ? options.length - 1 : i
		if (!options[i].disabled) {
			nextOption = options[i]
		}
	}

	return nextOption
}

interface IRadioButtonGroup<T> extends IFormControl {
	value: T | undefined | null
	options?: IRadioButtonOption<T>[]
	onChange?: (value: T) => void
	displayLabel?: string
	orientation?: 'vertical' | 'horizontal' | 'horizontalMultiline'
	screenTip?: string
	autoFocus?: boolean
	buttonSize?: IRadioButtonSize
}

export function RadioButtonGroup<T extends string | number>(props: IRadioButtonGroup<T>) {
	const id = useID(props.id)
	const selectedByKey = useRef(false)
	const selectedOptionRef = useRef<HTMLButtonElement>(null)

	const orientation = props.orientation || 'vertical'

	const orientationClassnames = classNames(
		orientation === 'vertical' && classes.vertical,
		(orientation === 'horizontal' || orientation === 'horizontalMultiline') && classes.horizontal,
		orientation === 'horizontalMultiline' && classes.wrap
	)

	const selectedIndex = props.options?.findIndex((option) => option.value === props.value)
	const firstSelectableIndex = props.options?.findIndex((option) => !option.disabled)
	const noSelectedIndex = selectedIndex === -1

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (
			(e.key === 'ArrowDown' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowLeft') &&
			!props.readOnly &&
			!props.disabled &&
			props.options &&
			props.onChange &&
			selectedIndex !== undefined &&
			firstSelectableIndex !== undefined &&
			firstSelectableIndex !== -1
		) {
			const currentIndex = selectedIndex === -1 ? firstSelectableIndex : selectedIndex
			const reverse = e.key === 'ArrowUp' || e.key === 'ArrowLeft'
			const nextOption = getNextOption(currentIndex, props.options, reverse)
			if (nextOption.value !== props.value) {
				props.onChange(nextOption.value)
				selectedByKey.current = true
			}

			e.preventDefault()
			e.stopPropagation()
		}
	}

	useEffect(() => {
		if (selectedByKey.current && selectedOptionRef.current) {
			selectedOptionRef.current.focus()
			selectedByKey.current = false
		}
	}, [props.value])

	return (
		<FormControl
			id={id}
			label={props.label}
			labelPosition={props.labelPosition}
			hideLabel={props.hideLabel}
			labelProps={props.labelProps}
			icon={props.icon}
			labelContentLayout={props.labelContentLayout}
			disabled={props.disabled}
			error={props.error}
			warning={props.warning}
			labelSubText={props.labelSubText}
			validationText={props.validationText}
			validationTextPosition={props.validationTextPosition}
			subText={props.subText}
			reserveHelperTextSpace={props.reserveHelperTextSpace}
			disableBorder
			margin={props.margin}
			readOnly={props.readOnly}
			required={props.required}
			className={props.className}
			screenTip={props.screenTip}
			dataAttributes={props.dataAttributes}
			onClick={props.onClick}
			onMouseDown={props.onMouseDown}
		>
			<div
				className={orientationClassnames}
				role="radiogroup"
				onKeyDown={onKeyDown}
				id={id}
				data-cy={id + '-radiobutton-group'}
			>
				{props.options &&
					props.options.map((option, i) => {
						return (
							<RadioButton
								key={option.value} //If the data does not have unique values, the control will behave unexpectedly anyways. Thus we can assume the value is unique for the purposes of key
								index={i}
								selectedRef={i === selectedIndex ? selectedOptionRef : undefined}
								id={`${id}-${i}`}
								groupId={id}
								dataAttributes={{
									...props.dataAttributes,
									'data-autofocus': String(
										props.autoFocus && (i === selectedIndex || (noSelectedIndex && i === firstSelectableIndex))
									),
								}}
								value={option.value}
								valueLabel={option.label}
								onChange={props.onChange}
								checked={i === selectedIndex}
								tabStop={i === selectedIndex || (noSelectedIndex && i === firstSelectableIndex)}
								disabled={props.disabled || option.disabled}
								readOnly={props.readOnly}
								disableUniformHeight={orientation === 'vertical'}
								wordWrapValueLabel={orientation === 'vertical'}
								buttonSize={props.buttonSize}
							/>
						)
					})}
			</div>
		</FormControl>
	)
}
