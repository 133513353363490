import React from 'react'
import { DetailPanel } from './DetailPanel'
import { MasterPanel } from './MasterPanel'
import type { IDataPropertyDescription } from '../types/IDataPropertyDescription'
import type { ISortedColumn } from '../types/ISortedColumn'
import { createStyle } from '../../../theming'
import type { IDataWithIdentifier } from '../types/IDataWithIdentifier'
import type { DetailItemSurface } from '../types/IDetailItemSurfaceProps'
import type { IActionEntry } from '../types/IActionDescription'
import type { IMasterDetailPreakpoint } from '../types/IMasterDetailBreakpoint'

const classes = createStyle({
	detailView: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
	},

	withBorder: {
		background: 'white',
		border: '1px solid silver',
		borderRadius: '5px',
	},

	withShadow: {
		boxShadow: `0 6.4px 14.4px 0 rgba(${'0,0,0'}, 0.132), 0 1.2px 3.6px 0 rgba(${'0,0,0'}, 0.108)`,
	},

	leftMargin: {
		marginLeft: '16px',
	},

	rightMargin: {
		marginLeft: '16px',
	},
})

interface IDetailViewProps {
	title: string
	listTitle: string
	items: IDataWithIdentifier[]

	selectedIds: string[]
	setSelectedIds: (ids: string[]) => void

	activeId: string | undefined
	setActiveId: (id: string | undefined) => void
	itemName?: string | undefined | ((item: IDataWithIdentifier) => string | undefined)
	avatarContent?: string | ((item: IDataWithIdentifier) => JSX.Element | string)
	summaryContent?: string | ((item: IDataWithIdentifier) => string)

	columns: IDataPropertyDescription[]
	sortedColumns?: ISortedColumn[]
	sortColumns?: (sortedColumns: ISortedColumn[]) => void

	filterText?: string
	setFilterText?: (filterTExt: string) => void

	isMultiSelect?: boolean
	setIsMultiSelect?: (multiSelect: boolean) => void
	breakpoint: IMasterDetailPreakpoint

	itemActions?: IActionEntry[]
	selectedItemsActions?: IActionEntry[]
	globalActions?: IActionEntry[]
	handleGlobalAction?: (actionId: string) => void

	handleItemAction?: (actionId: string, itemId: string) => void
	handleSelectedItemsAction?: (actionId: string, itemIds: string[]) => void

	detailComponent?: DetailItemSurface

	back?: () => void
}

export const DetailView = React.forwardRef((props: IDetailViewProps, forwardedRef: React.Ref<HTMLDivElement>) => {
	return (
		<div className={classes.detailView} ref={forwardedRef}>
			<MasterPanel
				items={props.items}
				selectedIds={props.selectedIds}
				setSelectedIds={props.setSelectedIds}
				activeId={props.activeId}
				setActiveId={props.setActiveId}
				title={props.listTitle}
				back={props.back}
				width={280}
				itemName={props.itemName}
				avatarContent={props.avatarContent}
				summaryContent={props.summaryContent}
				columns={props.columns}
				sortedColumns={props.sortedColumns}
				sortColumns={props.sortColumns}
				filterText={props.filterText}
				setFilterText={props.setFilterText}
				isMultiSelect={props.isMultiSelect}
				setIsMultiSelect={props.setIsMultiSelect}
				globalActions={props.globalActions}
				handleGlobalAction={props.handleGlobalAction}
				selectedItemsActions={props.selectedItemsActions}
				handleSelectedItemsAction={props.handleSelectedItemsAction}
			/>
			<DetailPanel
				items={props.items}
				columns={props.columns}
				activeId={props.activeId}
				title={props.title}
				itemName={props.itemName}
				breakpoint={props.breakpoint}
				detailComponent={props.detailComponent}
				itemActions={props.itemActions}
				handleItemAction={props.handleItemAction}
				leftBorder
			/>
		</div>
	)
})

DetailView.displayName = 'DetailView'
