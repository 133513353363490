import type { CSSValues, CSSVarRef, ThemeDeclaration } from '../../theming/themingManager/themingManager.types'

/**
 * Declare a CSS variable reference to refer to variables within a theme declaration
 */
export const cssVar = <T extends ThemeDeclaration, K extends keyof CssKeyValuePair<T>>(target: T, key: K) => {
	const cssVar = (() => ({
		target: target,
		key: key,
	})) as CSSVarRef<K>

	// TODO only in dev
	Object.defineProperty(cssVar, 'name', { value: key, writable: false })
	return cssVar
}

type FilterStringValueKeys<T> = { [K in keyof T]: T[K] extends CSSValues | CSSVarRef ? K : never }[keyof T]
type CssKeyValuePair<T> = { [K in Extract<FilterStringValueKeys<T>, string>]: T[K] }
