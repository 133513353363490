import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

// Components
import { CellIcon } from './CellIcon'

// Web UI
import { createStyle } from '../../../../theming'
import { FormControlButton } from '../../../FormControl'

// Utils
import { tDataToRowData } from '../../utils'

// Hooks
import { useCellRendererEventListeners } from './useCellRendererEventListeners'
import { useCellStyles } from './useCellStyles'

// Enums and Interfaces
import type { CellData, ICellRendererProps, TData } from '../../Table.types'
import { e_RenderType } from '../../Table.types'
import { CellRendererControl } from './CellRendererControl'
import type { ICellRendererParams } from '@ag-grid-community/core'
import { getEarlyReturn, getOnIconClick, getOnLinkClick } from './utils'
import { getRowNodeId } from './getRowNodeId'
import { CellSelectionCheckMark } from '../CellCheckMark'
import { setNodeSelected } from '../../utils/setNodeSelected'
import { Viewer } from './Viewer'
import { useCellFlags, useOnClick, usePushChanges } from './hooks'
import { IconButtonMenu } from '../../../Menu'
import { e_Placement } from '../../../../enums/e_Placement'
import { useContextMenuItems } from '../../hooks/useContextMenuItems'

const classes = createStyle({
	gridCellNoRightPadding: { paddingRight: `0px !important` },
	formControlButton: { alignSelf: 'stretch', color: 'inherit' },
})

export const CellRenderer = (props: ICellRendererProps & ICellRendererParams<TData, CellData>) => {
	const cellData = props.value
	const { renderType, singleClickEdit = false, node, fillVariant, textAlignment = 'left' } = props

	const cellDataOnRender = useMemo(() => {
		return props.getCellDataOnRender?.(props.node.id, props.column?.getId())
	}, [props])

	const nodeId = getRowNodeId(node)

	const disabled = cellData?.disabled ?? false

	const [value, setValue] = useState(cellData?.value)
	const [displayLabel, setDisplayLabel] = useState(props.valueFormatted ?? '')
	const [editingMode, setEditingMode] = useState(false)

	const onLinkClick = getOnLinkClick(props.node.data, cellData?.onClick ?? props.onClick)

	const [isButtonContextMenuOpen, setIsButtonContextMenuOpen] = useState(false)

	const {
		isAvatarCell,
		isDefaultActionCell,
		isSummaryCell,
		isEditableCell,
		isCheckMarkCell,
		isRenderingPillOrCircle,
		isMultiSelectColumn,
		isPlainTextCell,
		isLinkCell,
		isFirstColumnWithGrouping,
		showIconAfter,
		showIconBefore,
		showContextMenu,
		editActionIconName,
		hoverOrFocus,
	} = useCellFlags(props, cellDataOnRender, value, editingMode, displayLabel, onLinkClick, isButtonContextMenuOpen)

	const { contextMenuItems, onOpenContextMenuWithButton } = useContextMenuItems(props.getContextMenuItems)

	useEffect(() => {
		if ([e_RenderType.checkMark, e_RenderType.lookup].includes(renderType) || isDefaultActionCell) {
			setValue(cellData?.value)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cellData?.value])

	useEffect(() => {
		setDisplayLabel(props.valueFormatted ?? '')
	}, [props.valueFormatted])

	const stopEditing = useCallback(() => {
		popperOpen.current = false
		// TODO PBU 02.01.2024: See if we can remove this. We set editing to false other places as well (in useCellRendererEventListeners) so this is redundant in many cases.
		// Setting editing mode to false here will sometimes stop some other code from running due to checks for editing mode. (e.g. in onEnterPress in useCellRendererEventListeners.tsx)
		setEditingMode(false)
		props.eGridCell.focus()
	}, [props.eGridCell])

	const pushChanges = usePushChanges(props, setDisplayLabel, setValue, stopEditing)

	const onClick = useOnClick(props, editingMode, singleClickEdit, isCheckMarkCell, setNodeSelected, setEditingMode)

	const popperOpen = useRef(false)

	useCellRendererEventListeners(
		nodeId,
		cellData,
		value,
		editingMode,
		isEditableCell,
		props,
		props.eGridCell,
		pushChanges,
		setEditingMode,
		() => setValue(cellData?.value)
	)

	useCellStyles(
		props.eGridCell,
		isMultiSelectColumn,
		editingMode,
		isEditableCell,
		showContextMenu,
		isPlainTextCell,
		isLinkCell,
		isFirstColumnWithGrouping
	)

	useEffect(() => {
		if (isPlainTextCell && isEditableCell && !editActionIconName) {
			props.eGridCell?.addEventListener('click', onClick)
		}

		return () => {
			if (isPlainTextCell && isEditableCell && !editActionIconName) {
				props.eGridCell?.removeEventListener('click', onClick)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPlainTextCell])

	if (props.onRenderCell) {
		const rowData = tDataToRowData(node.data)
		if (rowData) {
			return props.onRenderCell(rowData, props.column!.getId())
		}
	}

	if (isPlainTextCell && !isLinkCell) {
		props.eGridCell.classList.remove(classes.gridCellNoRightPadding)

		if (!editingMode) {
			return getEarlyReturn(
				renderType,
				isCheckMarkCell,
				isSummaryCell,
				cellData?.value,
				props.formatCellValue,
				displayLabel
			)
		}
	}

	const onIconClick = getOnIconClick(nodeId, node.data, props.onClick, cellData?.onIconClick)

	if ((editActionIconName && hoverOrFocus) || showContextMenu) {
		props.eGridCell.classList.add(classes.gridCellNoRightPadding)
	} else {
		props.eGridCell.classList.remove(classes.gridCellNoRightPadding)
	}

	const colId = props.column?.getId()
	const possiblyUndefinedNodeId = node.id

	return (
		<>
			{isMultiSelectColumn && (
				<CellSelectionCheckMark api={props.api} columnApi={props.columnApi} node={props.node} disabled={disabled} />
			)}
			{showIconBefore && (
				<CellIcon
					nodeId={nodeId}
					iconColor={cellData?.iconColor ?? cellDataOnRender?.iconColor ?? props.iconColor}
					iconName={cellData?.iconName ?? cellDataOnRender?.iconName ?? props.iconName}
					renderType={renderType}
					position="before"
					onIconClick={onIconClick}
				/>
			)}
			{!editingMode && (
				<Viewer
					avatarLabel={props.avatarLabel}
					cellClassName={props.cellClassName}
					cellData={cellData}
					cellDataOnRender={cellDataOnRender}
					column={props.column}
					compact={props.compact}
					disabled={disabled}
					displayLabel={displayLabel}
					editingMode={editingMode}
					eGridCell={props.eGridCell}
					fillVariant={fillVariant}
					getCellClassNameOnRender={props.getCellClassNameOnRender}
					interpretation={props.interpretation}
					isAvatarCell={isAvatarCell}
					isCheckMarkCell={isCheckMarkCell}
					isDefaultActionCell={isDefaultActionCell}
					isEditableCell={isEditableCell}
					isRenderingPillOrCircle={isRenderingPillOrCircle}
					node={node}
					nodeId={nodeId}
					pillWidth={props.pillWidth}
					renderType={renderType}
					textAlignment={textAlignment}
					value={value}
					onClick={onClick}
					onLinkClick={onLinkClick}
					pushChanges={pushChanges}
				/>
			)}
			{editingMode && (
				<CellRendererControl
					id={nodeId}
					inputValue={value}
					displayLabel={displayLabel}
					setTempValue={setValue}
					setValueImmediately={pushChanges}
					onClose={stopEditing}
					popperOpen={popperOpen}
					setDisplayLabel={setDisplayLabel}
					{...props}
				/>
			)}
			{showIconAfter && (
				<CellIcon
					nodeId={nodeId}
					iconColor={cellData?.iconColor ?? cellDataOnRender?.iconColor ?? props.iconColor}
					iconName={cellData?.iconName ?? cellDataOnRender?.iconName ?? props.iconName}
					renderType={renderType}
					position="after"
					onIconClick={onIconClick}
				/>
			)}
			{showContextMenu && possiblyUndefinedNodeId && colId && (
				<IconButtonMenu
					key={`${nodeId}-contextMenu`}
					iconClassName="Fluent-More"
					screenTip={'More'}
					disabled={disabled}
					menuItems={contextMenuItems}
					menuPlacement={e_Placement.bottomStart}
					withGenericButton
					onOpen={() => {
						onOpenContextMenuWithButton(possiblyUndefinedNodeId, colId)
						setIsButtonContextMenuOpen(true)
					}}
					onClose={() => {
						setIsButtonContextMenuOpen(false)
					}}
				/>
			)}
			{!editingMode && hoverOrFocus && editActionIconName && (
				<FormControlButton
					className={classes.formControlButton}
					iconClassName={editActionIconName}
					onClick={(e) => {
						popperOpen.current = true
						onClick(e)
					}}
					iconSize={editActionIconName === 'Fluent-ChevronDown' ? 'extraSmall' : 'small'}
				/>
			)}
		</>
	)
}
