import type { SemanticColors } from '../semanticMapping'
import { cssVar } from '../../theming/themingManager'

export const generateButtonColorsFromSemanticPalette = (semanticPalette: SemanticColors) => {
	return {
		colors: {
			background: cssVar(semanticPalette.button, 'background'),
			color: cssVar(semanticPalette.button, 'text'),
			border: cssVar(semanticPalette.button, 'border'),
		},
		hoverColors: {
			background: cssVar(semanticPalette.button, 'hoveredBackground'),
			color: cssVar(semanticPalette.button, 'hoveredText'),
			border: cssVar(semanticPalette.button, 'border'),
		},
		focusedColors: {
			background: cssVar(semanticPalette.button, 'hoveredBackground'),
			color: cssVar(semanticPalette.button, 'hoveredText'),
			border: cssVar(semanticPalette.button, 'focusBorder'),
			outline: cssVar(semanticPalette.button, 'outline'),
		},
		pressedColors: {
			background: cssVar(semanticPalette.button, 'pressedBackground'),
			color: cssVar(semanticPalette.button, 'pressedText'),
			border: cssVar(semanticPalette.button, 'border'),
		},
		checkedColors: {
			background: cssVar(semanticPalette.button, 'checkedBackground'),
			color: cssVar(semanticPalette.button, 'checkedText'),
			border: cssVar(semanticPalette.button, 'border'),
		},
		checkedHoverColors: {
			background: cssVar(semanticPalette.button, 'checkedHoveredBackground'),
			color: cssVar(semanticPalette.button, 'checkedHoveredText'),
			border: cssVar(semanticPalette.button, 'border'),
		},
		disabledColors: {
			background: cssVar(semanticPalette.button, 'disabledBackground'),
			color: cssVar(semanticPalette.button, 'disabledText'),
			border: cssVar(semanticPalette.button, 'disabledBorder'),
		},
		disabledCheckedColors: {
			background: cssVar(semanticPalette.button, 'disabledBackground'),
			color: cssVar(semanticPalette.button, 'disabledText'),
			border: cssVar(semanticPalette.button, 'disabledBorder'),
		},
	}
}
