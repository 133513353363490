import React, { useState } from 'react'
import { Button, Text, TextInput, PasswordInput, useEventListener } from '@genusbiz/web-ui/controls'
import { createStyle } from '@genusbiz/web-ui/theming'
import { operatorApi } from 'src/modules/operatorApi'
import { e_OperatorStorageKeys } from 'src/enums/e_OperatorStorageKeys'
import { useTranslation } from 'react-i18next'
import { e_DisplayState } from 'src/enums/e_DisplayState'

interface AppProps {
	provider: {
		isFormAuth: boolean
		providerColor: string
		displayName: string
		description: string
		providerStartUrl: string
	}
	userName?: string
	onNativeUserNameChanged: (userName: string) => void
	setDisplayState: (value: e_DisplayState) => void
	onValidSignIn: () => void
}

const classes = createStyle({
	forgotPasswordButton: {
		marginTop: '16px',
		alignSelf: 'center',
	},

	buttonGroup: {
		marginTop: '8px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
	operatorLoginButton: {
		minWidth: '250px',
	},
})

export const OperatorLogin = (props: AppProps) => {
	const { t } = useTranslation()

	const [hasSubmitted, setHasSubmitted] = useState(false)
	const [autoFilled, setAutoFilled] = useState(false)
	const [formPassword, setFormPassword] = useState('')
	const [showCapsWarning, setShowCapsWarning] = useState(false)
	const [hasErrorType, setHasErrorType] = useState<'none' | 'unauthorized' | 'other'>('none')

	const checkForCapsLock = (e: React.KeyboardEvent) => {
		if (e.getModifierState) {
			setShowCapsWarning(e.getModifierState('CapsLock'))
		}
	}

	useEventListener('keydown', checkForCapsLock)

	const handleNativeUserNameChanged = (userName: string) => {
		props.onNativeUserNameChanged && props.onNativeUserNameChanged(userName)
	}

	const authenticate = async (username: string, password: string) => {
		await operatorApi
			.authenticateOperatorUser(username, password)
			.then((res) => {
				if (res.status === 401) {
					setHasErrorType('unauthorized')
					setHasSubmitted(false)
				} else if (res.status === 200) {
					// Trenger ikke spørre om persist session i operator frontend
					// props.setDisplayState('persistSession')
					props.onValidSignIn()
				}
			})
			.catch(() => {
				setHasErrorType('other')
				setHasSubmitted(false)
			})
	}

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()
				if (!props.userName) {
					return
				}
				void authenticate(props.userName, formPassword)

				props.userName && sessionStorage.setItem(e_OperatorStorageKeys.username, props.userName)
				setHasSubmitted(true)
			}}
		>
			<div className="vertical spacing horizontal-stretch-items">
				{hasErrorType === 'unauthorized' && (
					<Text statusColor="error" style={{ marginTop: 0 }}>
						{t('ERROR:INVALID_LOGIN')}
					</Text>
				)}
				{hasErrorType === 'other' && (
					<Text statusColor="error" style={{ marginTop: 0 }}>
						{t('ERROR:COM')}
					</Text>
				)}
				<TextInput
					value={props.userName}
					onChange={handleNativeUserNameChanged}
					placeholder={t('SIGN_IN_WINDOW:USERNAME')}
					autoComplete="username"
					name="username"
					id="username"
					autoCapitalize="none"
				/>
				<PasswordInput
					onChange={setFormPassword}
					onKeyDown={(event: React.KeyboardEvent) => {
						setShowCapsWarning(event.getModifierState('CapsLock'))
					}}
					placeholder={t('SIGN_IN_WINDOW:PASSWORD')}
					error={showCapsWarning}
					autoComplete="password"
					onAutoFill={() => setAutoFilled(true)}
					name="password"
					id="password"
				/>
				<div className={classes.buttonGroup}>
					<Button
						variant="primary"
						disabled={hasSubmitted || !(autoFilled || (props.userName && formPassword))}
						label={t('SIGN_IN_WINDOW:SIGN_IN')}
						size="large"
						showSpinner={hasSubmitted}
						type="submit"
						className={classes.operatorLoginButton}
					/>
				</div>
			</div>
		</form>
	)
}
