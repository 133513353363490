import type { ReactNode } from 'react'
import React, { useState } from 'react'

import type { EditorState, LexicalEditor } from 'lexical'
import { ParagraphNode, TextNode } from 'lexical'

import type { InitialConfigType } from '@lexical/react/LexicalComposer'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { AutoFocusPlugin, DisabledPlugin } from './plugins'
import { ToolbarPlugin } from './plugins/ToolbarPlugin'
import { richTextTheme } from './plugins/theme'
import { createStyle } from '../../theming'

import { ListItemNode, ListNode } from '@lexical/list'

import { LinkNode } from '@lexical/link'

import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { HeadingNode } from '@lexical/rich-text'

import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import TreeViewPlugin from './plugins/TreeViewPlugin'
import { TableActionMenuPlugin } from './plugins/TableActionMenuPlugin'
import { TableCellResizerPlugin } from './plugins/TableCellResizerPlugin/TableCellResizer'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { ImagesPlugin } from './plugins/ImagesPlugin'
import { ImageNode } from './nodes/ImageNode'
import DragDropPastePlugin from './plugins/DragDropPastePlugin'
import { $generateHtmlFromNodes } from '@lexical/html'
import { CustomTextNode } from './nodes'
import { CustomParagraphNode } from './nodes/CustomParagraphNode'
import './styles/styles.css'
import './styles/editorTheme.css'
import { CustomTableCellNode } from './nodes/CustomTableCellNode'
import { ControlledPlugin } from './plugins/ControlledPlugin'
import { makeDocumentFromHtml } from './utils/makeDocumentFromHtml'
import { CustomTableNode } from './nodes/CustomTableNode'
import { TablePlugin } from './plugins/TablePlugin'
import { RichTextStyles } from './utils/RichTextStyles'
import type { e_ToolbarElement } from './interfaces/e_ToolbarElement'
import { CodeNode, CodeHighlightNode } from '@lexical/code'
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin'
import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin/CodeActionMenuPlugin'
import { CustomCodeNode } from './nodes/CustomCodeNode'
import RegisterListenersPlugin from './plugins/RegisterListenersPlugin'

const classes = createStyle({
	editorInput: {
		outline: 'none',
		position: 'relative',
	},
	container: {
		width: '100%',
		height: '100%',
		display: 'flex',
		overflow: 'hidden',
		flexDirection: 'column',
		marginTop: '4px',
	},
	inputWrapper: {
		resize: 'none',
		position: 'relative',
		tabSize: 1,
		outline: 0,
		padding: '0px 0px 5px',
		flex: 3,
		overflow: 'auto',
	},
})

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error) {
	throw error
}

interface IProps {
	/**
	 * Used to reset the editor for a new document. If the id changes, the editor will clear its contents, reset its history and re-initialize with provided data.
	 */
	id?: string
	/**
	 * Displays a tree view of the document structure. Can also display the DOM structure.
	 */
	showTreeView?: boolean
	/**
	 * The initial value of the editor. The value will not change after initialization unless the id changes,
	 * or overwrite is set to true.
	 */
	value?: string
	/**
	 * If set to true, new values will cause editor content to be overwritten
	 */
	overwrite?: boolean
	/**
	 * Untrottled onChange handler. Called whenever the editor's content changes.
	 * If overwrite is set to true, changes will cause editor to be cleared and filled again, causing cursor to jump to the end.
	 * This prop should therefore be used with care.
	 */
	onChange?: (html: string, key?: string) => void
	/*
	 * Called when an error occurs during Lexical updates. If not provided, errors will be thrown.
	 * If provided, errors will be passed to the handler and the rich text editor will attempt to recover gracefully.
	 */
	onError?: (error: Error) => void
	/**
	 * Plugins that get access to the editor state
	 */
	children?: ReactNode | ReactNode[]
	/*
	 * If set to true, the editor will use theme from context
	 */
	inheritTheme?: boolean
	displayInToolbar?: Partial<Record<e_ToolbarElement, boolean>>
	/**
	 * Set the control to autofocus on first render if set true
	 */
	autofocus?: boolean
	/**
	 * Disable the control if set true
	 */
	isDisabled?: boolean
}

export const RichTextEditor = (props: IProps) => {
	const { showTreeView, onChange, overwrite, value } = props

	const initialConfig: InitialConfigType = {
		namespace: 'MyEditor',
		theme: richTextTheme,
		onError: props.onError || onError,
		editable: !props.isDisabled,

		nodes: [
			CustomTextNode,
			{
				replace: TextNode,
				with: (node: TextNode) => {
					return new CustomTextNode(node.__text, node.__key)
				},
				withKlass: CustomTextNode,
			},
			CustomParagraphNode,
			{
				replace: ParagraphNode,
				with: () => {
					return new CustomParagraphNode()
				},
				withKlass: CustomParagraphNode,
			},
			ListNode,
			ListItemNode,
			LinkNode,
			CustomTableCellNode,
			{
				replace: TableCellNode,
				with: (node: TableCellNode) => {
					return new CustomTableCellNode(node.__headerState, node.__colSpan, node.__width)
				},
				withKlass: CustomTableCellNode,
			},
			CustomTableNode,
			{
				replace: TableNode,
				with: () => {
					return new CustomTableNode()
				},
				withKlass: CustomTableNode,
			},
			TableRowNode,
			ImageNode,
			HeadingNode,
			CodeHighlightNode,
			CustomCodeNode,
			{
				replace: CodeNode,
				with: (node: CodeNode) => {
					return new CustomCodeNode(node.__language)
				},
				withKlass: CustomCodeNode,
			},
		],
	}

	const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)

	function handleChange(editorState: EditorState, editor: LexicalEditor, tags: Set<string>) {
		if (!onChange || tags.has('silent')) {
			return
		}
		editorState.read(() => {
			const rawHtml = $generateHtmlFromNodes(editor, null)
			const html = makeDocumentFromHtml(rawHtml, props.inheritTheme)
			onChange(html, props.id)
		})
	}

	const onRef = (_floatingAnchorElem: HTMLDivElement) => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem)
		}
	}

	return (
		<LexicalComposer initialConfig={initialConfig}>
			<div className={classes.container} data-cy={'lexical-editor-wrapper'}>
				<ToolbarPlugin
					displayInToolbar={props.displayInToolbar}
					inheritTheme={props.inheritTheme}
					isDisabled={props.isDisabled}
				/>
				<RichTextStyles shouldInheritTheme={props.inheritTheme}>
					<TablePlugin />
					{!props.isDisabled && <TableCellResizerPlugin />}
					<RichTextPlugin
						contentEditable={
							<div className={classes.inputWrapper} ref={onRef}>
								<ContentEditable
									className={classes.editorInput + ' ContentEditable__root'}
									data-testid="editor-input"
								/>
								{floatingAnchorElem && <CodeActionMenuPlugin anchorElement={floatingAnchorElem} />}
							</div>
						}
						placeholder={null}
						ErrorBoundary={LexicalErrorBoundary}
					/>
					<ControlledPlugin id={props.id} value={value} overwrite={overwrite} />
					<OnChangePlugin ignoreHistoryMergeTagChange ignoreSelectionChange onChange={handleChange} />
					<HistoryPlugin />
					<DisabledPlugin isDisabled={!!props.isDisabled} />
					<ListPlugin />
					<LinkPlugin />
					{showTreeView && <TreeViewPlugin />}
					{!props.isDisabled && <ImagesPlugin />}
					<DragDropPastePlugin />
					<CodeHighlightPlugin />
					{props.children}
					{props.autofocus && <AutoFocusPlugin />}
				</RichTextStyles>
			</div>
			{floatingAnchorElem && !props.isDisabled ? (
				<>
					<TableActionMenuPlugin anchorElem={floatingAnchorElem} />
				</>
			) : (
				<></>
			)}
			<RegisterListenersPlugin />
		</LexicalComposer>
	)
}
