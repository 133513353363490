import { useContext, useEffect, useMemo } from 'react'
import { KeyboardShortcutListenerContext } from './KeyboardShortcutListenerContext'
import type { IKeyboardShortcutListenerContext } from './IKeyboardShortcutListenerContext'
import { getOrCreateKeyboardListenerContext, removeKeyboardListenerContext } from './keyboardListenerContexts'

export const useRegisterKeyboardShortcutListenerContext = (
	id: string | undefined,
	isRootContext: boolean
): IKeyboardShortcutListenerContext => {
	const parentContext = useContext(KeyboardShortcutListenerContext)

	const parentContextId = parentContext?.contextId
	const listenerContext = useMemo(() => {
		return id
			? getOrCreateKeyboardListenerContext(id, isRootContext ? undefined : parentContextId, isRootContext)
			: undefined
	}, [id, parentContextId, isRootContext])

	useEffect(() => {
		return () => {
			if (listenerContext) {
				removeKeyboardListenerContext(listenerContext.contextId)
			}
		}
	}, [listenerContext, parentContext])

	return listenerContext || parentContext
}
