import { useContext, useEffect } from 'react'

import { refreshCheckMarkColumn } from '../utils/selectionUtils'
import { setsAreEqual } from '../utils/setsAreEqual'

import type { AgGridReact } from '@ag-grid-community/react'
import type { TData } from '../Table.types'
import { getCheckboxState } from '../utils'
import { TableContext } from '../providers/TableContextProvider'
import { SELECTION_SUPPRESS_FINISH_ACTIONS } from '../consts'

export const useSetSelection = (
	gridRef: React.RefObject<AgGridReact<TData>>,
	multiSelect: boolean,
	selection: string[] | undefined,
	selectableRowCountAfterFilter: React.MutableRefObject<number>
) => {
	const { checkMarkValue, setCheckMarkValue } = useContext(TableContext)

	useEffect(() => {
		if (!gridRef.current?.api) {
			return
		}

		const { api, columnApi } = gridRef.current

		const oldSelectedIds = new Set(api.getSelectedNodes().map((node) => node.id))
		const newSelectedIds = new Set(selection)

		if (setsAreEqual(oldSelectedIds, newSelectedIds)) {
			return
		}

		const nodesToSelect = new Set([...newSelectedIds].filter((id) => !oldSelectedIds.has(id)))
		const nodesToDeselect = new Set([...oldSelectedIds].filter((id) => id && !newSelectedIds.has(id)))

		// @ts-ignore
		nodesToSelect.forEach((id) => api.getRowNode(id)?.setSelected(true, false, SELECTION_SUPPRESS_FINISH_ACTIONS))
		nodesToDeselect.forEach(
			// @ts-ignore
			(id) => id && api.getRowNode(id)?.setSelected(false, false, SELECTION_SUPPRESS_FINISH_ACTIONS)
		)

		if (multiSelect) {
			refreshCheckMarkColumn(api, columnApi)

			const newHeaderCheckMarkState = getCheckboxState(selectableRowCountAfterFilter.current, api)
			if (newHeaderCheckMarkState !== checkMarkValue) {
				setCheckMarkValue?.(newHeaderCheckMarkState)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gridRef, multiSelect, selectableRowCountAfterFilter, selection, setCheckMarkValue])
}
