import { useContext, useEffect, useRef } from 'react'

import { getCheckboxState } from '../utils'
import type { ColDef, GridApi } from '@ag-grid-community/core'
import type { ICellEditingProps, ICellRendererProps, RowData, RowTreeData, TData } from '../Table.types'
import type { ITableColumnHeaderProps } from '../components/ColumnHeader/ColumnHeader'
import { TableContext } from '../providers/TableContextProvider'
import type { MenuItem } from '../../Menu'

export const useUpdateColumnDefs = (
	gridApi: GridApi | undefined,
	rowData: RowData[] | RowTreeData[],
	multiSelect: boolean,
	getContextMenuItems:
		| ((id: string, columnId: string, contextMenuCallback: (contextMenuItems: MenuItem[]) => void) => void)
		| undefined,
	cellEditingProps: ICellEditingProps | undefined
) => {
	const { checkMarkValue, setCheckMarkValue } = useContext(TableContext)

	const selectableRowCountLength = (rowData as RowData[]).filter((data) => !data.disableSelection).length
	const rowCountAfterFilter = useRef(selectableRowCountLength)

	useEffect(() => {
		if (gridApi) {
			let i = 0
			gridApi.forEachNodeAfterFilter((node) => {
				if (!node.group) {
					if (!node.data?.disableSelection) {
						i++
					}
				}
			})

			rowCountAfterFilter.current = i

			// Update header check mark context
			const newHeaderCheckMarkState = getCheckboxState(rowCountAfterFilter.current, gridApi)
			if (newHeaderCheckMarkState !== checkMarkValue) {
				setCheckMarkValue?.(newHeaderCheckMarkState)
			}
		} else {
			rowCountAfterFilter.current = selectableRowCountLength
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectableRowCountLength])

	useEffect(() => {
		if (gridApi) {
			const columnDefs = gridApi.getColumnDefs() as ColDef<TData>[]
			columnDefs?.forEach((colDef) => {
				colDef.cellRendererParams = {
					...colDef.cellRendererParams,
					getContextMenuItems,
					cellEditingProps,
				} as ICellRendererProps
			})

			columnDefs && gridApi.setColumnDefs(columnDefs)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getContextMenuItems, cellEditingProps])

	useEffect(() => {
		if (gridApi) {
			const columnDefs = gridApi.getColumnDefs() as ColDef<TData>[]
			columnDefs?.forEach((colDef, index) => {
				colDef.headerComponentParams = {
					...colDef.headerComponentParams,
					multiSelect: multiSelect && index === 0,
				} as ITableColumnHeaderProps

				colDef.cellRendererParams = {
					...colDef.cellRendererParams,
					multiSelect: multiSelect && index === 0,
				} as ICellRendererProps
			})

			columnDefs && gridApi.setColumnDefs(columnDefs)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [multiSelect])

	return rowCountAfterFilter
}
