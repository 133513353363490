import clsx from 'clsx'
import React from 'react'
import { ColumnSortPanel } from './ColumnSortPanel'
import { FilterTextPanel } from './FilterTextPanel'
import type { IDataPropertyDescription } from '../types/IDataPropertyDescription'
import type { ISortedColumn } from '../types/ISortedColumn'
import { createStyle } from '../../../theming'
import { GenericButton } from '../../../controls/GenericButton'
import type { IActionEntry } from '../types/IActionDescription'
import { e_actionType } from '../types/IActionDescription'
import { Divider } from '../../../controls/Divider'
import { useTranslation } from '../../../translation'

const classes = createStyle({
	headerControl: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},

	headerControlCompact: {},

	headerControlContent: {
		display: 'flex',
		alignItems: 'center',
		gap: '4px',
		padding: '8px',

		borderBottom: '1px solid silver',
	},

	headerTitle: {
		fontSize: '20px',
		fontWeight: 600,
	},

	headerControlCommandArea: {
		display: 'flex',
		alignItems: 'center',
		padding: '8px',
		gap: '4px',
	},

	headerControlCommandAreaMain: {
		flex: 1,
		display: 'flex',
		height: '28px',
		alignItems: 'center',
		padding: '0px 8px',
		justifyContent: 'flex-end',
	},

	headerControlCommandAreaCompact: {
		display: 'flex',
		alignItems: 'center',
		padding: '4px 8px',
		justifyContent: 'flex-end',
		borderBottom: '1px solid silver',
		background: 'whitesmoke',
	},

	headerButton: {
		font: 'inherit',

		width: '28px',
		height: '28px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '20px',
		padding: 'unset',
		outline: 'none',
		border: 'none',
		background: 'transparent',
		'&:hover': {
			background: 'rgba(0,0,0,0.1)',
		},
		'&:active': {
			background: 'rgba(0,0,0,0.2)',
		},
	},
})

interface IHeaderControlProps {
	title: string
	count?: number
	back?: () => void
	children?: React.ReactNode | React.ReactNode[]

	filterText?: string
	setFilterText?: (filterTExt: string) => void

	columns?: IDataPropertyDescription[]
	sortedColumns?: ISortedColumn[]
	sortColumns?: (sortedColumns: ISortedColumn[]) => void
	compactView?: boolean

	isMultiSelect?: boolean
	setIsMultiSelect?: (multiSelect: boolean) => void

	selectedItemsActions?: IActionEntry[]

	handleSelectedItemsAction?: (actionId: string, itemIds: string[]) => void
	globalActions?: IActionEntry[]
	handleGlobalAction?: (actionId: string) => void

	selectedIds?: string[]
}

const showCommandArea = true

export const HeaderControl = (props: IHeaderControlProps) => {
	const { tcvvi } = useTranslation()

	return (
		<div className={classes.headerControl}>
			<div className={classes.headerControlContent}>
				{props.back && <GenericButton className={classes.headerButton} onClick={props.back} icon={'Basic-ArrowLeft'} />}
				<span className={classes.headerTitle}>
					{props.title}
					{props.children}
					{props.count !== undefined && ` (${props.count})`}
				</span>

				{!props.compactView && (
					<div className={clsx(classes.headerControlCommandArea, classes.headerControlCommandAreaMain)}>
						{props.setFilterText && (
							<FilterTextPanel filterText={props.filterText} setFilterText={props.setFilterText} />
						)}

						{props.columns && props.sortColumns && (
							<ColumnSortPanel
								columns={props.columns}
								sortedColumns={props.sortedColumns}
								sortColumns={props.sortColumns}
							/>
						)}

						{props.handleGlobalAction && props.globalActions && props.globalActions.length > 0 && (
							<>
								<Divider vertical />
								{props.globalActions?.map((actionEntry, index) => {
									if (actionEntry.type === e_actionType.separator) {
										return <Divider key={`divider_${index}`} vertical />
									} else if (actionEntry.type === e_actionType.action) {
										return (
											<GenericButton
												key={actionEntry.id}
												icon={actionEntry.iconClassName}
												onClick={() => props.handleGlobalAction && props.handleGlobalAction(actionEntry.id)}
												screenTip={actionEntry.screenTip}
												dataAttributes={actionEntry.dataAttributes}
											/>
										)
									} else {
										return null
									}
								})}
							</>
						)}

						{props.isMultiSelect &&
							props.handleSelectedItemsAction &&
							props.selectedItemsActions &&
							props.selectedItemsActions.length > 0 && (
								<>
									<Divider vertical />
									{props.selectedItemsActions?.map((actionEntry, index) => {
										if (actionEntry.type === e_actionType.separator) {
											return <Divider key={`divider_${index}`} vertical />
										} else if (actionEntry.type === e_actionType.action) {
											return (
												<GenericButton
													key={actionEntry.id}
													icon={actionEntry.iconClassName}
													onClick={() =>
														props.handleSelectedItemsAction &&
														props.selectedIds &&
														props.handleSelectedItemsAction(actionEntry.id, props.selectedIds)
													}
													screenTip={actionEntry.screenTip}
													disabled={!props.selectedIds || props.selectedIds.length === 0}
												/>
											)
										} else {
											return null
										}
									})}
								</>
							)}

						{props.setIsMultiSelect && props.selectedItemsActions && props.selectedItemsActions.length > 0 && (
							<>
								<Divider vertical />
								<GenericButton
									icon={props.isMultiSelect ? 'Fluent-CheckboxComposite' : 'Fluent-Checkbox'}
									onClick={() => props.setIsMultiSelect && props.setIsMultiSelect(!props.isMultiSelect)}
									isChecked={props.isMultiSelect}
									screenTip={tcvvi('GENERAL:SELECT')}
								/>
							</>
						)}
					</div>
				)}
			</div>

			{props.compactView && showCommandArea && (
				<div className={clsx(classes.headerControlCommandArea, classes.headerControlCommandAreaCompact)}>
					{props.setFilterText && <FilterTextPanel filterText={props.filterText} setFilterText={props.setFilterText} />}

					{props.columns && props.sortColumns && (
						<ColumnSortPanel
							columns={props.columns}
							sortedColumns={props.sortedColumns}
							sortColumns={props.sortColumns}
						/>
					)}

					{props.handleGlobalAction && props.globalActions && props.globalActions.length > 0 && (
						<>
							<Divider vertical />
							{props.globalActions?.map((actionEntry, index) => {
								if (actionEntry.type === e_actionType.separator) {
									return <Divider key={`divider_${index}`} vertical />
								} else if (actionEntry.type === e_actionType.action) {
									return (
										<GenericButton
											key={actionEntry.id}
											icon={actionEntry.iconClassName}
											onClick={() => props.handleGlobalAction && props.handleGlobalAction(actionEntry.id)}
											screenTip={actionEntry.screenTip}
										/>
									)
								} else {
									return null
								}
							})}
						</>
					)}

					{props.isMultiSelect &&
						props.handleSelectedItemsAction &&
						props.selectedItemsActions &&
						props.selectedItemsActions.length > 0 && (
							<>
								<Divider vertical />
								{props.selectedItemsActions?.map((actionEntry, index) => {
									if (actionEntry.type === e_actionType.separator) {
										return <Divider key={`divider_${index}`} vertical />
									} else if (actionEntry.type === e_actionType.action) {
										return (
											<GenericButton
												key={actionEntry.id}
												icon={actionEntry.iconClassName}
												onClick={() =>
													props.handleSelectedItemsAction &&
													props.selectedIds &&
													props.handleSelectedItemsAction(actionEntry.id, props.selectedIds)
												}
												screenTip={actionEntry.screenTip}
												disabled={!props.selectedIds || props.selectedIds.length === 0}
											/>
										)
									} else {
										return null
									}
								})}
							</>
						)}

					{props.setIsMultiSelect && props.selectedItemsActions && props.selectedItemsActions.length > 0 && (
						<>
							<Divider vertical />
							<GenericButton
								icon={props.isMultiSelect ? 'Fluent-CheckboxComposite' : 'Fluent-Checkbox'}
								onClick={() => props.setIsMultiSelect && props.setIsMultiSelect(!props.isMultiSelect)}
								isChecked={props.isMultiSelect}
								screenTip={tcvvi('GENERAL:SELECT')}
							/>
						</>
					)}
				</div>
			)}
		</div>
	)
}
