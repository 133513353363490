import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'

import type { LocaleUtils } from 'react-day-picker'
import DayPicker from 'react-day-picker'

import MomentLocaleUtils from 'react-day-picker/moment'
import moment from 'moment'
import { DatePickerType } from './DatePicker.types'
import { createStyle } from '../../theming'
import { YearPicker } from './YearPicker'
import { MonthPicker } from './MonthPicker'
import { DatePickerHeader } from './DatePickerHeader'
import { useTranslation } from '../../translation'
import { DatePickerButton } from './DatePickerButton'
import clsx from 'clsx'

// React-day-picker does not correclty export interfaces correctly:
// props that can be partial are required, hence this default object
const reactDayPickerClassNamesDefaults = {
	interactionDisabled: 'DayPicker--interactionDisabled',
	months: 'DayPicker-Months',

	navBar: 'DayPicker-NavBar',
	navButtonPrev: 'DayPicker-NavButton DayPicker-NavButton--prev',
	navButtonNext: 'DayPicker-NavButton DayPicker-NavButton--next',
	navButtonInteractionDisabled: 'DayPicker-NavButton--interactionDisabled',

	body: 'DayPicker-Body',
	weekNumber: 'DayPicker-WeekNumber',
	footer: 'DayPicker-Footer',
	todayButton: 'DayPicker-TodayButton',

	disabled: 'disabled',
	outside: 'outside',
} as const

const elementSize = 28

const dayElementSize = `${elementSize}px`

const classes = createStyle((theme) => ({
	datePicker: {
		width: '224px',
		minHeight: '260px',
		display: 'flex',
		flexDirection: 'column',
		fontSize: theme.typography.body1.fontSize,
	},
	dayPicker: {
		boxShadow: theme.shadows.medium,
		backgroundColor: theme.palette.background.white,
	},
	container: {
		flex: 1,
	},
	wrapper: {
		position: 'relative',
		flexDirection: 'row',

		backgroundColor: theme.colors.list.background,

		color: theme.colors.list.text,
		'&:focus': {
			outline: 'none',
			border: '1px solid ' + theme.colors.body.focusBorder,
		},
	},
	caption: {
		display: 'table-caption',
		marginBottom: 0,
		padding: '0 8px',
		textAlign: 'left',
	},
	day: {
		width: dayElementSize,
		height: dayElementSize,
		display: 'table-cell',
		padding: 0,
		verticalAlign: 'middle',
		textAlign: 'center',
		cursor: 'pointer',
		border: '1px solid transparent',
		'&:focus': {
			outline: 'none',
			border: '1px solid ' + theme.colors.body.focusBorder,
		},
		'&:hover': {
			background: theme.colors.list.hoveredBackground,
		},
	},

	weekNumber: {
		width: dayElementSize,
		height: dayElementSize,
		display: 'table-cell',
		padding: 0,
		verticalAlign: 'middle',
		textAlign: 'center',
		opacity: 0.5,
	},
	week: {
		display: 'table-row',
	},
	weekdays: {
		// display: 'table-header-group',
	},
	weekdaysRow: {
		display: ' table-row',
	},
	weekday: {
		width: dayElementSize, //'28px',
		height: dayElementSize,
		display: 'table-cell',
		padding: 0,
		verticalAlign: 'middle',
		textAlign: 'center',
	},
	month: {
		display: 'table',
		userSelect: 'none',
	},
	today: {
		background: theme.colors.primaryButton.background + ' !important',
		color: theme.colors.primaryButton.text + ' !important',
	},
	todayButton: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		paddingTop: 8,
	},
	selected: {
		background: theme.colors.list.checkedBackground,
	},
}))

export interface IDatePickerProps {
	selectedDay: Date
	onDateSelected: (date: Date) => void
	dataAttributes?: Record<string, string>
}

export const DatePicker = (props: IDatePickerProps) => {
	const [date, setDate] = useState<Date>(props.selectedDay)

	const [pickerType, setPickerType] = useState(DatePickerType.dayPicker)
	const focusPickerSelector = useRef<string | undefined>()

	const { tcvi } = useTranslation()

	const dayRef = useRef<HTMLDivElement>(null)

	const onDayClick = (day: Date) => {
		props.onDateSelected(day)
	}

	const onYearClick = (year: Date) => {
		setDate(year)
		setPickerType(DatePickerType.monthPicker)

		// when year has changed, focus should be set to first month in monthpicker
		focusPickerSelector.current = '[data-defaultmonth]'
	}

	const onMonthClick = (month: Date) => {
		setDate(month)
		setPickerType(DatePickerType.dayPicker)
		// when year has changed, focus should be set to first day in daypicker
		focusPickerSelector.current = '.selectedday'
	}

	const changePickerType = (picker: DatePickerType) => {
		setPickerType(picker)
		// when picker type has changed retain focus
		focusPickerSelector.current = '[data-pickertype]'
	}

	const onMonthChange = (month: Date) => {
		setDate(month)
	}

	useEffect(() => {
		setDate(props.selectedDay)
	}, [props.selectedDay])

	const onChange = (date: Date) => {
		setDate(date)
	}

	const selectToday = () => {
		onChange(new Date())
	}

	useLayoutEffect(() => {
		if (focusPickerSelector.current && dayRef.current) {
			// Set focus to element according to specified selector
			const button = dayRef.current.querySelector(focusPickerSelector.current) as HTMLElement

			if (button) {
				button.focus()
			}
		}
		focusPickerSelector.current = undefined
	}, [pickerType])

	const [startYear, setStartYear] = useState(Math.floor(date.getFullYear() / 10) * 10)

	const dayPickerClassNames = {
		...reactDayPickerClassNamesDefaults,
		container: classes.container,
		today: classes.today,
		selected: clsx(classes.selected, 'selectedday'),
		wrapper: classes.wrapper,
		day: classes.day,
		caption: classes.caption,
		weekday: classes.weekday,
		weekdays: classes.weekdays,
		weekdaysRow: classes.weekdaysRow,
		week: classes.week,
		month: classes.month,
		weekNumber: classes.weekNumber,
	}

	return (
		<div
			onPointerDown={(e) => e.stopPropagation()}
			ref={dayRef}
			{...props.dataAttributes}
			className={classes.datePicker}
		>
			{pickerType === DatePickerType.dayPicker ? (
				<>
					<DayPicker
						tabIndex={0}
						localeUtils={MomentLocaleUtils}
						locale={moment.locale()}
						selectedDays={date}
						month={date}
						classNames={dayPickerClassNames}
						onDayClick={onDayClick}
						onMonthChange={onMonthChange}
						showOutsideDays
						showWeekNumbers
						navbarElement={() => null}
						captionElement={(captionElementProps: { locale: string; localeUtils: LocaleUtils }) => {
							return (
								<DatePickerHeader
									date={date}
									tabIndex={0}
									locale={captionElementProps.locale}
									localeUtils={captionElementProps.localeUtils}
									setPickerType={changePickerType}
									onChange={onChange}
									pickerType={pickerType}
									setStartYear={() => {
										return
									}}
									startYear={2000}
								/>
							)
						}}
					/>
					<div className={classes.todayButton}>
						<DatePickerButton label={tcvi('GENERAL:GO_TO_TODAY')} onClick={selectToday} />
					</div>
				</>
			) : (
				<DatePickerHeader
					date={date}
					setPickerType={setPickerType}
					pickerType={pickerType}
					tabIndex={0}
					locale={moment.locale()}
					localeUtils={MomentLocaleUtils}
					onChange={onChange}
					setStartYear={setStartYear}
					startYear={startYear}
				/>
			)}
			{pickerType === DatePickerType.yearPicker && (
				<YearPicker
					date={date || new Date()}
					onYearClick={onYearClick}
					onChange={(date) => {
						setDate(date)
					}}
					tabIndex={0}
					startYear={startYear}
				/>
			)}
			{pickerType === DatePickerType.monthPicker && (
				<MonthPicker
					date={date || new Date()}
					localeUtils={MomentLocaleUtils}
					locale={moment.locale()}
					onMonthClick={onMonthClick}
					tabIndex={0}
				/>
			)}
		</div>
	)
}
