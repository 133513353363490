import { e_MenuItemType, MenuItem } from '@genusbiz/web-ui/controls'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { e_OperatorResources } from 'src/enums/e_OperatorResources'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { operatorFrontendSelectors } from 'src/features/OperatorFrontend/duck/operatorFrontendSelectors'
import { OperatorCommandBar } from '../CommandBar/OperatorCommandBar'
import { OperatorClusterEventTable } from './OperatorClusterEventTable'

export const Monitoring = () => {
	const autoReloadEnabled = useSelector(operatorFrontendSelectors.selectAutoReload('kubernetesEvents'))
	const commandBarItems: MenuItem[] = []
	const dispatch = useDispatch()
	const { t } = useTranslation()

	commandBarItems.push({
		type: e_MenuItemType.action,
		name: autoReloadEnabled ? t('GENERAL:TURN_OFF_AUTOMATIC_RELOAD') : t('GENERAL:TURN_ON_AUTOMATIC_RELOAD'),
		iconClassName: autoReloadEnabled ? 'Fluent-Cancel' : 'Fluent-Refresh',
		onClick: () => {
			dispatch(operatorFrontendActions.setAutoReload(e_OperatorResources.kubernetesEvents, !autoReloadEnabled))
		},
	})

	return (
		<>
			<OperatorCommandBar items={commandBarItems} />
			<OperatorClusterEventTable />
		</>
	)
}
