import axios, { AxiosError, AxiosPromise, AxiosRequestConfig } from 'axios'

import { makeRequestConfig, createProcessResponse } from './configHelpers'
import { e_OperatorApiService } from './types'

import { getStore } from 'src/utils/store'
import { sessionStorageService } from '../sessionStorageService/sessionStorageService'
import { e_OperatorStorageKeys } from 'src/enums/e_OperatorStorageKeys'
import { authenticationActions } from 'src/features/Authentication/duck'
import { IDataPartition, IGService, IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { IEndpoint } from 'src/interfaces/IEndpoint'
import { IEnvironmentAvailabilitySchedule } from 'src/interfaces/IAvailabilityWindow'
import { ISetEnvironmentAvailabilitySchedule } from 'src/features/Kubernetes/duck/IKubernetesActions'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { IPod } from 'src/interfaces/IPod'
import { IDeployment } from 'src/interfaces/IDeployment'
import { IStatefulSet } from 'src/interfaces/IStatefulSet'
import { IClusterEvent } from 'src/interfaces/IClusterEvent'
import { IOperatorErrorLog } from 'src/interfaces/IErrorLog'
import { IControlLoopStatus } from 'src/interfaces/IControlLoopStatus'
import { IJob } from 'src/interfaces/IJob'
import { IClusterDiagramNode } from 'src/interfaces/IClusterDiagram'
import { IMetaModelManagerEnvVariables } from 'src/interfaces/IMetaModelManagerEnvVariables'
import { IEnvironmentResourceOverview } from 'src/interfaces/IEnvironmentResourceOverview'
import { e_ScaleOperation } from 'src/enums/e_ScaleOperation'
import { IWorkloadsResourcePlan } from 'src/interfaces/IWorkloadsResourcePlan'
import { IUserInfo } from 'src/interfaces/IUserInfo'

/***************************************************************
 * Initialization code
 ***************************************************************/

let _errorHandler = (err: AxiosError<{ Message: string; StatusCode: number }>) => {
	if (err.response && err.response.data && err.response.data.Message) {
		err.message = err.message + '. ' + err.response.data.Message
	}
	return Promise.reject(err)
}

const setErrorHandler = (errorHandler: typeof _errorHandler) => {
	_errorHandler = errorHandler
}

/***************************************************************
 * KubernetesOperator specific
 ***************************************************************/

const fetchOperatorUp = () => _get(e_OperatorApiService.probe, '')

const fetchOperatorConfig = () => _get(e_OperatorApiService.config, '')

const fetchKubernetesVersion = () => _get(e_OperatorApiService.kubernetesInfo, '/version')

const fetchUserInfo = () => _get<IUserInfo>(e_OperatorApiService.account, '/userinfo')

const fetchMetaModelManagerEnvVariables = () =>
	_get<IMetaModelManagerEnvVariables>(e_OperatorApiService.metaModelManagerEnvVariables, '')

const fetchEnvironmentResourceOverview = (environmentType: e_EnvironmentOperatingType) => {
	const environment = e_EnvironmentOperatingType[environmentType]

	return _get<IEnvironmentResourceOverview>(e_OperatorApiService.resourceOverview, `/${environment}`)
}

const authenticateOperatorUser = (username: string, password: string) =>
	_auth(e_OperatorApiService.authentication, '', username, password)

const logOut = () => _auth(e_OperatorApiService.authentication, '/signout')

const fetchOperatorK8sRuntimes = () => _get<IK8sRuntime[]>(e_OperatorApiService.k8sRuntime, '/')
const updateOperatorK8sRuntime = (k8sRuntime: IK8sRuntime) =>
	_put<IK8sRuntime>(e_OperatorApiService.k8sRuntime, `${k8sRuntime.name}/featureenabling`, k8sRuntime)
const createOperatorK8sRuntime = (k8sRuntime: IK8sRuntime) =>
	_post<IK8sRuntime>(e_OperatorApiService.k8sRuntime, '/', k8sRuntime)
const deleteOperatorK8sRuntime = (k8sRuntime: string) =>
	_delete<IK8sRuntime>(e_OperatorApiService.k8sRuntime, `/${k8sRuntime}`)

const fetchOperatorErrorLog = () => _get<IOperatorErrorLog>(e_OperatorApiService.runtimeReporting, '/errorlog')
const clearOperatorErrorLog = () => _post(e_OperatorApiService.runtimeReporting, '/errorlog/clear')

const fetchOperatorClusterEvents = (k8sRuntime: string) =>
	_get<IClusterEvent[]>(e_OperatorApiService.k8sRuntime, `/${k8sRuntime}/event`)

const fetchOperatorEndpoints = () => _get<IEndpoint[]>(e_OperatorApiService.endpoint, '/')

const fetchDataPartitions = () => _get<IDataPartition[]>(e_OperatorApiService.dataPartition, '/')
const fetchRuntimeFeatures = () => _get<IGService[]>(e_OperatorApiService.k8sRuntime, '/features')

const fetchOperatorPods = (k8sRuntime: string) =>
	_get<IPod[]>(e_OperatorApiService.workload, `/${k8sRuntime}/pod?includeMetrics=true`)

const fetchPodLog = (
	k8sRuntime: string,
	podName: string,
	containerName: string,
	includeTimestamps: boolean,
	previous: boolean
) => {
	return _get<string>(
		e_OperatorApiService.workload,
		`/${k8sRuntime}/pod/log?podname=${podName}&taillines=1000${
			containerName ? '&containerName=' + containerName : ''
		}&includeTimestamps=${includeTimestamps.toString()}&previous=${previous.toString()}`
	)
}

const deleteOperatorPods = (k8sRuntime: string, podNames: string[]) => {
	return _post(e_OperatorApiService.workload, `/${k8sRuntime}/pod?op=delete`, podNames)
}

const fetchOperatorDeployments = (k8sRuntime: string) =>
	_get<IDeployment[]>(e_OperatorApiService.workload, `/${k8sRuntime}/deployment`)

const fetchOperatorStatefulSets = (k8sRuntime: string) =>
	_get<IStatefulSet[]>(e_OperatorApiService.workload, `/${k8sRuntime}/statefulset`)

const fetchOperatorJobs = (k8sRuntime: string) => _get<IJob[]>(e_OperatorApiService.workload, `/${k8sRuntime}/job`)

const fetchClusterDiagramNodes = (k8sRuntime: string) =>
	_get<IClusterDiagramNode[]>(e_OperatorApiService.workload, `/${k8sRuntime}/clusterdiagram/graph`)

const fetchControlLoopStatus = () => _get<IControlLoopStatus>(e_OperatorApiService.controlLoopReport, '')

const fetchEnvironmentAvailabilitySchedule = () => _get(e_OperatorApiService.environmentAvailability, '')

const saveEnvironmentAvailabilitySchedule = (environmentAvailabilitySchedule: IEnvironmentAvailabilitySchedule) =>
	_post<ISetEnvironmentAvailabilitySchedule>(
		e_OperatorApiService.environmentAvailability,
		'',
		environmentAvailabilitySchedule
	)

const fetchOperatorWorkloadsResourcePlan = () =>
	new Promise<IWorkloadsResourcePlan>((resolve, reject) => {
		const emtptyWorkloadsResourcePlan: IWorkloadsResourcePlan = {
			scalableResourceConfigurations: [],
			id: '',
			createdUtc: '',
		}
		_get<IWorkloadsResourcePlan>(e_OperatorApiService.workloadsResourcePlan, '')
			.then((result) => {
				if (result) {
					resolve(result)
				}
				resolve(emtptyWorkloadsResourcePlan)
			})
			.catch(reject)
	})

const addOrUpdateWorkloadsResourcePlan = (
	k8sRuntime: string,
	workloadName: string,
	workloadType: string,
	replicaCount: number
) =>
	_post(
		e_OperatorApiService.workload,
		`/${k8sRuntime}/${workloadType}/?${workloadType}Name=${workloadName}&op=${e_ScaleOperation.SetScalableResourceConfig}&replicas=${replicaCount}`,
		[]
	)

const removeWorkloadsResourcePlan = (k8sRuntime: string, workloadName: string, workloadType: string) =>
	_post(
		e_OperatorApiService.workload,
		`/${k8sRuntime}/${workloadType}/?${workloadType}Name=${workloadName}&op=${e_ScaleOperation.RemoveScalableResourceConfig}`,
		[]
	)
/***************************************************************
 * End of Endpoints
 ***************************************************************/

export const operatorApi = {
	setErrorHandler,

	fetchOperatorUp,
	fetchOperatorConfig,
	fetchKubernetesVersion,
	fetchUserInfo,
	fetchMetaModelManagerEnvVariables,
	fetchEnvironmentResourceOverview,

	authenticateOperatorUser,
	logOut,

	fetchOperatorK8sRuntimes,
	updateOperatorK8sRuntime,
	createOperatorK8sRuntime,
	deleteOperatorK8sRuntime,

	fetchOperatorClusterEvents,
	fetchOperatorErrorLog,
	clearOperatorErrorLog,

	fetchOperatorPods,
	fetchPodLog,
	deleteOperatorPods,

	fetchOperatorDeployments,
	fetchOperatorStatefulSets,
	fetchOperatorJobs,
	fetchClusterDiagramNodes,

	fetchControlLoopStatus,
	fetchOperatorEndpoints,
	fetchDataPartitions,
	fetchRuntimeFeatures,

	fetchEnvironmentAvailabilitySchedule,
	saveEnvironmentAvailabilitySchedule,

	fetchOperatorWorkloadsResourcePlan,
	addOrUpdateWorkloadsResourcePlan,
	removeWorkloadsResourcePlan,
}

/***************************************************************
 * CRUD Helpers
 ***************************************************************/

/**
 * Fetches one or more resources, or attributes of resources.
 *
 * @param resourcePath Path to resource
 */
const _get = <T>(genusApiService: e_OperatorApiService, resourcePath = '', config?: AxiosRequestConfig) => {
	const requestConfig = makeRequestConfig(genusApiService)
	const axiosInstance = axios.create(requestConfig)

	const p_request = () => axiosInstance.get(resourcePath, config) as AxiosPromise
	return _settleCrudRequest(p_request) as Promise<T>
}

const _post = <T>(genusApiService: e_OperatorApiService, resourcePath = '', payload?: object) => {
	const requestConfig = makeRequestConfig(genusApiService)

	const axiosInstance = axios.create(requestConfig)

	const p_request = () => axiosInstance.post(resourcePath, payload) as AxiosPromise
	return _settleCrudRequest(p_request) as Promise<T>
}

const _put = <T>(genusApiService: e_OperatorApiService, resourcePath = '', payload?: object) => {
	const requestConfig = makeRequestConfig(genusApiService)

	const axiosInstance = axios.create(requestConfig)

	const p_request = () => axiosInstance.put(resourcePath, payload) as AxiosPromise
	return _settleCrudRequest(p_request) as Promise<T>
}

const _delete = <T>(genusApiService: e_OperatorApiService, resourcePath = '', payload?: object) => {
	const requestConfig = makeRequestConfig(genusApiService)

	const axiosInstance = axios.create(requestConfig)

	const p_request = () => axiosInstance.delete(resourcePath, payload) as AxiosPromise
	return _settleCrudRequest(p_request) as Promise<T>
}

const _auth = (genusApiService: e_OperatorApiService, resourcePath = '', username?: string, password?: string) => {
	const requestConfig = makeRequestConfig(genusApiService)
	const axiosInstance = axios.create(requestConfig)
	axiosInstance.defaults.withCredentials = true

	const config = {
		validateStatus: (status: number) => {
			return (status >= 200 && status <= 302) || status === 401 // Resolve only if the status code is 2xx, or 401 Unauthorized
		},
		auth: { username: username || '', password: password || '' },
	}

	return axiosInstance.post(resourcePath, {}, config).then().catch()
}

const _settleCrudRequest = (p_request: () => AxiosPromise) =>
	new Promise((resolve, reject) => {
		const processResponse = createProcessResponse()
		const tryPerformRequest = () => p_request().then(processResponse)

		return tryPerformRequest()
			.then(resolve)
			.catch((err) => _reauthenticate(err, tryPerformRequest, reject))
	}).catch(_errorHandler)

/***************************************************************
 * Reauthentication
 ***************************************************************/
const _reauthenticate = (err: AxiosError, _retry: () => AxiosPromise, reject: any) => {
	if (err.response && err.response.status === 401) {
		const store = getStore()
		if (store) {
			store.dispatch(authenticationActions.setSignedIn(false))
			sessionStorageService.write(e_OperatorStorageKeys.persistSession, false)
		}
	} else {
		reject(err)
	}
}
