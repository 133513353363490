import React, { useMemo } from 'react'
import { HeaderControl } from './HeaderControl'
import type { IDataPropertyDescription } from '../types/IDataPropertyDescription'
import type { ISortedColumn } from '../types/ISortedColumn'
import { createStyle } from '../../../theming'
import type { IDataWithIdentifier } from '../types/IDataWithIdentifier'
import type { IListItem } from '../../../controls/List'
import { List } from '../../../controls/List'
import type { IActionEntry } from '../types/IActionDescription'

const classes = createStyle((theme) => ({
	masterPanel: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},

	withBorder: {
		background: 'white',
		border: '1px solid silver',
		borderRadius: '5px',
	},

	withShadow: {
		boxShadow: `0 6.4px 14.4px 0 rgba(${'0,0,0'}, 0.132), 0 1.2px 3.6px 0 rgba(${'0,0,0'}, 0.108)`,
	},

	leftMargin: {
		marginLeft: '16px',
	},

	rightMargin: {
		marginLeft: '16px',
	},

	masterPanelList: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		overflowX: 'hidden',
		overflowY: 'auto',
		alignItems: 'stretch',
		background: 'white',
	},

	item: {
		display: 'flex',
		padding: '4px 0px',
		userSelect: 'none',
	},

	avatar: {
		background: theme.colors.appBar.background,
		color: theme.colors.appBar.text,
		fontSize: '12px',
		marginRight: '8px',
		alignSelf: 'center',
		minWidth: '32px',
		maxWidth: '32px',
		height: '32px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		fontWeight: 600,
	},
	content: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'center',
		gap: '4px',
		textAlign: 'left',
	},

	itemName: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},

	summaryContent: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: '12px',
		color: 'rgba(0,0,0,0.5)',
	},
}))

interface IMasterPanelProps {
	title: string
	items: IDataWithIdentifier[]

	selectedIds: string[]
	setSelectedIds: (ids: string[]) => void

	activeId: string | undefined
	setActiveId: (id: string | undefined) => void
	back?: () => void
	width?: number

	itemName?: string | undefined | ((item: IDataWithIdentifier) => string | undefined)
	avatarContent?: string | undefined | ((item: IDataWithIdentifier) => JSX.Element | string | undefined)
	summaryContent?: string | undefined | ((item: IDataWithIdentifier) => string | undefined)

	columns: IDataPropertyDescription[]
	sortedColumns?: ISortedColumn[]
	sortColumns?: (sortedColumns: ISortedColumn[]) => void

	filterText?: string
	setFilterText?: (filterTExt: string) => void

	isMultiSelect?: boolean
	setIsMultiSelect?: (multiSelect: boolean) => void

	selectedItemsActions?: IActionEntry[]

	handleSelectedItemsAction?: (actionId: string, itemIds: string[]) => void
	globalActions?: IActionEntry[]
	handleGlobalAction?: (actionId: string) => void
}

export const MasterPanel = React.forwardRef((props: IMasterPanelProps, forwardedRef: React.Ref<HTMLDivElement>) => {
	const getItemName = (item: IDataWithIdentifier) => {
		if (!props.itemName) {
			return item.id
		}

		if (typeof props.itemName === 'string') {
			return item[props.itemName]
		}

		if (typeof props.itemName === 'function') {
			return props.itemName(item)
		}
	}

	const getAvatarContent = (item: IDataWithIdentifier) => {
		if (!props.avatarContent) {
			return '?'
		}

		if (typeof props.avatarContent === 'string') {
			return item[props.avatarContent]
		}

		if (typeof props.avatarContent === 'function') {
			return props.avatarContent(item)
		}
	}

	const getSummaryContent = (item: IDataWithIdentifier) => {
		if (!props.summaryContent) {
			return ''
		}

		if (typeof props.summaryContent === 'string') {
			return item[props.summaryContent]
		}

		if (typeof props.summaryContent === 'function') {
			return props.summaryContent(item)
		}
	}

	const listItems: IListItem[] = useMemo(() => {
		return props.items.map((item) => {
			return {
				id: item.id,
				data: item,
				screenTip: getItemName(item)?.toString() || undefined,
			}
		})
	}, [props.items])

	const renderListItem = (item: IListItem & { isSelected: boolean; isDisabled: boolean; isFocused?: boolean }) => {
		const data = item.data as IDataWithIdentifier

		const avatarContent = getAvatarContent(data)

		return (
			<div key={data.id} className={classes.item}>
				<div className={classes.avatar}>{avatarContent}</div>
				<div className={classes.content}>
					<span className={classes.itemName}>{getItemName(data)}</span>
					<span className={classes.summaryContent}>{getSummaryContent(data)}</span>
				</div>
			</div>
		)
	}

	return (
		<div
			className={classes.masterPanel}
			style={{
				width: props.width ? `${props.width}px` : undefined,
				flex: props.width ? 'unset' : 1,
			}}
			ref={forwardedRef}
		>
			<HeaderControl
				title={props.title}
				back={props.back}
				filterText={props.filterText}
				setFilterText={props.setFilterText}
				columns={props.columns}
				sortedColumns={props.sortedColumns}
				sortColumns={props.sortColumns}
				isMultiSelect={props.isMultiSelect}
				setIsMultiSelect={props.setIsMultiSelect}
				selectedItemsActions={props.selectedItemsActions}
				handleSelectedItemsAction={props.handleSelectedItemsAction}
				globalActions={props.globalActions}
				handleGlobalAction={props.handleGlobalAction}
				selectedIds={props.selectedIds}
				compactView
			/>

			<List
				selectionMode={props.isMultiSelect ? 'checkBox' : 'single'}
				items={listItems}
				selectedItemIds={props.selectedIds}
				onSelection={props.setSelectedIds}
				onRenderItem={renderListItem}
				onActivate={props.setActiveId}
				activateOnDoubleClick={false}
				itemFillWidth
			/>
		</div>
	)
})

MasterPanel.displayName = 'MasterPanel'
