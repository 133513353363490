import type { TData } from '../../Table.types'
import { e_RenderType } from '../../Table.types'
import type { ColDef, ColGroupDef, Column, GridApi } from '@ag-grid-community/core'
import { LEGAL_KEYS_EDIT_MODE } from '../../consts'
import { e_Interpretation } from '../../enums/e_Interpretation'

export const isEditable = (readOnly: boolean, isFooter: boolean, isAvatarCell: boolean) =>
	!readOnly && !isFooter && !isAvatarCell

// Validate if renderer is of type `<Avatar />`
export const isAvatarControl = (renderType: e_RenderType) => renderType === e_RenderType.avatar

export const hasDefaultAction = (renderType: e_RenderType, interpretation: e_Interpretation) =>
	renderType === e_RenderType.text &&
	[e_Interpretation.email, e_Interpretation.internetUrl, e_Interpretation.phone].includes(interpretation)

// Validate if renderer is of type `<Checkbox />`
export const isCheckMarkControl = (renderType: e_RenderType | undefined) => renderType === e_RenderType.checkMark

// Validate if code is a letter, number, symbol or F2
export function isTypingLetter(code: string) {
	let isTyping = false
	LEGAL_KEYS_EDIT_MODE.every((legalKey) => {
		if (code.includes(legalKey)) {
			isTyping = true
			return false
		}

		return true
	})

	return isTyping
}

// Function used to focus a new Cell based on custom rules.
export function focusNextCell(
	api: GridApi<TData>,
	column: Column | undefined,
	key: 'enter' | 'tab',
	event: KeyboardEvent
) {
	const currentRowIndex = api.getFocusedCell()?.rowIndex
	let columnIdToFocus = column?.getColId()

	if (currentRowIndex === undefined || columnIdToFocus === undefined) {
		return
	}

	const topLevelcolumns = api.getColumnDefs()?.filter((column: ColDef<TData>) => !column.hide && !column.rowGroup)

	const columns = getLeafColumns(topLevelcolumns).filter((column: ColDef<TData>) => !column.hide)

	const columnIndex = columns.findIndex((column: ColDef<TData>) => column.colId === columnIdToFocus)

	if (columnIndex === -1) {
		return
	}

	if (
		!event.shiftKey &&
		key === 'tab' &&
		columnIndex === columns.length - 1 &&
		currentRowIndex === api.getDisplayedRowCount() - 1
	) {
		return
	} else if (event.shiftKey && key === 'tab' && columnIndex === 0 && currentRowIndex === 0) {
		return
	} else {
		event.preventDefault()
	}

	const numberOfRows = api.getDisplayedRowCount()

	const { newRowIndex, newColumnIndex } = moveIndices(
		key,
		currentRowIndex,
		numberOfRows,
		event.shiftKey,
		columnIndex,
		columns
	)

	columnIdToFocus = (columns[newColumnIndex] as ColDef<TData>)?.colId ?? columnIdToFocus

	const rowToFocus = api.getDisplayedRowAtIndex(newRowIndex)

	if (rowToFocus !== undefined) {
		api.setFocusedCell(rowToFocus.rowIndex || 0, columnIdToFocus)
		rowToFocus?.setSelected(true, true)
		api.ensureIndexVisible(newRowIndex)
		api.ensureColumnVisible(columnIdToFocus)
	}
}

function getLeafColumns(topLevelcolumns: (ColDef<TData> | ColGroupDef<TData>)[] | undefined): ColDef<TData>[] {
	if (topLevelcolumns === undefined) {
		return []
	}
	return topLevelcolumns?.reduce((acc: ColDef<TData>[], column) => {
		if ('children' in column && column.children) {
			return [...acc, ...getLeafColumns(column.children)]
		}

		return [...acc, column]
	}, [])
}

function moveIndices(
	key: string,
	currentRowIndex: number,
	numberOfRows: number,
	shiftKey: boolean,
	columnIndex: number | undefined,
	columns: ColDef<TData, any>[]
): { newRowIndex: number; newColumnIndex: number } {
	if (key === 'enter') {
		const rowIndexShift = shiftRowIndexByEnter(currentRowIndex, numberOfRows, shiftKey)
		return { newRowIndex: currentRowIndex + rowIndexShift, newColumnIndex: columnIndex ?? 0 }
	}
	const rowIndexShift = shiftRowIndexByTab(columnIndex, columns, shiftKey, currentRowIndex, numberOfRows)
	const newColumnIndex = getColumnIndex(columnIndex, columns, shiftKey, rowIndexShift)
	return { newRowIndex: currentRowIndex + rowIndexShift, newColumnIndex }
}

function shiftRowIndexByEnter(currentRowIndex: number, numberOfRows: number, shiftKey: boolean) {
	if ((currentRowIndex === numberOfRows - 1 && !shiftKey) || (currentRowIndex === 0 && shiftKey)) {
		return 0
	}
	return shiftKey ? -1 : 1
}

/**
 * Shifts row if its either the first (+ shift) or the last column that is tabbed
 */
function shiftRowIndexByTab(
	columnIndex: number | undefined,
	columns: ColDef<TData, any>[],
	shiftKey: boolean,
	currentRowIndex: number,
	numberOfRows: number
) {
	// next column down, if not already at bottom
	if (columnIndex === columns.length - 1 && !shiftKey && currentRowIndex !== numberOfRows - 1) {
		return 1
	}
	//next column up, if not already at top
	if (columnIndex === 0 && shiftKey && currentRowIndex !== 0) {
		return -1
	}
	return 0
}

function getColumnIndex(
	columnIndex: number | undefined,
	columns: ColDef<TData, any>[],
	shiftKey: boolean,
	rowIndexShift: number
): number {
	//Either last or first column, but row is not changed: Keep the same column
	if (((columnIndex === columns.length - 1 && !shiftKey) || (columnIndex === 0 && shiftKey)) && rowIndexShift === 0) {
		return columnIndex
	}
	//Last column tabbed, select first column. Row will be moved one down
	if (columnIndex === columns.length - 1 && !shiftKey) {
		return 0
	}

	if (columnIndex === 0 && shiftKey) {
		//First column shift tabbed, select last column. Row will be moved one up
		return columns.length - 1
	}

	// TODO PBU 02.01.2024: See if we can return null in order to handle the case where there is no column index
	if (columnIndex === undefined) {
		return 0
	}

	//Indicating normal column shift left and right
	if (shiftKey) {
		return columnIndex - 1
	}
	return columnIndex + 1
}

export function getContextMenuButtonPlacement(
	hasColumnContextMenu: boolean,
	contextMenuButton: 'inline' | 'firstColumn' | 'lastColumn' | undefined,
	isFooter: boolean,
	editingModeWithHiddenContextMenu: boolean
) {
	if (!isFooter && !editingModeWithHiddenContextMenu) {
		if (hasColumnContextMenu || contextMenuButton === 'inline') {
			return 'after'
		} else if (contextMenuButton && ['firstColumn', 'lastColumn'].includes(contextMenuButton)) {
			return 'before'
		}
	}
}

export function getFormControlButtonIcon(renderType: e_RenderType, interpretation: e_Interpretation) {
	switch (renderType) {
		case e_RenderType.date:
			return 'Fluent-Event'
		case e_RenderType.dropdown:
			return 'Fluent-ChevronDown'
		case e_RenderType.duration:
			return interpretation === e_Interpretation.integerTime ? 'Fluent-ChevronDown' : undefined
		case e_RenderType.lookup:
			return 'Fluent-Search'
		default:
			return undefined
	}
}
