import { e_NavigationItem } from 'src/enums/e_NavigationItems'
import { e_OperatorResources } from 'src/enums/e_OperatorResources'
import { IConfig } from 'src/interfaces/IConfig'
import { IControlLoopStatus } from 'src/interfaces/IControlLoopStatus'
import { IOperatorErrorLog } from 'src/interfaces/IErrorLog'
import { IPod } from 'src/interfaces/IPod'
import {
	IClearOperatorErrorLog,
	IFetchConfig,
	IFetchK8sRuntimes,
	IFetchOperatorErrorLog,
	ISetAutoReload,
	ISetControlLoopStatus,
	ISetKubernetesVersion,
	ISetUserInfo,
	ISetNavigationItem,
	ISetSelectedK8sRuntime,
	ISetSelectedK8sRuntimeName,
	ISetSelectedK8sRuntimeNames,
	ISetSelectedPods,
	ISetSelectedWorkloadController,
	IStart,
	IStartContinuousUpdate,
} from './IOperatorFrontendActions'
import { operatorFrontendTypes } from './operatorFrontendTypes'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'
import { IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { IUserInfo } from 'src/interfaces/IUserInfo'

const start = (): IStart => ({
	type: operatorFrontendTypes.START,
})

const startContinuousUpdate = (): IStartContinuousUpdate => ({
	type: operatorFrontendTypes.CONTINUOUS_UPDATE,
})
const setInitialized = () => ({
	type: operatorFrontendTypes.SET_INITIALIZED,
})

const fetchConfig = (): IFetchConfig => ({ type: operatorFrontendTypes.FETCH_CONFIG })
const setConfig = (config: IConfig) => ({ type: operatorFrontendTypes.SET_CONFIG, config })

const setKubernetesVersion = (kubernetesVersion: string): ISetKubernetesVersion => ({
	type: operatorFrontendTypes.SET_KUBERNETES_VERSION,
	kubernetesVersion,
})

const setUserInfo = (userInfo: IUserInfo): ISetUserInfo => ({
	type: operatorFrontendTypes.SET_USER_INFO,
	userInfo,
})

const setSelectedK8sRuntimes = (k8sRuntimeNames: string[]): ISetSelectedK8sRuntimeNames => ({
	type: operatorFrontendTypes.SET_SELECTED_K8S_RUNTIME_NAMES,
	k8sRuntimeNames,
})

const setSelectedK8sRuntime = (k8sRuntime: IK8sRuntime): ISetSelectedK8sRuntime => ({
	type: operatorFrontendTypes.SET_SELECTED_K8S_RUNTIME,
	k8sRuntime,
})

const setSelectedPods = (pods: IPod[]): ISetSelectedPods => ({
	type: operatorFrontendTypes.SET_SELECTED_PODS,
	pods,
})

const setSelectedWorkloadController = (
	workloadController: IWorkloadController | null
): ISetSelectedWorkloadController => ({
	type: operatorFrontendTypes.SET_SELECTED_WORKLOAD_CONTROLLER,
	workloadController,
})

const fetchK8sRuntimes = (): IFetchK8sRuntimes => ({ type: operatorFrontendTypes.FETCH_K8S_RUNTIMES })

const setSelectedK8sRuntimeName = (k8sRuntime: string): ISetSelectedK8sRuntimeName => ({
	type: operatorFrontendTypes.SET_SELECTED_K8S_RUNTIME_NAME,
	k8sRuntime,
})

const setNavigationItem = (navigationItem: e_NavigationItem, selection?: string): ISetNavigationItem => ({
	type: operatorFrontendTypes.SET_NAVIGATION_ITEM,
	navigationItem,
	selection,
})

const setControlLoopStatus = (controlLoopStatus: IControlLoopStatus): ISetControlLoopStatus => ({
	type: operatorFrontendTypes.SET_CONTROL_LOOP_STATUS,
	controlLoopStatus,
})
const setAutoReload = (resource: e_OperatorResources, value: boolean): ISetAutoReload => ({
	type: operatorFrontendTypes.SET_AUTO_RELOAD,
	resource,
	value,
})

const fetchOperatorErrorLog = (): IFetchOperatorErrorLog => ({
	type: operatorFrontendTypes.FETCH_OPERATOR_ERROR_LOG,
})

const clearOperatorErrorLog = (): IClearOperatorErrorLog => ({
	type: operatorFrontendTypes.CLEAR_OPERATOR_ERROR_LOG,
})

const setOperatorErrorLog = (operatorErrorLog: IOperatorErrorLog) => ({
	type: operatorFrontendTypes.SET_OPERATOR_ERROR_LOG,
	operatorErrorLog,
})

export const operatorFrontendActions = {
	start,
	startContinuousUpdate,
	setInitialized,
	fetchConfig,
	setConfig,
	setKubernetesVersion,
	setUserInfo,
	setSelectedK8sRuntimes,
	setSelectedPods,
	setSelectedWorkloadController,
	setSelectedK8sRuntimeName,
	setSelectedK8sRuntime,
	setNavigationItem,
	setControlLoopStatus,
	fetchK8sRuntimes,
	setAutoReload,
	fetchOperatorErrorLog,
	clearOperatorErrorLog,
	setOperatorErrorLog,
}
