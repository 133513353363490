import React from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'

const classes = createStyle((theme) => ({
	borderRight: {
		'&:not(:last-child)': {
			borderRight: '1px solid ' + theme.colors.button.border,
		},
	},
	toggleButton: {
		flex: 1,
		lineHeight: '19px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minWidth: 36,

		fontSize: theme.typography.body1.fontSize,
		fontWeight: theme.typography.body1.fontWeight,
		cursor: 'pointer',
		color: theme.colors.button.text,
		background: 0,
		userSelect: 'none',
		outline: 0,
		border: '1px solid transparent',
		'&:focus-visible': {
			border: '1px solid ' + theme.colors.body.focusBorder,
		},
	},
	enabled: {
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.button.hoveredBackground,
			color: theme.colors.button.hoveredText,
		},
	},
	disabled: {
		cursor: 'default',
		opacity: 0.5,
	},
	active: {
		'&.active': {
			backgroundColor: theme.colors.button.pressedBackground,
			color: theme.colors.button.pressedText,
		},
	},
	hidden: {
		position: 'relative',
		visibility: 'hidden',
		height: 0,
	},
}))

interface IToggleButtonProps {
	children?: React.ReactNode | React.ReactNode[]
	disabled?: boolean
	hidden?: boolean
	isActive: boolean
	onClick: () => void
	screenTip?: string
	className?: string
	disableBorderRight?: boolean
}

export const ToggleButton = (props: IToggleButtonProps) => {
	return (
		<button
			className={classNames(
				classes.toggleButton,
				{
					[classes.enabled]: !props.disabled,
					[classes.disabled]: props.disabled,
					[classes.hidden]: props.hidden,
					[classes.active]: props.isActive,
					['active']: props.isActive,
					[classes.borderRight]: !props.disableBorderRight,
				},
				props.className
			)}
			disabled={props.disabled}
			onClick={(e: React.MouseEvent) => {
				e.stopPropagation()
				props.onClick()
			}}
			title={props.screenTip}
		>
			{props.children}
		</button>
	)
}
