import React, { useLayoutEffect, useRef } from 'react'
import { createStyle } from '../../../theming'
import { getCssString } from '../styles'
import { generateNanoId } from '../../utils/generateNanoId'

const classes = createStyle({
	container: {
		overflow: 'auto',
	},
})

interface IProps {
	shouldInheritTheme?: boolean
	children: React.ReactNode | React.ReactNode[]
}

export const RichTextStyles = (props: IProps) => {
	const { children, shouldInheritTheme } = props

	const id = useRef('rt_' + generateNanoId())

	useLayoutEffect(() => {
		const style = document.createElement('style')
		style.innerHTML = getCssString(id.current, shouldInheritTheme)
		document.head.appendChild(style)
		return () => {
			document.head.removeChild(style)
		}
	}, [shouldInheritTheme])

	return (
		<div id={id.current} className={classes.container}>
			{children}
		</div>
	)
}
