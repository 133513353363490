import { useRef } from 'react'

import { readColumnStateFromSessionStorage, writeColumnStateToSessionStorage } from '../utils/storageUtils'
import type { ColumnState } from '@ag-grid-community/core'

export const useOnColumnStateChanged = (id: string | undefined, onColumnStateChanged: (() => void) | undefined) => {
	// Store original column state to detect if the grid recieves a new column state during a session
	const originalColumnState = useRef<ColumnState[]>()

	// Called from Grid Callbacks that affect Column state
	return (columnState: ColumnState[], updatedResizedColumnIds?: string[], isGridReadyEvent = false) => {
		onColumnStateChanged?.()

		// Only handle session storage when Table id is included
		if (!id) {
			return
		}
		const columnStateFromStorage = readColumnStateFromSessionStorage(id)

		if (isGridReadyEvent) {
			// Store original Column Defs in storage when a session starts.
			// If we have a missmatch in Column Defs during a session, we need to delete session storage and use the newest Column Defs
			originalColumnState.current = columnStateFromStorage?.originalColumnState ?? columnState
		} else {
			const resizedColumnIds = updatedResizedColumnIds ?? (columnStateFromStorage?.resizedColumnIds || [])

			writeColumnStateToSessionStorage(id, {
				columnState,
				originalColumnState: originalColumnState.current ?? [],
				resizedColumnIds: Array.from(new Set(resizedColumnIds)),
			})
		}
	}
}
