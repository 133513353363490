import type { ChangeEvent } from 'react'
import React, { useState, useRef } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'

import { MultiValueInputChipList } from './MultiValueInputChipList'
import { useForwardedRef } from '../utils/useForwardedRef'
import type { MultiValueInputItem } from './MultiValueInput.types'

const classes = createStyle((theme) => ({
	wrap: {
		display: 'flex',
		justifyContent: 'space-between',
		flex: 1,
	},
	multiValueInput: {
		width: '100%',
		overflowY: 'auto',
		cursor: 'text',
		display: 'flex',
		flexWrap: 'wrap',
	},
	multiValueInputDisabled: {
		cursor: 'default',
		background: theme.colors.input.disabledBackground,
	},
	multiValueInputReadOnly: {
		cursor: 'default',
	},
	inputWrapper: {
		display: 'flex',
		flex: 1,
		'&:focus': {
			outline: 'none',
		},
	},
	input: {
		fontWeight: 'inherit',
		flex: 1,
		textOverflow: 'ellipsis',
		'&::placeholder': {
			color: theme.colors.input.placeholderText,
		},
	},
	hiddenInput: {
		position: 'absolute',
		visibility: 'hidden',
		height: 0,
		overflow: 'hidden',
		whiteSpace: 'pre',
	},
}))

export interface IMultiValueInputProps<T> {
	id?: string
	dataAttributes?: Record<string, string | undefined>
	placeholder?: string
	inputValue: string
	displayTagLabel?: string
	tags: MultiValueInputItem<T>[]
	className?: string
	disabled?: boolean
	readOnly?: boolean
	defaultAction?: () => void
	onSelection: (itemIds: T[]) => void
	onMouseDown?: (e: React.MouseEvent) => void
	onClick?: (e: React.MouseEvent) => void
	onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void
	onInputFocus?: () => void
	onInputBlur?: () => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void
	name?: string
	// Fake direct prop "ref" interface that should have been extended by React.forwardRef,
	// but because of lacking proper higher-order type inference generics are lost
	// so typecasting of wrapped component is necessary
	ref?: React.Ref<HTMLInputElement>
	dropdownListId?: string
	dropdownIsOpen?: boolean
	activeDropdownItem?: string
}

function MultiValueInputWithRef<T>(props: IMultiValueInputProps<T>, ref: React.Ref<HTMLInputElement>) {
	const inputRef = useForwardedRef<HTMLInputElement>(ref)
	const wrapperRef = useRef<HTMLInputElement>(null)

	const [currentIndex, setCurrentIndex] = useState(-1)

	const focusInputField = () => {
		inputRef.current?.focus()
	}

	const onMouseDown = (event: React.MouseEvent) => {
		if (props.disabled || props.readOnly) {
			return
		}

		props.onMouseDown?.(event)
	}

	const onClick = (event: React.MouseEvent) => {
		if (props.disabled || props.readOnly) {
			return
		}

		setCurrentIndex(-1)

		props.onClick?.(event)

		scrollToBottom()
	}

	const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		switch (e.key) {
			case 'k':
				if (e.ctrlKey) {
					e.preventDefault()
					props.defaultAction?.()
				}
				break
			case 'Enter':
				e.preventDefault()
				props.defaultAction?.()
				break
			case 'Backspace':
				if (props.tags.length && inputRef.current?.selectionEnd === 0) {
					e.preventDefault()
					deleteItem(props.tags.length - 1)
				}
				break
			case 'ArrowLeft':
				if (props.tags.length && inputRef.current?.selectionEnd === 0) {
					e.preventDefault()
					selectNewItem(props.tags.length - 1)
				}
				break
			case 'Home':
				if (props.tags.length && inputRef.current?.selectionEnd === 0) {
					selectNewItem(0)
				}
				break
			default:
				props.onKeyDown?.(e)
		}
	}

	const selectNewItem = (index: number) => {
		setCurrentIndex(index)
		if (index === -1) {
			focusInputField()
		}
	}

	const deleteItem = (index: number) => {
		const selectedItemValues = props.tags.map((tag) => tag.value)
		if (index > -1 && index <= selectedItemValues.length) {
			setCurrentIndex(-1)
			focusInputField()
			selectedItemValues.splice(index, 1)
			props.onSelection(selectedItemValues)
		}
	}

	const scrollToBottom = () => {
		if (wrapperRef.current !== null) {
			wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight
		}
	}

	const multiValueInputClassNames = classNames(
		{
			[classes.multiValueInput]: true,
			[classes.multiValueInputDisabled]: props.disabled,
			[classes.multiValueInputReadOnly]: props.readOnly,
		},
		props.className
	)

	const showPlaceholder = props.tags.length === 0 ? props.placeholder : undefined

	return (
		<div className={classes.wrap}>
			<div className={multiValueInputClassNames} onMouseDown={onMouseDown} onClick={onClick} ref={wrapperRef}>
				<MultiValueInputChipList
					id={props.id}
					displayChipLabel={props.displayTagLabel}
					chips={props.tags}
					currentIndex={currentIndex}
					selectNewChip={selectNewItem}
					onDeleteChip={deleteItem}
					onBlur={() => setCurrentIndex(-1)}
					maxWidth={(wrapperRef.current && `${wrapperRef.current.offsetWidth - 32}px`) || undefined}
					disabled={props.disabled}
					readOnly={props.readOnly}
				/>
				<div className={classes.inputWrapper} tabIndex={-1}>
					<input
						id={props.id}
						{...props.dataAttributes}
						placeholder={showPlaceholder}
						autoComplete="off" // autoComplete="off" does now work with Chrome
						spellCheck="false"
						role="combobox"
						aria-controls={props.dropdownIsOpen ? props.dropdownListId : undefined}
						aria-activedescendant={props.dropdownIsOpen ? props.activeDropdownItem : undefined}
						className={classes.input}
						value={props.inputValue}
						onChange={props.onInputChange}
						onKeyDown={onKeyDown}
						onFocus={props.onInputFocus}
						onBlur={props.onInputBlur}
						ref={inputRef}
						disabled={props.disabled}
						readOnly={props.readOnly}
						name={props.name}
					/>
					<div className={classes.hiddenInput}>{props.inputValue}</div>
				</div>
			</div>
		</div>
	)
}

MultiValueInputWithRef.displayName = 'MultiValueInput'

export const MultiValueInput = React.forwardRef(MultiValueInputWithRef) as typeof MultiValueInputWithRef
