import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'

import type { IFormControl } from '../FormControl'
import { FormControl, FormControlButton } from '../FormControl'
import type { DropdownMenuItem, IDropdownOption } from './Dropdown.types'
import { DropdownList } from './DropdownList'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useStyleContext } from '../utils/Style.context'
import { useID } from '../utils/useID'
import { useTranslation } from '../../translation'

import type { DropdownOptionSelectable } from './DropdownOptionsHandler'
import { DropdownOptionsHandler, isSelectableOption } from './DropdownOptionsHandler'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { ReadOnlyIndicator } from '../FormControl/ReadOnlyIndicator'

const classes = createStyle((theme) => ({
	linkedValue: {
		color: theme.colors.links.text + '!important',
		'&:hover': {
			textDecoration: 'underline',
			color: theme.colors.links.hoveredText,
		},
	},
	input: {
		fontWeight: 'inherit',
		display: 'flex',
		flex: 1,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		alignItems: 'center',
		padding: '1px 0',
	},
	enabled: { cursor: 'auto' },
	buttonWrapper: { display: 'flex' },
	hidden: { visibility: 'hidden' },
}))

interface IDropdownInputBase<T> extends IFormControl {
	displayLabel?: string
	initialFilterTerm?: string
	onCtrlClick?: (e: React.MouseEvent, value: T | null | undefined) => void
	options?: IDropdownOption<T>[]
	isLoadingOptions?: boolean
	defaultOpen?: boolean
	onOpen?: () => void
	onClose?: () => void
	screenTip?: string
	placeholder?: string
	filterOperator?: 'startsWith' | 'includes'
	style?: React.CSSProperties
	className?: string
	reserveIconSpace?: boolean
	restrictValueToOptions?: boolean
	headerLeftItems?: DropdownMenuItem<T>[]
	headerRightItems?: DropdownMenuItem<T>[]
	footerLeftItems?: DropdownMenuItem<T>[]
	footerRightItems?: DropdownMenuItem<T>[]
	// Fake direct prop "ref" interface that should have been extended by React.forwardRef,
	// but because of proper lacking higher-order type inference generics are lost
	// so typecasting of wrapped component is necessary
	ref?: React.Ref<HTMLInputElement>
	emptyListMessage?: string
	maxHeight?: number // Max height of Dropdown in pixels
}

interface IDropdownInput<T> extends IDropdownInputBase<T> {
	value: T | undefined
	onChange?: (value: T, immediate?: boolean) => void
	allowNull?: false
}

interface IDropdownInputNullable<T> extends IDropdownInputBase<T> {
	value: T | undefined | null
	onChange?: (value: T | null, immediate?: boolean) => void
	allowNull: true
}

function DropdownInputWithForwardRef<T>(
	props: IDropdownInput<T> | IDropdownInputNullable<T>,
	ref: React.Ref<HTMLInputElement>
) {
	const { filterOperator = 'startsWith', restrictValueToOptions = true, defaultOpen = false, onClose } = props

	const { tcvi } = useTranslation()
	const id = useID(props.id)
	const dropdownListId = useID()
	const options = new DropdownOptionsHandler(props.options, props.allowNull, tcvi, props.emptyListMessage)

	const [isOpen, setIsOpen] = useState(!!props.initialFilterTerm || defaultOpen)
	const [filterTerm, setFilterTerm] = useState(props.initialFilterTerm)
	const [focusedOrHovered, setFocusedOrHovered] = useState(false)
	const [activeDescendant, setActiveDescendant] = useState<string | undefined>(undefined)

	const inputRef = useForwardedRef<HTMLInputElement>(ref)
	const anchorElementRef = useRef(null)
	const openedByTouch = useRef(false)

	const displayText = useMemo(() => {
		if (props.value === null) {
			return ''
		}
		const selectedValue = options.find((item) => {
			return isSelectableOption(item) && item.value === props.value
		}) as DropdownOptionSelectable<T>
		if (selectedValue) {
			return selectedValue.label
		}
		if (!restrictValueToOptions && props.value) {
			return String(props.value)
		}
	}, [props.options, props.value])

	let filtered: DropdownOptionsHandler<T>
	if (filterTerm) {
		filtered = options.filter(filterTerm, filterOperator)
	} else {
		filtered = options
	}

	const preselectedIndex = filtered.findIndex(
		(item) => isSelectableOption(item) && item.value === props.value && item.type !== 'null'
	)
	const firstAvailableItemIndex = filtered.findIndex((item) => isSelectableOption(item) && item.type !== 'null')
	const firstAvailableItem: any = filtered.get(firstAvailableItemIndex)
	const inputValue = getInputValue(filterTerm, displayText || props.displayLabel, isOpen)

	const { dynamicsStyle, placeholder, readOnlyIndicatorStyle } = { ...useStyleContext().style?.input }
	const renderDropdownButton = !dynamicsStyle || (focusedOrHovered && !props.disabled && !props.readOnly)

	useEffect(() => {
		if (defaultOpen) {
			props.onOpen?.()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const openDropdown = () => {
		props.onOpen?.()
		setIsOpen(true)
	}

	const closeDropdown = useCallback(() => {
		setIsOpen(false)
		setFilterTerm(undefined)
		onClose?.()
		openedByTouch.current = false
	}, [onClose])

	const onInputClick = (e: React.MouseEvent) => {
		if ((e.ctrlKey || e.metaKey) && props.onCtrlClick) {
			props.onCtrlClick?.(e, props.value)
			e.preventDefault()
			e.stopPropagation()
			return
		}

		if (props.readOnly) {
			return
		}

		if (!isOpen) {
			openDropdown()
		} else {
			closeDropdown()
		}
	}

	const onOptionSelect = (value: T | null) => {
		if (props.readOnly) {
			return
		}
		if (value !== null) {
			props.onChange?.(value, true)
		} else if (props.allowNull) {
			props.onChange?.(value, true)
		}
		closeDropdown()
	}

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.readOnly) {
			return
		}
		setFilterTerm(e.target.value)

		if (restrictValueToOptions) {
			return
		}

		if (e.target.value === '' && props.allowNull) {
			props.onChange?.(null, true)
		} else {
			props.onChange?.(e.target.value as any)
		}
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (!isOpen && !['Enter', 'Escape', 'Shift', 'Tab', 'Control', 'Meta'].includes(e.key)) {
			openDropdown()
		}

		// Closing of Dropdown is done by keydown-eventlistener in DropdownList or in onOptionSelect
	}

	return (
		<React.Fragment>
			<FormControl
				id={id}
				label={props.label}
				labelPosition={props.labelPosition}
				labelProps={props.labelProps}
				icon={props.icon}
				labelContentLayout={props.labelContentLayout}
				hideLabel={props.hideLabel}
				ref={anchorElementRef}
				screenTip={props.screenTip}
				labelSubText={props.labelSubText}
				validationText={props.validationText}
				validationTextPosition={props.validationTextPosition}
				subText={props.subText}
				reserveHelperTextSpace={props.reserveHelperTextSpace}
				disabled={props.disabled}
				readOnly={props.readOnly}
				required={props.required}
				margin={props.margin}
				error={props.error}
				warning={props.warning}
				className={props.className}
				stretch={props.stretch}
				disableBorder={props.disableBorder}
				onFocusedOrHovered={setFocusedOrHovered}
			>
				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignLeft />
				)}

				<input
					id={id}
					{...props.dataAttributes}
					tabIndex={props.disabled ? -1 : undefined}
					role="combobox"
					aria-haspopup="listbox"
					aria-expanded={isOpen}
					aria-controls={isOpen ? dropdownListId : undefined}
					aria-activedescendant={isOpen ? activeDescendant : undefined}
					autoComplete="off"
					spellCheck="false"
					className={classNames(classes.input, {
						[classes.linkedValue]: props.onCtrlClick,
						[classes.enabled]: !props.disabled,
					})}
					onTouchStart={() => (openedByTouch.current = true)}
					onClick={onInputClick}
					onFocus={() => inputRef.current?.select()}
					onChange={onInputChange}
					onKeyDown={onKeyDown}
					placeholder={props.placeholder ?? placeholder}
					ref={inputRef}
					value={inputValue}
					type="text"
					readOnly={props.readOnly}
					disabled={props.disabled}
					name={props.name}
					style={props.style}
				/>

				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
				)}

				{!(props.readOnly && dynamicsStyle) && (
					<FormControlButton
						disabled={props.disabled || props.readOnly}
						iconClassName="Fluent-ChevronDown"
						showSpinner={props.isLoadingOptions}
						onMouseDown={(e) => isOpen && e.preventDefault()}
						onClick={isOpen ? closeDropdown : openDropdown}
						tabStop={false}
						iconSize="extraSmall" // to avoid massive chevron
						className={classNames(classes.buttonWrapper, { [classes.hidden]: !renderDropdownButton })}
						dataAttributes={{ ['data-cy']: id + '-dropdown-input-button' }}
					/>
				)}
			</FormControl>
			<DropdownList
				anchorElement={anchorElementRef}
				maxHeight={props.maxHeight}
				isOpen={isOpen}
				isLoadingOptions={props.isLoadingOptions}
				options={filtered}
				value={props.value || (firstAvailableItem ? firstAvailableItem['value'] : undefined)}
				onChange={onOptionSelect}
				onClose={closeDropdown}
				multiSelect={false}
				preselectedIndex={preselectedIndex !== -1 ? preselectedIndex : firstAvailableItemIndex}
				style={props.style}
				className={props.className}
				reserveIconSpace={props.reserveIconSpace}
				disableSelectWithSpace
				restrictValueToOptions={props.restrictValueToOptions}
				headerLeftItems={props.headerLeftItems}
				headerRightItems={props.headerRightItems}
				footerLeftItems={props.footerLeftItems}
				footerRightItems={props.footerRightItems}
				emptyListMessage={props.emptyListMessage}
				id={dropdownListId}
				onActiveItemChange={setActiveDescendant}
				disableCloseOnViewportChange={openedByTouch.current}
			/>
		</React.Fragment>
	)
}

DropdownInputWithForwardRef.displayName = 'DropdownInput'

export const DropdownInput = React.forwardRef(DropdownInputWithForwardRef) as typeof DropdownInputWithForwardRef

function getInputValue(filterTerm: string | undefined, displayText: string | undefined, isOpen: boolean) {
	if (!isOpen) {
		return displayText || ''
	}
	return filterTerm !== undefined ? filterTerm : displayText || ''
}
