import React from 'react'

import type { IFormControl } from '../FormControl'
import { FormControl, ValueLabel } from '../FormControl'
import { createStyle } from '../../theming'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useID } from '../utils/useID'
import { InputHeight } from '../utils/InputHeight'
import clsx from 'clsx'

const classes = createStyle((theme) => ({
	checkbox: {
		background: 0,
		padding: '0px',
		alignItems: 'baseline',
		border: 'none',
		display: 'flex',
		cursor: 'pointer',
		color: 'inherit',
		outlineStyle: 'solid',
		outlineColor: 'transparent',
		outlineWidth: 1,
		outlineOffset: -1,
		fontWeight: 'inherit',
		// transition: `outline-color ${theme.transitions.duration.shorter}`,
		'&:disabled, &[aria-readonly="true"], &[aria-disabled="true"]': {
			cursor: 'default',
		},
		'&:focus-visible': { outlineColor: theme.colors.body.focusBorder },
	},
	checkboxInFormControl: {
		alignSelf: 'flex-start',
		position: 'relative',
		width: '100%',
	},
	checkboxInFormControlLabelReplacement: {
		marginRight: '0.325rem',
	},

	checkBoxPadding: {
		padding: theme.controls.input.padding,
	},

	checkboxSquare: {
		fontWeight: 'normal',
		display: 'flex',
		justifyContent: 'center',
		padding: 0,
		marginLeft: 2,
		transition: 'background 0.2s',
		alignSelf: 'center',
		alignItems: 'center',
		border: '1px solid ' + theme.colors.input.border,
		background: theme.colors.input.background,
		flex: '0 0 auto',

		'$checkbox:not(:disabled)[aria-checked="true"][aria-disabled="false"] &': {
			borderColor: theme.colors.input.checkedBackground,
			background: theme.colors.input.checkedBackground,
			'&:hover': {
				borderColor: theme.colors.input.checkedHoveredBackground,
				background: theme.colors.input.checkedHoveredBackground,
			},
		},
		'$checkbox:disabled[aria-disabled="true"] &': {
			borderColor: theme.colors.input.disabledBackground,
		},
		'$checkbox:disabled[aria-checked="true"]&': {
			background: theme.palette.background.neutralLight,
			opacity: 0.4,
		},
	},
	checkboxSquareDisabled: {
		'$checkbox[aria-checked="true"] &': {
			background: theme.controls.button.checkedColors.color,
		},
		opacity: 0.4,
		borderColor: theme.controls.input.disabledColors.border,
	},
	checkmark: {
		opacity: 0,
		marginTop: 1,
		transition: 'opacity ' + theme.transitions.duration.short,
		color: theme.colors.input.text,

		'$checkbox[aria-checked="true"][aria-disabled="false"] &': {
			color: theme.colors.input.background,
			opacity: 1,
		},
		'$checkbox[aria-checked="true"][aria-disabled="true"] &': {
			color: theme.colors.input.background,
			opacity: 1,
		},
		'$checkbox[aria-checked="mixed"] &': {
			opacity: 1,
		},
		'$checkbox:not(:disabled):hover[aria-checked="false"][aria-disabled="false"] &': {
			opacity: 0.4,
		},
		'$checkbox:disabled[aria-checked="true"] &, $checkbox:disabled[aria-checked="mixed"] &': {
			cursor: 'default',
			color: theme.colors.input.disabledText,
			opacity: 1,
		},
	},
	label: {
		display: 'block',
		marginLeft: '0.2rem',
		marginRight: 2,
	},
	wrapLabel: {
		textAlign: 'left',
		whiteSpace: 'pre-wrap',
		overflowWrap: 'break-word',
	},
}))

export type ICheckmarkSize = 18 | 26 | 38

interface ICheckboxProps extends IFormControl {
	id?: string
	className?: string
	valueLabel?: string
	readOnly?: boolean
	screenTip?: string
	hasCheckMarkOnly?: boolean
	disableUniformHeight?: boolean
	wordWrapValueLabel?: boolean
	checkmarkSize?: ICheckmarkSize
}

interface ICheckboxDeterminateProps extends ICheckboxProps {
	indeterminate?: false
	checked?: boolean
	onChange?: (val: boolean) => void
}

interface ICheckboxIndeterminateProps extends ICheckboxProps {
	indeterminate: true
	checked?: boolean | null
	onChange?: (val: boolean | null) => void
}

export const Checkbox = React.forwardRef(
	(props: ICheckboxDeterminateProps | ICheckboxIndeterminateProps, forwardedRef: React.Ref<HTMLButtonElement>) => {
		const { subText = '', checkmarkSize = 18 } = props
		const id = useID(props.id)

		const ref = useForwardedRef<HTMLButtonElement>(forwardedRef)

		const onClick = () => {
			if (props.indeterminate) {
				let val: boolean | null
				switch (props.checked) {
					case null:
						val = true
						break
					case true:
						val = false
						break
					case false:
						val = null
						break
					default:
						val = false
						break
				}
				props.onChange?.(val)
			} else {
				props.onChange?.(!props.checked)
			}
		}

		const checkboxSquareClasses = clsx(classes.checkboxSquare, { [classes.checkboxSquareDisabled]: props.disabled })

		const checkmarkSizeStyle = {
			fontSize: `${checkmarkSize - 4}px`,
			width: `${checkmarkSize}px`,
			height: `${checkmarkSize}px`,
		}

		const handleOnKeyDown = (e: React.KeyboardEvent) => {
			if (e.key === ' ') {
				e.preventDefault()
				props.onChange?.(!props.checked)
			}
		}

		const Checkmark = (
			<button
				id={id}
				{...props.dataAttributes}
				className={clsx(classes.checkbox, {
					[classes.checkBoxPadding]: props.disableUniformHeight && !props.hasCheckMarkOnly,
					[classes.checkboxInFormControl]: !props.hasCheckMarkOnly,
					[classes.checkboxInFormControlLabelReplacement]: !props.hasCheckMarkOnly && !props.valueLabel,
					[props.className || '']: props.className !== undefined && props.hasCheckMarkOnly,
				})}
				onClick={!props.disabled && !props.readOnly ? onClick : undefined}
				onKeyDown={handleOnKeyDown}
				role="checkbox"
				aria-checked={props.checked === null ? 'mixed' : props.checked === true}
				aria-labelledby={id ? id + '-label' : undefined}
				aria-describedby={id ? id + '-subText' : undefined}
				aria-disabled={props.disabled ? true : false}
				ref={ref}
				tabIndex={!props.disabled ? 0 : undefined}
				style={props.hasCheckMarkOnly && props.margin ? props.margin : undefined}
			>
				{!props.disableUniformHeight && !props.hasCheckMarkOnly && <InputHeight border />}
				<span className={checkboxSquareClasses} style={checkmarkSizeStyle}>
					{props.checked !== null && <i aria-hidden="true" className={classes.checkmark + ' Fluent-CheckMark'} />}
					{props.checked === null && <i aria-hidden="true" className={classes.checkmark + ' Fluent-Remove'} />}
				</span>
				{props.valueLabel && (
					<ValueLabel value={props.valueLabel} disabled={props.disabled} wordWrap={props.wordWrapValueLabel} />
				)}
			</button>
		)

		if (props.hasCheckMarkOnly) {
			return Checkmark
		}

		return (
			<FormControl
				id={id}
				label={props.label}
				labelPosition={props.labelPosition}
				hideLabel={props.hideLabel}
				labelProps={props.labelProps}
				icon={props.icon}
				labelContentLayout={props.labelContentLayout}
				disabled={props.disabled}
				error={props.error}
				warning={props.warning}
				screenTip={props.screenTip}
				labelSubText={props.labelSubText}
				validationText={props.validationText}
				validationTextPosition={props.validationTextPosition}
				subText={subText}
				reserveHelperTextSpace={props.reserveHelperTextSpace}
				disableBorder
				margin={props.margin}
				readOnly={props.readOnly}
				required={props.required}
				className={props.className}
			>
				{Checkmark}
			</FormControl>
		)
	}
)

Checkbox.displayName = 'Checkbox'
