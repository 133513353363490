import React, { useRef } from 'react'
import { Button, Text } from '@genusbiz/web-ui/controls'
import { createStyle } from '@genusbiz/web-ui/theming'
import { useTranslation } from 'react-i18next'
import { e_LoginResponse } from 'src/enums/e_LoginResponse'

const classes = createStyle({
	forgotPasswordButton: {
		marginTop: '16px',
		alignSelf: 'center',
	},

	buttonGroup: {
		marginTop: '8px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
})

interface ISignInWindowContentProps {
	onValidSignIn: () => void
	onInvalidSignIn: (loginResponse: string) => void
	loginResponse?: string
}

const navigateToAuth = () => {
	window.location.href = window.location.origin + '/api/authentication/entraid'
}

export const OperatorEntraIdLogin = (props: ISignInWindowContentProps) => {
	const { t } = useTranslation()

	const loginResponse = useRef(props.loginResponse)

	const mounted = useRef(false)

	if (!mounted.current) {
		if (loginResponse.current === e_LoginResponse.VALID_LOGIN) {
			props.onValidSignIn()
		} else if (loginResponse.current === e_LoginResponse.INVALID_LOGIN) {
			props.onInvalidSignIn(loginResponse.current as string)
		}
		mounted.current = true
	}

	return (
		<div className="vertical spacing horizontal-stretch-items">
			<>
				{loginResponse.current === e_LoginResponse.INVALID_LOGIN && (
					<Text statusColor="error" style={{ marginTop: 0 }}>
						{t('ERROR:INVALID_LOGIN_ENTRA_ID')}
					</Text>
				)}
				<div className={classes.buttonGroup}>
					<Button
						variant="primary"
						label={t('SIGN_IN_WINDOW:SIGN_IN_ENTRA_ID')}
						size="large"
						onClick={() => {
							navigateToAuth()
						}}
					/>
				</div>
			</>
		</div>
	)
}
