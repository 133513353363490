import mergeWith from 'lodash/mergeWith'
import type { DeepMerge, ThemeDeclaration } from '../../theming/themingManager/themingManager.types'

/**
 * Utility function to extend of theme with additional properties.
 * Base theme will be prioritized; properties with primitive values in 'extend theme' cannot overwrite object values in 'base theme'
 */
export const extendTheme = <T extends ThemeDeclaration, U extends ThemeDeclaration>(base: T, extend: U) => {
	return mergeWith(
		base,
		extend,
		(base: ThemeDeclaration[keyof ThemeDeclaration], extend: ThemeDeclaration[keyof ThemeDeclaration]) => {
			if (typeof base === 'object' && typeof extend !== 'object') {
				return base
			}
		}
	) as object as DeepMerge<T, U>
}
