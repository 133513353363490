import type { GridApi, IRowNode } from '@ag-grid-community/core'
import type { TData } from '../Table.types'

export const getCheckboxState = (
	rowCount: number | undefined,
	api: GridApi<TData> | undefined,
	node?: IRowNode<TData>
) => {
	if (!rowCount) {
		return false
	}

	// If node !== undefined, this function is called for a group, not a header. Thus childrenSelected will always be empty for header check marks
	const childrenSelected = node !== undefined ? node.allLeafChildren?.filter((child) => child.isSelected()) || [] : []

	const selectionCount =
		childrenSelected.length > 0 || node?.group ? childrenSelected.length : api?.getSelectedNodes().length || 0

	return selectionCount === rowCount ? true : selectionCount === 0 ? false : 'mixed'
}
