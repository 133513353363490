import { useCallback } from 'react'
import type { CellData, ICellRendererProps, TData, Value } from '../../../Table.types'
import { e_RenderType } from '../../../Table.types'
import type { ICellRendererParams } from '@ag-grid-community/core'
import { tDataToRowData } from '../../../utils'

export const usePushChanges = (
	props: ICellRendererProps & ICellRendererParams<TData, CellData>,
	setDisplayLabel: (value: string) => void,
	setValue: (value: Value) => void,
	stopEditing: () => void
) => {
	const cellData = props.value
	const { node, renderType, cellEditingProps, colDef } = props
	const onChange = cellEditingProps?.onChange

	return useCallback(
		(value: Value) => {
			const tData = node.data
			const rowData = tDataToRowData(tData)

			if (value !== cellData?.value) {
				if (![e_RenderType.dropdown, e_RenderType.lookup].includes(renderType)) {
					setDisplayLabel((props.formatCellValue?.(value) ?? value ?? '')?.toString())
				}

				setValue(value)

				rowData && onChange?.(rowData, colDef?.field || '', value)
			}

			stopEditing()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[cellData?.value, colDef?.field, node.data, onChange, stopEditing]
	)
}
