import type { ReactNode } from 'react'
import React, { useEffect, useState, useRef } from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'

import { ListItem } from './ListItem'
import type { IListItem } from './IListItem'
import type { IContextMenu, IContextMenuContext } from '../ContextMenu//IContextMenu'
import type { IInsertIndex } from '../utils/useDropObject'
import { Divider } from '../Divider'
import { useContextMenu } from '../ContextMenu'
import { useDropObject } from '../utils/useDropObject'

import { DropTarget } from '../utils/DropTarget'
import { alphaSortObjectsByStringProperty } from '../utils/sortObjects'
import _flatten from 'lodash/flatten'

const classes = createStyle((theme) => ({
	list: {
		overflow: 'auto',
		display: 'flex',
		position: 'relative',
		flex: 1,
		flexDirection: 'column',
	},

	listContent: {
		outline: 'none',
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		// overflow: 'auto',
		// alignItems: 'stretch',
		// contain: 'strict',
	},

	listContentFillWidth: {
		alignItems: 'stretch',
	},
	listGroups: {
		display: 'flex',
		flexDirection: 'column',
	},
	grow: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
	},
	disabled: {
		background: theme.colors.input.disabledBackground,
		color: theme.colors.button.disabledText,
		overflow: 'hidden',
	},
	emptyListMessage: {
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

interface IListProps {
	id?: string
	listRef?: React.Ref<HTMLDivElement>

	items: IListItem[][] | IListItem[]
	selectedItemIds?: string[]
	disabled?: boolean
	onRenderItem?: (item: IListItem & { isSelected: boolean; isDisabled: boolean; isFocused?: boolean }) => JSX.Element
	onSelection?: (itemIds: string[]) => void
	onActivate?: (itemId: string) => void
	activateOnDoubleClick?: boolean

	//Necessary for drag and drop
	handleOnDrop?: (insertIndex: IInsertIndex, itemType: string | symbol, item: IListItem) => void
	handleCanDrop?: (insertIndex: IInsertIndex, itemType: string | symbol | null, item: IListItem) => boolean
	dropTargetTypes?: string[]
	dragSourceType?: string
	startDrag?: (activeItemId?: string) => void
	dragCompleted?: () => void

	getItemFromSearch?: (flatListItems: IListItem[], searchKeys: string) => IListItem | undefined
	contextMenu?: IContextMenu
	buildContextMenuContent?: (selectedItemIds: string[]) => IContextMenu
	onContextMenu?: (id: string, e: React.MouseEvent) => void
	onListKeyDown?: (event: React.KeyboardEvent) => void

	//Options:
	selectionMode?: 'multi' | 'single' | 'checkBox'
	selectionBackgroundStyle?: 'default' | 'noBackground'
	focusStyle?: 'default' | 'border' | 'none'
	multiSelectIsToggle?: boolean
	sortProperty?: string
	useDragHandle?: boolean
	noItemPadding?: boolean

	itemDisplayNameProperty?: string
	emptyListMessage?: string | ReactNode | ReactNode[]

	contextMenuContext?: IContextMenuContext
	dataVariables?: Record<string, string>

	subText?: string
	error?: boolean
	warning?: boolean
	reserveHelperTextSpace?: 'showWhenRequired' | 'reserveSpace' | 'off' | true | false

	screenTip?: string

	itemFillWidth?: boolean
}

export const List = (props: IListProps) => {
	const isSelectionControlled = () => {
		return props.selectionMode !== undefined && props.selectedItemIds !== undefined
	}

	const getInitialGroupedItems = (items: IListItem[][] | IListItem[], sortProperty?: string) => {
		let initiallyGroupedItems: IListItem[][] = []
		if (items && items[0]) {
			if (!Array.isArray(items[0])) {
				initiallyGroupedItems = [items as IListItem[]]
			} else {
				initiallyGroupedItems = items as IListItem[][]
			}
		}

		if (sortProperty) {
			return initiallyGroupedItems.map((group) => {
				return alphaSortObjectsByStringProperty(group, sortProperty)
			})
		}
		return initiallyGroupedItems
	}

	const getInitialSelectedIds = () => {
		return isSelectionControlled() && props.selectedItemIds ? props.selectedItemIds : []
	}

	const propItems = props.items
	const [groupedItems, setGroupedItems] = useState(getInitialGroupedItems(props.items, props.sortProperty))

	const selectedItemIds = useRef(getInitialSelectedIds())
	const [activeItemId, setActiveItemId] = useState(
		selectedItemIds.current.length > 0 ? selectedItemIds.current[0] : undefined
	)
	const [focusedItemId, setFocusedItemId] = useState<string>()
	const [anchorItemId, setAnchorItemId] = useState<string>()
	const [activeShiftSelect, setActiveShiftSelect] = useState(false)

	const focusedListItemRef = React.useRef<HTMLDivElement>(null)

	const didNavigateByKey = React.useRef(false)
	let searchKeys = ''
	const searchTimeOut = React.useRef<number>()
	const selectionTimer = React.useRef<number>()

	useEffect(() => {
		if (isSelectionControlled() && !selectionTimer.current) {
			selectedItemIds.current = props.selectedItemIds ? props.selectedItemIds : []
		} else {
			selectionTimer.current = undefined
		}
	}, [props.selectionMode, props.selectedItemIds])

	useEffect(() => {
		let newGroupedItems: IListItem[][]
		if (propItems && propItems[0]) {
			if (!Array.isArray(propItems[0])) {
				newGroupedItems = [propItems as IListItem[]]
			} else {
				newGroupedItems = propItems as IListItem[][]
			}
		} else {
			newGroupedItems = []
		}

		let sortedGroupedItems
		if (props.sortProperty) {
			const sortProperty = props.sortProperty
			sortedGroupedItems = newGroupedItems.map((group) => {
				return alphaSortObjectsByStringProperty(group, sortProperty)
			})
		} else {
			sortedGroupedItems = newGroupedItems
		}
		setGroupedItems(sortedGroupedItems)
	}, [props.items])

	useEffect(() => {
		if (didNavigateByKey.current && focusedListItemRef.current) {
			focusedListItemRef.current.focus()
			didNavigateByKey.current = false
		}
	})

	const contextMenu = props.buildContextMenuContent
		? props.buildContextMenuContent(selectedItemIds.current)
		: props.contextMenu
	const onContextMenu = useContextMenu(contextMenu?.items ? contextMenu.items : [])

	const handleOnContextMenu = (e: React.MouseEvent) => {
		if (props.onContextMenu) {
			activeItemId && props.onContextMenu(activeItemId, e)
			return
		}

		onContextMenu(e)
	}

	const onMouseDown = (id: string, e: React.MouseEvent) => {
		const item = getItemById(id)
		const rightMousePressed = e.nativeEvent.button === 2

		if (rightMousePressed) {
			if (!isSelectedItem(item.id)) {
				onSelection([item.id], false)
			}
		}

		//set active item and set anchor if not set or if not SHIFT pressed.
		const shiftPressed = e.shiftKey
		const ctrlPressed = e.ctrlKey

		const clickedId = id === anchorItemId && ctrlPressed ? undefined : id

		const newAnchorID = !shiftPressed || !anchorItemId ? clickedId : anchorItemId
		if (props.selectionMode) {
			setSelection(id, e)
		}

		setActiveItemId(id === activeItemId && ctrlPressed ? undefined : id)
		setFocusedItemId(id)
		setAnchorItemId(newAnchorID)
	}

	const onActivate = () => {
		activeItemId && props.onActivate?.(activeItemId)
	}

	const getItemById = (id: string) => {
		const item = flatten(groupedItems).find((groupedItem: IListItem) => groupedItem.id === id)

		if (!item) {
			throw new Error('List- getItemById: could not find item')
		}

		return item
	}

	const isSelectedItem = (id: string) => {
		return !!selectedItemIds.current.find((itemId: string) => itemId === id)
	}

	const onSelection = (itemIds: string[], debounce = false) => {
		const uniqueItemIds = itemIds.filter((item, pos, ary) => {
			return !pos || item !== ary[pos - 1]
		})
		// update internal state
		selectedItemIds.current = uniqueItemIds

		if (!props.onSelection) {
			return
		}
		if (debounce) {
			clearTimeout(selectionTimer.current)
			selectionTimer.current = window.setTimeout(() => props.onSelection?.(uniqueItemIds), 200)
		} else {
			props.onSelection(uniqueItemIds)
		}
	}

	const setSelection = (id: string, e: any) => {
		const item = getItemById(id)

		const shiftPressed = e.shiftKey
		const ctrlPressed = e.ctrlKey || e.metaKey

		if (props.selectionMode === 'single') {
			if ((ctrlPressed || props.multiSelectIsToggle) && isSelectedItem(item.id)) {
				onSelection([], true)
			} else {
				onSelection([item.id])
			}
			return
		} //

		if (!shiftPressed && !ctrlPressed) {
			props.multiSelectIsToggle ? toggleSelect(item.id) : onSelection([item.id])
		} else if (!shiftPressed && ctrlPressed) {
			// add selected item to list
			toggleSelect(item.id)
		} else if (shiftPressed && !ctrlPressed) {
			// set selected to groupedItems from anchor to item
			shiftSelect(item.id)
		} else if (shiftPressed && ctrlPressed) {
			// add groupedItems from anchor to item to selected
			addativeSelectFromAnchor(item.id)
		}
	}

	const addativeSelectFromAnchor = (id: string) => {
		const newPartSelection = getNewShiftSelection(id)
		const newSelection = [...selectedItemIds.current, ...newPartSelection].sort()
		onSelection(newSelection)
	}

	const shiftSelect = (id: string) => {
		const newSelection = getNewShiftSelection(id)
		onSelection(newSelection)
	}

	const getNewShiftSelection = (id: string) => {
		const anchorIndexPosition = anchorItemId ? getIndexPositionById(anchorItemId) : { groupIndex: 0, index: 0 }

		const activeIndexPosition = id ? getIndexPositionById(id) : { groupIndex: 0, index: 0 }

		let newSelection

		if (
			!(
				anchorIndexPosition.groupIndex === activeIndexPosition.groupIndex &&
				anchorIndexPosition.index === activeIndexPosition.index
			)
		) {
			if (anchorIndexPosition.groupIndex === activeIndexPosition.groupIndex) {
				const startIndex =
					anchorIndexPosition.index < activeIndexPosition.index ? anchorIndexPosition.index : activeIndexPosition.index
				const endIndex =
					anchorIndexPosition.index < activeIndexPosition.index ? activeIndexPosition.index : anchorIndexPosition.index

				newSelection = groupedItems[anchorIndexPosition.groupIndex]
					.slice(startIndex, endIndex + 1)
					.map((dataItem: IListItem) => dataItem.id)
			} else {
				if (anchorIndexPosition.groupIndex < activeIndexPosition.groupIndex) {
					const newSelectionPartOne = groupedItems[anchorIndexPosition.groupIndex]
						.slice(anchorIndexPosition.index)
						.map((dataItem: IListItem) => dataItem.id)
					const newSelectionPartTwo = groupedItems[activeIndexPosition.groupIndex]
						.slice(0, activeIndexPosition.index + 1)
						.map((dataItem: IListItem) => dataItem.id)
					newSelection = [...newSelectionPartOne, ...newSelectionPartTwo]
				} else {
					const newSelectionPartOne = groupedItems[activeIndexPosition.groupIndex]
						.slice(activeIndexPosition.index)
						.map((dataItem: IListItem) => dataItem.id)
					const newSelectionPartTwo = groupedItems[anchorIndexPosition.groupIndex]
						.slice(0, anchorIndexPosition.index + 1)
						.map((dataItem: IListItem) => dataItem.id)
					newSelection = [...newSelectionPartOne, ...newSelectionPartTwo]
				}
			}
		} else {
			newSelection = [id]
		}

		return newSelection
	}

	const toggleSelect = (id: string, addative?: boolean) => {
		const index = selectedItemIds.current.indexOf(id)
		let newSelection: string[]

		if (index === -1) {
			newSelection = [...selectedItemIds.current, id].sort()

			onSelection(newSelection)
		} else if (!addative) {
			newSelection = [...selectedItemIds.current.slice(0, index), ...selectedItemIds.current.slice(index + 1)]
			onSelection(newSelection, true)
		}
	}

	const flatten = (groupedItemsToFlatten: IListItem[][]) => {
		return _flatten(groupedItemsToFlatten)
	}

	const handleSearchByKeys = (shiftPressed: boolean, ctrlPressed: boolean) => {
		if (!props.getItemFromSearch) {
			return
		}

		const fullFlatList = flatten(groupedItems)
		const activeIndex = fullFlatList.findIndex((flatItem) => flatItem.id === activeItemId)
		const filteredFlatList = fullFlatList.filter((_, index) => index > activeIndex)

		let item = props.getItemFromSearch(filteredFlatList, searchKeys)
		if (!item) {
			item = props.getItemFromSearch(fullFlatList, searchKeys)
		}

		if (!item) {
			return
		}

		const indexPosition = getIndexPositionById(item.id)
		setItemStateOnKeyDown(indexPosition, shiftPressed, ctrlPressed, false)
	}

	const updateSearchKeys = (key: string, shiftPressed: boolean, ctrlPressed: boolean) => {
		clearTimeout(searchTimeOut.current)
		searchKeys += key
		handleSearchByKeys(shiftPressed, ctrlPressed)
		searchTimeOut.current = window.setTimeout(() => {
			searchKeys = ''
		}, 200)
	}

	const getIndexPositionById = (id: string) => {
		if (props.items.length === 0) {
			return { groupIndex: 0, index: 0 }
		}

		const indexPosition: {
			groupIndex: number
			index: number
		} = groupedItems.reduce(
			(acc, currentGroupedItems, groupIndex) => {
				const index = currentGroupedItems.findIndex((item: IListItem) => item.id === id)
				if (index !== -1) {
					acc = {
						groupIndex: groupIndex,
						index: index,
					}
				}
				return acc
			},
			{ groupIndex: -1, index: -1 }
		)

		if (indexPosition.groupIndex === -1 || indexPosition.index === -1) {
			throw new Error('List- getIndexById: could not find index')
		}

		return indexPosition
	}

	const [insertIndex, handleDropHover, handleOnDrop, handleDragLeave, handleCanDrop] = useDropObject(
		props.items,
		getIndexPositionById,
		props.handleOnDrop,
		props.handleCanDrop
	)

	const onKeyUp = (e: any) => {
		if (e.key === 'Shift') {
			setActiveShiftSelect(false)
		}
	}

	const onKeyDown = (e: any) => {
		let indexPosition: { groupIndex: number; index: number } | undefined = undefined

		const focusedItemPosition = focusedItemId ? getIndexPositionById(focusedItemId) : undefined
		const activeItemPosition = activeItemId ? getIndexPositionById(activeItemId) : undefined

		const activeIndexPosition = focusedItemPosition || activeItemPosition || { groupIndex: 0, index: 0 }

		const shiftPressed = e.shiftKey
		const ctrlPressed = e.ctrlKey

		if (props.onListKeyDown) {
			props.onListKeyDown(e)

			if (e.defaultPrevented) {
				return
			}
		}

		switch (e.key) {
			case 'ArrowDown':
				e.preventDefault()

				if (!activeShiftSelect && shiftPressed && !ctrlPressed) {
					setActiveShiftSelect(true)

					onSelection(focusedItemId ? [focusedItemId] : [])
				}

				didNavigateByKey.current = true
				if (activeIndexPosition.index < groupedItems[activeIndexPosition.groupIndex].length - 1) {
					indexPosition = {
						groupIndex: activeIndexPosition.groupIndex,
						index: activeIndexPosition.index + 1,
					}
				} else if (activeIndexPosition.groupIndex < groupedItems.length - 1) {
					indexPosition = { groupIndex: activeIndexPosition.groupIndex + 1, index: 0 }
				} else {
					indexPosition = activeIndexPosition
				}

				break

			case 'ArrowUp':
				e.preventDefault()

				if (!activeShiftSelect && shiftPressed && !ctrlPressed) {
					setActiveShiftSelect(true)

					onSelection(focusedItemId ? [focusedItemId] : [])
				}

				didNavigateByKey.current = true
				if (activeIndexPosition.index > 0) {
					indexPosition = {
						groupIndex: activeIndexPosition.groupIndex,
						index: activeIndexPosition.index - 1,
					}
				} else if (activeIndexPosition.groupIndex > 0) {
					indexPosition = {
						groupIndex: activeIndexPosition.groupIndex - 1,
						index: groupedItems[activeIndexPosition.groupIndex - 1].length - 1,
					}
				} else {
					indexPosition = activeIndexPosition
				}

				break

			case 'Enter':
				if (activeItemId) {
					onActivate()
				}
				break
			case 'Home': {
				if (shiftPressed) {
					addativeSelectFromAnchor((props.items[0] as IListItem).id)
				}
				indexPosition = {
					groupIndex: activeIndexPosition.groupIndex,
					index: 0,
				}
				break
			}
			case 'End': {
				if (shiftPressed) {
					addativeSelectFromAnchor((props.items[props.items.length - 1] as IListItem).id)
				}
				indexPosition = {
					groupIndex: activeIndexPosition.groupIndex,
					index: props.items.length - 1,
				}
				break
			}
			case 'a': {
				if (props.selectionMode === 'multi' && ctrlPressed) {
					e.preventDefault()

					if (props.items.length === props.selectedItemIds?.length) {
						onSelection([])
						setFocusedItemId((props.items[0] as IListItem).id)
						indexPosition = {
							groupIndex: activeIndexPosition.groupIndex,
							index: 0,
						}
					} else {
						if (!Array.isArray(propItems[0])) {
							// props.items are IListItem[] rather than IListItem[][]
							onSelection((props.items as IListItem[]).map((item: IListItem) => item.id))
						} else {
							const newSelection: string[] = []
							;(props.items as IListItem[][]).forEach((itemList: IListItem[]) =>
								itemList.forEach((item: IListItem) => newSelection.push(item.id))
							)
							onSelection(newSelection)
						}
						indexPosition = {
							groupIndex: activeIndexPosition.groupIndex,
							index: props.items.length - 1,
						}
					}
				} else if (props.selectionMode === 'single' && props.getItemFromSearch) {
					// If 'a' is pressed and it is not multi select, then regular key searching should be triggered.
					// This case is only for holding ctrl while in multi select mode which should select all items
					updateSearchKeys(e.key, shiftPressed, ctrlPressed)
				}
				break
			}
			case ' ':
				if (focusedItemId) {
					e.preventDefault()

					if (shiftPressed && ctrlPressed) {
						addativeSelectFromAnchor(focusedItemId)
						setAnchorItemId(focusedItemId)

						break
					}
					if (shiftPressed) {
						setAnchorItemId(focusedItemId)

						break
					}

					setSelection(focusedItemId, e)
					setAnchorItemId(focusedItemId)
				}
				break

			default: {
				if (props.getItemFromSearch) {
					updateSearchKeys(e.key, shiftPressed, ctrlPressed)
				}
			}
		}

		if (indexPosition === undefined) {
			return
		}

		setItemStateOnKeyDown(indexPosition, shiftPressed, ctrlPressed, true)
	}

	const setItemStateOnKeyDown = (
		indexPosition: { groupIndex: number; index: number },
		shiftPressed: boolean,
		ctrlPressed: boolean,
		debounce: boolean
	) => {
		const item = groupedItems[indexPosition.groupIndex][indexPosition.index]

		if (props.selectionMode === 'single') {
			// set all: focused, active, anchor, selection
			setFocusedItemId(item.id)
			setActiveItemId(item.id)
			setAnchorItemId(item.id)
			onSelection([item.id], debounce)
		} else if (!shiftPressed && !ctrlPressed) {
			setFocusedItemId(item.id)
		} else if (!shiftPressed && ctrlPressed) {
			// keep selected, anchor, set focused
			setFocusedItemId(item.id)
		} else if (shiftPressed && !ctrlPressed) {
			// keep anchor, set focused, set selection
			setFocusedItemId(item.id)
			if (!didNavigateByKey) {
				const newSelection = getNewShiftSelection(item.id)
				onSelection(newSelection)
			} else if (focusedItemId) {
				toggleSelect(item.id, true)
			}
		} else if (shiftPressed && ctrlPressed) {
			// keep anchor, set focused, add/remove to selection
			setFocusedItemId(item.id)
			if (!didNavigateByKey) {
				const newPartSelection = getNewShiftSelection(item.id)
				const newSelection = [...selectedItemIds.current, ...newPartSelection].sort()
				onSelection(newSelection)
			}
		}
	}

	const startDrag = () => {
		if (!props.startDrag) {
			return
		}
		if (props.selectionMode === 'multi' && !props.useDragHandle) {
			const addActiveItemToSelectedItems =
				activeItemId && !selectedItemIds.current.find((itemId: string) => itemId === activeItemId)
			if (addActiveItemToSelectedItems && activeItemId) {
				const newSelection = [...selectedItemIds.current, activeItemId]
				onSelection(newSelection)
			}
		} else if (props.selectionMode === 'single') {
			if (activeItemId && activeItemId !== selectedItemIds.current[0]) {
				const newSelection = [activeItemId]
				onSelection(newSelection)
			}
		}

		return props.startDrag(activeItemId)
	}

	const dragCompleted = () => {
		props.selectionMode !== 'checkBox' && onSelection([])
		props?.dragCompleted && props.dragCompleted()
	}

	const focusFirstAvailableItem = () => {
		if (props.items.length === 0 || !props.selectionMode || didNavigateByKey.current || !props.selectedItemIds) {
			// We do not mess with focus if the list has no selection mode (is not selection controlled),
			// or the user is currently navigating it.
			// Some lists do not keep track of selected items, in which case we skip
			return
		}

		if (document.activeElement && document.activeElement !== focusedListItemRef.current) {
			//A user has navigated to the list without clicking an item on the list

			const flatListIds = props.items.flat().map((item) => item.id)

			if (props.selectedItemIds.length > 0) {
				if (!flatListIds.includes(props.selectedItemIds[0])) {
					//the list has changed
					onSelection([flatListIds[0]])
					setFocusedItemId(flatListIds[0])
					return
				}
				didNavigateByKey.current = true
				setFocusedItemId(props.selectedItemIds[0])
				return
			}

			if (!focusedItemId || (focusedItemId && !flatListIds.includes(focusedItemId))) {
				// This is the first time the user navigates to the list
				setFocusedItemId(flatListIds[0])

				didNavigateByKey.current = true
			}
		}
	}

	const useDoubleClickToActivate = props.activateOnDoubleClick || props.activateOnDoubleClick === undefined

	return (
		<div className={classes.list} onContextMenu={handleOnContextMenu}>
			<div
				className={classNames(classes.listContent, {
					[classes.disabled]: props.disabled,
					[classes.listContentFillWidth]: props.itemFillWidth,
				})}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyUp}
				tabIndex={props.disabled ? undefined : 0}
				ref={props.listRef}
				onFocus={focusFirstAvailableItem}
				{...props.dataVariables}
			>
				<DropTarget
					dropTargetTypes={props.dropTargetTypes}
					onDrop={handleOnDrop}
					onHover={handleDropHover}
					onCanDrop={handleCanDrop}
					onDragLeave={handleDragLeave}
					className={classNames(classes.listGroups, groupedItems.length < 1 ? classes.emptyListMessage : undefined)}
				>
					{groupedItems?.length > 0 ? (
						groupedItems.map((dataGroup: any, index: number) => {
							return (
								<div key={'group-' + index} className={classes.grow}>
									{dataGroup.length > 0 && index > 0 && <Divider key={'divider-' + index} />}
									{dataGroup.map((dataItem: IListItem, i: number) => {
										const isSelected = selectedItemIds.current.includes(dataItem.id)

										return (
											<ListItem
												key={dataItem.id}
												id={dataItem.id}
												dataItem={dataItem}
												displayNameProperty={props.itemDisplayNameProperty}
												onRenderItem={props.onRenderItem}
												onMouseDown={onMouseDown}
												onClick={!useDoubleClickToActivate ? onActivate : undefined}
												onDoubleClick={useDoubleClickToActivate === true ? onActivate : undefined}
												isSelected={isSelected}
												isDisabled={dataItem.disabled || props.disabled}
												ref={dataItem.id === focusedItemId ? focusedListItemRef : undefined}
												displayDropIndicatorBefore={
													i === 0 && i === insertIndex.index && index === insertIndex.groupIndex
												}
												displayDropIndicatorAfter={i + 1 === insertIndex.index && index === insertIndex.groupIndex}
												checkBox={props.selectionMode === 'checkBox'}
												checkClick={toggleSelect}
												dragHandle={props.useDragHandle}
												dragCompleted={dragCompleted}
												startDrag={startDrag}
												dragSourceType={props.dragSourceType}
												setActiveItemId={setActiveItemId}
												isFocused={focusedItemId === dataItem.id}
												enableDrag={props.dragSourceType !== undefined}
												selectionBackgroundStyle={props.selectionBackgroundStyle}
												focusStyle={props.focusStyle}
												noPadding={props.noItemPadding}
												screenTip={props.screenTip}
											/>
										)
									})}
								</div>
							)
						})
					) : (
						<div className={classNames({ [classes.disabled]: props.disabled })}>{props.emptyListMessage}</div>
					)}
				</DropTarget>
			</div>
		</div>
	)
}
