import React from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'

import { Icon } from '../Icon'
import type { IListItem } from './IListItem'
import { DropIndicator } from '../utils/DropIndicator'

import { DragSource } from '../utils/DragSource'
import type { DragSourceMonitor } from 'react-dnd'

const classes = createStyle((theme) => ({
	row: {
		padding: '0px 8px',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'row',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		position: 'relative',
		outline: 'none',
		textOverflow: 'ellipsis',
		userSelect: 'none',
	},

	rowWithCheckbox: {
		padding: '0px 8px 0px 0px',
	},
	dropIndicator: {
		display: 'flex',
		flexGrow: 1,
	},
	defaultBackgrounds: {
		'&:hover': {
			background: theme.colors.list.hoveredBackground,
			color: theme.colors.list.text,
		},
		'&:hover:active': {
			background: theme.controls.list.checkedHoverColors.background,
			color: theme.controls.list.checkedHoverColors.color,
		},
	},
	borderColor: {
		'&:focus': {
			outline: '1px solid ' + theme.colors.dataItem.selectedBorder,
		},
	},
	focus: {
		'&:focus': { background: theme.controls.list.checkedColors.background },
		'&focus:hover': {
			background: theme.controls.list.checkedHoverColors.background,
			color: theme.controls.list.checkedHoverColors.color,
		},
		'&focus:hover:active': {
			background: theme.colors.list.checkedHoveredBackground,
			color: theme.controls.list.checkedHoverColors.color,
		},
		'&:focus-visible': {
			outline: '1px solid ' + theme.colors.dataItem.selectedBorder,
		},
	},
	rowNoPadding: { padding: 0 },
	rowSelected: {
		background: theme.controls.list.checkedColors.background,
		color: theme.controls.list.checkedColors.color,
		'&:hover': {
			background: theme.controls.list.checkedHoverColors.background,
			color: theme.controls.list.checkedHoverColors.color,
		},
	},
	disabled: {
		cursor: 'default',
		pointerEvents: 'none',
		background: theme.colors.input.disabledBackground,
		color: theme.colors.button.disabledText,
	},
	itemContent: {
		display: 'flex',
		alignItems: 'center',
		margin: '2px 0px',
		width: '100%',
	},
	itemCaption: { margin: '0 8px' },
	buttons: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	check: {
		width: 18,
		height: 18,
		lineHeight: 1,
		verticalAlign: 'top',
		position: 'relative',
		userSelect: 'none',
		'&:focus': {
			outline: '1px solid ' + theme.colors.body.focusBorder,
		},

		'&::before': {
			content: "''",
			position: 'absolute',
			top: 1,
			right: 1,
			bottom: 1,
			left: 1,
			opacity: 0,
			borderRadius: '50%',
			background: theme.palette.primary.themePrimary,
			'$checkbox[aria-checked="true"] &': {
				opacity: 1,
			},
			'$checkbox[aria-checked="false"] &': {
				'$row:hover &': {
					opacity: 1,
					background: theme.palette.background.white,
				},
			},
		},
	},
	circle: {
		display: 'inline-block',
		fontSize: 18,
		left: 0,
		top: 0,
		width: 18,
		height: 18,
		textAlign: 'center',
		verticalAlign: 'middle',
		color: theme.colors.body.focusBorder,
		'$checkbox[aria-checked="true"] &': {
			color: theme.palette.background.white,
		},
	},
	checkMark: {
		fontSize: 16,
		position: 'absolute',
		left: 0.5,
		top: 0,
		width: 18,
		height: 18,
		textAlign: 'center',
		verticalAlign: 'middle',
		opacity: 0,
		color: theme.palette.background.white,
		'$checkbox[aria-checked="true"] &': {
			opacity: 1,
			color: theme.palette.background.white + ' !important',
		},
		'$checkbox:hover &': {
			opacity: 1,
			color: theme.colors.list.text,
		},
	},
	checkbox: {
		display: 'flex',
		position: 'relative',
		minHeight: 32,
		width: 34,
		flexShrink: 0,
		alignItems: 'center',
		justifyContent: 'center',
	},
	dragHandle: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		cursor: 'grab',
	},
}))

interface IListItemProps {
	id: string
	onRenderItem?: (item: IListItem & { isSelected: boolean; isDisabled: boolean; isFocused?: boolean }) => JSX.Element
	onMouseDown: (id: string, e: React.MouseEvent) => void
	onMouseUp?: (id: string, e: React.MouseEvent) => void
	onDoubleClick?: (id: string) => void
	onClick?: (id: string) => void
	dataItem: IListItem
	displayNameProperty?: string
	isSelected: boolean
	isDisabled: boolean
	displayDropIndicatorBefore?: boolean
	displayDropIndicatorAfter?: boolean
	checkBox?: boolean
	checkClick: (id: string) => void
	dragHandle?: boolean
	noPadding?: boolean

	setActiveItemId: (itemId: string) => void
	isFocused?: boolean
	selectionBackgroundStyle?: 'default' | 'noBackground'
	focusStyle?: 'default' | 'border' | 'none'

	dragSourceType?: string
	startDrag?: (monitor: DragSourceMonitor) => void
	dragCompleted?: () => void
	enableDrag?: boolean

	screenTip?: string
}

export const ListItem = React.forwardRef((props: IListItemProps, ref: React.Ref<HTMLDivElement>) => {
	const { selectionBackgroundStyle = 'default', focusStyle = 'default', isSelected, isDisabled, isFocused } = props

	const onMouseDown = (e: React.MouseEvent) => {
		props.onMouseDown(props.id, e)
	}

	const onClick = () => {
		props.onClick?.(props.id)
	}

	const onDoubleClick = () => {
		props.onDoubleClick?.(props.id)
	}

	const caption = props.displayNameProperty ? props.dataItem[props.displayNameProperty] : props.dataItem.caption
	const iconClassName = props.dataItem.icon

	const tabIndex = -1
	const displayDropIndicator = props.displayDropIndicatorBefore || props.displayDropIndicatorAfter || false

	const itemContent = (
		<>
			{props.checkBox && (
				<div className={classes.checkbox} aria-label="Row checkbox" role="checkbox" aria-checked={isSelected}>
					<div
						className={classNames(classes.check)}
						onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							e.stopPropagation()
							props.checkClick(props.id)
						}}
						onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							e.stopPropagation()
						}}
					>
						<i aria-hidden="true" className={classes.checkMark + ' Fluent-StatusCircleCheckmark'} />
						<i aria-hidden="true" className={classes.circle + ' Fluent-CircleRing'} />
					</div>
				</div>
			)}

			<div className={classes.itemContent}>
				{props.onRenderItem?.({ ...props.dataItem, isSelected, isDisabled, isFocused })}
				{!props.onRenderItem && (
					<>
						{iconClassName && <Icon iconClassName={iconClassName} />}
						<div className={classes.itemCaption}>{caption}</div>
					</>
				)}
			</div>
		</>
	)

	return props.dragHandle ? (
		<div
			key={props.id}
			id={props.id}
			tabIndex={tabIndex}
			className={classNames(classes.row, {
				[classes.rowWithCheckbox]: props.checkBox,
				[classes.rowSelected]: isSelected && selectionBackgroundStyle === 'default',
				[classes.defaultBackgrounds]: true,
				[classes.borderColor]: focusStyle === 'border',
				[classes.focus]: focusStyle === 'default',
				[classes.rowNoPadding]: props.onRenderItem,
				[classes.disabled]: props.isDisabled,
			})}
			onMouseDown={onMouseDown}
			onClick={onClick}
			onDoubleClick={onDoubleClick}
			ref={ref}
			data-rowid={props.id}
			title={props.dataItem.screenTip || ''}
			data-place="right"
			data-cy={props.id}
		>
			<DropIndicator
				key={props.id + 'hoveredAfter'}
				height={2}
				hoveredPos={props.displayDropIndicatorBefore ? 'top' : 'bottom'}
				visible={displayDropIndicator}
				className={classes.dropIndicator}
			>
				{itemContent}
				<DragSource
					dragSourceType={props.dragSourceType}
					initiateDrag={props.startDrag}
					dragCompleted={props.dragCompleted}
					disableDrag={!props.enableDrag}
				>
					<div
						className={classes.dragHandle}
						onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							props.setActiveItemId(props.id)
							e.stopPropagation()
						}}
						onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							e.stopPropagation()
						}}
					>
						<Icon iconName="Fluent-GripperDotsVertical" />
					</div>
				</DragSource>
			</DropIndicator>
		</div>
	) : (
		<DragSource
			dragSourceType={props.dragSourceType}
			initiateDrag={props.startDrag}
			dragCompleted={props.dragCompleted}
			disableDrag={!props.enableDrag}
		>
			<div
				key={props.id}
				id={props.id}
				tabIndex={tabIndex}
				className={classNames(classes.row, {
					[classes.rowWithCheckbox]: props.checkBox,
					[classes.rowSelected]: isSelected && selectionBackgroundStyle === 'default',
					[classes.defaultBackgrounds]: true,
					[classes.borderColor]: focusStyle === 'border',
					[classes.focus]: focusStyle === 'default',
					[classes.rowNoPadding]: props.noPadding,
					[classes.disabled]: props.isDisabled,
				})}
				onMouseDown={onMouseDown}
				onClick={onClick}
				onDoubleClick={onDoubleClick}
				ref={ref}
				data-rowid={props.id}
				title={props.dataItem.screenTip || props.screenTip}
				data-place="right"
			>
				<DropIndicator
					key={props.id + 'hoveredAfter'}
					height={2}
					hoveredPos={props.displayDropIndicatorBefore ? 'top' : 'bottom'}
					visible={displayDropIndicator}
					className={classes.dropIndicator}
				>
					{itemContent}
				</DropIndicator>
			</div>
		</DragSource>
	)
})

ListItem.displayName = 'ListItem'
