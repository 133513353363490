import React, { useRef, useState } from 'react'

import { createStyle } from '../../../../theming'

import {
	CellRendererDate,
	CellRendererDropdown,
	CellRendererDuration,
	CellRendererLookup,
	CellRendererNumber,
	CellRendererText,
	CellRendererViewer,
} from './cellRenderers'

import { tDataToRowData } from '../../utils'

import { e_DateFormat } from '../../enums/e_DateFormat'
import type { CellData, ICellRendererProps, Option, TData, Value } from '../../Table.types'
import { e_RenderType } from '../../Table.types'
import type { ICellRendererParams } from '@ag-grid-community/core'
import type { IFilterEvent } from '../../../LookupInput'

const classes = createStyle({
	control: {
		height: '100%',
		width: '100%',
		'& > div': { height: '100%', width: '100%', lineHeight: '20px' },
	},
})

interface ICellRendererControlProps {
	id: string
	// cellData: CellData | null | undefined,
	inputValue: Value
	displayLabel: string
	renderType: ICellRendererProps['renderType']
	popperOpen: React.MutableRefObject<boolean>
	// cellDataOnRender: Partial<Pick<CellData, 'readOnly' | 'iconName' | 'iconColor' | 'avatarLabel'>> | undefined
	setTempValue: (value: Value) => void
	setValueImmediately: (value: Value) => void
	setDisplayLabel: (value: string) => void
	onClose: () => void
}

export const CellRendererControl = (
	props: ICellRendererControlProps & ICellRendererProps & ICellRendererParams<TData, CellData>
) => {
	const {
		cellEditingProps,
		// eslint-disable-next-line destructuring/no-rename
		value: cellData,
		id,
		interpretation,
		filterOperator,
		dataType,
		renderType,
		inputValue,
		allowNull = false,
		isPercentNumber = false,
	} = props

	// const rowHeight = useMemo(() => {
	// 	return (props.node.rowHeight ?? 0) - 1
	// }, [props.node.rowHeight])

	// const classes = useStyles(rowHeight)

	const colId = props.column?.getId() || ''

	const [options, setOptions] = useState<Option[]>([])
	const [isLoadingOptions, setIsLoadingOptions] = useState(false)

	const onOptionsReceived = (query: string | undefined) => (options: Option[]) => {
		setOptions(options)
		setIsLoadingOptions(false)

		if (query) {
			// Cache Lookup Options
			lookupOptionsCache.current.set(query, options)
		}
	}

	const lookupOptionsCache = useRef(new Map<string, Option[]>())
	const onNeedOptions = (event?: IFilterEvent) => {
		if (event?.filterTerm && lookupOptionsCache.current.has(event.filterTerm)) {
			// Get Lookup Options from cache
			setOptions([...(lookupOptionsCache.current.get(event.filterTerm) as Option[])])
		} else {
			setIsLoadingOptions(true)
			cellEditingProps?.onNeedOptions?.(id, colId, event?.filterTerm, onOptionsReceived(event?.filterTerm))
		}
	}

	const onClick = props.onClick
		? (targetEl: React.RefObject<HTMLElement>) => {
				const rowData = tDataToRowData(props.node.data)
				if (rowData) {
					if (cellData?.onClick) {
						cellData.onClick(rowData, targetEl)
					} else if (props.onClick) {
						props.onClick(rowData, targetEl)
					}
				}
		  }
		: undefined

	const hasError = !!cellData?.error

	if (props.node.footer) {
		return <CellRendererViewer value={props.displayLabel} />
	}

	switch (renderType) {
		case e_RenderType.text:
			return (
				<CellRendererText
					key={id}
					value={inputValue?.toString()}
					setValue={props.setTempValue}
					interpretation={interpretation}
					className={classes.control}
					hasError={hasError}
					onClick={onClick}
					onBlur={props.onClose}
				/>
			)

		case e_RenderType.number:
			return (
				<CellRendererNumber
					key={id}
					value={inputValue as number}
					dataType={props.dataType as 'int' | 'float'}
					setValue={props.setTempValue}
					className={classes.control}
					isPercentNumber={isPercentNumber}
					hasError={hasError}
					onClick={onClick}
				/>
			)

		case e_RenderType.dropdown: {
			return (
				<CellRendererDropdown
					key={id}
					value={cellData?.value}
					displayValue={props.displayLabel}
					filterOperator={filterOperator}
					options={options}
					allowNull={allowNull}
					onNeedOptions={onNeedOptions}
					setValue={props.setValueImmediately}
					onClose={props.onClose}
					isLoadingOptions={isLoadingOptions}
					className={classes.control}
					hasError={hasError}
					onClick={onClick}
					setDisplayLabel={props.setDisplayLabel}
				/>
			)
		}
		case e_RenderType.lookup: {
			return (
				<CellRendererLookup
					key={id}
					value={inputValue?.toString()}
					displayLabel={props.displayLabel}
					onNeedOptions={onNeedOptions}
					options={options}
					isLoadingOptions={isLoadingOptions}
					setValue={props.setValueImmediately}
					onClose={props.onClose}
					className={classes.control}
					hasError={hasError}
					onClick={onClick}
				/>
			)
		}
		case e_RenderType.date:
			return (
				<CellRendererDate
					key={id}
					value={cellData?.value?.toString()}
					popperOpen={props.popperOpen}
					dataType={dataType}
					setValue={props.setTempValue}
					setValueImmediately={props.setValueImmediately}
					format={(props.format || e_DateFormat.default) as e_DateFormat}
					formatValue={props.formatValue}
					className={classes.control}
					hasError={hasError}
					onClick={onClick}
				/>
			)

		case e_RenderType.duration:
			return (
				<CellRendererDuration
					key={id}
					value={cellData?.value as number}
					popperOpen={props.popperOpen}
					interpretation={props.interpretation}
					setValue={props.setTempValue}
					onClose={props.setValueImmediately}
					className={classes.control}
					hasError={hasError}
					onClick={onClick}
				/>
			)

		case e_RenderType.none:
			return null
		default:
			return <span key={id}>{'<Unknown render type>'}</span>
	}
}
