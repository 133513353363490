import { Button, NumberInput, Spinner, Text } from '@genusbiz/web-ui/controls'
import { createStyle } from '@genusbiz/web-ui/theming'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { defaultIcons } from 'src/consts/defaultIcons'
import { e_ScaledBy } from 'src/enums/e_ScaledBy'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { IWorkloadController } from 'src/interfaces/IWorkloadController'
import { operatorApi } from 'src/modules/operatorApi'
import { getWorkloadControllerScaledBy } from 'src/utils/workloadControllerUtils'

const classes = createStyle({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	outerContent: {
		margin: '12px',
		display: 'flex',
		flexDirection: 'column',
	},
	innerContent: {
		display: 'flex',
		flexDirection: 'row',
	},
	input: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		height: '100%',
		width: '100%',
		alignItems: 'flex-end',
	},
	button: {
		marginLeft: '8px',
		width: '120px',
		height: '32px',
	},
})
interface IWorkloadDetailsProps {
	workloadController: IWorkloadController
	refresh: (emptyBeforeRefresh?: boolean) => Promise<void>
}
export const WorkloadControllerScaler = (props: IWorkloadDetailsProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const [savedReplicaCount, setSavedReplicaCount] = useState<number>(props.workloadController.desiredReplicas)
	const [desiredReplicaCount, setDesiredReplicaCount] = useState<number>(savedReplicaCount)
	const [errorMessage, setErrorMessage] = useState<string>()
	const [ableToUpdateReplicaCount, setAbleToUpdateReplicaCount] = useState(false)
	const [allowScaling, setAllowScaling] = useState(true)
	const [scaledBy, setScaledBy] = useState<e_ScaledBy>(e_ScaledBy.default)
	const [showApplySpinner, setShowApplySpinner] = useState(false)
	const [showResetSpinner, setShowResetSpinner] = useState(false)

	const updateReplicaCountDisabled = () => {
		if (props.workloadController.hasAutoScaler) {
			setAbleToUpdateReplicaCount(false)
			setErrorMessage(props.workloadController.name + ' ' + t('WORKLOAD_RESOURCE_PLAN:SCALE_DISABLED_AUTOSCALER'))
		} else if (props.workloadController.replicaInfo.isDisabled) {
			setAllowScaling(false)
			setAbleToUpdateReplicaCount(false)
			setErrorMessage(`${props.workloadController.name} ${t('WORKLOAD_RESOURCE_PLAN:SCALE_DISABLED')}`)
		} else if (desiredReplicaCount > 1000) {
			setErrorMessage(t('WORKLOAD_RESOURCE_PLAN:SCALE_MORE'))
			setAbleToUpdateReplicaCount(false)
		} else if (desiredReplicaCount < 0) {
			setErrorMessage(t('WORKLOAD_RESOURCE_PLAN:SCALE_LESS'))
			setAbleToUpdateReplicaCount(false)
		} else {
			setErrorMessage('')
			setAbleToUpdateReplicaCount(true)
		}
	}

	useEffect(() => {
		const count = props.workloadController.desiredReplicas !== null ? props.workloadController.desiredReplicas : 0
		setSavedReplicaCount(count)
		setScaledBy(getWorkloadControllerScaledBy(props.workloadController))
	}, [props.workloadController])

	useEffect(() => {
		updateReplicaCountDisabled()
	}, [desiredReplicaCount])

	const removeScaleableResourceReplicaCount = () => {
		if (props.workloadController.runtimeConfig.k8sRuntimeId && props.workloadController.name) {
			setShowResetSpinner(true)
			setDesiredReplicaCount(props.workloadController.replicaInfo.defaultReplicaCount)
			void operatorApi
				.removeWorkloadsResourcePlan(
					props.workloadController.runtimeConfig.k8sRuntimeId,
					props.workloadController.name,
					props.workloadController.kind
				)
				.then(() => {
					dispatch(
						modalManagerActions.showSnackbar({
							message: `${t('WORKLOAD_RESOURCE_PLAN:REPLICA_COUNT_RESET')} ${props.workloadController.name}`,
							icon: defaultIcons.check,
						})
					)
					setSavedReplicaCount(props.workloadController.replicaInfo.defaultReplicaCount)
					setAbleToUpdateReplicaCount(false)
					setTimeout(() => {
						props
							.refresh(false)
							.then(() => {
								setShowResetSpinner(false)
							})
							.catch(() => {
								setShowResetSpinner(false)
							})
					}, 1000)
				})
				.catch(() => {
					dispatch(
						modalManagerActions.showSnackbar({
							message: `${props.workloadController.name} ${t('WORKLOAD_RESOURCE_PLAN:UPDATE_REPLICA_COUNT_FAILED')}`,
							icon: defaultIcons.error,
						})
					)
				})
		}
	}

	const updateScaleableResourceReplicaCount = () => {
		if (props.workloadController.runtimeConfig.k8sRuntimeId && props.workloadController.name) {
			setShowApplySpinner(true)
			void operatorApi
				.addOrUpdateWorkloadsResourcePlan(
					props.workloadController.runtimeConfig.k8sRuntimeId,
					props.workloadController.name,
					props.workloadController.kind,
					desiredReplicaCount
				)
				.then(() => {
					dispatch(
						modalManagerActions.showSnackbar({
							message: `${props.workloadController.name} ${t(
								'WORKLOAD_RESOURCE_PLAN:UPDATE_REPLICA_COUNT'
							)}: ${desiredReplicaCount}`,
							icon: defaultIcons.check,
						})
					)
					setSavedReplicaCount(desiredReplicaCount)
					setAbleToUpdateReplicaCount(false)
					setTimeout(() => {
						props
							.refresh(false)
							.then(() => {
								setShowApplySpinner(false)
							})
							.catch(() => {
								setShowApplySpinner(false)
							})
					}, 1000)
				})
				.catch(() => {
					dispatch(
						modalManagerActions.showSnackbar({
							message: `${props.workloadController.name} ${t('WORKLOAD_RESOURCE_PLAN:UPDATE_REPLICA_COUNT_FAILED')}`,
							icon: defaultIcons.error,
						})
					)
				})
		}
	}

	return (
		<>
			{allowScaling && (
				<div className={classes.container}>
					<h4>{t('WORKLOAD_RESOURCE_PLAN:SCALE') + ' ' + t('GENERAL:WORKLOAD_CONTROLLER')}</h4>
					<div className={classes.outerContent}>
						<Text>{`${props.workloadController.runtimeConfig.k8sRuntimeId}/${props.workloadController.name} ${t(
							'WORKLOAD_RESOURCE_PLAN:UPDATE_REPLICA_COUNT'
						)}`}</Text>
						<div className={classes.innerContent}>
							<div className={classes.input}>
								<NumberInput
									onChange={(value) => {
										const newReplicaCount = !value ? 0 : value
										setDesiredReplicaCount(newReplicaCount)
									}}
									value={desiredReplicaCount}
									label={t('WORKLOAD_RESOURCE_PLAN:DESIRED_REPLICAS')}
									onKeyDown={(event) => {
										if (event.key === 'Enter') {
											// Cancel the default action, if needed
											event.preventDefault()
											// Trigger the button element with a click

											updateScaleableResourceReplicaCount()
										}
									}}
									stretch
									textAlignment="right"
								/>
							</div>
							<div className={classes.buttonGroup}>
								{scaledBy !== e_ScaledBy.default && (
									<Button
										onClick={() => {
											removeScaleableResourceReplicaCount()
										}}
										className={classes.button}
										screenTip={t('WORKLOAD_RESOURCE_PLAN:RESET_TO_DEFAULT_DESC')}
										variant="inline"
									>
										{showResetSpinner && <Spinner />}
										{!showResetSpinner && t('WORKLOAD_RESOURCE_PLAN:RESET_TO_DEFAULT')}
									</Button>
								)}
								<Button
									disabled={!ableToUpdateReplicaCount}
									variant="primary"
									onClick={updateScaleableResourceReplicaCount}
									className={classes.button}
								>
									{showApplySpinner && <Spinner />}
									{!showApplySpinner && t('GENERAL:APPLY')}
								</Button>
							</div>
						</div>
						<Text style={{ color: 'red', whiteSpace: 'pre-wrap' }}>{errorMessage}</Text>
					</div>
				</div>
			)}
		</>
	)
}
