import React, { useRef, useState, useEffect, useCallback } from 'react'
import classNames from 'clsx'
import numeral from 'numeral'

import type { IFormControl } from '../FormControl'
import { FormControl, FormControlButton } from '../FormControl'
import { createStyle } from '../../theming'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useID } from '../utils/useID'
import { useStyleContext } from '../utils/Style.context'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { ReadOnlyIndicator } from '../FormControl/ReadOnlyIndicator'

const classes = createStyle({
	alignRight: { textAlign: 'right' },
	alignCenter: { textAlign: 'center' },
	input: { fontWeight: 'inherit' },
})

interface INumberInputProps extends IFormControl {
	value?: number | null
	incrementInterval?: number
	placeholder?: string
	formatString?: string
	valueFormatter?: (value: any) => any
	isPercentNumber?: boolean
	formatting?: any
	type?: 'int' | 'float'
	textAlignment?: 'left' | 'center' | 'right'
	min?: number
	max?: number
	onChange?: (value: number | null) => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>, parsedNumber?: number | null) => void
	onBlur?: (value: number | null) => void
	actionButtonRef?: React.Ref<HTMLButtonElement>
}

enum Modes {
	display,
	edit,
}

const clampValue = (value: number, min: number | undefined, max: number | undefined) => {
	if (min !== undefined && value < min) {
		return min
	}

	if (max !== undefined && value > max) {
		return max
	}

	return value
}

const isPercentFormat = (formatString: string | undefined, isPercentNumber: boolean | undefined) => {
	if (isPercentNumber) {
		return true
	}

	if (!formatString) {
		return false
	}

	return formatString.includes('NFP')
}

export const NumberInput = React.forwardRef((props: INumberInputProps, forwardedRef: React.Ref<HTMLInputElement>) => {
	const id = useID(props.id)
	const inputRef = useForwardedRef<HTMLInputElement>(forwardedRef)
	const inputNumber = useRef<number | null | undefined>(props.value)

	const [editValue, setEditValue] = useState<string>()
	const [displayValue, setDisplayValue] = useState<string>()
	const userIsWriting = useRef(false)

	const [invalidEditValue, setInvalidEditValue] = useState(false)

	const [mode, setMode] = useState(Modes.display)

	const formatEditValue = useCallback(
		(value?: number | null) => {
			if (value === null || value === undefined) {
				return
			}

			if (isPercentFormat(props.formatString, props.isPercentNumber)) {
				return numeral(value).multiply(100).value().toString().replace('.', numeral.localeData().delimiters.decimal)
			}

			return value.toString().replace('.', numeral.localeData().delimiters.decimal)
		},
		[props.formatString]
	)

	const formatDisplayValue = useCallback(
		(value?: number | null) => {
			if (value === null || value === undefined) {
				return
			}

			if (props.valueFormatter) {
				return props.valueFormatter(value)
			}

			return formatEditValue(value)
		},
		[props.valueFormatter, props.formatting, formatEditValue]
	)

	useEffect(() => {
		// We should not update the edit value while the input is focused

		if (userIsWriting.current === true) {
			return
		}

		inputNumber.current = props.value
		setEditValue(formatEditValue(props.value))
		setDisplayValue(formatDisplayValue(props.value))
	}, [props.value, formatDisplayValue])

	const parseNumber = (value: string) => {
		// undefined means invalid value, and returning a number or null means value has been parsed

		if (!value.length) {
			// value null is a valid value
			return null
		}

		if (props.type === 'int' && (value.includes(',') || value.includes('.') || value.includes(' '))) {
			// thousand separator or decimal separator is not valid in an int
			return undefined
		}

		const localeData = numeral.localeData()

		// use a regular expression to pre-validate the number string
		let genericNumberString = value
		if (props.type === 'int') {
			// required format: (-)123456

			const intPattern = /^-?\d+$/

			if (!intPattern.test(value.trim())) {
				return undefined
			}
		} else {
			let floatValue = value
			const firstCharacterIsCommaOrPeriod = /^[,.]/.test(floatValue.trim())
			if (firstCharacterIsCommaOrPeriod) {
				floatValue = `0${value}`
			}
			// required format: (-)123(.456)
			const floatPattern = new RegExp(`^-?[0-9]+(\\${localeData.delimiters.decimal}[0-9]+)?$`)

			if (!floatPattern.test(floatValue.trim())) {
				return undefined
			}

			genericNumberString = floatValue.replace(localeData.delimiters.decimal, '.')
		}

		// Make sure we have a value left to parse after conversion to generic number string
		if (
			!genericNumberString.length ||
			genericNumberString === localeData.delimiters.thousands ||
			genericNumberString === localeData.delimiters.decimal
		) {
			return undefined
		}

		if (isNaN(Number(genericNumberString))) {
			return undefined
		}

		const number = props.type === 'float' ? parseFloat(genericNumberString) : parseInt(genericNumberString)

		if (isPercentFormat(props.formatString, props.isPercentNumber)) {
			return numeral(number).divide(100).value()
		}

		return clampValue(number, props.min, props.max)
	}

	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseNumber(event.target.value)

		setEditValue(event.target.value)
		setInvalidEditValue(value === undefined)

		if (value !== undefined) {
			if (props.value === value) {
				return
			}

			inputNumber.current = value

			props.onChange?.(value)
		}
	}

	const handleClick = () => {
		userIsWriting.current = true
	}

	const selectInputValueOnRender = useRef(false)
	const onFocus = () => {
		setMode(Modes.edit)
		setEditValue(formatEditValue(props.value))
		setInvalidEditValue(props.value === undefined)
		selectInputValueOnRender.current = true
	}

	useEffect(() => {
		if (selectInputValueOnRender.current) {
			inputRef.current?.select()
			selectInputValueOnRender.current = false
		}
	})

	const onBlur = () => {
		userIsWriting.current = false
		setMode(Modes.display)
		setDisplayValue(formatDisplayValue(props.value))
		setEditValue(formatEditValue(props.value))
		setInvalidEditValue(props.value === undefined)

		if (inputNumber.current === undefined) {
			return
		}

		props.onBlur?.(inputNumber.current)
	}

	const adjustValue = (value: string, increment: number) => {
		inputRef.current?.select()

		const parsedValue = parseNumber(value)

		let incrementedValue = clampValue((parsedValue || 0) + increment, props.min, props.max)

		if (props.type === 'float') {
			incrementedValue = parseFloat(incrementedValue.toPrecision(12))
		}

		inputNumber.current = incrementedValue

		setEditValue(formatEditValue(incrementedValue))
		setInvalidEditValue(incrementedValue === undefined)

		props.onChange?.(incrementedValue)
	}

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (props.readOnly || props.disabled) {
			return
		}

		switch (e.key) {
			case 'Enter':
				props.defaultAction?.()
				break
			case 'ArrowUp':
				e.preventDefault()
				props.incrementInterval && adjustValue((e.target as HTMLInputElement).value, props.incrementInterval)
				break
			case 'ArrowDown':
				e.preventDefault()
				props.incrementInterval && adjustValue((e.target as HTMLInputElement).value, -props.incrementInterval)
		}

		props.onKeyDown?.(e, inputNumber.current)
	}

	const inputValue = mode === Modes.display ? displayValue : editValue

	const { placeholder, readOnlyIndicatorStyle } = { ...useStyleContext().style?.input }

	const numberInputMode = props.type === 'int' ? 'numeric' : 'decimal'
	const inputMode = props.min !== undefined && props.min >= 0 ? numberInputMode : 'text'

	const contentCSS = classNames(
		{
			[classes.alignRight]: props.textAlignment === 'right',
			[classes.alignCenter]: props.textAlignment === 'center',
		},
		classes.input
	)

	return (
		<FormControl
			id={id}
			label={props.label}
			labelPosition={props.labelPosition}
			icon={props.icon}
			labelContentLayout={props.labelContentLayout}
			hideLabel={props.hideLabel}
			labelSubText={props.labelSubText}
			validationText={props.validationText}
			validationTextPosition={props.validationTextPosition}
			subText={props.subText}
			reserveHelperTextSpace={props.reserveHelperTextSpace}
			disabled={props.disabled}
			readOnly={props.readOnly}
			required={props.required}
			error={props.error || invalidEditValue}
			warning={props.warning}
			margin={props.margin}
			labelProps={props.labelProps}
			className={props.className}
			disableBorder={props.disableBorder}
			screenTip={props.screenTip}
			onClick={props.onClick}
			onMouseDown={props.onMouseDown}
			borderClassName={props.borderClassName}
		>
			{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeValue && (
				<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignLeft />
			)}
			<input
				ref={inputRef}
				className={contentCSS}
				id={id}
				{...props.dataAttributes}
				placeholder={props.placeholder ?? placeholder}
				value={inputValue !== undefined ? inputValue : ''}
				onKeyDown={onKeyDown}
				type="text"
				autoComplete="off" // autoComplete="off" does now work with Chrome
				spellCheck="false"
				onChange={onInputChange}
				onFocus={onFocus}
				onBlur={onBlur}
				onClick={handleClick}
				disabled={props.disabled}
				readOnly={props.readOnly}
				name={props.name}
				inputMode={inputMode}
				min={props.min}
				max={props.max}
			/>
			{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterValue && (
				<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
			)}
			{props.defaultAction && !props.hideActionButton && (
				<FormControlButton
					iconClassName={props.actionIcon}
					screenTip={props.actionScreenTip}
					onClick={!props.disabled ? props.defaultAction : undefined}
					disabled={props.disabled}
					ref={props.actionButtonRef}
					{...props.dataAttributes}
				/>
			)}
		</FormControl>
	)
})

NumberInput.defaultProps = {
	actionIcon: 'Fluent-Forward',
}

NumberInput.displayName = 'NumberInput'
