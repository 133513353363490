import type { DialogButtonDescription } from '../DialogTypes'

export const generateCommonButtonDescription = (
	commonButton: 'ok' | 'cancel' | 'yes' | 'no' | 'close',
	handleClose: (commonButton: 'ok' | 'cancel' | 'yes' | 'no' | 'close') => void,
	translator: (t: string) => string,
	isValid?: boolean
): DialogButtonDescription => {
	switch (commonButton) {
		case 'ok':
			return {
				title: translator('COMMAND:OK'),
				onClick: () => handleClose('ok'),
				isEnabled: isValid !== undefined ? isValid : true,
				isDefault: true,
				isCancel: false,
				alignLeft: false,
				dataAttributes: { 'data-cy': 'dialog-ok-btn' },
			}
		case 'cancel':
			return {
				title: translator('COMMAND:CANCEL'),
				onClick: () => handleClose('cancel'),
				isEnabled: true,
				isDefault: false,
				isCancel: true,
				alignLeft: false,
				dataAttributes: { 'data-cy': 'dialog-cancel-btn' },
			}
		case 'yes':
			return {
				title: translator('COMMAND:YES'),
				onClick: () => handleClose('yes'),
				isEnabled: isValid !== undefined ? isValid : true,
				isDefault: true,
				isCancel: false,
				alignLeft: false,
				dataAttributes: { 'data-cy': 'dialog-yes-btn' },
			}
		case 'no':
			return {
				title: translator('COMMAND:NO'),
				onClick: () => handleClose('no'),
				isEnabled: true,
				isDefault: false,
				isCancel: false,
				alignLeft: false,
				dataAttributes: { 'data-cy': 'dialog-no-btn' },
			}
		case 'close':
			return {
				title: translator('COMMAND:CLOSE'),
				onClick: () => handleClose('cancel'),
				isEnabled: true,
				isDefault: false,
				isCancel: false,
				alignLeft: false,
				dataAttributes: { 'data-cy': 'dialog-close-btn' },
			}
	}
}
