import type { TData, Value } from '../Table.types'

export function formatGroupCellValue(data: TData | undefined, formattedValue: Value, value: Value) {
	if (data?.rowName) {
		return data.rowName
	}

	if (formattedValue === '') {
		return '---'
	}

	const _formattedValue = getStringFromValidValue(formattedValue)
	if (_formattedValue) {
		return _formattedValue
	}

	const _value = getStringFromValidValue(value)
	if (_value) {
		return _value
	}

	return '---'
}

function getStringFromValidValue(value: Value) {
	if (value === undefined || value === null) {
		return null
	}
	const trimmedStringValue = value.toString().trim()
	if (trimmedStringValue) {
		return trimmedStringValue
	}
	return null
}
