import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'

import type { DropdownListMenuItem, IDropdownOption } from './Dropdown.types'
import { DropdownList } from './DropdownList'
import type { IFormControl } from '../FormControl'
import { FormControl } from '../FormControl'
import type { DropdownOptionSelectable } from './DropdownOptionsHandler'
import { DropdownOptionsHandler, isSelectableOption } from './DropdownOptionsHandler'
import { useID } from '../utils/useID'
import { useTranslation } from '../../translation'
import { useForwardedRef } from '../utils/useForwardedRef'
import { InputHeight } from '../utils/InputHeight'
import { useStyleContext } from '../utils/Style.context'
import { Icon } from '../Icon/Icon'
import { Spinner } from '../Spinner'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { ReadOnlyIndicator } from '../FormControl/ReadOnlyIndicator'

const classes = createStyle((theme) => ({
	linkedValue: {
		color: theme.colors.links.text + '!important',
		'&:hover': {
			textDecoration: 'underline',
			color: theme.colors.links.hoveredText,
		},
	},
	button: {
		fontWeight: 'inherit',
		display: 'flex',
		flex: 1,
		padding: 0,
		alignItems: 'center',
		overflow: 'hidden',
		userSelect: 'none',
		background: 'none',
		border: 'none',
		color: theme.colors.button.text,
		'&:focus': {
			outline: 'none',
		},
	},

	dropdownIcon: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		padding: '1px 8px',
	},
	spinner: {
		width: 14,
		height: 14,
		margin: '0px 4px',
		cursor: 'progress',
	},
	enabled: {
		cursor: 'pointer',
	},
	disabled: {
		opacity: 0.5,
		cursor: 'auto',
	},
	content: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		flex: 1,
		textAlign: 'left',
		padding: theme.controls.input.padding,
	},
	placeholder: {
		color: theme.colors.input.placeholderText,
	},
}))

interface IDropdownBase<T> extends IFormControl {
	ref?: React.ForwardedRef<HTMLDivElement>
	displayLabel?: string
	isLoadingOptions?: boolean
	defaultOpen?: boolean
	onOpen?: () => void
	onClose?: () => void
	onClick?: (e: React.MouseEvent) => void
	onContextMenu?: (e: React.MouseEvent) => void
	onCtrlClick?: (e: React.MouseEvent) => void
	allowNull?: boolean
	options?: IDropdownOption<T>[]
	placeholder?: string
	screenTip?: string
	reserveIconSpace?: boolean
	style?: React.CSSProperties
	headerLeftItems?: DropdownListMenuItem[]
	headerRightItems?: DropdownListMenuItem[]
	footerLeftItems?: DropdownListMenuItem[]
	footerRightItems?: DropdownListMenuItem[]
	emptyListMessage?: string
	maxHeight?: number
	wrapperDataAttributes?: Record<string, string>
}

interface ISingleSelectDropdown<T> extends IDropdownBase<T> {
	value: T | undefined
	values?: undefined
	onChange?: (value: T) => void
	multiSelect?: false
	allowNull?: false
}

interface ISingleSelectDropdownNullable<T> extends IDropdownBase<T> {
	value: T | undefined | null
	values?: undefined
	onChange?: (value: T | null) => void
	multiSelect?: false
	allowNull: true
}

interface IMultiSelectDropdown<T> extends IDropdownBase<T> {
	value?: undefined
	values?: T[]
	onChange?: (values: T[]) => void
	multiSelect: true
	allowNull?: undefined
}

const DropdownForwardRef = <T,>(
	props: ISingleSelectDropdown<T> | ISingleSelectDropdownNullable<T> | IMultiSelectDropdown<T>,
	ref: React.Ref<HTMLButtonElement>
) => {
	const { defaultOpen = false, onClose } = props

	const { tcvi } = useTranslation()
	const options = useMemo(
		() => new DropdownOptionsHandler(props.options, props.allowNull, tcvi, props.emptyListMessage),
		[props.allowNull, props.emptyListMessage, props.options, tcvi]
	)

	const values = useMemo(() => new Set<T | null>(props.values), [props.values])

	const [localValue, setLocalValue] = useState<T | null>()

	const [isOpen, setIsOpen] = useState(defaultOpen)

	useEffect(() => {
		if (defaultOpen) {
			props.onOpen?.()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setLocalValue(props.value)
	}, [props.value])

	useEffect(() => {
		if (props.multiSelect && !values.size) {
			setLocalValue(undefined)
		}

		if (localValue !== undefined) {
			return
		}

		options.some((item) => {
			if (isSelectableOption(item) && values.has(item.value as T | null)) {
				setLocalValue(item.value as T | null)
				return true
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.values])

	const id = useID(props.id)

	const titleRef = useForwardedRef<HTMLButtonElement>(ref)

	const anchorElementRef = useRef<HTMLDivElement>(null)

	const dropdownListId = useID()
	const [activeDescendant, setActiveDescendant] = useState<string | undefined>(undefined)

	const displayValue = useMemo(() => {
		let newDisplayValue = ''
		if (options) {
			if (props.multiSelect && values.size) {
				const labels: string[] = []
				options.forEach((item) => {
					if (isSelectableOption(item) && values.has(item.value as T | null)) {
						labels.push(item.label)
					}
				})
				newDisplayValue = labels.join(', ')
			} else {
				const selectedValue = options.find((item) => {
					return isSelectableOption(item) && item.value === localValue
				}) as DropdownOptionSelectable<T>

				if (selectedValue?.type !== 'null' && selectedValue?.label) {
					newDisplayValue = selectedValue.label
				}
			}
		}
		return newDisplayValue
	}, [options, props.multiSelect, values, localValue])

	const preselectedIndex = options.findIndex((item) => isSelectableOption(item) && item.value === localValue)

	const onOptionSelect = (value: T | null) => {
		if (props.multiSelect) {
			if (values.has(value)) {
				values.delete(value)

				props.onChange?.(Array.from(values) as T[])

				const iterator = values.values()
				const result = iterator.next()
				if (!result.done) {
					setLocalValue(result.value)
				}
			} else {
				values.add(value)

				props.onChange?.(Array.from(values) as T[])

				setLocalValue(value)
			}
		} else {
			props.onChange?.(value as T)

			setLocalValue(value)
			closeDropdown()
		}
	}

	const onClick = (e: React.MouseEvent) => {
		props.onClick?.(e)
		if (props.readOnly) {
			return
		}
		e.stopPropagation()
		if ((e.ctrlKey || e.metaKey) && props.onCtrlClick) {
			props.onCtrlClick(e)
		} else {
			if (isOpen) {
				closeDropdown()
			} else {
				openDropdown()
			}
		}
	}

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (props.readOnly) {
			return
		}
		if (!isOpen) {
			if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
				e.preventDefault()

				openDropdown()
			}
		}
		// Closing of Dropdown is done by keydown-eventlistener in DropdownList or in onOptionSelect
	}

	const openDropdown = () => {
		if (!props.disabled && !props.readOnly) {
			props.onOpen?.()
			setIsOpen(true)
			titleRef.current?.focus()
		}
	}

	const closeDropdown = useCallback(() => {
		setIsOpen(false)
		titleRef.current?.focus()
		onClose?.()
	}, [onClose, titleRef])

	const { placeholder, readOnlyIndicatorStyle } = { ...useStyleContext().style?.input }
	const showPlaceholder = !props.displayLabel && !displayValue && (props.placeholder ?? placeholder)

	return (
		<React.Fragment>
			<FormControl
				id={id}
				label={props.label}
				labelPosition={props.labelPosition}
				labelProps={props.labelProps}
				icon={props.icon}
				labelContentLayout={props.labelContentLayout}
				hideLabel={props.hideLabel}
				ref={anchorElementRef}
				screenTip={props.screenTip}
				labelSubText={props.labelSubText}
				validationText={props.validationText}
				validationTextPosition={props.validationTextPosition}
				subText={props.subText}
				reserveHelperTextSpace={props.reserveHelperTextSpace}
				disabled={props.disabled}
				readOnly={props.readOnly}
				required={props.required}
				margin={props.margin}
				error={props.error}
				warning={props.warning}
				onMouseDown={(e) => isOpen && e.preventDefault()}
				className={props.className}
				stretch={props.stretch}
				disableBorder={props.disableBorder}
				dataAttributes={props.wrapperDataAttributes}
				contentDataAttributes={props.contentDataAttributes}
			>
				<InputHeight />
				<button
					ref={titleRef}
					id={props.id}
					onKeyDown={onKeyDown}
					aria-haspopup="listbox"
					aria-expanded={isOpen}
					aria-controls={isOpen ? dropdownListId : undefined}
					aria-activedescendant={isOpen ? activeDescendant : undefined}
					onClick={onClick}
					onContextMenu={props.onContextMenu}
					data-placeholder={props.placeholder || placeholder}
					className={classNames(classes.button, {
						[classes.linkedValue]: props.onCtrlClick,
						[classes.enabled]: !props.disabled,
						[classes.disabled]: props.disabled,
					})}
					title={props.screenTip || props.displayLabel || displayValue}
					style={props.style}
					data-cy={{ ['data-cy']: id + '-dropdown-button' }}
				>
					{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeValue && (
						<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignLeft />
					)}

					<span
						className={classNames(classes.content, showPlaceholder && classes.placeholder)}
						{...props.dataAttributes}
					>
						{props.displayLabel || displayValue || (showPlaceholder ? showPlaceholder : undefined) || '\xa0'}
					</span>
					{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterValue && (
						<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
					)}

					{props.isLoadingOptions && <Spinner as="span" className={classes.spinner} />}
					{!props.isLoadingOptions && (
						<span className={classes.dropdownIcon}>
							<Icon iconName="Fluent-ChevronDown" size="extraSmall" />
						</span>
					)}
				</button>
			</FormControl>
			<DropdownList
				anchorElement={anchorElementRef}
				maxHeight={props.maxHeight}
				isOpen={isOpen}
				isLoadingOptions={props.isLoadingOptions}
				options={options}
				value={localValue}
				values={values}
				onChange={onOptionSelect}
				onClose={closeDropdown}
				multiSelect={!!props.multiSelect}
				preselectedIndex={preselectedIndex}
				className={props.className}
				reserveIconSpace={props.reserveIconSpace}
				style={props.style}
				statusText={props.isLoadingOptions ? tcvi('GENERAL:LOADING') + '...' : undefined}
				typeJumping
				headerLeftItems={props.headerLeftItems}
				headerRightItems={props.headerRightItems}
				footerLeftItems={props.footerLeftItems}
				footerRightItems={props.footerRightItems}
				emptyListMessage={props.emptyListMessage}
				id={dropdownListId}
				onActiveItemChange={setActiveDescendant}
			/>
		</React.Fragment>
	)
}

export const Dropdown = React.forwardRef(DropdownForwardRef) as typeof DropdownForwardRef
