import type { RowData, TData } from '../../../Table.types'
import { tDataToRowData } from '../../../utils'

export const getOnIconClick = (
	nodeId: string,
	nodeData: TData | undefined,
	propsOnClick: ((rowData: RowData, targetEl: React.RefObject<HTMLElement>) => void) | undefined,
	cellDataOnIconClick: ((rowId: string) => void) | undefined
) => {
	if (propsOnClick) {
		return (targetEl: React.RefObject<HTMLElement>) => {
			const rowData = tDataToRowData(nodeData)

			rowData && propsOnClick(rowData, targetEl)
		}
	}

	if (cellDataOnIconClick) {
		return () => {
			cellDataOnIconClick(nodeId)
		}
	}
}
