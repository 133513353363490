import { DurationInput } from '../../../../DurationInput'
import { e_Interpretation } from '../../../enums/e_Interpretation'
import React, { useEffect, useRef } from 'react'
import type { ICellRenderer, Value } from '../../../Table.types'

interface ICellRendererDuration extends ICellRenderer {
	value: number | undefined
	interpretation: e_Interpretation
	popperOpen: React.MutableRefObject<boolean>
	onClose: (value: Value) => void
}

export const CellRendererDuration = (props: ICellRendererDuration) => {
	const valueRef = useRef<number | null | undefined>(props.value)
	const inputRef = useRef<HTMLInputElement>(null)

	const onChange = (value: number | null | undefined) => {
		valueRef.current = value
		props.setValue?.(value)
	}

	const onClose = () => {
		props.onClose(valueRef.current)
	}

	const isIntegerTime = props.interpretation === e_Interpretation.integerTime

	useEffect(() => {
		inputRef.current?.focus()
	}, [])

	return (
		<DurationInput
			ref={inputRef}
			value={props.value}
			onChange={onChange}
			onClose={onClose}
			enableOptionDropdown
			isIntegerTime={isIntegerTime}
			dropdownOpen={props.popperOpen.current}
			reserveHelperTextSpace="off"
			className={props.className}
			error={props.hasError}
		/>
	)
}
