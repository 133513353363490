import React from 'react'
import classNames from 'clsx'

import { createStyle } from '../../theming'
import { Icon } from '../Icon'
import { e_LabelContentLayout } from './Label.types'
import { ReadOnlyIndicator } from './ReadOnlyIndicator'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { useTranslation } from '../../translation'
import { e_RequiredIndicatorStyle } from '../../enums/e_RequiredIndicatorStyle'

const classes = createStyle((theme) => ({
	label: {
		whiteSpace: 'nowrap',
		lineHeight: 'normal',
	},
	disabled: {
		userSelect: 'none',
		opacity: 0.5,
	},

	boldLabel: {
		fontWeight: 'bold !important',
	},

	marginTop: { margin: '4px 0' },
	marginRight: { marginRight: '0.4rem' },
	marginLeft: { marginLeft: '0.2rem' },
	labelContent: {
		display: 'flex',
		alignItems: 'baseline',
		'&[data-required-indicator="displaySymbolAfterLabel"]:after': {
			content: '" *"',
			position: 'relative',
			top: -3,
			lineHeight: '0.5em',
		},
		'&[data-required-indicator="displaySymbolBeforeLabel"]:before': {
			content: '"* "',
			position: 'relative',
			top: -3,
			lineHeight: '0.5em',
			paddingRight: 2,
		},
	},
	readOnlyIndicatorVisible: { minWidth: 18 },
	requiredIndicator: {
		lineHeight: '0.5em',
		alignSelf: 'start',
		color: theme.colors.body.text,
	},
	content: {
		display: 'inline-flex',
		gap: 4,
		height: '100%',
		alignItems: 'baseline',
		'& i': {
			alignSelf: 'center',
		},
	},
	textAndIcon: {
		'& i': {
			order: 2,
		},
	},
	subText: {
		marginTop: '0.2rem',
		whiteSpace: 'initial',
		fontWeight: 'normal',
	},
}))

interface ILabelProps {
	id?: string
	text?: string
	position?: 'top' | 'right' | 'left'
	required?: boolean
	labelProps: { [attribute: string]: string | number | React.CSSProperties }
	disabled?: boolean
	readOnly?: boolean
	className?: string
	style?: React.CSSProperties
	icon?: string
	labelContentLayout?: e_LabelContentLayout
	labelSubText?: string
	indicateReadOnly?: boolean
	colonAfterLabel?: boolean
	boldLabel?: boolean
	disableLabelIdLinking?: boolean
	readOnlyIndicatorStyle?: e_ReadOnlyIndicatorStyle
	requiredIndicatorStyle?: e_RequiredIndicatorStyle
}

export const Label = (props: ILabelProps) => {
	const { tcvi } = useTranslation()

	const labelId = props.text && props.id ? props.id + '-label' : undefined

	const { labelContentLayout: contentLayout = e_LabelContentLayout.text } = props

	const {
		boldLabel,
		colonAfterLabel,
		readOnlyIndicatorStyle,
		requiredIndicatorStyle = e_RequiredIndicatorStyle.displaySymbolAfterLabel,
	} = props

	const labelContentCSS = classNames(classes.labelContent, {
		[classes.boldLabel]: boldLabel,
	})

	const css = classNames(
		classes.label,
		{
			[classes.disabled]: props.disabled,
			[classes.marginLeft]: props.position === 'right',
			[classes.marginRight]: props.position === 'left',
			[classes.marginTop]: props.position === 'top' || !props.position,
			[labelContentCSS]: !props.labelSubText,
		},
		props.labelProps.className,
		props.className
	)

	const labelProps = { ...props.labelProps }
	delete labelProps.className

	/**
	 * Used to set the required indicator ('*') in the before or after in the css
	 */
	const dataRequiredIndicator = props.required ? requiredIndicatorStyle : undefined

	const textAndIcon = (
		<>
			{contentLayout !== e_LabelContentLayout.text && <Icon iconClassName={props.icon} />}
			{contentLayout !== e_LabelContentLayout.icon ? props.text : ' \u200B'}
		</>
	)

	const textAndIconContent = (
		<>
			{[e_LabelContentLayout.iconAndText, e_LabelContentLayout.textAndIcon].includes(contentLayout) ? (
				<span
					className={classNames(classes.content, {
						[classes.textAndIcon]: e_LabelContentLayout.textAndIcon === contentLayout,
					})}
				>
					{textAndIcon}
				</span>
			) : (
				<>{textAndIcon}</>
			)}
		</>
	)

	const labelContent = (
		<>
			{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeLabel && props.indicateReadOnly && (
				<ReadOnlyIndicator isReadOnly={props.readOnly === true} alwaysVisible={props.position === 'left'} />
			)}
			{textAndIconContent}
			{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterLabel && props.indicateReadOnly && (
				<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
			)}

			{colonAfterLabel && <span aria-hidden="true">:</span>}
		</>
	)

	return (
		<label
			className={css}
			id={labelId}
			htmlFor={!props.disableLabelIdLinking ? labelId && props.id : undefined}
			{...labelProps}
			data-required-indicator={!props.labelSubText && dataRequiredIndicator}
			title={props.required ? tcvi('GENERAL:THIS_FIELD_IS_REQUIRED') : undefined}
		>
			{props.labelSubText ? (
				<>
					<span className={labelContentCSS} data-required-indicator={dataRequiredIndicator}>
						{labelContent}
					</span>
					<span className={classes.subText}>{props.labelSubText}</span>
				</>
			) : (
				labelContent
			)}
		</label>
	)
}

Label.defaultProps = {
	text: '',
	labelProps: {},
}
