import React from 'react'
import { HeaderControl } from './HeaderControl'
import type { IDataPropertyDescription } from '../types/IDataPropertyDescription'
import type { ISortedColumn } from '../types/ISortedColumn'
import { createStyle } from '../../../theming'
import type { IDataWithIdentifier } from '../types/IDataWithIdentifier'
import { MasterDetailAgTableView } from './MasterDetailAgTableView'
import type { IActionEntry } from '../types/IActionDescription'
import type { IMasterDetailPreakpoint } from '../types/IMasterDetailBreakpoint'
import type { IMasterViewColumnItemDescription } from '../types/IMasterViewColumnItemDescription'

const classes = createStyle({
	masterview: {
		flex: 1,
		// background: "rgba(128,0,0, 0.2)",
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},

	masterTable: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		overflow: 'auto',
		alignItems: 'stretch',
		background: 'white',
	},

	item: {
		display: 'flex',
		padding: '4px 8px',
		userSelect: 'none',
	},

	tableHeader: {
		display: 'flex',
		padding: '8px 8px',
		userSelect: 'none',
		position: 'sticky',
		top: '0px',
		background: 'white',
		borderBottom: '1px solid silver',
	},
	itemSelected: {
		background: 'rgba(0,0,0,0.2)',
	},
	itemActive: {
		background: 'rgba(0,0,0,0.4)',
	},
})

interface IMasterViewProps {
	id: string
	title: string
	items: IDataWithIdentifier[]

	selectedIds: string[]
	setSelectedIds: (ids: string[]) => void

	activeId: string | undefined
	setActiveId: (id: string | undefined) => void
	columns: IDataPropertyDescription[]
	columnGroups?: IMasterViewColumnItemDescription[]

	sortedColumns?: ISortedColumn[]
	sortColumns?: (sortedColumns: ISortedColumn[]) => void

	filterText?: string
	setFilterText?: (filterText: string) => void

	isMultiSelect?: boolean
	setIsMultiSelect?: (multiSelect: boolean) => void

	breakpoint: IMasterDetailPreakpoint

	itemActions?: IActionEntry[]
	selectedItemsActions?: IActionEntry[]

	handleItemAction?: (actionId: string, itemId: string) => void
	handleSelectedItemsAction?: (actionId: string, itemIds: string[]) => void
	globalActions?: IActionEntry[]
	handleGlobalAction?: (actionId: string) => void
}

export const MasterView = React.forwardRef((props: IMasterViewProps, forwardedRef: React.Ref<HTMLDivElement>) => {
	return (
		<div className={classes.masterview} ref={forwardedRef}>
			<HeaderControl
				title={props.title}
				filterText={props.filterText}
				setFilterText={props.setFilterText}
				columns={props.columns}
				sortedColumns={props.sortedColumns}
				sortColumns={props.sortColumns}
				isMultiSelect={props.isMultiSelect}
				setIsMultiSelect={props.setIsMultiSelect}
				count={props.items.length}
				selectedIds={props.selectedIds}
				selectedItemsActions={props.selectedItemsActions}
				globalActions={props.globalActions}
				handleGlobalAction={props.handleGlobalAction}
				handleSelectedItemsAction={props.handleSelectedItemsAction}
			/>
			<MasterDetailAgTableView
				id={props.id}
				items={props.items}
				columns={props.columns}
				columnGroups={props.columnGroups}
				sortedColumns={props.sortedColumns}
				sortColumns={props.sortColumns}
				activeId={props.activeId}
				setActiveId={props.setActiveId}
				selectedIds={props.selectedIds}
				setSelectedIds={props.setSelectedIds}
				isMultiSelect={props.isMultiSelect}
				itemActions={props.itemActions}
				handleItemAction={props.handleItemAction}
			/>
		</div>
	)
})

MasterView.displayName = 'MasterView'
