import React from 'react'
import { e_MenuItemType, MenuItem } from '@genusbiz/web-ui/controls/Menu'
import { kubernetesActions } from 'src/features/Kubernetes/duck/kubernetesActions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createStyle } from '@genusbiz/web-ui/theming'
import { CommandBar } from '@genusbiz/web-ui/controls'
const classes = createStyle((theme) => ({
	operatorCommandBar: {
		backgroundColor: theme.colors.panel.background,
		display: 'flex',
		borderBottom: '1px solid ' + theme.colors.panel.border,
		justifyContent: 'space-between',
	},
	fullWidth: { float: 'left' },
	rightCommandBar: { float: 'right', marginRight: 16 },
}))

export const OperatorEndpointCommandBar = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const reload = () => {
		dispatch(kubernetesActions.fetchEndpoints())
	}

	const items: MenuItem[] = [
		{ type: e_MenuItemType.divider },
		{
			type: e_MenuItemType.action,
			name: t('GENERAL:REFRESH'),
			iconClassName: 'Fluent-Refresh',
			onClick: () => {
				reload()
			},
		},
		{ type: e_MenuItemType.divider },
	]

	return (
		<div className={classes.operatorCommandBar}>
			<div className={classes.fullWidth}>
				<CommandBar items={items} />
			</div>
		</div>
	)
}
