import React, { useRef, useEffect, useLayoutEffect, useContext, useMemo, useCallback } from 'react'
import ReactDOM from 'react-dom'
import type { PopperOptions } from 'popper.js'
import PopperJS from 'popper.js'
import classNames from 'clsx'
import { Fade } from './Fade'
import { topWindow } from './topWindow'
import { e_Placement } from '../../enums/e_Placement'
import { getCurrentAbsolutePosition } from './getCurrentAbsolutePosition'
import { getAllDocuments } from './getAllDocuments'
import { useEventListener } from './useEventListener'
import { createStyle, useGetComputedThemeVariation } from '../../theming'
import { useResizeObserver } from './useResizeObserver'
import {
	DATA_BLOCKING_MODAL_ELEMENT,
	DATA_PORTAL_ELEMENT,
	isBehindBlockingElement,
	isTopmostPortalElement,
} from './portalUtils'

type IPopperContext = React.MutableRefObject<HTMLDivElement | null>
const PopperContext = React.createContext<IPopperContext>({ current: null })

const classes = createStyle((theme) => ({
	popper: { zIndex: theme.zIndex.popper, pointerEvents: 'auto' },
	layer: {
		position: 'fixed',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		pointerEvents: 'none',
		overflow: 'clip',
		zIndex: theme.zIndex.popper,
	},
	layerBlocking: { pointerEvents: 'auto' },
}))

interface IPopperProps {
	anchorElement?: React.RefObject<HTMLElement | undefined>
	position?: {
		x: number
		y: number
	}
	children: React.ReactNode
	open: boolean
	className?: string
	style?: React.CSSProperties
	arrowElement?: React.ReactNode
	onCreate?: (popperNode: HTMLDivElement, string: PopperJS.PopperOptions, anchorElement?: HTMLElement) => void
	onCreated?: () => void
	onUpdate?: (popperNode: HTMLDivElement) => void
	onOutsideClick?: (e: React.MouseEvent) => void
	onEntering?: () => void
	onEntered?: () => void
	onExiting?: () => void
	onExited?: () => void

	onTabPressed?: (e: KeyboardEvent) => void
	onEscapePressed?: (e: KeyboardEvent) => void

	applyStyle?: (data: PopperJS.Data) => void
	enablePortal?: boolean
	enableBackdrop?: boolean
	placement: e_Placement
	autoFlip: boolean
	captureEvents: boolean
	windowPadding?: number
	fade?: boolean
	offset?: string
	dataAttributes?: Record<string, string>
	blocking?: boolean
	useStylesFromDocumentRoot?: boolean
}

export const Popper = (props: IPopperProps) => {
	const { onTabPressed, onEscapePressed } = props

	const popperNode = useRef<HTMLDivElement>(null)
	const topWindowAnchorParent = useRef<HTMLDivElement>()
	const popper = useRef<PopperJS>()
	const popperParentChildRef = useContext(PopperContext)
	const popperChild = useRef<HTMLDivElement>(null)

	const documents = useMemo(() => {
		return getAllDocuments()
	}, [props.open])

	const anchorElementStyles = useGetComputedThemeVariation(props.anchorElement, !props.open)
	const inheritedStyles = !props.useStylesFromDocumentRoot ? anchorElementStyles : undefined

	const onMouseDown = useCallback(
		(e: MouseEvent) => {
			if (!popperNode.current) {
				return
			}

			if (props.blocking) {
				return
			}

			if (e.defaultPrevented || !props.onOutsideClick) {
				return
			}

			if (!props.open) {
				return
			}

			if (isBehindBlockingElement(popperNode.current)) {
				return
			}

			// check if clicked directly on a child of the popper root element
			const targetElement = e.target as Element

			const isTargetChildOfPopperElement = targetElement && popperNode.current.contains(targetElement)

			if (isTargetChildOfPopperElement) {
				return
			}

			const elementsAtClickPos = document.elementsFromPoint(e.clientX, e.clientY)

			documents.some((document) => {
				const isInDocument = document.contains(e.target as Node)
				const isNotInPopperNode = popperNode.current && !elementsAtClickPos.includes(popperNode.current)
				const isNotInChildPopperNode = popperChild.current ? !elementsAtClickPos.includes(popperChild.current) : true
				const isNotInAnchorNode = props.anchorElement?.current
					? !props.anchorElement.current.contains(e.target as Node)
					: true

				return isInDocument && isNotInPopperNode && isNotInChildPopperNode && isNotInAnchorNode
			}) && props.onOutsideClick(e as unknown as React.MouseEvent)
		},
		[documents, props.open, props.onOutsideClick]
	)

	useLayoutEffect(() => {
		// create the popperjs instance when mounted
		createPopper()
	}, [])

	useEffect(() => {
		popper.current?.scheduleUpdate()
	}, [props.placement])

	useLayoutEffect(() => {
		if (props.open) {
			popperParentChildRef.current = popperNode.current

			if (popper.current && popperNode.current) {
				props.onUpdate?.(popperNode.current)
				createPopper()
			} else {
				createPopper()
			}
		}
	}, [props.open])

	const onResize = useCallback(() => {
		if (!props.open) {
			return
		}

		if (popper.current && popperNode.current) {
			createPopper()
		}
	}, [props.open])

	useEventListener('resize', onResize, topWindow)

	const createPopper = () => {
		if (!popperNode.current || (!props.anchorElement && !props.position) || !props.open) {
			return
		}

		const popperProperties: PopperOptions = {
			// PopperJS callbacks should be used for both props.onCreate & props.onUpdate
			// Refactor this logic when PopperJS is upgraded to v2
			placement: props.placement,
			modifiers: {
				arrow: { enabled: !!props.arrowElement },
				flip: { enabled: props.autoFlip },
				shift: { enabled: true },
				computeStyle: {
					gpuAcceleration: false,
				},
				preventOverflow: {
					boundariesElement: 'viewport',
					escapeWithReference: false,
					padding: props.windowPadding,
				},
				beforeApplyStyle: {
					// Custom modifier that may be applied just before the style is actually applied
					enabled: props.applyStyle !== undefined,
					order: 899, // the builtin modifier applyStyle uses order 900
					fn: (data) => {
						if (props.applyStyle) {
							props.applyStyle(data)
						}
						return data
					},
				},
				applyStyle: {
					enabled: props.applyStyle === undefined,
				},
			},
		}

		if (props.onCreated) {
			popperProperties.onCreate = props.onCreated
		}

		if (props.offset) {
			popperProperties.modifiers!.offset = {
				offset: props.offset,
				enabled: true,
				order: 200,
			}
		}

		const anchorIsInDifferentDOM =
			props.anchorElement?.current && props.anchorElement.current.ownerDocument === topWindow.document
		if (window === topWindow || anchorIsInDifferentDOM || !props.enablePortal) {
			let anchor = props.anchorElement?.current

			if (props.position) {
				const position = {
					width: 0,
					height: 0,
					top: props.position.y,
					y: props.position.y,
					bottom: props.position.y,
					left: props.position.x,
					x: props.position.x,
					right: props.position.x,
				}

				anchor = {
					getBoundingClientRect: () => position,
				} as HTMLDivElement
			}

			if (anchor) {
				props.onCreate?.(popperNode.current, popperProperties, anchor)
				popper.current = new PopperJS(anchor, popperNode.current, popperProperties)
			}
		} else {
			// When not in the top level document,
			// Create a popper element in the top level document as this is where the popper element will be mouted
			const parent = topWindow.document.createElement('div')
			parent.setAttribute('data-meta', 'embedded-popper-anchor-parent')
			parent.style.position = 'absolute'
			parent.style.top = '0'
			parent.style.left = '0'
			parent.style.visibility = 'hidden'

			topWindowAnchorParent.current?.remove()
			topWindowAnchorParent.current = parent

			const anchor = topWindow.document.createElement('div')
			anchor.style.position = 'absolute'

			const frameOffset = getCurrentAbsolutePosition(window.frameElement!)

			if (props.position) {
				anchor.style.top = `${frameOffset.y + props.position.y}px`
				anchor.style.left = `${frameOffset.x + props.position.x}px`
			} else if (props.anchorElement?.current) {
				const anchorRect = props.anchorElement.current.getBoundingClientRect()
				anchor.style.top = `${frameOffset.y + anchorRect.y}px`
				anchor.style.left = `${frameOffset.x + anchorRect.x}px`
				anchor.style.width = `${anchorRect.width}px`
				anchor.style.height = `${anchorRect.height}px`
			}

			parent.appendChild(anchor)
			topWindow.document.body.appendChild(parent)

			props.onCreate?.(popperNode.current, popperProperties, anchor)
			popper.current = new PopperJS(anchor, popperNode.current, popperProperties)
		}
	}

	// Remove any unused top window anchor divs
	useEffect(() => {
		return () => {
			topWindowAnchorParent.current?.remove()
		}
	}, [])

	const anchorElement = props.anchorElement?.current

	useEffect(() => {
		createPopper()
	}, [anchorElement])

	// install resize observer
	const onContentResize = useCallback(() => {
		popper.current?.update()
	}, [popperNode.current])

	useResizeObserver(onContentResize, popperNode)

	const onAnchorResize = useCallback(() => {
		popper.current?.scheduleUpdate()
	}, [popperNode.current])

	useResizeObserver(onAnchorResize, props.anchorElement)

	const destroyPopper = () => {
		if (!popper.current) {
			return
		}

		topWindowAnchorParent.current?.remove()

		popper.current.destroy()
		popper.current = undefined
	}

	useEffect(() => {
		if (props.open) {
			documents.forEach((document) => {
				props.onOutsideClick && document.defaultView?.addEventListener('mousedown', onMouseDown, props.captureEvents)
			})
		}

		return () => {
			documents.forEach((document) => {
				props.onOutsideClick && document.defaultView?.removeEventListener('mousedown', onMouseDown, props.captureEvents)
			})
		}
	}, [props.open, props.onOutsideClick, documents, onMouseDown])

	const onKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Tab' && onTabPressed) {
				const isTopmost = popperNode.current && isTopmostPortalElement(popperNode.current)
				if (isTopmost) {
					e.stopPropagation()
					onTabPressed(e)
				}
			} else if (e.key === 'Escape' && onEscapePressed) {
				const isTopmost = popperNode.current && isTopmostPortalElement(popperNode.current)
				if (isTopmost) {
					e.stopPropagation()
					onEscapePressed(e)
				}
			}
		},
		[onEscapePressed, onTabPressed]
	)

	useEffect(() => {
		topWindow.addEventListener('keydown', onKeyDown)

		return () => topWindow.removeEventListener('keydown', onKeyDown)
	}, [onKeyDown])

	const dataPortalAttr = useMemo(
		() => ({ [DATA_PORTAL_ELEMENT]: true, [DATA_BLOCKING_MODAL_ELEMENT]: props.blocking ? true : undefined }),
		[props.blocking]
	)

	const content = (
		<div
			className={classNames(classes.popper, props.className)}
			style={{ ...inheritedStyles, ...props.style, position: 'absolute', top: 0, left: 0 }}
			ref={popperNode}
			{...props.dataAttributes}
			{...dataPortalAttr}
		>
			{props.children}
			{props.arrowElement}
		</div>
	)

	const openContent = props.open ? content : null
	const wrapper = props.fade ? (
		<Fade
			show={props.open}
			onEntering={props.onEntering}
			onEntered={props.onEntered}
			onExiting={props.onExiting}
			onExited={() => {
				destroyPopper()
				props.onExited?.()
			}}
		>
			{content}
		</Fade>
	) : (
		openContent
	)

	const popperElement = <PopperContext.Provider value={popperChild}>{wrapper}</PopperContext.Provider>

	if (props.enablePortal && props.enableBackdrop) {
		if (!props.open) {
			return ReactDOM.createPortal(popperElement, topWindow.document.body)
		}
		return ReactDOM.createPortal(
			<div className={classNames(classes.layer)}>{popperElement}</div>,
			topWindow.document.body
		)
	}

	if (props.enablePortal) {
		return ReactDOM.createPortal(popperElement, topWindow.document.body)
	}

	return popperElement
}

Popper.defaultProps = {
	open: false,
	placement: e_Placement.bottom,
	enablePortal: false,
	autoFlip: true,
	captureEvents: true,
	fade: true,
}
