import React from 'react'
import ReactDOM from 'react-dom'

import { Snackbar } from '@genusbiz/web-ui/surfaces/Snackbar'

import { ISnackbarProps } from 'src/interfaces/IModalProps'
import { modalTypes } from './modalTypes'
import { createStyle } from '@genusbiz/web-ui/theming'

export interface ISnackbarModalProps extends ISnackbarProps {
	hideModal?: (type: modalTypes) => void
}

export interface ISnackbarArrayProps {
	snackbarModalProps: ISnackbarModalProps[]
}

const classes = createStyle((theme) => ({
	snackStack: {
		position: 'fixed',
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column-reverse',
		zIndex: theme.zIndex.popper,
		pointerEvents: 'none',
		alignItems: 'flex-end',
		top: 0,
		left: 0,
		paddingBottom: 8,
		paddingRight: 8,
	},
	'@keyframes bounce': {
		'0%': { transform: 'translateY(10px)', opacity: 0 },
	},
	bounce: {
		animationName: '$bounce',
		animationDuration: theme.transitions.duration.short,
		animationIterationCount: 1,
		animationDelay: '0s',
		transform: 'translateY(0px)',
		animationTimingFunction: theme.transitions.easing.easeOut,
		opacity: 1,
		maxWidth: '75%',
		marginTop: '4px',
	},
}))

export const SnackModal = (props: ISnackbarArrayProps) => {
	const snackbarStack = props.snackbarModalProps.map((snackbar, index) => {
		const handleCloseClick = () => {
			return snackbar.hideModal ? snackbar.hideModal(modalTypes.SNACKBAR) : undefined
		}

		snackbar.autoHideDuration = snackbar.autoHideDuration || 10000 // Default to 10 seconds
		const spanKey = 'span' + index
		const snackbarKey = 'snackbar' + index
		return (
			<span key={spanKey} className={classes.bounce}>
				<Snackbar
					key={snackbarKey}
					showCloseButton={snackbar.showCloseButton}
					onClose={handleCloseClick}
					autoHideDuration={snackbar.autoHideDuration}
					message={snackbar.message}
					iconClassName={snackbar.icon}
				/>
			</span>
		)
	})

	return ReactDOM.createPortal(<div className={classes.snackStack}>{snackbarStack}</div>, window.document.body)
}
