import React from 'react'
import { MasterDetailSurface } from '../MasterDetailSurface'
import { fiveHundredPersons } from './persons'
import type { IDataPropertyDescription } from '../types/IDataPropertyDescription'
import type { IDetailItemSurfaceProps } from '../types/IDetailItemSurfaceProps'
import type { IActionEntry } from '../types/IActionDescription'
import { e_actionType } from '../types/IActionDescription'
import type { IMasterViewColumnItemDescription } from '../types/IMasterViewColumnItemDescription'

const columnDescriptions: IDataPropertyDescription[] = [
	{ displayName: 'First Name', propertyId: 'firstName', sortable: true, datatype: 'string', widthHintPx: 100 },
	{ displayName: 'Last Name', propertyId: 'lastName', sortable: true, datatype: 'string', widthHintPx: 100 },
	{ displayName: 'Gender', propertyId: 'gender', sortable: true, datatype: 'string' },
	{ displayName: 'Phone', propertyId: 'phone', sortable: true, datatype: 'string', widthHintPx: 100 },
	{ displayName: 'Email', propertyId: 'email', sortable: true, datatype: 'email' },
	{ displayName: 'Birth Date', propertyId: 'birthDate', sortable: true, datatype: 'string' },
	{ displayName: 'Street', propertyId: 'street', sortable: true, datatype: 'string' },
	{ displayName: 'Street Number', propertyId: 'streetNumber', sortable: true, datatype: 'string' },
	{ displayName: 'Postal Code', propertyId: 'postalCode', sortable: true, datatype: 'string' },
	{ displayName: 'City', propertyId: 'city', sortable: true, datatype: 'string' },
	{ displayName: 'Registered', propertyId: 'registered', sortable: true, datatype: 'boolean' },
]

const columnGroupDescriptions: IMasterViewColumnItemDescription[] = [
	'firstName',
	'lastName',
	'gender',
	'phone',
	'email',
	'birthDate',
	{ groupId: 'address', displayName: 'Address', children: ['street', 'streetNumber', 'postalCode', 'city'] },
	'registered',
]

const PersonDetailSurface = (props: IDetailItemSurfaceProps) => {
	const person = props.dataItem

	return (
		<div style={{ padding: '8px' }}>
			<h1>{`${person['firstName']} ${person['lastName']}`}</h1>
			<div>Demo of detail surface for a single person.</div>
			<div>{`Current breakpoint: ${props.breakpoint ? props.breakpoint.name : 'unknown'}`}</div>
		</div>
	)
}

const globalActions: IActionEntry[] = [
	{ type: e_actionType.action, id: 'refresh', iconClassName: 'Fluent-Refresh', screenTip: 'Refresh' },
	{ type: e_actionType.separator },
	{ id: 'new', type: e_actionType.action, iconClassName: 'Fluent-Add', screenTip: 'New Person' },
]

const personItemActions: IActionEntry[] = [
	{ id: 'open', type: e_actionType.action, iconClassName: 'Fluent-OpenInNewWindow', screenTip: 'Open' },
	{ id: 'delete', type: e_actionType.action, iconClassName: 'Fluent-Cancel', screenTip: 'Delete' },
]
const personSelectedItemActions: IActionEntry[] = [
	{ id: 'archive', type: e_actionType.action, iconClassName: 'Fluent-Archive', screenTip: 'Archive' },
	{ id: 'share', type: e_actionType.action, iconClassName: 'SocialMedia-SocialShare', screenTip: 'Share' },
]

// eslint-disable-next-line react/no-multi-comp
export const MasterDetailSurfaceDemo = () => {
	const handleItemAction = (actionId: string, itemId: string) => {
		// eslint-disable-next-line no-console
		console.log('handleItemAction', actionId, itemId)
	}

	const handleSelectedItemsAction = (actionId: string, itemIds: string[]) => {
		// eslint-disable-next-line no-console
		console.log('handleSelectedItemsAction', actionId, itemIds)
	}

	const handleGlobalAction = (actionId: string) => {
		// eslint-disable-next-line no-console
		console.log('handleGlobalAction', actionId)
	}

	return (
		<MasterDetailSurface
			id="master-detail-demo"
			propertyDescriptions={columnDescriptions}
			columnGroups={columnGroupDescriptions}
			data={fiveHundredPersons}
			contentName={'Person'}
			contentNamePlural={'Persons'}
			itemName={(item) => `${item['firstName']} ${item['lastName']}`}
			avatarContent={(item) =>
				`${item['firstName']} ${item['lastName']}`
					.split(' ')
					.map((name) => name[0])
					.join('')
			}
			summaryContent={(item) => `${item['email']}`}
			detailComponent={PersonDetailSurface}
			itemActions={personItemActions}
			handleItemAction={handleItemAction}
			selectedItemsActions={personSelectedItemActions}
			handleSelectedItemsAction={handleSelectedItemsAction}
			globalActions={globalActions}
			handleGlobalAction={handleGlobalAction}
		/>
	)
}
