import React from 'react'

// AG-grid
import type { ICellRendererParams } from '@ag-grid-community/core'

// Components
import { CellSelectionCheckMark } from '../CellCheckMark'

// Interfaces and enums
import type { ICellRendererProps, TData } from '../../Table.types'

// Utils
import { createStyle } from '../../../../theming'
import { formatGroupCellValue } from '../../utils/formatGroupCellValue'

export interface IGroupCellRendererProps {
	multiSelect: boolean
}

const classes = createStyle((theme) => ({
	row: { fontWeight: theme.typography.button.fontWeight },
}))

export const GroupCellRenderer = (props: ICellRendererParams<TData> & IGroupCellRendererProps) => {
	const { node, formatValue, valueFormatted, value } = props

	const groupingFieldId = node.field ?? 'value'

	const colDef = props.columnApi.getColumn(groupingFieldId)?.getColDef()
	const cellRendererParams = colDef?.cellRendererParams as ICellRendererProps | undefined

	const formattedValue = formatValue ? formatValue(value) : formatGroupCellValue(props.data, valueFormatted, value)
	const headerName = colDef?.headerName

	const groupName =
		cellRendererParams?.prefixGroup && headerName !== undefined ? headerName + ': ' + formattedValue : formattedValue

	if (node.footer) {
		return null
	}

	return (
		<div
			style={{
				display: 'flex',
				height: '100%',
				alignItems: 'center',
				gap: 4,
			}}
			className={classes.row}
			title={`${groupName} ${node.allChildrenCount ?? 0})`}
		>
			{props.multiSelect && <CellSelectionCheckMark api={props.api} columnApi={props.columnApi} node={props.node} />}

			<div>{groupName}</div>
			{node.allChildrenCount !== null && <div>({node.allChildrenCount ?? 0})</div>}
		</div>
	)
}
