import React, { useState, useRef } from 'react'
import classNames from 'clsx'

import { Menu } from '../Menu/Menu'
import { Icon } from '../Icon'

import type { CommandBarItem } from './CommandBar.types'
import { e_ItemContentLayout, getContentLayout } from '../Menu/MenuItem.types'
import type { IContentLayout } from '../Menu/MenuItem.types'
import type { ICommandBarClasses } from './CommandBar'

interface ICommandBarSubmenuProps {
	classes: ICommandBarClasses
	name?: string
	id?: string
	iconClassName?: string
	iconColorClass?: string
	items?: CommandBarItem[]
	hidden?: boolean
	disabled?: boolean
	screenTip?: string
	size?: 'small' | 'medium'
	contentLayout?: IContentLayout
	onToggleIsOpen?: (isOpen: boolean) => void
	dataAttributes?: Record<string, string>
	showDropdownButton?: boolean
}

export const CommandBarSubmenu = (props: ICommandBarSubmenuProps) => {
	const { showDropdownButton = true } = props

	const [isOpen, setIsOpen] = useState(false)
	const buttonRef = useRef<HTMLDivElement>(null)

	const classes = classNames(props.classes.commandBarItem, {
		[props.classes.enabled]: !props.disabled,
		[props.classes.disabled]: props.disabled,
		[props.classes.active]: isOpen,
		[props.classes.commandBarItemSmall]: props.size === 'small',
	})

	const toggleDropdownMenu = (e: React.MouseEvent | React.KeyboardEvent) => {
		e.preventDefault()
		e.stopPropagation()
		if (props.disabled) {
			return
		}
		setIsOpen(!isOpen)

		props.onToggleIsOpen?.(!isOpen)
	}

	const handleCloseSubmenu = () => {
		setIsOpen(false)

		props.onToggleIsOpen?.(false)
	}

	const contentLayout = getContentLayout(props.contentLayout, 'CommandBar')

	const renderIconValue =
		props.iconClassName &&
		(!contentLayout || contentLayout === e_ItemContentLayout.icon || contentLayout === e_ItemContentLayout.iconAndText)
	const renderTextValue =
		props.name &&
		(!contentLayout || contentLayout === e_ItemContentLayout.text || contentLayout === e_ItemContentLayout.iconAndText)

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key === ' ') {
			e.preventDefault()
			e.stopPropagation()

			if (!props.disabled && toggleDropdownMenu) {
				toggleDropdownMenu(e)
			}
		}
	}

	return (
		<div
			title={props.screenTip}
			className={classes}
			id={props.id}
			{...props.dataAttributes}
			ref={buttonRef}
			onClick={toggleDropdownMenu}
			onKeyPress={handleKeyPress}
			tabIndex={!props.disabled ? 0 : undefined}
		>
			{renderIconValue && props.iconClassName && (
				<span
					className={classNames(
						{
							[props.classes.buttonIcon]: !renderTextValue,
							[props.classes.buttonIconWithText]: renderTextValue,
						},
						props.iconColorClass
					)}
				>
					<Icon iconClassName={props.iconClassName} />
				</span>
			)}
			{renderTextValue && <span>{props.name}</span>}
			{showDropdownButton && (
				<span className={props.classes.submenuIcon}>
					<i className="Fluent-ChevronDown" />
				</span>
			)}
			{props.items && (
				<Menu
					anchorEl={buttonRef}
					items={props.items}
					open={isOpen}
					onClose={handleCloseSubmenu}
					iconColorClass={props.iconColorClass}
					dataAttributes={{ 'data-cy': 'command-bar-submenu' }}
				/>
			)}
		</div>
	)
}
