import type { IKeyboardShortcut } from './IKeyboardShortcut'
import { e_keyCode } from './e_keyCode'

export const isSpecialKeyboardShortcut = (keyEvent: IKeyboardShortcut) => {
	const key = keyEvent.keyCode

	switch (key) {
		case e_keyCode.F1: // Help
		case e_keyCode.F3: // Search
		case e_keyCode.F5: // Refresh
		case e_keyCode.F6: // Focus address bar
		case e_keyCode.F7: // Toggle caret browsing
		case e_keyCode.F11: // Toggle fullscreen
		case e_keyCode.F12: // Toggele developer tools
			return true
	}

	if (keyEvent.ctrlOrMetaKey) {
		switch (key) {
			case e_keyCode.KeyN:
			case e_keyCode.KeyT:
			case e_keyCode.KeyW:
				return true
		}
	}

	if (keyEvent.altKey) {
		switch (key) {
			case e_keyCode.F4:
				return true
		}
	}

	return false
}
