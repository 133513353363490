import React, { useCallback, useMemo } from 'react'
import type { LexicalEditor } from 'lexical'
import { Dropdown } from '../../Dropdown'
import { defaultFontSize, defaultFontSizes } from '../plugins'
import { getHandleChangeFont } from './getHandleChangeFont'
import { useTranslation } from '../../../translation'

const fontSizeOptions = defaultFontSizes.map((fontSize) => ({
	label: String(fontSize),
	value: defaultFontSize === fontSize ? null : `${fontSize}pt`,
}))

const inheritFontSizeOptions = defaultFontSizes.map((fontSize) => ({
	label: String(fontSize),
	value: `${fontSize}pt`,
}))

export function useFontSizeDropdownRenderFn(
	editor: LexicalEditor,
	value: string,
	inheritTheme: boolean | undefined,
	isDisabled?: boolean
) {
	const handleChangeFontSize = getHandleChangeFont(editor, 'font-size')

	const { tcvi } = useTranslation()

	const options = useMemo(
		() =>
			inheritTheme ? [{ label: tcvi('GENERAL:INHERIT'), value: null }, ...inheritFontSizeOptions] : fontSizeOptions,
		[inheritTheme, tcvi]
	)

	return useCallback(
		(renderedIn: 'CommandBar' | 'Menu') => {
			const displayLabel = value ? `${parseInt(value)}` : tcvi('GENERAL:INHERIT')
			return (
				<span style={{ width: getDropdownWidth(renderedIn, inheritTheme) }}>
					<Dropdown
						options={options}
						value={value || `${defaultFontSize}pt`}
						onChange={handleChangeFontSize}
						disabled={isDisabled}
						stretch
						displayLabel={displayLabel}
						screenTip={`${tcvi('GENERAL:FONT_SIZE')}: ${displayLabel}`}
					/>
				</span>
			)
		},
		[handleChangeFontSize, inheritTheme, isDisabled, options, tcvi, value]
	)
}
function getDropdownWidth(renderedIn: 'CommandBar' | 'Menu', isInheritingTheme?: boolean) {
	if (renderedIn === 'Menu') {
		return '100%'
	}
	if (isInheritingTheme) {
		return '78px'
	}
	return '55px'
}
