import { useCallback, useMemo } from 'react'
import { useTranslation } from '../../../translation'

// Table
import { CellRendererWrapper } from '../components/CellRenderer/CellRendererWrapper'

// Utils
import { getAggFunctionKey } from '../utils/aggFunctions'
import { getCellClass, getCellStyle, useCellClassRules } from '../utils/stylingUtils'
import {
	getComparator,
	getFilterParams,
	getQuickFilterText,
	getTooltipValueGetter,
	getValueFormatter,
	suppressKeyboardEvent,
} from '../utils/colDefUtils'
import { isCellRendererNeeded } from '../utils/isCellRendererNeeded'

// Enums and Interfaces
import type { ICellEditingProps, ICellRendererProps, IColumnDef, ITableRowData, TData } from '../Table.types'
import { e_RenderType } from '../Table.types'
import type { ColDef, ColGroupDef } from '@ag-grid-community/core'
import { e_Interpretation } from '../enums/e_Interpretation'
import { e_DataType } from '../enums/e_DataType'
import { e_FilterOperator } from '../components/CellRenderer/cellRenderers/CellRendererDropdown'
import type { ITableColumnHeaderProps } from '../components/ColumnHeader/ColumnHeader'
import { getExcelStyleId } from '../utils/excelStyles'
import { isAvatarControl, isCheckMarkControl } from '../components/CellRenderer/cellRendererUtils'
import { readColumnStateFromSessionStorage } from '../utils/storageUtils'
import { e_TableColumnSummaryFunction } from '../enums/e_TableColumnSummaryFunction'
import type { MenuItem } from '../../Menu'

export const useColDefs = (
	id: string | undefined,
	columnDefs: IColumnDef[],
	clearCellOnDelete: boolean,
	contextMenuButton: ITableRowData['contextMenuButton'],
	getContextMenuItems:
		| ((id: string, columnId: string, contextMenuCallback: (contextMenuItems: MenuItem[]) => void) => void)
		| undefined,
	cellEditingProps: ICellEditingProps | undefined,
	singleClickEdit = false,
	disableColumnHeaderMenu = false,
	multiSelect = false,
	masterDetail = false,
	verticalHeader = false,
	disableRowClickSelection = false,
	compact = false,
	disableFilterAndSorting = false,

	enableTableGrouping = true
) => {
	const cellClassRules = useCellClassRules()

	const { tcvi } = useTranslation()

	const columnState = id ? readColumnStateFromSessionStorage(id)?.columnState : undefined

	const getColumnDef = useCallback(
		(columnDef: IColumnDef, isFirstColumn: boolean): ColDef<TData> | ColGroupDef<TData> => {
			const columnStateInSessionStorage = columnState?.find((cs) => cs.colId === columnDef.field)
			const isFirstLeafColumn = isFirstColumn && !columnDef.children?.length

			const {
				dataType = e_DataType.string,
				enableFilter = true,
				enableGrouping = true,
				fillVariant = 'default',
				flashCellsOnNewValue = false,
				initialWidth = 'fitToLabelAndContent',
				interpretation = e_Interpretation.string,
				prefixGroup = false,
				renderType = e_RenderType.text,
			} = columnDef

			const isAvatarCell = isAvatarControl(renderType)
			const isCheckmarkCell = isCheckMarkControl(renderType)
			const hasContextMenuButton = contextMenuButton === 'firstColumn' || contextMenuButton === 'inline'

			const needsCellRenderer = isCellRendererNeeded({
				isGroup: !!columnDef.rowGroup,
				hasCustomCellRenderer: !!columnDef.onRenderCell,
				hasOnClick: !!columnDef.onCellClick,
				renderType: renderType,
				hasIcon: !!columnDef.iconPlacement,
				multiSelect: multiSelect && isFirstColumn,
				editable: columnDef.readOnly === false,
				hasContextMenu: columnDef.hasContextMenu || hasContextMenuButton,
				interpretation,
				fillVariant,
			})

			const hasSummaryFunction = ![undefined, e_TableColumnSummaryFunction.none].includes(
				columnDef.columnSummaryFunction
			)

			const excelStyleId = getExcelStyleId(columnDef.numberFormat, columnDef.dataType, columnDef.format)

			const cellRendererParams: ICellRendererProps = {
				renderType,
				hasCustomCellRenderer: needsCellRenderer,
				iconPlacement: columnDef.iconPlacement,
				textAlignment: columnDef.textAlignment,
				hasColumnContextMenu: !!columnDef.hasContextMenu,
				lockColumnContextMenu: !!columnDef.lockContextMenu && !!columnDef.hasContextMenu,
				hasContextMenuButton,
				multiSelect,
				isFirstColumn: isFirstLeafColumn,
				getContextMenuItems,
				fillVariant,
				pillWidth: columnDef.pillWidth,
				singleClickEdit,
				clearCellOnDelete: clearCellOnDelete && !!columnDef.allowNull,
				onRenderCell: columnDef.onRenderCell,
				dataType: dataType,
				interpretation,
				formatOnExport: !!columnDef.formatOnExport,
				readOnly: columnDef.readOnly,
				avatarLabel: columnDef.avatarLabel,
				iconName: columnDef.iconName,
				iconColor: columnDef.iconColor,
				selectable: !disableRowClickSelection,
				compact,
				cellEditingProps,
				filterOperator: columnDef.controlProps?.filterOperator ?? e_FilterOperator.startsWith,
				onClick: columnDef.onCellClick,
				format: columnDef.format,
				allowNull: columnDef.allowNull,
				isPercentNumber: columnDef.isPercentNumber,
				formatCellValue: columnDef.formatValue,
				getCellDataOnRender: columnDef.getCellDataOnRender,
				cellClassName: columnDef.cellClassName,
				getCellClassNameOnRender: columnDef.getCellClassNameOnRender,
				// Store width (number/fitToContent/fitToLabelAndContent) as own property we can validate against
				widthFromProps: initialWidth,

				// Store min/max width as own properties we can revert to
				minWidthFromProps: columnDef.initialMinWidth,
				maxWidthFromProps: columnDef.initialMaxWidth,
				masterDetail,
				prefixGroup,
			}

			const headerComponentParams: ITableColumnHeaderProps = {
				...columnDef.headerComponentParams,
				disableMenu: disableColumnHeaderMenu,
				multiSelect,
				isVertical: verticalHeader,
				disableFilterAndSorting: !!columnDef.lockPosition || disableFilterAndSorting,
				screenTip: columnDef.screenTip,
			}

			const initialWidthFromStorage =
				columnStateInSessionStorage?.width ?? (typeof initialWidth === 'number' ? initialWidth : undefined)

			const colDef: ColDef<TData> | ColGroupDef<TData> = {
				autoHeight: columnDef.autoHeight,
				cellRendererParams,
				headerComponentParams,
				colId: columnDef.field,
				field: columnDef.field,
				suppressCellFlash: !flashCellsOnNewValue,
				headerName: columnDef.headerName,
				hide: columnDef.hide || columnDef.rowGroup,
				menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
				aggFunc: getAggFunctionKey(columnDef.columnSummaryFunction, columnDef.dataType),
				initialSort: columnDef.sort,
				initialSortIndex: columnDef.sortIndex,
				rowGroup: columnDef.rowGroup,
				rowGroupIndex: columnDef.rowGroupIndex,
				enableRowGroup: enableGrouping && enableTableGrouping,
				pinned: columnDef.pinned,
				lockPosition: columnDef.lockPosition,
				lockVisible: !!columnDef.lockPosition,
				resizable: !columnDef.lockPosition,
				sortable: !columnDef.lockPosition && !disableFilterAndSorting,
				filter: enableFilter ? 'agMultiColumnFilter' : false,
				filterParams: enableFilter
					? getFilterParams(
							columnDef.field,
							columnDef.dataType,
							interpretation,
							isCheckmarkCell,
							hasSummaryFunction,
							tcvi
					  )
					: undefined,
				width: typeof columnDef.width === 'number' ? columnDef.width : undefined,
				initialWidth: initialWidthFromStorage,
				maxWidth: !columnStateInSessionStorage?.width ? columnDef.initialMaxWidth : undefined,
				minWidth: !columnStateInSessionStorage?.width ? columnDef.initialMinWidth : undefined,
				comparator: getComparator(columnDef.dataType),
				tooltipValueGetter: getTooltipValueGetter(
					isAvatarCell,
					isCheckmarkCell,
					columnDef.getTooltip,
					columnDef.cellScreenTip,
					tcvi
				),
				valueFormatter: getValueFormatter(isCheckmarkCell, hasSummaryFunction, tcvi),
				cellRenderer: getCellRenderer(masterDetail, isFirstColumn, needsCellRenderer),
				cellStyle: getCellStyle(columnDef.textAlignment),
				cellClassRules,
				cellClass: getCellClass(columnDef.cellClassName, excelStyleId, columnDef.getCellClassNameOnRender),
				suppressKeyboardEvent,
				getQuickFilterText: getQuickFilterText,
				children: columnDef.children?.map((child, index) => ({
					...getColumnDef(child, isFirstColumn && index === 0),
				})),
			}

			return colDef
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	return useMemo(() => {
		contextMenuButton === 'lastColumn' &&
			columnDefs.push({
				field: 'ContextMenuLastColumn',
				renderType: e_RenderType.none,
				initialWidth: 26,
				textAlignment: 'center',
				headerComponentParams: { disableMenu: true },
				lockPosition: 'right',
			})

		return columnDefs.map((colDef, index) => getColumnDef(colDef, index === 0))
	}, [getColumnDef, columnDefs, contextMenuButton])
}
function getCellRenderer(masterDetail: boolean, isFirstColumn: boolean, needsCellRenderer: boolean) {
	if (masterDetail && isFirstColumn) {
		return 'agGroupCellRenderer'
	}
	if (needsCellRenderer) {
		return CellRendererWrapper
	}
}
