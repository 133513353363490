import { NumberInput } from '../../../../NumberInput'
import React, { useEffect, useRef } from 'react'
import type { ICellRenderer } from '../../../Table.types'

interface ICellRendererNumber extends ICellRenderer {
	value: number | null | undefined
	dataType: 'int' | 'float'
	isPercentNumber: boolean
}
export const CellRendererNumber = (props: ICellRendererNumber) => {
	const ref = useRef<HTMLInputElement>(null)

	useEffect(() => {
		ref.current?.focus()
		ref.current?.select()
	}, [])

	return (
		<NumberInput
			ref={ref}
			value={props.value}
			type={props.dataType}
			className={props.className}
			onChange={props.setValue}
			textAlignment="right"
			error={props.hasError}
			isPercentNumber={props.isPercentNumber}
		/>
	)
}
