import { useEventListener } from '../../../utils/useEventListener'
import type { ICellRendererParams } from '@ag-grid-community/core'
import { e_Interpretation } from '../../enums/e_Interpretation'
import { useCallback, useEffect } from 'react'
import type { CellData, ICellRendererProps, TData, Value } from '../../Table.types'
import { e_RenderType } from '../../Table.types'
import { focusNextCell, isCheckMarkControl, isTypingLetter } from './cellRendererUtils'
import { setNodeSelected } from '../../utils/setNodeSelected'

export const useCellRendererEventListeners = (
	nodeId: string,
	cellData: CellData | null | undefined,
	// cellValue: Value,
	value: Value,
	editingMode: boolean,
	editable: boolean,
	props: ICellRendererProps & ICellRendererParams<TData, CellData>,
	eGridCell: HTMLElement,
	pushChanges: (value: Value) => void,
	setEditingMode: (value: boolean) => void,
	onEscapePressed: () => void
) => {
	const isCheckMarkCell = isCheckMarkControl(props.renderType)

	const stopEditingAndFocusNextCell = useCallback(
		(e: KeyboardEvent, key: 'enter' | 'tab') => {
			if (editingMode) {
				pushChanges(value)
			}

			focusNextCell(props.api, props.column, key, e)
		},
		[editingMode, value, props.api, props.column, pushChanges]
	)

	const onEnterPress = useCallback(
		(e: KeyboardEvent) => {
			if (e.ctrlKey) {
				if (!editingMode) {
					isCheckMarkCell ? pushChanges(!value) : cellData?.onIconClick?.(nodeId)
				}

				return
			}

			if (editingMode) {
				stopEditingAndFocusNextCell(e, 'enter')
			} else {
				const hasClickableAction =
					!!cellData?.onIconClick || [e_Interpretation.email, e_Interpretation.phone].includes(props.interpretation)
				if (hasClickableAction) {
					if (props.interpretation === e_Interpretation.email && !!cellData?.value) {
						window.location.href = `mailto:${cellData?.value as string}`
					} else if (props.interpretation === e_Interpretation.phone && !!cellData?.value) {
						window.open(`tel:${cellData?.value as string}`)
					}
				}
			}
		},
		[
			editingMode,
			isCheckMarkCell,
			pushChanges,
			value,
			cellData,
			nodeId,
			stopEditingAndFocusNextCell,
			props.interpretation,
		]
	)

	const onKeyDown = useCallback(
		(e: KeyboardEvent) => {
			switch (e.key) {
				case 'Enter':
					onEnterPress(e)
					break
				case 'ArrowUp':
				case 'ArrowRight':
				case 'ArrowDown':
				case 'ArrowLeft':
					// Prevent AG Grid cell navigation when in editing mode
					editingMode && e.stopPropagation()
					break
				case 'Escape':
					if (editingMode) {
						e.preventDefault()

						setEditingMode(false)
						props.eGridCell.focus()

						// Reset to old value
						onEscapePressed()
					}
					break
				case 'Delete':
					if (editingMode) {
						props.clearCellOnDelete && pushChanges(undefined)
					}
					break
				case 'Tab':
					stopEditingAndFocusNextCell(e, 'tab')
					break
				case 'a':
					if (editingMode) {
						e.stopPropagation()
					}
			}

			if (editingMode || !editable || isCheckMarkCell) {
				return
			}

			// Start editing if a letter, number, symbol or F2 is pressed
			if ((isTypingLetter(e.code) && !e.ctrlKey) || (e.ctrlKey && e.key === ' ')) {
				e.stopPropagation()

				setNodeSelected(true, {
					node: props.node,
					gridApi: props.api,
					columnApi: props.columnApi,
					clearSelection: true,
				})

				setEditingMode(true)
			}
		},
		[
			editingMode,
			editable,
			isCheckMarkCell,
			onEnterPress,
			stopEditingAndFocusNextCell,
			setEditingMode,
			props.eGridCell,
			props.clearCellOnDelete,
			props.node,
			props.api,
			props.columnApi,
			onEscapePressed,
			pushChanges,
		]
	)

	const onPointerDown = (e: React.PointerEvent) => {
		if (!editable || !editingMode) {
			return
		}

		const isNotInAnchorNode = !eGridCell.contains(e.target as Node)

		if (isNotInAnchorNode) {
			setEditingMode(false)

			if (![e_RenderType.dropdown, e_RenderType.lookup].includes(props.renderType)) {
				pushChanges(value)
			}
		}
	}

	useEventListener('pointerdown', onPointerDown)

	useEffect(() => {
		props.eGridCell.addEventListener('keydown', onKeyDown)
		return () => {
			props.eGridCell.removeEventListener('keydown', onKeyDown)
		}
	}, [props.eGridCell, onKeyDown])
}
