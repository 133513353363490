import React from 'react'
import { Dialog } from './Dialog'
import { createStyle } from '../../theming'

const classes = createStyle({
	dialogContents: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		overflow: 'auto',
	},
})

interface IDialogProps {
	onYes: () => void
	onNo: () => void
	onCancel: () => void
	isValid?: boolean
}

export const DialogConfirmationMessage = (props: IDialogProps) => {
	const title = 'Confirm'

	const confirmationMessage = props.isValid
		? 'Do you want to save changes?'
		: 'Changes you have made are invalid. Do you want to resume editing?'

	const handleCloseDialog = (button: 'ok' | 'cancel' | 'yes' | 'no' | 'close') => {
		switch (button) {
			case 'yes': {
				props.isValid ? props.onYes() : props.onCancel()
				break
			}
			case 'no': {
				props.onNo()
				break
			}
			case 'cancel': {
				props.onCancel()
				break
			}
		}
	}

	return (
		<Dialog title={title} width={350} isOpen commonButtonSet={'yesNoCancel'} handleClose={handleCloseDialog}>
			<div className={classes.dialogContents}>{confirmationMessage}</div>
		</Dialog>
	)
}
