import React from 'react'
import { Icon } from '../../Icon'
import { createStyle } from '../../../theming'

const classes = createStyle((theme) => ({
	elementContainer: {
		margin: '4px',
		color: theme.palette.foreground.neutralDark,
	},
	outerContainer: { width: '100%', color: theme.colors.body.text },
	innerContainer: { display: 'flex', justifyContent: 'center', flexDirection: 'column' },
}))

interface IProps {
	icon?: string
	title?: string
	subtitle?: string
}

export const NoRowsOverlay = (props: IProps) => {
	const { subtitle = 'No rows to display' } = props
	return (
		<div className={classes.outerContainer}>
			<div className={classes.innerContainer}>
				{props.icon && <Icon iconClassName={props.icon} className={classes.elementContainer} size={'extraLarge'} />}
				{props.title && <b className={classes.elementContainer}>{props.title}</b>}
				{<i className={classes.elementContainer}>{subtitle}</i>}
			</div>
		</div>
	)
}
