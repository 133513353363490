import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import ReactDom from 'react-dom'
import { topWindow } from '../utils/topWindow'
import { FocusTrap } from '../utils/FocusTrap'
import { useForwardedRef } from '../utils/useForwardedRef'
import { createStyle } from '../../theming'
import { useEventListener } from '../utils/useEventListener'
import throttle from 'lodash/throttle'
import classNames from 'clsx'
import {
	DATA_BLOCKING_MODAL_ELEMENT,
	DATA_PORTAL_ELEMENT,
	isBehindBlockingElement,
	isTopmostPortalElement,
} from '../utils/portalUtils'

const MOBILE_WIDTH = 600

const formatPixel = (number: number) => {
	return `${number}px`
}

const classes = createStyle((theme) => ({
	modal: {
		position: 'fixed',
		zIndex: theme.zIndex.modal,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 1,
		transition: `opacity ${theme.transitions.duration.short}`,
		[`@media (max-width:${MOBILE_WIDTH}px)`]: {
			width: '100%!important',
			height: '100%!important',
			top: '0!important',
			left: '0!important',
			transform: 'none!important',
		},
		top: 0,
		left: 0,
		maxHeight: '100%',
		maxWidth: '100%',
	},

	modalDisablePositioning: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		pointerEvents: 'none',
	},

	modalContent: {
		height: '100%',
		outline: 'none',
	},

	beforeInitialized: {
		opacity: 0,
	},

	backdrop: {
		position: 'fixed',
		bottom: 0,
		right: 0,
		top: 0,
		left: 0,
		opacity: 1,
		transition: `opacity ${theme.transitions.duration.standard}`,

		background: theme.controls.dialog.backdropColor || theme.colors.modal.background,
		zIndex: theme.zIndex.modal,
	},

	backdropWithCustomRoot: {
		position: 'absolute',
	},

	invisibleBackdrop: {
		opacity: 0,
	},

	stayOnTop: {
		zIndex: `calc(${theme.zIndex.modal} + 1)`,
	},

	backdropStayOnTop: {
		pointerEvents: 'none',
	},
}))

interface IModalProps {
	fullScreen?: boolean
	isOpen: boolean
	ariaLabel?: string
	children: React.ReactNode
	hideBackdrop?: boolean
	stayOnTop?: boolean
	modalRootSelector?: string
	ref: React.Ref<HTMLDivElement>
	disablePositioning?: boolean
	backdropClassName?: string
	modalClassName?: string
	blocking?: boolean
	onBackdropClick?: (event: React.MouseEvent<HTMLElement>) => void
	onEscapePressed?: (event: React.KeyboardEvent<HTMLElement>) => void
}

const initializeAriaContentHidden = () => {
	const rootElement = topWindow.document.getElementById('app')

	if (!rootElement) {
		return
	}

	rootElement.setAttribute('aria-hidden', 'true')
}

const restoreContentAriaHidden = () => {
	const rootElement = topWindow.document.getElementById('app')

	if (!rootElement) {
		return
	}

	rootElement.removeAttribute('aria-hidden')
}

let openModalCount = 0

const setModalOpen = () => {
	openModalCount++

	if (openModalCount === 1) {
		initializeAriaContentHidden()
	}
}

const setModalClosed = () => {
	openModalCount--

	if (openModalCount === 0) {
		restoreContentAriaHidden()
	}
}

const establishDefaultCSSSize = (element: HTMLDivElement) => {
	const style = element.style

	const width = element.getBoundingClientRect().width
	const height = element.getBoundingClientRect().height

	const pixelWidth = formatPixel(width)
	const pixelHeight = formatPixel(height)

	if (pixelWidth !== style.getPropertyValue('--width')) {
		style.setProperty('--width', pixelWidth)
	}
	if (pixelHeight !== style.getPropertyValue('--height')) {
		style.setProperty('--height', pixelHeight)
	}
}

// @ts-ignore
const root = topWindow.parent?.__STORYBOOKAPI__
	? topWindow.document.getElementById('storybook-root') || topWindow.document.body
	: topWindow.document.body

export const Modal = React.forwardRef((props: IModalProps, forwardedRef: React.Ref<HTMLDivElement>) => {
	const ref = useForwardedRef<HTMLDivElement>(forwardedRef)

	const { onEscapePressed } = props

	const isModalOpen = useRef(false)
	const modal = useRef<HTMLDivElement>(null)

	const customRootElement = props.modalRootSelector
		? topWindow.document.querySelector(props.modalRootSelector)
		: undefined
	const modalRootElement = customRootElement || root

	const [playFadeIn, setPlayFadeIn] = useState(true)
	const isUnresolvedPosition = useRef(true)

	useEffect(() => {
		isModalOpen.current = props.isOpen

		if (props.isOpen) {
			setModalOpen()
		} else {
			if (ref.current) {
				// avoid setting the counter on load
				setModalClosed()
			}
			isUnresolvedPosition.current = true
		}
	}, [props.isOpen, ref])

	useEffect(() => {
		return () => {
			// when unmounted, we may experience cases where unmount happens instead of toggling open prop
			if (isModalOpen.current) {
				setModalClosed()
			}
		}
	}, [])

	const onKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLElement>) => {
			if (!props.isOpen) {
				return
			}

			if (e.key === 'Escape' && onEscapePressed) {
				const isTopmost = ref.current && isTopmostPortalElement(ref.current)
				if (isTopmost) {
					e.stopPropagation()
					onEscapePressed(e)
				}
			}
		},
		[onEscapePressed, props.isOpen]
	)

	const onBackdropClick = (e: React.MouseEvent<HTMLElement>) => {
		if (props.blocking) {
			return
		}

		if (modal.current && isBehindBlockingElement(modal.current)) {
			return
		}

		props.onBackdropClick?.(e)
	}

	const onResize = throttle(() => {
		const modalRef = ref.current
		if (!modalRef) {
			return
		}

		if (topWindow.innerWidth <= MOBILE_WIDTH) {
			return
		}

		isUnresolvedPosition.current && resolvePosition(modalRef)

		const style = modalRef.style

		if (style.getPropertyValue('width') === '') {
			style.setProperty('--width', `${modalRef.getBoundingClientRect().width}px`)
		}
		if (style.getPropertyValue('height') === '') {
			style.setProperty('--height', `${modalRef.getBoundingClientRect().height}px`)
		}
	}, 100)

	useEventListener('resize', onResize, topWindow)

	const dataPortalAttr = useMemo(
		() => ({ [DATA_PORTAL_ELEMENT]: true, [DATA_BLOCKING_MODAL_ELEMENT]: props.blocking ? true : undefined }),
		[props.blocking]
	)

	const refTransform = isUnresolvedPosition.current
		? `translate(${topWindow.innerWidth / 2}px, ${topWindow.innerHeight / 2}px) translate(-50%, -50%)`
		: undefined

	const resolvePosition = (element: HTMLDivElement) => {
		const transform = window.getComputedStyle(element).transform
		const transformX = parseInt(transform.split(', ')[4])
		const transformY = parseInt(transform.split(', ')[5])

		const style = element.style

		style.setProperty('--left', `${transformX}px`)
		style.setProperty('--top', `${transformY}px`)

		style.setProperty('--width', `${element.getBoundingClientRect().width}px`)
		style.setProperty('--height', `${element.getBoundingClientRect().height}px`)

		style.transform = ''
		style.left = `clamp(0px, var(--left), calc(100% - var(--width)))`
		style.top = `clamp(0px, var(--top), calc(100% - var(--height)))`
		isUnresolvedPosition.current = false
	}

	if (ref.current) {
		establishDefaultCSSSize(ref.current)
	}

	const fixSubpixelTranslation = (element: HTMLDivElement) => {
		const transform = window.getComputedStyle(element).transform
		const transformX = parseFloat(transform.split(', ')[4])
		const transformY = parseFloat(transform.split(', ')[5])

		const subpixelTranslationX = transformX % 1
		const subpixelTranslationY = transformY % 1

		if (subpixelTranslationX || subpixelTranslationY) {
			element.style.transform =
				element.style.transform + ` translate(${-subpixelTranslationX}px, ${-subpixelTranslationY}px)`
		}
	}

	const resolvePositionOnPointerInteraction = (element: HTMLDivElement) => {
		const listener = () => {
			if (topWindow.innerWidth <= MOBILE_WIDTH) {
				return
			}
			isUnresolvedPosition.current && resolvePosition(element)
			element.removeEventListener('pointerdown', listener)
		}

		element.addEventListener('pointerdown', listener)
	}

	useLayoutEffect(() => {
		const modalRef = ref.current

		if (!modalRef || !props.isOpen) {
			return
		}

		resolvePositionOnPointerInteraction(modalRef)
		setPlayFadeIn(false)
	}, [props.isOpen, props.modalRootSelector])

	if (ref.current) {
		fixSubpixelTranslation(ref.current)
	}

	return props.isOpen
		? ReactDom.createPortal(
				<>
					<div
						role="dialog"
						aria-modal="true"
						ref={modal}
						className={classNames(
							classes.backdrop,
							customRootElement && classes.backdropWithCustomRoot,
							props.stayOnTop && classes.stayOnTop,
							props.stayOnTop && props.hideBackdrop && classes.backdropStayOnTop,
							(playFadeIn || props.hideBackdrop) && classes.invisibleBackdrop,

							props.backdropClassName
						)}
						onMouseDown={onBackdropClick}
					/>
					<div
						aria-label={props.ariaLabel}
						{...dataPortalAttr}
						className={classNames(
							classes.modal,
							props.disablePositioning && classes.modalDisablePositioning,
							props.stayOnTop && classes.stayOnTop,
							playFadeIn && classes.beforeInitialized,
							props.modalClassName
						)}
						ref={ref}
						style={{ transform: refTransform }}
					>
						<FocusTrap>
							<div tabIndex={-1} className={classes.modalContent} onKeyDown={onKeyDown}>
								{props.children}
							</div>
						</FocusTrap>
					</div>
				</>,
				modalRootElement
		  )
		: null
})

Modal.displayName = 'Modal'
