import classNames from 'clsx'

import type { CellClassParams, ColDef, RowClassParams, CellStyleFunc, CellStyle } from '@ag-grid-community/core'
import { createStyle } from '../../../theming'
import { e_TableColumnTextAlignment } from '../enums/e_TableColumnTextAlignment'
import { CELL_PADDING, ROW_HEIGHT, ROW_HEIGHT_COMPACT } from '../consts'
import type { CellData, ICellRendererProps, TData } from '../Table.types'

const classes = createStyle((theme) => ({
	bold: { fontWeight: 'bold' },
	italic: { fontStyle: 'italic' },
	underline: { textDecoration: 'underline' },
	strikethrough: { textDecoration: 'line-through' },
	underlineAndStrikethrough: { textDecoration: 'line-through underline' },
	error: { borderColor: `${theme.palette.error.errorDark} !important` },
}))

const getIsBoldOrIsItalic = (property: 'isBold' | 'isItalic') => (params: CellClassParams<TData>) => {
	const cellData = params.value as CellData
	const rowData = params.data as TData

	const cellValue = cellData?.[property]

	return !!(cellValue === true || (cellValue === 'inherit' && rowData?.[property]))
}

// Both `underline` and `strikethrough` are set using same CSS Property (`textDecoration`).
// Thus, it needs a bit more complex calculation and is handled in separate function.
export const getTextDecoration =
	(className: 'underline' | 'strikethrough' | 'underlineAndStrikethrough') =>
	(params: { colDef?: ColDef<TData>; value: CellData; data: TData | undefined }) => {
		if (!params.value || !params.data) {
			return false
		}

		// eslint-disable-next-line destructuring/no-rename
		const { hasUnderline: cellHasUnderline, hasStrikethrough: cellHasStrikethrough } = params.value
		// eslint-disable-next-line destructuring/no-rename
		const { hasUnderline: rowHasUnderline, hasStrikethrough: rowHasStrikethrough } = params.data
		const hasUnderline = cellHasUnderline === true || (cellHasUnderline === 'inherit' && rowHasUnderline)
		const hasStrikethrough =
			cellHasStrikethrough === true || (cellHasStrikethrough === 'inherit' && rowHasStrikethrough)

		let textDecoration: string | undefined = undefined
		if (hasUnderline && hasStrikethrough) {
			textDecoration = 'underlineAndStrikethrough'
		} else if (hasUnderline) {
			textDecoration = 'underline'
		} else if (hasStrikethrough) {
			textDecoration = 'strikethrough'
		}

		return textDecoration === className
	}

// Rules which can be applied to include certain CSS classes.
export const useCellClassRules = () => {
	return {
		[classes.bold]: getIsBoldOrIsItalic('isBold'),
		[classes.italic]: getIsBoldOrIsItalic('isItalic'),
		[classes.underline]: getTextDecoration('underline'),
		[classes.strikethrough]: getTextDecoration('strikethrough'),
		[classes.underlineAndStrikethrough]: getTextDecoration('underlineAndStrikethrough'),
		[classes.error]: (params: CellClassParams<TData, CellData>) => !!params.value?.error,
	}
}

// Callback version of property rowStyle to set style for each row individually. Function should return an object of CSS values or undefined for no styles.
export const getRowStyle = (compact: boolean, isUsingAutoHeight: boolean) => (params: RowClassParams<TData>) => {
	return {
		background: params.data?.background ?? '',
		color: params.data?.color ?? 'inherit',
		lineHeight: `${(compact ? ROW_HEIGHT_COMPACT : ROW_HEIGHT) - 2 - CELL_PADDING * 2}px`,
		...(isUsingAutoHeight ? {} : { height: `${compact ? ROW_HEIGHT_COMPACT : ROW_HEIGHT}px` }),
	}
}

// Callback version of property `rowClass` to set class(es) for each row individually.
// Function should return either a string (class name), array of strings (array of class names) or undefined for no class.
export const getRowClass =
	(rowClassName?: string, getRowClassNameOnRender?: (nodeId: string | undefined) => string | undefined) =>
	(params: RowClassParams<TData>) => {
		return classNames(rowClassName, getRowClassNameOnRender?.(params.node.id))
	}

export const getCellClass =
	(
		cellClassName: string | undefined,
		excelStyleId: string | undefined,
		getCellClassNameOnRender?: (nodeId: string | undefined, colId: string | undefined) => string | undefined
	) =>
	(params: CellClassParams<TData>) => {
		if (params.node.footer || params.node.group) {
			return
		}
		const fillCell = ['default', undefined].includes(
			(params.colDef.cellRendererParams as ICellRendererProps).fillVariant
		)

		return fillCell
			? classNames(excelStyleId, cellClassName, getCellClassNameOnRender?.(params.node.id, params.colDef.colId))
			: excelStyleId
	}

// An object of css values / or function returning an object of css values for a particular cell.
export const getCellStyle =
	(textAlignment: 'right' | 'left' | 'center' | undefined): CellStyleFunc<TData, CellData> =>
	(params: CellClassParams<TData, CellData>) => {
		const fillVariant = (params.colDef.cellRendererParams as ICellRendererProps).fillVariant

		const fillCell = ['default', undefined].includes(fillVariant)

		const background = params.value?.background
		const color = params.value?.color

		const cellStyle: CellStyle = {
			display: !fillCell ? 'flex' : 'inline-block',
			alignItems: 'center',
			paddingTop: CELL_PADDING,
			paddingBottom: CELL_PADDING,
			justifyContent: textAlignment ?? e_TableColumnTextAlignment.left,
			paddingLeft: CELL_PADDING,
			paddingRight: CELL_PADDING,
			color: color ?? 'inherit',
			textAlign: textAlignment ?? e_TableColumnTextAlignment.left,
			textDecoration: 'inherit',
			lineHeight: 'inherit',
			gap: 4,
		}

		if (fillCell && background) {
			cellStyle.background = background
		}

		return cellStyle
	}
